import { AddCircle, AlternateEmail, Person, PersonSearch } from "@mui/icons-material";
import { Box, ListItemButton, Stack, Typography } from "@mui/material";
import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    MutationDefinition,
    QueryDefinition
} from "@reduxjs/toolkit/dist/query";
import { UseMutation, UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { CreateInCollectionButton } from "../../components/CreateInCollectionButton";
import EasyFormColumn from "../../components/Forms/EasyFormColumn";
import EasyFormMany from "../../components/Forms/EasyFormMany";
import { AutocompleteFieldRender } from "../../components/Forms/Renders/AutocompleteFieldRender";
import { FieldEditorRender } from "../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender } from "../../components/Forms/Renders/TextFieldRender";
import { TextRenderer } from "../../components/Forms/Renders/TextRenderer";
import { OpenInNewTabButton } from "../../components/OpenInNewTabButton";
import TooltipComponent from "../../components/TooltipComponent";
import Loading from "../../components/loading";
import { PaginationQueryPackage } from "../../models/API/QueryParams/PaginationQueryPackage";
import { RequestMutationWrapper } from "../../models/API/RequestMutationWrapper";
import { AttachContactRequest, CombinedUserContact, ContactResponse } from "../../models/Contact";
import { ContactLabel, LabelContextEnum } from "../../models/ContactLabel";
import { UserResponse } from "../../models/User";
import { CreateResponse } from "../../redux/CreateResponse";
import { GetManyPackage } from "../../redux/GetManyPackage";
import { PermissionEnum } from "../Permission/PermissionEnum";
import { AbilityContext } from "../UserApi/logic/Can";
import { CreateExternalContactButton } from "./CreateExternalContactButton";
import { contactLabelsApiSlice, useGetAllContactLabelsQuery } from "./redux/contactLabelApiSlice";
import {
    useUpdateClientContactMutation,
    useUpdateOrganisationContactMutation,
    useUpdateServiceContactMutation
} from "./redux/contactsApiSlice";
import { OrganisationContext } from "../../components/Layout/OrganisationPicker";


export interface ContactFormProps {
    entityUuid: string
    ownerOrganisationUuid: string
    administratingOrganisationUuid?: string
    users: UserResponse[]
    externalContacts: ContactResponse[]
    context: LabelContextEnum
    useRemoveContactFromEntity: UseMutation<MutationDefinition<RequestMutationWrapper<ContactResponse>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, void, "portalapi">>
    useGetContactsByEntityUuid: UseQuery<QueryDefinition<GetManyPackage<object>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, ContactResponse[], "portalapi">>
    useAttachContact: UseMutation<MutationDefinition<RequestMutationWrapper<AttachContactRequest>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, CreateResponse, "portalapi">>
}

export class ContactResponseView extends ContactResponse {
    labels?: ContactLabel[]
}

export function ContactForm(props: ContactFormProps) {
    const { t } = useTranslation()
    const allContactlabelsResult = useGetAllContactLabelsQuery({
        pagination: new PaginationQueryPackage()
    })

    const ability = useContext(AbilityContext)
    const currentOrganisation = useContext(OrganisationContext)?.organisation

    const [organisationContacts, setOrganisationContacts] = useState<ContactResponseView[]>([])
    const [createContactWindowIsOpen, setCreateContactWindowIsOpen] = useState<boolean>(false);
    const [contactUuidToPreSelect, setContactUuidToPreselect] = useState<string | undefined>(undefined)
    const [emailForNewExternalContact, setEmailForNewExternalContact] = useState<string>("")

    const allContactsForOrganisationResult = props.useGetContactsByEntityUuid({
        pagination: new PaginationQueryPackage(),
        uuid: props.entityUuid
    });
    const labelsForUserLazy = contactLabelsApiSlice.useLazyGetContactLabelsForContactQuery();
    const labelsForUserLazyTrigger = labelsForUserLazy[0]

    const [loading, setLoading] = useState(false)

    const labelsForContext = allContactlabelsResult.data?.filter(label => {
        return label.label_contexts?.includes(props.context)
    })

    useMemo(() => {

        let allPromses: Promise<ContactResponseView>[] = []
        allPromses = allContactsForOrganisationResult.data?.map(async contact => {
            const contactView = contact as ContactResponseView;

            const labels = await labelsForUserLazyTrigger({
                pagination: new PaginationQueryPackage(),
                uuid: contact.uuid
            }).unwrap();
            contactView.labels = labels ?? [];

            return contactView;
        }) ?? []

        setLoading(true)
        Promise.all(allPromses).then(contactViews => setOrganisationContacts(contactViews)).finally(() => setLoading(false))

    }, [JSON.stringify(allContactsForOrganisationResult.data)])

    if (loading || allContactsForOrganisationResult.isLoading || labelsForUserLazy[1].isLoading || allContactlabelsResult.isLoading)
        return <Loading />

    const getContactRows = (contacts: CombinedUserContact[] | undefined) => {
        const rows: Array<FieldEditorRender[]> = []
        contacts?.forEach((contact) => {
            const row = getSingleRowContactRows(contact);
            const linkToContact = new TextRenderer({
                displayText: "",
                fieldName: "",
                nestedfieldName: "",
                uniqueId: contact?.uuid ?? "",
                render: () =>
                    contact?.user_uuid != undefined ?
                        <Box textAlign="center"><OpenInNewTabButton icon={<PersonSearch/>}
                            tooltipText={t("Open user attached to contact")}
                            url={`/users/${contact?.user_uuid}`} /></Box> :
                        <Box textAlign="center"><OpenInNewTabButton icon={<AlternateEmail/>}
                            tooltipText={t("Open contact without user")}
                            url={`/contacts/${contact?.external_contact_uuid}`} /></Box>
            });

            const firstName = new TextFieldRender({
                displayText: "First name",
                fieldName: "",
                nestedfieldName: "first_name",

                type: "text",
                uniqueId: contact?.uuid ?? "",
            });

            const lastName = new TextFieldRender({
                displayText: "Last name",
                fieldName: "",
                nestedfieldName: "last_name",

                type: "text",
                uniqueId: contact?.uuid ?? "",
            });

            const email = new TextFieldRender({
                displayText: "Email",
                fieldName: "",
                nestedfieldName: "email",

                type: "text",
                uniqueId: contact?.uuid ?? "",
            });

            rows.push([linkToContact, firstName, lastName, email, ...row])
        })
        return rows;
    }

    const getSingleRowContactRows = (contact: CombinedUserContact | undefined) => {
        return [
            new AutocompleteFieldRender({
                displayText: "Contact labels",
                fieldName: "",
                nestedfieldName: "labels",
                multipleSelect: true,
                isOptionEqualToValue: (option, value) => option.uuid === value.uuid,
                getOptionLabelFunc: (option: ContactLabel) => option.label ?? "",
                options: labelsForContext ?? [],
                uniqueId: contact?.uuid ?? "",
            }),
        ]
    }

    const allUsersModified = props.users?.map<CombinedUserContact>(user => ({
        uuid: user.uuid,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        user_uuid: user.uuid
    }))

    const allContactsModified = props.externalContacts?.map<CombinedUserContact>(contact => ({
        uuid: contact.uuid,
        email: contact.email,
        first_name: undefined,
        last_name: undefined,
        user_uuid: undefined,
        external_contact_uuid: contact.external_contact_uuid
    }));

    const allowedContacts = [...allUsersModified ?? [], ...allContactsModified ?? []]

    let editMutation = useUpdateServiceContactMutation;
    let isAllowedToWrite = false;

    switch (props.context) {
        case LabelContextEnum.SERVICE:
            isAllowedToWrite = ability.can(PermissionEnum.SERVICE_WRITE, { uuid: props.ownerOrganisationUuid })
                || ability.can(PermissionEnum.SERVICE_WRITE, { uuid: props.administratingOrganisationUuid })
            editMutation = useUpdateServiceContactMutation
            break;
        case LabelContextEnum.ORGANISATION:
            isAllowedToWrite = ability.can(PermissionEnum.ORGANISATION_WRITE, { uuid: props.ownerOrganisationUuid })
                || ability.can(PermissionEnum.ORGANISATION_WRITE, { uuid: props.administratingOrganisationUuid })
            editMutation = useUpdateOrganisationContactMutation
            break;
        case LabelContextEnum.CLIENT:
            isAllowedToWrite = ability.can(PermissionEnum.CLIENT_WRITE, { uuid: props.ownerOrganisationUuid })
                || ability.can(PermissionEnum.CLIENT_WRITE, { uuid: props.administratingOrganisationUuid })
            editMutation = useUpdateClientContactMutation
            break;
    }

    const findContactToAutoSelect = (contacts: CombinedUserContact[]) => {
        return contacts.find(contact => contact.uuid === contactUuidToPreSelect);
    }

    return (
        <>

            <EasyFormMany
                confirmDeletionText="Are you sure you wish to remove this contact?"
                key={JSON.stringify(organisationContacts)}
                isAllowedToDelete={() => isAllowedToWrite}
                isAllowedToEdit={() => isAllowedToWrite}
                isAllowedToCreate={() => isAllowedToWrite}
                ressourceOwnerId={props.entityUuid!}
                arrayPath=""
                editButtonJsx={(contact: ContactResponse) =>
                    <CreateInCollectionButton
                        validationScheme={Yup.object().shape({})}
                        toEdit={contact}
                        ressourceOwnerId={props.entityUuid}
                        fieldEditorRenders={getSingleRowContactRows(contact)}
                        tooltip="Edit contact"
                        createNew={() => {
                            throw new Error("Dont create new here")
                        }}
                        saveMutation={editMutation} />
                }
                initialValue={organisationContacts}
                getManyByIdAction={props.useGetContactsByEntityUuid}
                saveMutation={editMutation}
                deleteMutation={props.useRemoveContactFromEntity}
                createButtonJsx={
                    <CreateInCollectionButton
                        key="createContactAttachment"
                        validationScheme={Yup.object().shape({
                            contact: Yup.object().required(t("Required")).typeError(t("Required"))
                        })}
                        ressourceOwnerId={props.entityUuid!}
                        afterSaveAction={() => {
                            setContactUuidToPreselect(undefined);
                        }
                        }
                        fieldEditorRenders={[
                            new AutocompleteFieldRender<CombinedUserContact>({
                                displayText: t("Contacts"),
                                fieldName: "contact",
                                multipleSelect: false,
                                valueToAutoSelect: findContactToAutoSelect(allowedContacts),
                                filterOptions: (options, state) => {
                                    return options.filter(x => state.inputValue == "" ? true : x.email!.toLowerCase().includes(state.inputValue.toLowerCase()))
                                },
                                getOptionLabelFunc: (option: CombinedUserContact) => option.email ?? "",
                                options: allowedContacts ?? [],
                                renderOption: (_props, option) => (
                                    <Stack alignItems="center" direction="row" spacing={2}>
                                        {option.user_uuid != undefined ?
                                            <TooltipComponent title={t("Contact with user") + ""}><Person /></TooltipComponent> :
                                            <TooltipComponent title={t("Contact without user") + ""}><AlternateEmail /></TooltipComponent>
                                        }
                                        <Typography>{option.email}</Typography>
                                    </Stack>
                                ),
                                noOptionsText: (input: string) => (
                                    <ListItemButton onClick={() => {
                                        setEmailForNewExternalContact(input);
                                        setCreateContactWindowIsOpen(true);
                                    }}>
                                        <Stack spacing={1} direction="row" alignItems="center">
                                            <AddCircle />
                                            <Typography alignItems="center"><>{t("Create contact")}</>
                                            </Typography>
                                        </Stack>
                                    </ListItemButton>
                                ),

                                uniqueId: "no_id"
                            }),
                            new AutocompleteFieldRender({
                                displayText: "Contact labels",
                                fieldName: "labels",
                                multipleSelect: true,
                                getOptionLabelFunc: (option: ContactLabel) => option.label ?? "",
                                options: labelsForContext ?? [],
                                uniqueId: "no_id",
                            }),
                        ]}
                        tooltip="Attach contact"
                        createNew={() => {
                            const attachContactRequest = new AttachContactRequest();
                            setContactUuidToPreselect(undefined);
                            return attachContactRequest;
                        }}
                        saveMutation={props.useAttachContact} />
                }
                SignupSchema={Yup.object().shape({})}
                idToFetch={props.entityUuid!}
                columns={[
                    new EasyFormColumn("Contacts", "", [])
                ]}
                getFieldRows={getContactRows}
                newButtonTooltip="Add new contact"
            />
            {
                !createContactWindowIsOpen ? <></> :
                    <CreateExternalContactButton
                        ownerOrganisationUuid={currentOrganisation?.uuid ?? props.ownerOrganisationUuid}
                        open={true}
                        initialValue={emailForNewExternalContact}
                        afterSaveAction={(response) => {
                            const uuid = (response as CreateResponse).uuid;
                            setContactUuidToPreselect(uuid);
                            setCreateContactWindowIsOpen(false)
                        }
                        }
                        onClose={() => {
                            setCreateContactWindowIsOpen(false);
                        }
                        } />
            }
        </>
    )
}