import { FieldArray, FormikProps } from 'formik';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import EasyFormColumn from '../../../components/Forms/EasyFormColumn';
import { FormType } from '../../../components/Forms/EasyFormMany';
import { EasyFormRow } from '../../../components/Forms/FormLayout';
import { FieldEditorRender } from '../../../components/Forms/Renders/FieldEditorRender';
import { TextFieldRender } from '../../../components/Forms/Renders/TextFieldRender';
import { IsIpAddressValidation } from '../../../components/Forms/Validations/IsIpAddressValidation';
import { CreateHostingSiteRequest } from '../../../models/Hostingsite';
import { Subnet } from '../../../models/Subnet';

export function getSubnetsValidationScheme(t: TFunction<"translation", undefined>) {
    return Yup.object().shape({
        subnets: Yup.array().required(t('Required')).of(Yup.object().shape({
            subnet: IsIpAddressValidation(true)
        }))
    });
}

function getRows(
    hostingSiteRequest: CreateHostingSiteRequest
): Array<FieldEditorRender[]> {

    const rows: Array<FieldEditorRender[]> = []

    hostingSiteRequest.subnets?.forEach((subnet) => {
        const newRow: FieldEditorRender[] = [
            new TextFieldRender({
                displayText: "IP scope",
                showLabel: true,
                fieldName: "subnets",
                nestedfieldName: "subnet",
                type: "text",
                columns: true,
                uniqueId: subnet.uuid!
            })
        ]
        rows.push(newRow)
    })
    return rows;
}

export default function SubnetsStep(props: { formikProps: FormikProps<CreateHostingSiteRequest>, formPropsStuf: any }) {
    const { t } = useTranslation();
    return (
        <FieldArray
            name="subnets"
            render={arrayHelpers => (
                <EasyFormRow
                    confirmDeletionText={t("Are you sure you wish to remove this subnet?")}
                    {...props.formikProps}
                    {...props.formPropsStuf}
                    editModeAlways={true}
                    editable={true}
                    isDeleteable={true}
                    saveMutation={() => { throw "Dont save plz" }}
                    formType={FormType.LIST}
                    createNew={() => new Subnet()}
                    editMode={true}
                    getFieldRows={(serviceRequest) => getRows(serviceRequest as CreateHostingSiteRequest)}
                    columns={[
                        new EasyFormColumn("Subnets", "", [])
                    ]}
                    arrayHelpers={arrayHelpers} />
            )
            }
        />


    )
}