import { FormikProps } from "formik";
import { OrganisationBadge } from "../../../components/Badges/OrganisationBadge";
import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import { EasyFormSideBySide } from "../../../components/Forms/FormLayout";
import { TextFieldRender } from "../../../components/Forms/Renders/TextFieldRender";
import { TextRenderer } from "../../../components/Forms/Renders/TextRenderer";
import { CreateHostingSiteRequest } from "../../../models/Hostingsite";
import Organisation from "../../../models/Organisation";

export default function HostingSiteInfoStep(props: { formikProps: FormikProps<CreateHostingSiteRequest>, formPropsStuf: any }) {
    return (
        <EasyFormSideBySide
            validateOnMount
            {...props.formikProps}
            {...props.formPropsStuf}
            columns={[
                new EasyFormColumn("Information", "", [
                    new TextRenderer({
                        displayText: "Organisation",
                        fieldName: "organisation",
                        uniqueId: "no_id",
                        render: (content) => <OrganisationBadge ressource={content as Organisation} />
                    }),
                    new TextFieldRender({
                        displayText: "Name",
                        fieldName: "name",
                        type: "text",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "Address",
                        fieldName: "address",
                        type: "text",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "SDN V4 ID",
                        fieldName: "sdn_v4_id",
                        type: "text",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "Theft mark",
                        fieldName: "theft_mark",
                        type: "text",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "Vlan name",
                        fieldName: "vlan_name",
                        type: "text",
                        uniqueId: "no_id"
                    }),
                ]),
            ]}
            editMode={true} />
    )
}