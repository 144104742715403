
import getEnvironment from '../../../env'
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper';
import { ServiceApproverRequest } from '../../../models/Service';
import { UserResponse } from '../../../models/User';
import { CreateResponse } from '../../../redux/CreateResponse';
import HandleQuery from '../../../redux/EndpointQueryHandler';
import { GetManyPackage } from '../../../redux/GetManyPackage';
import handleResponse from '../../../redux/handleResponse';
import { portalApiSlice } from '../../../redux/portalApiSlice';
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse';

const baseurl = getEnvironment().REACT_APP_API_BASEURL;
// Define a endpoint using a base URL and expected endpoints
export const serviceApproversApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllServiceApprovers: builder.query<UserResponse[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/service-approvers`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Service approvers could not be fetched" }),
        pagination : pack.pagination
      }),
      transformResponse: (a: UserResponse[], b, c) => transformMultipleResponseToRightType(UserResponse, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'service-approvers',
        ...result.map(({ uuid }) => ({ type: 'service-approvers', uuid: uuid }) as const)
      ]
    }),
    getServiceApproverById: builder.query<UserResponse, string>({
      query: (uuid) => HandleQuery({
        url: `${baseurl}/service-approvers/${uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Service approver could not be fetched" }),
      }),
      transformResponse: (a: UserResponse, b, c) => transformSingleResponseToRightType(UserResponse, a, b, c),
      providesTags: (_result, _error, uuid) => [{ type: 'service-approvers', uuid: uuid }]
    }),
    getServiceApproversByServiceId: builder.query<UserResponse[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/services/${pack.uuid}/service-approvers`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Service approvers could not be fetched" }),
        pagination : pack.pagination
      }),
      transformResponse: (a: UserResponse[], b, c) => transformMultipleResponseToRightType(UserResponse, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'service-approvers',
        ...result.map(({ uuid }) => ({ type: 'service-approvers', uuid: uuid }) as const)
      ]
    }),
    updateServiceApprover: builder.mutation<UserResponse, RequestMutationWrapper<ServiceApproverRequest>>({
      query: (organisation) => HandleQuery({
        url: `${baseurl}/service-approvers/${organisation.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Service approver was updated!", toastErrorText: "Service approver could not be updated" }),
        method: "PATCH",
        body: organisation.data
      }),
      transformResponse: (a: UserResponse, b, c) => transformSingleResponseToRightType(UserResponse, a, b, c),
      invalidatesTags: (_result, _error, args) => [{ type: 'service-approvers', uuid: args.data.uuid }]
    }),
    addServiceApprover: builder.mutation<CreateResponse, RequestMutationWrapper<ServiceApproverRequest>>({
      query: (serviceApproverRequest) => HandleQuery({
        url: `${baseurl}/services/${serviceApproverRequest.parentId}/service-approvers`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Service approver was created", toastErrorText: "Service approver could not be created" }),
        method: 'POST',
        body: {
          user_uuid : serviceApproverRequest.data.approver?.uuid
        },
      }),
      transformResponse: (a: UserResponse, b, c) => transformSingleResponseToRightType(UserResponse, a, b, c),

      invalidatesTags: ['service-approvers'],
    }),
    deleteServiceApprover: builder.mutation<void, RequestMutationWrapper<UserResponse>>({
      query: (serviceApproverRequest) => HandleQuery({
        url: `${baseurl}/services/${serviceApproverRequest.parentId}/service-approvers/${serviceApproverRequest.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Service approver was deleted!", toastErrorText: "Service approver could not be deleted" }),
      }),
      
      invalidatesTags: (_result, _error, args) => [{ type: 'service-approvers', uuid: args.data.uuid }]
    }),
  }),
})


export const { useGetAllServiceApproversQuery, useGetServiceApproverByIdQuery, useDeleteServiceApproverMutation, useUpdateServiceApproverMutation, useGetServiceApproversByServiceIdQuery, useAddServiceApproverMutation } = serviceApproversApiSlice
















