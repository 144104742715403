import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganisationBadge } from "../../components/Badges/OrganisationBadge";
import { normaliseFieldrenders } from "../../components/CreateButtonProps";
import DialogMenuItem from "../../components/DialogMenuItem";
import EasyForm from "../../components/Forms/EasyForm";
import EasyFormColumn from "../../components/Forms/EasyFormColumn";
import { FormType } from "../../components/Forms/EasyFormMany";
import { AutocompleteFieldRender } from "../../components/Forms/Renders/AutocompleteFieldRender";
import { PaginationQueryPackage } from "../../models/API/QueryParams/PaginationQueryPackage";
import HostingsiteBase, { HostingsiteResponse } from "../../models/Hostingsite";
import { OrganisationResponse } from "../../models/Organisation";
import { useGetAllOrganisationsQuery } from "../OrganisationApi/redux/organisationApiSlice";
import { useMoveOrganisationsHostingsiteMutation } from "./hostingsitesApiSlice";
interface moveHostingSiteProps {
    hostingSite: HostingsiteResponse
}

export const MoveHostingSite = (props: moveHostingSiteProps) => {

    const organisationsResult = useGetAllOrganisationsQuery({
        pagination: new PaginationQueryPackage()
    });

    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    const newHostingSite = new HostingsiteBase();
    newHostingSite.owner_organisation = props.hostingSite.owner_organisation;
    newHostingSite.administrating_organisation = props.hostingSite.administrating_organisation;

    const getMoveHostingsiteRows = () => {
        return [
            new AutocompleteFieldRender({
                displayText: "Owner organisation",
                fieldName: "",
                getOptionLabelFunc: (option: OrganisationResponse) => option.name!,
                renderOption: (_props, option) => <OrganisationBadge ressource={option as OrganisationResponse} />,
                nestedfieldName: "owner_organisation",
                options: organisationsResult.data ?? [],
                uniqueId: crypto.randomUUID()
            }),
            new AutocompleteFieldRender({
                displayText: "Administration organisation",
                fieldName: "",
                getOptionLabelFunc: (option: OrganisationResponse) => option.name!,
                renderOption: (_props, option) => <OrganisationBadge ressource={option as OrganisationResponse} />,
                nestedfieldName: "administrating_organisation",
                options: organisationsResult.data ?? [],
                uniqueId: crypto.randomUUID()
            }),
        ];
    }

    return (

        <DialogMenuItem
            buttonContent={
                <>
                    <SyncAltIcon />
                    {t("Change organisations")}</>
            }
            open={open}
            setOpen={setOpen}
            key={JSON.stringify(props.hostingSite)}>
            <EasyForm
                isAllowedToEdit={() => true}
                editModeAlways={true}
                arrayPath=""
                ressourceOwnerId={props.hostingSite.owner_organisation?.uuid}
                SignupSchema={undefined}
                initialValue={props.hostingSite}
                columns={[
                    new EasyFormColumn("", "", normaliseFieldrenders(getMoveHostingsiteRows()))
                ]}
                formType={FormType.INFORMATION}
                getFieldRows={() => []}
                saveMutation={useMoveOrganisationsHostingsiteMutation}
                createNew={() => { throw new Error("Dont create new here") }}
                afterSaveAction={() => {
                    setOpen(false)
                }}
            />
        </DialogMenuItem>
    );
}