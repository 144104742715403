import { Laptop } from "@mui/icons-material";
import Client from "../../models/Client";
import { OpenInNewTabButton } from "../OpenInNewTabButton";
import { BadgeProps, BaseBadge } from "./BaseBadge";

export function ClientBadge(props: BadgeProps<Client>) {
    if (props.textOverride)
        return <BaseBadge {...props} content={<>{props.textOverride}</>} icon={Laptop} />

    const content = props?.ressource?.name
    const linkIcon = <BaseBadge deepContent {...props} content={<>{content}</>} icon={Laptop} />
    return <OpenInNewTabButton url={`/clients/${props?.ressource?.uuid}`} icon={linkIcon} padding={0} />
}