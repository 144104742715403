import { AddCircle } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { useContext, useState } from "react";
import * as Yup from 'yup';
import { CreateEntityButton } from "../../components/CreateEntityButton";
import EasyStepper from "../../components/EasyStepper";
import { OrganisationContext } from "../../components/Layout/OrganisationPicker";
import AgreementRequest from "../../models/Agreement";
import { CriticalityEnum } from "../../models/enums/CriticalityEnum";
import { CreateResponse } from "../../redux/CreateResponse";
import { PermissionEnum } from "../Permission/PermissionEnum";
import { Can } from "../UserApi/logic/Can";
import AgreementInfoStep from "./createAgreementFlow/AgreementInfoStep";
import AgreementSimpleVisualiser from "./createAgreementFlow/AgreementSimpleVisualiser";
import PickClientsStep from "./createAgreementFlow/PickClientsStep";
import PickServiceStep from "./createAgreementFlow/PickServiceStep";
import { useAddAgreementRequestMutation } from "./redux/agreementsApiSlice";

export const CreateAgreementButton = () => {
    const [open, setOpen] = useState<boolean>(false)
    const organisationContext = useContext(OrganisationContext)?.organisation

    return (
        <Can I={PermissionEnum.CLIENT_WRITE} a={organisationContext} >
            <CreateEntityButton tooltip="Create agreement request" open={open} setOpen={setOpen} buttonIcon={<AddCircle />}>
                <CreateAgreementButtonContent afterSaveAction={() => { setOpen(false) }} />
            </CreateEntityButton>
        </Can >
    )
}

export interface CreateAgreementButtonContentProps {
    afterSaveAction: (agreement: CreateResponse) => void
}

export const CreateAgreementButtonContent = (props: CreateAgreementButtonContentProps) => {

    const [initialAgreement] = useState(new AgreementRequest())
    const createAgreement = useAddAgreementRequestMutation();
    const createAgreementTrigger = createAgreement[0];


    const pickServiceValidation = Yup.object().shape({
        services: Yup.array().min(1,t("You have to select at least one service")).required(t("Required"))
    })
    const pickClientsValidation = Yup.object().shape({
        clients: Yup.array().min(1,t("You have to select at least one client")).required(t("Required")),
    })
    const infoScheme = Yup.object().shape({
        criticality: Yup.mixed().oneOf(Object.values(CriticalityEnum)).required(t("Required")),
        description: Yup.string().required(t('Required')),
    })

    async function submitFunc(values: AgreementRequest) {
        await createAgreementTrigger({
            data: values
        }).unwrap()
            .then(result => props.afterSaveAction(result as CreateResponse))
            .catch()
    }

    function NextStepFunc(currentPage: number) {
        return currentPage + 1
    }
    function PreviousStepFunc(currentPage: number) {
        return currentPage - 1
    }

    return (
        <>

            <Box>
                <Grid container justifyContent="center">

                    <Grid item xs={12}>
                        <Typography variant="h6">{t("Request new agreement") + ""}</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">

                    <Grid item xs={10}>
                        <EasyStepper
                            nextPage={NextStepFunc}
                            previousPage={PreviousStepFunc}
                            submit={submitFunc}
                            initialValue={initialAgreement}
                            stepValidations={[
                                pickServiceValidation,
                                pickClientsValidation,
                                infoScheme,
                            ]}
                            stepTitles={[
                                "Pick services",
                                "Pick clients",
                                "Agreement info",

                            ]}
                            steps={[
                                PickServiceStep,
                                PickClientsStep,
                                AgreementInfoStep,
                            ]}>
                            {(formik) => (
                                <AgreementSimpleVisualiser formikprops={formik} />
                            )}
                        </EasyStepper>
                    </Grid>
                </Grid>

            </Box>

        </>
    )
}