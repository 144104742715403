import { useContext, useState } from "react";
import * as Yup from 'yup';
import { CreateInCollectionButton } from "../../../../components/CreateInCollectionButton";
import ErrorCodeHook from "../../../../components/DTO/ErrorCodeHook";
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import EasyFormMany from "../../../../components/Forms/EasyFormMany";
import { AutocompleteFieldRender } from "../../../../components/Forms/Renders/AutocompleteFieldRender";
import { FieldEditorRender } from "../../../../components/Forms/Renders/FieldEditorRender";
import { IpRender } from "../../../../components/Forms/Renders/IpRender";
import { IsIpAddressInSubnet, subnetToStringArray } from "../../../../components/Forms/Validations/IsIpAddressValidation";
import Loading from "../../../../components/loading";
import { ErrorCodeEnum } from "../../../../models/API/ErrorCodeEnum";
import { PaginationQueryPackage } from "../../../../models/API/QueryParams/PaginationQueryPackage";
import { IpAddress, IpAddressRequest } from "../../../../models/Client";
import { Subnet } from "../../../../models/Subnet";
import { useGetSubnetsByHostingsiteIdQuery } from "../../../OrganisationApi/redux/subnetApiSlice";
import { PermissionEnum } from "../../../Permission/PermissionEnum";
import { AbilityContext } from "../../../UserApi/logic/Can";
import { useGetClientByIdQuery } from "../../redux/clientsApiSlice";
import { useAddIpAddressForceMutation, useAddIpAddressMutation, useDeleteIpAddressMutation, useGetIpAddressesByClientQuery, useUpdateIpAddressMutation } from "../../redux/ipAddressApiSlice";
import { ClientTabProps } from "../ClientInfoButton";


export default function IpAddressesTab(props: ClientTabProps) {
    const ability = useContext(AbilityContext)
    const [selectedSubnet, setSelectedSubnet] = useState<string | undefined>(undefined)

    const clientResult = useGetClientByIdQuery(props.client_id);
    const hostingSiteUuid = clientResult.data?.hosting_site?.uuid
    const subnets = useGetSubnetsByHostingsiteIdQuery(
        { uuid: hostingSiteUuid, pagination: new PaginationQueryPackage() },
        { skip: hostingSiteUuid === undefined });

    const ipOptions = selectedSubnet ? subnetToStringArray(selectedSubnet) : [];

    if (clientResult.isLoading || subnets.isLoading)
        return <Loading />

    const SignupSchema = Yup.object().shape({
        subnet: Yup.object().nullable().required(),
        ipaddress: IsIpAddressInSubnet("subnet")
    });
    const getRows = (ipAddresses: IpAddress[]) => {
        const rows: Array<FieldEditorRender[]> = []
        ipAddresses?.forEach((ipaddress) => {
            const newRow: FieldEditorRender[] = [
                new AutocompleteFieldRender({
                    displayText: "SDN Subnet",
                    fieldName: "",
                    nestedfieldName: "subnet",
                    columns: "auto",
                    minWidth: 300,
                    showLabel: true,
                    options: subnets.data ?? [],
                    onChange: (subnet: Subnet) => setSelectedSubnet(subnet?.subnet),
                    getOptionLabelFunc: (option: Subnet) => option.subnet ?? "",
                    uniqueId: ipaddress.uuid
                }),
                new IpRender({
                    nestedfieldName: "ipaddress",
                    options: ipOptions,
                    uniqueId: ipaddress.uuid,
                }),
            ]

            rows.push(newRow)
        })
        return rows;
    }

    const errorToHook: ErrorCodeHook<IpAddress>[] = [];
    errorToHook.push(new ErrorCodeHook<IpAddress>(ErrorCodeEnum.OPERATION_REQUIRES_FORCE, "Ip address is already in use", "Create ip address anyways?", useAddIpAddressForceMutation));

    return (
        <>
            <EasyFormMany
                isAllowedToDelete={() => ability.can(PermissionEnum.CLIENT_WRITE, clientResult.data)}
                isAllowedToEdit={() => ability.can(PermissionEnum.CLIENT_WRITE, clientResult.data)}
                isAllowedToCreate={() => ability.can(PermissionEnum.CLIENT_WRITE, clientResult.data)}
                ressourceOwnerId={props.client_id}
                arrayPath=""
                confirmDeletionText="Are you sure you wish to delete this ip address?"
                getManyByIdAction={useGetIpAddressesByClientQuery}
                saveMutation={useUpdateIpAddressMutation}
                deleteMutation={useDeleteIpAddressMutation}
                createButtonJsx={
                    <CreateInCollectionButton
                        errorToMutation={errorToHook}
                        validationScheme={SignupSchema}
                        ressourceOwnerId={props.client_id}
                        fieldEditorRenders={getRows([new IpAddressRequest()]).find(() => true)!}
                        tooltip="Create ipaddress"
                        createNew={() => {
                            const ipAddress = new IpAddressRequest();
                            ipAddress.client = clientResult.data
                            return ipAddress;
                        }}
                        saveMutation={useAddIpAddressMutation} />
                }
                editButtonJsx={(ipAddress: IpAddress) => {
                    return <CreateInCollectionButton
                        validationScheme={SignupSchema}
                        toEdit={ipAddress}
                        onOpen={() => setSelectedSubnet(ipAddress.subnet?.subnet)}
                        createNew={() => { throw new Error("Dont create new here") }}
                        ressourceOwnerId={props.client_id}
                        fieldEditorRenders={getRows([ipAddress]).find(() => true)!}
                        tooltip="Edit ipaddress"
                        saveMutation={useUpdateIpAddressMutation} />
                }}

                SignupSchema={SignupSchema}
                idToFetch={props.client_id}
                columns={[
                    new EasyFormColumn("IP addresses", "", [])
                ]}
                getFieldRows={getRows}
                newButtonTooltip="Add new Ip Address"
            />
        </>
    )
}
