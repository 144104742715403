
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@emotion/react';
import { WifiOff } from '@mui/icons-material';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { StrictMode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { theme } from './Theme';
import BigLoading from './components/BigLoading';
import { AuthorisationContext } from './components/Layout/AuthorisationSelector';
import Layout from './components/Layout/Layout';
import { OrganisationContext } from './components/Layout/OrganisationPicker';
import getEnvironment from './env';
import { AgreementInfoContent } from './feature/AgreementApi/agreementInfoDialog/AgreementInfoButton';
import { AuthorisationDetailsContent } from './feature/AuthorisationApi/authorisationInfoDialog/AuthorisationDetailsButton';
import { ClientInfoContent } from './feature/ClientApi/clientInfoDialog/ClientInfoButton';
import { ExternalContactInfoContent } from './feature/ContactsApi/externalContactInfoButton/ExternalContactInfoButton';
import { HostingSiteInfoContent } from './feature/HostingSiteApi/hostingSiteInfoDialog/HostingSiteInfoButton';
import keycloak from './feature/Keycloak/Keycloak';
import PrivateRoute from './feature/Keycloak/PrivateRoute';
import { OrganisationInfoContent } from './feature/OrganisationApi/organisationButtons/details/OrganisationInfoButton';
import { organisationApiSlice, useGetAllAllowedOrganisationsQuery } from './feature/OrganisationApi/redux/organisationApiSlice';
import { NotCorrectRights } from './feature/Permission/NotCorrectRights';
import { ServiceInfoContent } from './feature/ServiceApi/serviceInfoDialog/ServiceInfoButton';
import { AbilityContext } from './feature/UserApi/logic/Can';
import { GetJWTToken, JWTToken, UserContext } from './feature/UserApi/logic/FetchUser';
import { useAddMyselfUserMutation, usersApiSlice } from './feature/UserApi/redux/userApiSlice';
import { userRoleApiSlice } from './feature/UserApi/redux/userRoleApiSlice';
import { UserDetailsContent } from './feature/UserApi/userDetailsButton/UserDetailsButton';
import { PaginationQueryPackage } from './models/API/QueryParams/PaginationQueryPackage';
import { AuthUser } from './models/AuthUser';
import OrganisationResponse from './models/Organisation';
import { Role, SelfResponse, UserAuthorisationResponse } from './models/User';
import { AgreementRequestsPage } from './pages/agreementRequests';
import { AgreementsPage } from './pages/agreements';
import { AuthorisationPage } from './pages/authorisations';
import { ClientsPage } from './pages/clients';
import { ContactLabelPage } from './pages/contactlabels';
import { ContactPage } from './pages/contacts';
import { EventsPage } from './pages/events';
import { HostingSitePage } from './pages/hostingSites';
import { OrganisationPage } from './pages/organisations';
import { PortAliasesPage } from './pages/portaliases';
import { ServiceWindowPage } from './pages/serviceWindows';
import { ServicePage } from './pages/services';
import { UserPage } from './pages/users';
import store from './redux/store';
import { IpInfoPage } from './pages/ipInfo';
import { useGetServicesByOrganisationAndApproverQuery } from './feature/ServiceApi/redux/serviceApiSlice';
import { ServiceResponse } from './models/Service';

export let AuthorisationToUseInHeader: UserAuthorisationResponse | undefined = undefined
export let SetAuthorisationToUseInHeader: (newAuthorisation: UserAuthorisationResponse | undefined) => void

function App() {

    const [user, setUser] = useState<AuthUser | undefined>(undefined)

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [organisation, setOrganisation] = useState<OrganisationResponse | undefined>(undefined);
    const [jwtToken, setJwtToken] = useState<JWTToken | undefined>(undefined);

    const userAuthorisationInUseInLocalStorage = localStorage.getItem('userAuthorisationInUse');
    const authorisationInUse = userAuthorisationInUseInLocalStorage == null ? undefined : JSON.parse(userAuthorisationInUseInLocalStorage);
    AuthorisationToUseInHeader = authorisationInUse;

    const getOrganisationLazy = organisationApiSlice.useLazyGetOrganisationByIdQuery();
    const getOrganisationLazyTrigger = getOrganisationLazy[0]


    SetAuthorisationToUseInHeader = (newAuthorisation) => {
        localStorage.setItem("userAuthorisationInUse", JSON.stringify(newAuthorisation));

        if (newAuthorisation == undefined) {
            localStorage.removeItem("userAuthorisationInUse")
        }
        
        window.location.reload();
        window.location.href = "/";
    };


    const { t, i18n } = useTranslation()

    const getAllowed = useGetAllAllowedOrganisationsQuery({ pagination: new PaginationQueryPackage() })

    const getUserRolesLazy = userRoleApiSlice.useLazyGetUserRolesQuery()
    const getUserRolesLazyTrigger = getUserRolesLazy[0]

    const createMyselfLazy = useAddMyselfUserMutation()
    const createMyselfLazyTrigger = createMyselfLazy[0]

    const getUserByTokenLazy = usersApiSlice.useLazyGetUserByTokenQuery();
    const getUserByTokenTrigger = getUserByTokenLazy[0];

    const keycloak = useKeycloak();

    const approverServicesResult = useGetServicesByOrganisationAndApproverQuery({
        pagination: new PaginationQueryPackage(),
        uuid: organisation?.uuid
    }, { skip: organisation === undefined });
    const approverServices = approverServicesResult.data ?? [];

    useMemo(async () => {
        if (keycloak.initialized) {
            const jwt = await GetJWTToken(keycloak.keycloak)
            setJwtToken(jwt)
            getUserRolesLazyTrigger();
        }
    }, [keycloak.initialized])


    useMemo(async () => {
        const userRoleDataResult = getUserRolesLazy[1];
        if (jwtToken && userRoleDataResult.isSuccess) {
            let selfResponse: SelfResponse | undefined = undefined

            const userResponseResult = await getUserByTokenTrigger();
            selfResponse = userResponseResult.data;

            const userDoesNotExistInApi = userResponseResult.error && (userResponseResult.error as FetchBaseQueryError).status == 403

            if (userDoesNotExistInApi) {
                await createMyselfLazyTrigger();
                const userResponseResult = await getUserByTokenTrigger();
                selfResponse = userResponseResult.data;
            }

            const authUser = new AuthUser();
            if (selfResponse) {

                authUser.email = selfResponse.email
                authUser.first_name = selfResponse.first_name!
                authUser.last_name = selfResponse.last_name!
                authUser.keycloak_uuid = selfResponse.keycloak_uuid
                authUser.uuid = selfResponse.uuid
                authUser.authorisations = selfResponse.authorisations
                authUser.role = authorisationInUse?.role ?? selfResponse.role;

                const userOrganisationUuid = authorisationInUse?.organisation_uuid ?? selfResponse.organisation?.uuid;
                authUser.organisation = (await getOrganisationLazyTrigger(userOrganisationUuid)).data
                authUser.permissions = userRoleDataResult.data?.find(perm => perm.name == authUser.role?.toString());
            }

            setUser(authUser)
            setOrganisation(authUser.organisation)
            setIsLoading(false)
        }
    }, [jwtToken?.sub, getUserRolesLazy[1].isSuccess, authorisationInUse?.uuid])

    useMemo(() => {
        localStorage.setItem('lastActvity', new Date().getTime() + "")
    }, []);

    document.addEventListener("click", () => {
        localStorage.setItem('lastActvity', new Date().getTime() + "")
    });

    const timeInterval = setInterval(() => {
        const lastAcivity = localStorage.getItem('lastActvity')
        const maxInactivity = getEnvironment().GET_INACTIVITY_MAX_MINUTES()
        const diffMs = Math.abs(new Date(parseInt(lastAcivity!)).getTime() - new Date().getTime()); // milliseconds between now & last activity
        const seconds = Math.floor((diffMs / 1000));
        const minute = Math.floor((seconds / 60));
        if (minute >= maxInactivity) {
            console.log(`No activity in last ${maxInactivity} minutes... Logging Out`)
            clearInterval(timeInterval)
            //code for logout or anything...
            keycloak.keycloak.logout()
        }

    }, 5000)


    const params = new URLSearchParams(window.location.search)
    const newLanguage = params.has("lng") ? params.get("lng") as string : "da"
    if (i18n.language != newLanguage)
        i18n.changeLanguage(newLanguage)

    if (getUserRolesLazy[1].isError)
        return (
            <Card>
                <CardContent>
                    <Stack alignItems="center" textAlign="center" direction="row" spacing={2}>
                        <Typography>{<WifiOff color="primary" fontSize="large" />}</Typography>
                        <Typography variant="h6">{t("Something went wrong with connection to api")}</Typography>
                    </Stack>
                </CardContent>
            </Card>
        )

    if (user == undefined || getUserRolesLazy[1].isLoading || isLoading || getAllowed.isLoading)
        return <BigLoading />

    if (!keycloak.keycloak.authenticated)
        return <NotCorrectRights />

    return (
        <ThemeProvider theme={theme}>
            <Router key={authorisationInUse?.uuid}>
                <OrganisationContext.Provider value={{ organisation: organisation, setOrganisation: (organisation) => setOrganisation(organisation) }}>
                    <AuthorisationContext.Provider value={{ userAuthorisationInUse: authorisationInUse, setUserAuthorisationInUse: SetAuthorisationToUseInHeader }}>
                        <UserContext.Provider value={user}>
                            <AbilityContext.Provider value={user.getAbility()}>
                                <Layout>
                                    <>
                                        <Routes>
                                            <Route path="/" element={getMainPage(user.role, approverServices)} />
                                            <Route path="/organisations" element={<PrivateRoute><OrganisationPage /></PrivateRoute>} />
                                            <Route path="/organisations/:organisationUuid" element={<PrivateRoute><OrganisationInfoContent shouldGetOrganisationFromUrl={true} /></PrivateRoute>} />
                                            <Route path="/hosting-sites" element={<PrivateRoute><HostingSitePage /></PrivateRoute>} />
                                            <Route path="/hosting-sites/:hostingSiteUuid" element={<PrivateRoute><HostingSiteInfoContent shouldGetOrganisationFromUrl={true} /></PrivateRoute>} />
                                            <Route path="/agreements" element={<AgreementsPage />} />
                                            <Route path="/contactlabels" element={<ContactLabelPage />} />
                                            <Route path="/agreements/:agreementUuid" element={<AgreementInfoContent shouldGetOrganisationFromUrl={true} />} />
                                            <Route path="/services" element={<ServicePage />} />
                                            <Route path="/services/:serviceUuid" element={<ServiceInfoContent shouldGetOrganisationFromUrl={true} />} />
                                            <Route path="/clients" element={<ClientsPage />} />
                                            <Route path="/clients/:clientUuid" element={<ClientInfoContent shouldGetOrganisationFromUrl={true} />} />
                                            <Route path="/portaliases" element={<PortAliasesPage />} />
                                            <Route path="/users" element={<UserPage />} />
                                            <Route path="/users/:userUuid" element={<UserDetailsContent shouldGetOrganisationFromUrl={true} />} />
                                            <Route path="/contacts" element={<ContactPage />} />
                                            <Route path="/contacts/:contactUuid" element={<ExternalContactInfoContent shouldGetOrganisationFromUrl={true} />} />
                                            <Route path="/events" element={<EventsPage />} />
                                            <Route path="/service-windows" element={<ServiceWindowPage />} />
                                            <Route path="/agreementrequests" element={<AgreementRequestsPage />} />
                                            <Route path="/authorisations" element={<AuthorisationPage />} />
                                            <Route path="/authorisations/:authorisationUuid" element={<AuthorisationDetailsContent shouldGetOrganisationFromUrl={true} />} />
                                            <Route path="/ipinfo" element={<IpInfoPage />} />
                                        </Routes>

                                    </>
                                </Layout>
                            </AbilityContext.Provider>
                        </UserContext.Provider>
                    </AuthorisationContext.Provider>
                </OrganisationContext.Provider>
            </Router>
        </ThemeProvider>
    )
}

const AppWrapper = () => {
    return (
        <Provider store={store}>
            <ReactKeycloakProvider
                initOptions={{
                    onLoad: 'login-required',
                    checkLoginIframe: false
                }}
                authClient={keycloak}
                autoRefreshToken={true}
            >
                <StrictMode>
                    <App />
                    <ToastContainer closeButton={true} limit={3} position="bottom-right" />
                </StrictMode>
            </ReactKeycloakProvider>
        </Provider >
    )
}

function getMainPage(role: Role | undefined, approverServices: ServiceResponse[]): React.ReactNode {
    switch (role) {
        case Role.ORG_ACCESS_PROSPECT:
            return approverServices.length > 0 ? <AgreementRequestsPage/> : <></>
        case Role.ORG_USER_READ_ONLY:
        case Role.ORG_USER_ADMIN:
            return <UserPage />
        default:
            return <ServicePage />
    }
}


export default AppWrapper;

