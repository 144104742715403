import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Button, ButtonGroup, Grid } from "@mui/material";
import { FieldArray, Form, Formik } from 'formik';
import { t } from "i18next";
import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { ServiceBadge } from "../../../components/Badges/ServiceBadge";
import { ServiceTagBadge } from '../../../components/Badges/ServiceTagBadge';
import DrawerMenuItem from "../../../components/DrawerMenuItem";
import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import { FormType } from "../../../components/Forms/EasyFormMany";
import { EasyFormRow, EasyFormSideBySide } from '../../../components/Forms/FormLayout';
import { AutocompleteFieldRender } from "../../../components/Forms/Renders/AutocompleteFieldRender";
import { FieldEditorRender } from '../../../components/Forms/Renders/FieldEditorRender';
import { IpRender } from '../../../components/Forms/Renders/IpRender';
import { TextRenderer } from "../../../components/Forms/Renders/TextRenderer";
import { IsIpAddressInSubnet, subnetToStringArray } from '../../../components/Forms/Validations/IsIpAddressValidation';
import Loading from "../../../components/loading";
import getEnvironment from '../../../env';
import { PaginationQueryPackage } from '../../../models/API/QueryParams/PaginationQueryPackage';
import { DNSSuffix } from '../../../models/DNSSuffix';
import HostingsiteBase, { HostingsiteResponse } from "../../../models/Hostingsite";
import { OrganisationResponse } from '../../../models/Organisation';
import Portalias from '../../../models/Portalias';
import { MoveServiceRequest, ServiceBase, ServiceResponse } from "../../../models/Service";
import { ServiceTagRequest, ServiceTagResponse } from '../../../models/ServiceTag';
import { Subnet } from '../../../models/Subnet';
import { hostingsitesApiSlice } from '../../HostingSiteApi/hostingsitesApiSlice';
import { DnsSuffixApiSlice } from '../../OrganisationApi/redux/dnsSuffixApiSlice';
import { useGetAllAllowedOrganisationsQuery } from '../../OrganisationApi/redux/organisationApiSlice';
import { serviceTagApiSlice } from '../../OrganisationApi/redux/serviceTagApiSlice';
import { subnetApiSlice } from '../../OrganisationApi/redux/subnetApiSlice';
import { PermissionEnum } from '../../Permission/PermissionEnum';
import { AbilityContext } from '../../UserApi/logic/Can';
import { useGetEndpointsByServiceIdQuery } from '../redux/endpointApiSlice';
import { useChangeServiceHostingsiteMutation, useGetServiceByIdQuery } from "../redux/serviceApiSlice";
import { ServiceTabProps } from './ServiceInfoButton';

export default function MoveServiceMenuItem(props: ServiceTabProps) {
    const [open, setOpen] = useState(false)

    return (
        <>
            <DrawerMenuItem
                buttonContent={

                    <>
                        <SyncAltIcon />
                        {t("Change hostingsite")}</>
                }
                open={open}
                setOpen={setOpen}>
                <MoveServiceForm setOpen={setOpen} {...props} />
            </DrawerMenuItem>

        </>
    )
}

function MoveServiceForm(props: ServiceTabProps & { setOpen: Dispatch<SetStateAction<boolean>> }) {
    const { t } = useTranslation()
    const serviceResult = useGetServiceByIdQuery(props.serviceId);

    const endpoints = useGetEndpointsByServiceIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.serviceId
    });
    const organisations = useGetAllAllowedOrganisationsQuery({
        pagination: new PaginationQueryPackage()
    });

    const ability = useContext(AbilityContext);
    const hostingsitesLazyFunc = hostingsitesApiSlice.useLazyGetHostingsitesByOrganisationIdQuery()[0];
    const subnetLazyFunc = subnetApiSlice.useLazyGetSubnetsByHostingsiteIdQuery()[0];
    const dnsSuffixLazyFunc = DnsSuffixApiSlice.useLazyGetDnsSuffixsByHostingSiteIdQuery()[0];
    const serviceTagsLazyFunc = serviceTagApiSlice.useLazyGetServiceTagsByHostingsiteIdQuery()[0];
    const addServiceTagFunc = serviceTagApiSlice.useAddServiceTagMutation()[0];
    const [changeHostingsite] = useChangeServiceHostingsiteMutation()

    const [hostingsitesResult, setHostingsitesResult] = useState<HostingsiteResponse[]>([])
    const [subnetResult, setSubnetResult] = useState<Subnet[]>([])
    const [dnsSuffixResult, setDnsSuffixResult] = useState<DNSSuffix[]>([])
    const [serviceTagsResult, setServiceTagsResult] = useState<ServiceTagResponse[]>([])
    const [selectedSubnets, setSelectedSubnets] = useState<Record<string, string>>({})
    const [initialSelectedSubnets, setInitialSelectedSubnets] = useState<Record<string, string>>({})
    const [ipOptions, setIpOptions] = useState<Record<string, string[]>>({})

    function refreshSubnets(hostingsiteUuid: string | undefined) {
        if (hostingsiteUuid == undefined) {
            setSubnetResult([])
        } else {
            subnetLazyFunc({
                pagination: new PaginationQueryPackage(),
                uuid: hostingsiteUuid
            }).unwrap()
                .then(result => { setSubnetResult(result); })
                .catch(() => setSubnetResult([]))
        }
    }

    function refreshDnsSuffixes(hostingsiteUuid: string | undefined) {
        if (hostingsiteUuid == undefined) {
            setDnsSuffixResult([])
        } else {
            dnsSuffixLazyFunc({
                pagination: new PaginationQueryPackage(),
                uuid: hostingsiteUuid
            }).unwrap()
                .then(result => { setDnsSuffixResult(result); })
                .catch(() => setDnsSuffixResult([]))
        }
    }

    function refreshServiceTags(hostingsiteUuid: string | undefined) {
        if (hostingsiteUuid == undefined) {
            setServiceTagsResult([])
        } else {
            serviceTagsLazyFunc({
                pagination: new PaginationQueryPackage(),
                uuid: hostingsiteUuid
            }).unwrap()
                .then(result => { setServiceTagsResult(result); })
                .catch(() => setServiceTagsResult([]))
        }
    }

    function refreshHostingSites(organisationUuid: string | undefined) {
        if (organisationUuid == undefined) {
            setHostingsitesResult([])
            return;
        } 
        hostingsitesLazyFunc({
            pagination: new PaginationQueryPackage(),
            uuid: organisationUuid
        }).unwrap()
            .then(result => {
                setHostingsitesResult(result);
            })
            .catch(() => setHostingsitesResult([]))
    }

    useMemo(() => {
        const updateIpOptions = { ...ipOptions }
        Object.values(selectedSubnets).forEach(subnet => {
            if (updateIpOptions[subnet] === undefined) {
                updateIpOptions[subnet] = subnetToStringArray(subnet)
            }
        })
        setIpOptions(updateIpOptions)
    }, [selectedSubnets])

    useMemo(() => {
        hostingsitesLazyFunc({
            pagination: new PaginationQueryPackage(),
            uuid: serviceResult.data?.hosting_site?.owner_organisation?.uuid
        }).unwrap().then(result => setHostingsitesResult(result))

        refreshSubnets(serviceResult.data?.hosting_site?.uuid);
        refreshDnsSuffixes(serviceResult.data?.hosting_site?.uuid);
        refreshServiceTags(serviceResult.data?.hosting_site?.uuid);

    }, [serviceResult.data?.hosting_site?.owner_organisation])

    useMemo(() => {
        if (endpoints.data !== undefined) {
            const updatedSelectedSubnets = { ...selectedSubnets }
            endpoints.data.forEach(endpoint => {
                if (endpoint.subnet?.subnet !== undefined) {
                    updatedSelectedSubnets[endpoint.uuid] = endpoint.subnet.subnet
                }
            })
            setInitialSelectedSubnets(updatedSelectedSubnets)
            setSelectedSubnets(updatedSelectedSubnets)
        }
    }, [endpoints.data])

    if (serviceResult.isLoading || endpoints.isLoading || organisations.isLoading)
        return (<Loading />)

    function getRows(moveServiceRequest: MoveServiceRequest): Array<FieldEditorRender[]> {
        const rows: Array<FieldEditorRender[]> = []

        moveServiceRequest.endpoints?.forEach((endpoint) => {
            const newRow: FieldEditorRender[] = [

                new TextRenderer({
                    displayText: "DNS",
                    fieldName: "endpoints",
                    nestedfieldName: "dns_name",
                    suffixText: ".",
                    uniqueId: endpoint.uuid!,
                    extraText: new TextRenderer({
                        displayText: "DNS preview",
                        fieldName: "endpoints",
                        nestedfieldName: "dns_suffix.dns_suffix",
                        suffixText: endpoint.dns_suffix?.dns_suffix === "" ? "" : "." + getEnvironment().REACT_APP_DSDN_DOMAIN,
                        uniqueId: endpoint.uuid!
                    })
                }),
                new AutocompleteFieldRender({
                    displayText: "SDN Subnet",
                    fieldName: "endpoints",
                    nestedfieldName: "subnet",
                    columns: "auto",
                    minWidth: 300,
                    showLabel: true,
                    options: subnetResult,
                    onChange: (subnet: Subnet) => {
                        const updatedSelectedSubnets = { ...selectedSubnets }
                        if (subnet?.subnet === undefined) {
                            delete updatedSelectedSubnets[endpoint.uuid]
                        } else {
                            updatedSelectedSubnets[endpoint.uuid] = subnet.subnet
                        }
                        setSelectedSubnets(updatedSelectedSubnets)
                    },
                    getOptionLabelFunc: (option: Subnet) => option.subnet ?? "",
                    uniqueId: endpoint.uuid,
                }),
                new IpRender({
                    fieldName: "endpoints",
                    nestedfieldName: "ip",
                    options: ipOptions[selectedSubnets[endpoint.uuid]] ?? [],
                    uniqueId: endpoint.uuid,
                }),
                new AutocompleteFieldRender({
                    displayText: "DNS-suffix",
                    showLabel: true,
                    fieldName: "endpoints",
                    nestedfieldName: "dns_suffix",
                    getOptionLabelFunc: (option: DNSSuffix) => option.dns_suffix ?? "",
                    options: dnsSuffixResult,
                    multipleSelect: false,
                    uniqueId: endpoint.dns_suffix?.uuid ?? "no_id",
                    minWidth: 250
                }),
                new AutocompleteFieldRender({
                    displayText: "Ports and protocols",
                    showLabel: true,
                    fieldName: "endpoints",
                    columns: "auto",
                    nestedfieldName: "ports",
                    options: [],
                    getOptionLabelFunc: (option: Portalias) => `${option.name}`,
                    disabled: true,
                    multipleSelect: true,
                    uniqueId: endpoint.uuid!,
                    minWidth: 300
                })
            ];

            rows.push(newRow);
        });
        return rows;
    }

    const moveServiceRequest = new MoveServiceRequest()
    moveServiceRequest.service = serviceResult.data;
    moveServiceRequest.owner_organisation = organisations.data?.find(org => org.uuid === serviceResult.data?.hosting_site?.owner_organisation?.uuid);
    moveServiceRequest.hosting_site = serviceResult.data?.hosting_site;
    moveServiceRequest.servicetag = serviceResult.data?.servicetag;
    moveServiceRequest.endpoints = endpoints.data;

    const formPropsStuf = {
        createNew: () => { throw "plz dont" },
        arrayPath: "",
        setEditModeTemp: () => true
    }

    const SignupSchema = getValidationScheme(hostingsitesResult, subnetResult, dnsSuffixResult)

    return (<Formik
        initialValues={moveServiceRequest}
        validationSchema={SignupSchema}
        onSubmit={async (values) => {
            if (values.servicetag) {
                const matchingServiceTagInNewHostingSite = serviceTagsResult.find(serviceTag => serviceTag.name === values.servicetag?.name)
                if (matchingServiceTagInNewHostingSite) {
                    values.servicetag = matchingServiceTagInNewHostingSite;
                } else {
                    const request = new ServiceTagRequest();
                    request.name = values.servicetag?.name;
                    request.hostingsite = values.hosting_site
                    await addServiceTagFunc({ data: request })
                        .unwrap()
                        .then(createdServiceTag => values.servicetag = createdServiceTag)
                }
            }

            changeHostingsite({
                data: values
            })
                .then(() => props.setOpen(false))
                .catch(() => { /* empty */ })
        }}
    >

        {(formikProps) => {
            return (
                <Form>
                    <FieldArray
                        name={""}
                        render={arrayHelpers => (
                            <Grid spacing={3} columns={1} container>
                                <Grid item xs={1}>
                                    <EasyFormSideBySide
                                        {...formikProps}
                                        columns={[
                                            new EasyFormColumn("Change hostingsite", "", [
                                                new TextRenderer({
                                                    displayText: "Service",
                                                    fieldName: "service",
                                                    render: (content) => <ServiceBadge ressource={content as ServiceBase} />,
                                                    uniqueId: "no_id"
                                                }),
                                                new AutocompleteFieldRender({
                                                    displayText: t("Organisation"),
                                                    fieldName: "owner_organisation",
                                                    showLabel: true,
                                                    getOptionLabelFunc: (option: OrganisationResponse) => option.name ?? "",
                                                    options: organisations.data ?? [],
                                                    onChange: (organisation) => {
                                                        refreshHostingSites(organisation.uuid);
                                                    },
                                                    uniqueId: "no_id",
                                                }),
                                                new AutocompleteFieldRender({
                                                    displayText: t("Hostingsite"),
                                                    fieldName: "hosting_site",
                                                    showLabel: true,
                                                    getOptionLabelFunc: (option: HostingsiteBase) => option.name ?? "",
                                                    options: hostingsitesResult ?? [],
                                                    onChange: (hostingSite) => {
                                                        refreshSubnets(hostingSite?.uuid);
                                                        refreshDnsSuffixes(hostingSite?.uuid);
                                                        refreshServiceTags(hostingSite?.uuid);
                                                        if (hostingSite && hostingSite.uuid === serviceResult.data?.hosting_site?.uuid) {
                                                            setSelectedSubnets(initialSelectedSubnets)
                                                        } else {
                                                            setSelectedSubnets({})
                                                        }
                                                    },
                                                    uniqueId: "no_id",
                                                }),
                                                new TextRenderer({
                                                    displayText: "Servicegroup",
                                                    fieldName: "servicetag",
                                                    uniqueId: "no_id",
                                                    disabled: true,
                                                    showLabel: true,
                                                    render: (option) => <ServiceTagBadge ressource={option as ServiceTagResponse} />,
                                                }),
                                            ])
                                        ]}
                                        {...formPropsStuf}
                                        editMode={true} />
                                </Grid>
                                {moveServiceRequest.endpoints?.length == 0 ? <></> :
                                    <Grid item xs={1}>
                                        <EasyFormRow
                                            confirmDeletionText=""
                                            isAllowedToEdit={(moveServiceRequest) => ability.can(PermissionEnum.SERVICE_WRITE, moveServiceRequest)}
                                            editModeAlways={true}
                                            editable={false}
                                            isDeleteable={false}
                                            saveMutation={() => { throw "Dont save plz" }}
                                            formType={FormType.LIST}
                                            {...formikProps}
                                            editMode={true}
                                            {...formPropsStuf}
                                            SignupSchema={SignupSchema}
                                            getFieldRows={getRows}
                                            columns={[
                                                new EasyFormColumn("Service Endpoints", "Endpoints should be using subnets from the new hostingsite", [])
                                            ]}
                                            arrayHelpers={arrayHelpers} />
                                    </Grid>
                                }
                                <Grid item xs={1}>
                                    <ButtonGroup>
                                        <Button variant="contained" type='submit'>{t("Save")}</Button>
                                        <Button variant="outlined" onClick={() => props.setOpen(false)}>{t("Cancel")}</Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        )
                        }
                    />
                </Form >)
        }
        }
    </Formik >)
}

function getValidationScheme(hostingSites: HostingsiteResponse[], subnets: Subnet[], dnsSuffixes: DNSSuffix[]) {
    return Yup.object().shape({
        service: Yup.object().optional(),
        owner_organisation: Yup.object()
            .typeError(t("Required"))
            .required(t("Required")),
        hosting_site: Yup.object()
            .typeError(t("Required"))
            .required(t("Required"))
            .test(t("Hosting site should be in the new organisation"), t("Hosting site should be in the new organisation"), (hostingSite) => {
                if (hostingSite) {
                    return hostingSites.map(h => h.uuid).includes(hostingSite.uuid)
                } else {
                    return false;
                }
            })
            .when("service", (service: ServiceResponse) => Yup.object()
                .test(t("Hosting site should be changed"), t("Hosting site should be changed"), (hostingSite) => {
                    return hostingSite?.uuid !== service.hosting_site?.uuid
                })
            ),
        endpoints: Yup.array().of(
            Yup.object().shape({
                subnet: Yup.object()
                    .typeError(t("Required"))
                    .required(t("Required"))
                    .test(t("Subnet should be in the new hostingsite"), t("Subnet should be in the new hostingsite"), (subnet) => {
                        if (subnet) {
                            return subnets.map(s => s.uuid).includes(subnet.uuid)
                        } else {
                            return false;
                        }
                    }),
                ip: IsIpAddressInSubnet("subnet"),
                dns_suffix: Yup.object()
                    .typeError(t("Required"))
                    .required(t("Required"))
                    .test(t("Dns suffix should be in the new hostingsite"), t("Dns suffix should be in the new hostingsite"), (dnsSuffix) => {
                        if (dnsSuffix) {
                            return dnsSuffixes.map(s => s.uuid).includes(dnsSuffix.uuid)
                        } else {
                            return false;
                        }
                    })
            })
        )
    });
}