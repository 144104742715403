import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { FormikProps } from "formik";
import { useContext, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { ObjectShape, TypeOfShape } from "yup/lib/object";
import { CriticalityBadge } from "../../../components/Badges/CriticalityBadge";
import { OrganisationBadge } from "../../../components/Badges/OrganisationBadge";
import { PropagationBadge } from "../../../components/Badges/PropagationBadge";
import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import { EasyFormSideBySide } from "../../../components/Forms/FormLayout";
import { AutocompleteFieldRender } from "../../../components/Forms/Renders/AutocompleteFieldRender";
import { TextFieldRender } from "../../../components/Forms/Renders/TextFieldRender";
import { TextRenderer } from "../../../components/Forms/Renders/TextRenderer";
import { OrganisationContext } from "../../../components/Layout/OrganisationPicker";
import { PaginationQueryPackage } from "../../../models/API/QueryParams/PaginationQueryPackage";
import HostingsiteBase from "../../../models/Hostingsite";
import { CreateServiceRequest } from "../../../models/Service";
import { ServiceTagResponse } from "../../../models/ServiceTag";
import { Subnet } from "../../../models/Subnet";
import { CriticalityEnum } from "../../../models/enums/CriticalityEnum";
import { PropagationEnum } from "../../../models/enums/PropagationEnum";
import { ServiceCategoryEnum } from "../../../models/enums/ServiceCategoryEnum";
import { GetManyPackage } from "../../../redux/GetManyPackage";
import { useGetHostingsitesByOrganisationIdQuery } from "../../HostingSiteApi/hostingsitesApiSlice";
import { subnetApiSlice } from "../../OrganisationApi/redux/subnetApiSlice";
import { UserContext } from "../../UserApi/logic/FetchUser";
import useServiceTag from "../Hooks/serviceTagHook";
import ServiceTagFieldRender from "../Renders/ServiceTagFieldRender";
import { DnsSuffixApiSlice } from "../../OrganisationApi/redux/dnsSuffixApiSlice";
import { DNSSuffix } from "../../../models/DNSSuffix";
import { ServiceCategoryBadge } from "../../../components/Badges/ServiceCategoryBadge";

function validateSubnets(input: TypeOfShape<ObjectShape>, subnetLazyTrigger: LazyQueryTrigger<QueryDefinition<GetManyPackage<object>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, string, Subnet[], "portalapi">>): Promise<boolean> {
    if (!input) {
        return new Promise((resolve, _) => resolve(true))
    }
    const hostingSite = input as unknown as HostingsiteBase

    return subnetLazyTrigger({
        pagination: new PaginationQueryPackage(),
        uuid: hostingSite?.uuid
    }, true).unwrap().then((result) => result.length != 0)
}

function validateDNSSuffixes(input: TypeOfShape<ObjectShape>, dnsLazyTrigger: LazyQueryTrigger<QueryDefinition<GetManyPackage<object>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, string, DNSSuffix[], "portalapi">>): Promise<boolean> {
    if (!input) {
        return new Promise((resolve, _) => resolve(true))
    }
    const hostingSite = input as unknown as HostingsiteBase

    return dnsLazyTrigger({
        pagination: new PaginationQueryPackage(),
        uuid: hostingSite?.uuid
    }, true).unwrap().then((result) => result.length != 0)
}

export function getInfoValidationScheme(t: TFunction<"translation", undefined>) {
    const subnetLazyTrigger = subnetApiSlice.useLazyGetSubnetsByHostingsiteIdQuery()[0]
    const dnsLazyTrigger = DnsSuffixApiSlice.useLazyGetDnsSuffixsByHostingSiteIdQuery()[0]
    return Yup.object().shape({
        hosting_site: Yup.object().typeError(t("Required")).required(t("Required"))
            .test("has_subnets", t("Hosting site has no subnets"), (input) => validateSubnets(input, subnetLazyTrigger))
            .test("has_dns_suffixes", t("Hosting site has no dns suffixes"), (input) => validateDNSSuffixes(input, dnsLazyTrigger)),
        name: Yup.string().required(t('Required')),
        criticality: Yup.mixed().oneOf(Object.values(CriticalityEnum)).required(t("Required")),
        propagation: Yup.mixed().oneOf(Object.values(PropagationEnum)).required(t("Required")),
        description: Yup.string().required(t('Required')),
        internal_description: Yup.string().required(t('Required')),
        servicetag: Yup.object().nullable()
    })
}

export default function ServiceInfoStep(props: { formikProps: FormikProps<CreateServiceRequest>, formPropsStuf: any }) {
    const [newlyCreatedServiceTag, setNewlyCreatedServiceTag] = useState<ServiceTagResponse | undefined>(undefined)
    const serviceTags = useServiceTag(props.formikProps.values.hosting_site)
    const { t } = useTranslation()
    useMemo(() => {
        props.formikProps.setFieldValue("approvers", undefined);
    }, [props.formikProps.values.hosting_site])

    const userContext = useContext(UserContext);
    const organisationContext = useContext(OrganisationContext);
    const organisation = organisationContext?.organisation ?? userContext?.organisation

    const allHostingsiteResults = useGetHostingsitesByOrganisationIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: organisationContext?.organisation?.uuid ?? userContext?.organisation?.uuid
    });

    return (
        <EasyFormSideBySide
            {...props.formikProps}
            {...props.formPropsStuf}
            signupSchema={getInfoValidationScheme(t)}
            columns={[
                new EasyFormColumn("Service", "", [
                    new TextFieldRender({
                        displayText: "Name",
                        fieldName: "name",
                        type: "text",
                        uniqueId: "no_id",
                    }),
                    new TextFieldRender({
                        displayText: "Description",
                        fieldName: "description",
                        multiline: true,
                        type: "text",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "Internal description",
                        fieldName: "internal_description",
                        multiline: true,
                        type: "text",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "General contact information",
                        fieldName: "general_contact_information",
                        multiline: true,
                        type: "text",
                        uniqueId: "no_id"
                    }),
                    new TextRenderer({
                        displayText: "Organisation",
                        fieldName: "organisation",
                        uniqueId: "no_id",
                        render: () => <OrganisationBadge ressource={organisation} />
                    }),
                    new AutocompleteFieldRender({
                        displayText: t("Hostingsite"),
                        fieldName: "hosting_site",
                        getOptionLabelFunc: (option: HostingsiteBase) => option.name ?? "",
                        options: allHostingsiteResults.data ?? [],
                        uniqueId: "no_id"
                    }),
                    new AutocompleteFieldRender({
                        displayText: t("Criticality"),
                        fieldName: "criticality",
                        getOptionLabelFunc: (option: CriticalityEnum) => option.toString() ?? "",
                        options: Object.values(CriticalityEnum) ?? [],
                        renderNonChangeable: (content) => <CriticalityBadge ressource={content as CriticalityEnum} />,
                        uniqueId: "no_id"
                    }),
                    new AutocompleteFieldRender({
                        displayText: t("Propagation"),
                        fieldName: "propagation",
                        getOptionLabelFunc: (option: PropagationEnum) => option.toString() ?? "",
                        options: Object.values(PropagationEnum) ?? [],
                        renderNonChangeable: (content) => <PropagationBadge ressource={content as PropagationEnum} />,
                        uniqueId: "no_id"
                    }),
                    new AutocompleteFieldRender({
                        displayText: t("Categories"),
                        fieldName: "service_categories",
                        multipleSelect: true,
                        getOptionLabelFunc: (option: ServiceCategoryEnum) => option.toString() ?? "",
                        renderOption: (_, option: ServiceCategoryEnum) => <><ServiceCategoryBadge asText ressource={option} /></>,
                        options: Object.values(ServiceCategoryEnum) ?? [],
                        uniqueId: "no_id"
                    }),
                    ServiceTagFieldRender({
                        translation: t,
                        serviceTags: serviceTags,
                        setNewlyCreatedServiceTag: setNewlyCreatedServiceTag,
                        newlyCreatedServiceTag: newlyCreatedServiceTag,
                        hostingSite: props.formikProps.values.hosting_site,
                    })
                ]),
            ]}

            editMode={true} />
    )
}