import { Grid } from "@mui/material";
import { FormikProps } from "formik";
import { useMemo, useState } from "react";
import AgreementRequest from "../../../models/Agreement";
import { ServiceResponse } from "../../../models/Service";
import { useGetAllServicesQuery } from "../../ServiceApi/redux/serviceApiSlice";
import { ServiceList } from "../../ServiceApi/serviceList";

export default function PickServiceStep(props: { formikProps: FormikProps<AgreementRequest>, formPropsStuf: any }) {
    const [selected, setSelected] = useState<ServiceResponse[]>([]);
    useMemo(() => {
        props.formikProps.setFieldValue("services", selected)
    }, [selected])
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ServiceList setCheckedServices={setSelected} checkedServices={selected} getServices={useGetAllServicesQuery} />
            </Grid>
        </Grid>
    )
}