import { NoAccounts } from "@mui/icons-material"
import { Grid, Card, CardContent, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export function NotCorrectRights() {
    const { t } = useTranslation()

    return (
        <Grid xs={12} paddingTop={2}>
            <Card>
                <CardContent>
                    <Stack alignItems="center" textAlign="center" direction="row" spacing={2}>
                        <Typography>{<NoAccounts color="primary" fontSize="large" />}</Typography>
                        <Typography variant="h6">{t("You dont have the correct permissions!")}</Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Grid >
    )
}