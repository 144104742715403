import { FetchArgs } from "@reduxjs/toolkit/dist/query";
import { PaginationQueryPackage} from "../models/API/QueryParams/PaginationQueryPackage";

class propsStuff {
    body?: any
    pagination?: PaginationQueryPackage
}

export default function HandleQuery(args: FetchArgs & propsStuff): FetchArgs {
    args.body = JSON.stringify(args.body)

    if (args.pagination)
        args.url = args.pagination.getQuery(args.url)

    return args;
}