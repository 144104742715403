import { AddCircle } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from "@mui/material";
import { FormikProps } from 'formik';
import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationButton } from '../../components/ConfirmationButton';
import { CreateEntityButton } from "../../components/CreateEntityButton";
import EasyStepper from '../../components/EasyStepper';
import { OrganisationContext } from "../../components/Layout/OrganisationPicker";
import { Error } from "../../models/API/Error";
import { ErrorCodeEnum } from '../../models/API/ErrorCodeEnum';
import { CreateClientRequest, IpAddress } from '../../models/Client';
import { AttachContactRequest } from '../../models/Contact';
import { LabelContextEnum } from '../../models/ContactLabel';
import { CreateResponse } from '../../redux/CreateResponse';
import ContactStep, { getContactValidationScheme } from '../ContactsApi/createFlow/Contacts';
import { PermissionEnum } from '../Permission/PermissionEnum';
import { Can } from '../UserApi/logic/Can';
import { ClientInfoContent } from './clientInfoDialog/ClientInfoButton';
import ClientInfoStep, { getInfoValidationScheme } from './createClientFlow/BaseInfo';
import IpAddressStep, { getIpAddressValidationScheme } from './createClientFlow/IpAddressStep';
import { useAddClientMutation, useForceAddClientMutation } from './redux/clientsApiSlice';

export const CreateClientButton = () => {

    const [open, setOpen] = useState(false)
    const [submittedClient, setSubmittedClient] = useState<CreateResponse | undefined>(undefined)
    const organisationContext = useContext(OrganisationContext)?.organisation

    return (
        <Can I={PermissionEnum.CLIENT_WRITE} a={organisationContext} >
            <CreateEntityButton tooltip="Create client" open={open} setOpen={setOpen} onClose={() => setSubmittedClient(undefined)} buttonIcon={<AddCircle />} >
                {submittedClient == undefined ?

                    <CreateClientButtonContent afterSaveAction={(client) => {
                        setSubmittedClient(client);
                    }} setOpen={setOpen} />
                    :
                    <ClientInfoContent client_uuid={submittedClient.uuid!} afterDeleteAction={() => setOpen(false)} />
                }
            </CreateEntityButton>
        </Can >
    )
}

export interface CreateClientButtonContentProps {
    afterSaveAction: (client: CreateResponse) => void
}

function ClientContactStep(props: { formikProps: FormikProps<CreateClientRequest>, formPropsStuf: any }) {
    const organisationContext = useContext(OrganisationContext)?.organisation
    return <ContactStep formikProps={props.formikProps} formPropsStuf={props.formPropsStuf} labelContext={LabelContextEnum.CLIENT} owner_organisation={organisationContext} />
}

export const CreateClientButtonContent = (props: CreateClientButtonContentProps & { setOpen: Dispatch<SetStateAction<boolean>> }) => {


    const { t } = useTranslation()

    const addClientMutation = useAddClientMutation()
    const forceAddClientMutation = useForceAddClientMutation()

    const [initial, setInitial] = useState<CreateClientRequest>(new CreateClientRequest())
    const [forceRequired, setForceRequired] = useState(false)

    useMemo(() => {
        const newClient = new CreateClientRequest();
        newClient.contacts = [new AttachContactRequest()]
        newClient.ip_addresses = [new IpAddress()]
        setInitial(newClient);
    }, [])

    async function createClient(values: CreateClientRequest) {
        await addClientMutation[0]({
            data: values
        }).unwrap()
            .then(result => props.afterSaveAction(result as CreateResponse))
            .catch((error: { data: Error }) => setForceRequired(error.data.error_code === ErrorCodeEnum.OPERATION_REQUIRES_FORCE))
    }

    async function forceCreateClient(values: CreateClientRequest) {
        await forceAddClientMutation[0]({
            data: values
        }).unwrap()
            .then(result => props.afterSaveAction(result as CreateResponse))
    }

    function NextStepFunc(currentPage: number, _values: CreateClientRequest) {
        return currentPage + 1
    }
    function PreviousStepFunc(currentPage: number) {
        setForceRequired(false);
        return currentPage - 1
    }

    return (
        <>
            <Box>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("Create client") + ""}</Typography>

                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={10}>
                        <EasyStepper<CreateClientRequest>
                            nextPage={NextStepFunc}
                            previousPage={PreviousStepFunc}
                            submit={forceRequired ? forceCreateClient : createClient}
                            initialValue={initial}
                            stepValidations={[
                                getInfoValidationScheme(t),
                                getIpAddressValidationScheme(t),
                                getContactValidationScheme(t)
                            ]}
                            stepTitles={[
                                "Information",
                                "IP addresses",
                                "Contacts",
                            ]}
                            steps={[
                                ClientInfoStep,
                                IpAddressStep,
                                ClientContactStep
                            ]}
                            saveButtonOverride={
                                !forceRequired ? undefined :
                                    (formikProps) =>
                                        <ConfirmationButton action={async () => { formikProps.submitForm() }} variant="contained" title={t("Ip address is already in use")} buttonText={t("Force save")}>
                                            <Stack>
                                                <Typography>{t("Create client anyways?") + " "}</Typography>
                                            </Stack>
                                        </ConfirmationButton>
                            } />
                    </Grid>
                </Grid>

            </Box>
        </>
    )
}