import AddCircle from "@mui/icons-material/AddCircle";
import { Box, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import DrawerButton from "../../components/DrawerButton";
import EasyForm, { FormType } from "../../components/Forms/EasyForm";
import EasyFormColumn from "../../components/Forms/EasyFormColumn";
import { TextFieldRender } from "../../components/Forms/Renders/TextFieldRender";
import TooltipComponent from "../../components/TooltipComponent";
import { AbilityContext, Can } from "../../feature/UserApi/logic/Can";
import Portalias from "../../models/Portalias";
import { PermissionEnum } from "../Permission/PermissionEnum";
import { PortaliasInfoContent } from "./portaliasInfoDialog/PortaliasInfoButton";
import { useAddPortaliasMutation } from "./redux/portaliasApiSlice";


export const CreatePortaliasButton = () => {
    const { t } = useTranslation()


    const [open, setOpen] = useState<boolean>(false)
    return (
        <Can I={PermissionEnum.GOD} a={Portalias} >
            <DrawerButton open={open} setOpen={setOpen} buttonContent={
                <TooltipComponent title={t("Create portalias") + ""}>
                    <AddCircle />
                </TooltipComponent>
            } >
                <DrawerContent />
            </DrawerButton>
        </Can>
    )
}
export const DrawerContent = () => {
    const [submittedPortalias, setSubmittedPortalias] = useState<Portalias | undefined>(undefined)
    return (
        <>
            {submittedPortalias == undefined ?
                <CreatePortaliasButtonContent afterSaveAction={(portalias) => setSubmittedPortalias(portalias)} /> :
                <PortaliasInfoContent portalias={submittedPortalias} />
            }
        </>
    )
}

export interface CreatePortaliasButtonContentProps {
    afterSaveAction: (portalias: Portalias) => void
}
export const CreatePortaliasButtonContent = (props: CreatePortaliasButtonContentProps) => {
    const SignupSchema = Yup.object().shape({
        name: Yup.string().required(),

    });
    const ability = useContext(AbilityContext)

    return (
        <>

            <Box>
                <Grid container justifyContent="center">

                    <Grid item xs={6}>
                        <Typography variant="h6">{t("Create portalias") + ""}</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={6}>

                        <EasyForm
                            isAllowedToEdit={(portalias) => ability.can(PermissionEnum.GOD, portalias)}
                            editModeAlways={true}
                            arrayPath=""
                            SignupSchema={SignupSchema}
                            columns={[
                                new EasyFormColumn("", "", [
                                    new TextFieldRender({
                                        displayText: "Name",
                                        fieldName: "name",
                                        uniqueId: "no_id",
                                        type: "text"
                                    })
                                ])
                            ]}
                            formType={FormType.INFORMATION}
                            getFieldRows={() => []}
                            saveMutation={useAddPortaliasMutation}
                            afterSaveAction={props.afterSaveAction}
                            createNew={() => new Portalias()}
                        />
                    </Grid>
                </Grid>

            </Box>
        </>
    )
}