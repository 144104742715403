
import AgreementRequest from "./Agreement";
import BaseModel from "./BaseModels/BaseModel";

import { AttachContactRequest, CombinedUserContact } from "./Contact";
import { HostingsiteResponse } from "./Hostingsite";

import { Subnet } from "./Subnet";

export default class Client extends BaseModel {
    short_id?: number
    name?: string
    description?: string
    internal_description?: string
    general_contact_information?: string
    hosting_site?: HostingsiteResponse
    ipaddresses: IpAddress[] = [] //more info needed
    contacts: CombinedUserContact[] = []
    agreements: AgreementRequest[] = []
    active_agreements?: number
    ip_addresses?: string[]
}
export class CreateClientRequest {
    name?: string
    description?: string
    internal_description?: string
    general_contact_information?: string
    hosting_site?: HostingsiteResponse
    contacts: AttachContactRequest[] = []
    ip_addresses: IpAddress[] = []
}

export class IpAddress extends BaseModel {
    subnet?: Subnet
    ipaddress?: string //Ip-addresse or CIDR
}
export class IpAddressRequest extends IpAddress {
    client?: Client
}