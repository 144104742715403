import InfoIcon from '@mui/icons-material/Info';
import { Tab } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DrawerButton from "../../../components/DrawerButton";
import { EntityMetaDataComponent } from '../../../components/EntityMetaDataComponent';
import { CustomTabs } from '../../../components/Forms/CustomTabs';
import AgreementMenu from "../../../components/IApprovalRessourceMenu";
import UseUrlParam from "../../../components/Logic/UseUrlProps";
import TabPanel from "../../../components/TabPanel";
import TooltipComponent from '../../../components/TooltipComponent';
import AgreementRequest from "../../../models/Agreement";
import { useAddApprovalMutation, useAddDenialMutation, useDeleteAgreementMutation, useGetAgreementByIdQuery } from "../redux/agreementsApiSlice";
import ContactsTab from "./tabs/ContactsTab";
import InfoTab from "./tabs/InfoTab";
import IpAddressesTab from "./tabs/IpAddressesTab";

export interface AgreementTabProps {
    agreementId: string
}

interface AgreementInfoDialogProps {
    agreement: AgreementRequest
    openFromStart?: boolean
    onClose?: () => void
}



export const AgreementInfoButton = (props: AgreementInfoDialogProps) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(props.openFromStart ?? false)
    return (
        <DrawerButton
            open={open}
            setOpen={setOpen}
            onClose={props.onClose}
            buttonContent={
                <TooltipComponent title={t("Details") + ""}>
                    <InfoIcon />
                </TooltipComponent>
            } footerContent={
                <EntityMetaDataComponent entity_uuid={props.agreement.uuid} />
            } >

            <AgreementInfoContent {...props} afterDeleteAction={() => { setOpen(false) }}></AgreementInfoContent>
        </DrawerButton>
    )
}

interface AgreementInfoDialogContentProps {
    agreement: AgreementRequest
    openFromStart?: boolean
    afterDeleteAction?: () => void
}

export const AgreementInfoContent = (props: AgreementInfoDialogContentProps | UseUrlParam) => {

    const params = useParams();
    const urlPropsUuid = (props as UseUrlParam).shouldGetOrganisationFromUrl ? params["agreementUuid"] : "";
    const agreementPropsUuid = (props as AgreementInfoDialogContentProps).agreement?.uuid

    const serviceUuid = agreementPropsUuid ?? urlPropsUuid;

    const [value, setValue] = React.useState(0);
    const { t } = useTranslation();
    const { data } = useGetAgreementByIdQuery(serviceUuid!)

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (data == undefined)
        return <></>

    const labels = []
    const tabPanel = []

    labels.push(t("Info") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><InfoTab agreementId={data.uuid!} {...props} /></TabPanel>)

    labels.push(t("Client Ip addresses") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><IpAddressesTab agreementId={data.uuid!} {...props} /></TabPanel>)

    labels.push(t("Contacts") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><ContactsTab agreementId={data.uuid!} {...props} /></TabPanel>)

    return (
        <>

            <CustomTabs
                value={value}
                onChange={handleChange}
                rightMenu={
                    <AgreementMenu
                        nameOfRessource="agreement"
                        primaryButtonText={t("Agreement") + ": " + data.client?.name + " (" + data.service?.name + ")"}
                        idToFetch={data.uuid}
                        getByIdAction={useGetAgreementByIdQuery}
                        approveMutation={useAddApprovalMutation}
                        denyMutation={useAddDenialMutation}
                        terminateMutation={useDeleteAgreementMutation}
                        afterDeleteAction={(props as AgreementInfoDialogContentProps).afterDeleteAction}
                    />}>
                {labels.map(label => (<Tab key={label} label={label} />))}
            </CustomTabs>
            {tabPanel.map(tabPanel => tabPanel)}
        </>
    )
}