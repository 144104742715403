import { Restore } from "@mui/icons-material"
import { Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { OrganisationBadge } from "../../../components/Badges/OrganisationBadge"
import DrawerButton from "../../../components/DrawerButton"
import TooltipComponent from "../../../components/TooltipComponent"
import Loading from "../../../components/loading"
import { EventsList } from "../../EventApi/eventList"
import { eventsApiSlice } from "../../EventApi/redux/eventApiSlice"
import { useGetOrganisationByIdQuery } from "../redux/organisationApiSlice"
import { OrganisationButtonProps } from "./OrganisationButtonProps"



export const OrganisationEventsButton = (props: OrganisationButtonProps) => {
    const { t } = useTranslation()

    const [open, setOpen] = useState<boolean>(false)
    const organisationResult = useGetOrganisationByIdQuery(props.organisationUuid!)
    if (organisationResult.isLoading) {
        return <Loading />
    }
    return (
        <DrawerButton open={open} setOpen={setOpen} buttonContent={
            <TooltipComponent title={t("Overview of events belonging to") + " " + organisationResult.data?.name}>
                <Restore />
            </TooltipComponent>
        } >
            <DrawerContent {...props} />
        </DrawerButton>
    )
}

const DrawerContent = (props: OrganisationButtonProps) => {
    const { t } = useTranslation()
    const organisationResult = useGetOrganisationByIdQuery(props.organisationUuid!)
    if (organisationResult.isLoading) {
        return <Loading />
    }
    return (
        <Stack spacing={2}>
            <Typography variant="h6">{t("Events belonging to")}  <OrganisationBadge ressource={organisationResult.data} /></Typography>
            <EventsList idToFetch={props.organisationUuid} getEvents={eventsApiSlice.useLazyGetEventsByOrganisationIdQuery} />
        </Stack>
    )
}