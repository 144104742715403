import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { OrganisationBadge } from "../../../../components/Badges/OrganisationBadge";
import ErrorCodeHook from "../../../../components/DTO/ErrorCodeHook";
import EasyForm, { FormType } from "../../../../components/Forms/EasyForm";
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import { AutocompleteFieldRender } from "../../../../components/Forms/Renders/AutocompleteFieldRender";
import { CheckboxFieldRender } from "../../../../components/Forms/Renders/CheckboxFieldRender";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import { TextRenderer } from "../../../../components/Forms/Renders/TextRenderer";
import { DateToFormattedString } from "../../../../components/Logic/DateLogic";
import { ErrorCodeEnum } from "../../../../models/API/ErrorCodeEnum";
import { OrganisationResponse } from "../../../../models/Organisation";
import { Role, UserPOST, UserResponse, getRolesUpTo, roleToString } from "../../../../models/User";
import { PermissionEnum } from "../../../Permission/PermissionEnum";
import { AbilityContext } from "../../logic/Can";
import { UserContext } from "../../logic/FetchUser";
import { useForceUpdateUserMutation, useGetUserByIdQuery, useUpdateUserMutation } from "../../redux/userApiSlice";
import { UserTabProps } from "../UserDetailsButton";


export default function InfoTab(props: UserTabProps) {
    const { t } = useTranslation();
    const ability = useContext(AbilityContext);
    const SignupSchema = Yup.object().shape({
        first_name: Yup.string().required(t("Required")).min(1, t("Minimum one character")),
        last_name: Yup.string().required(t("Required")).min(1, t("Minimum one character")),
        email: Yup.string().email(t("Email is invalid")).required(t("Required")),
        role: Yup.string().nullable().required(t("Required"))
    });

    const errorHook: ErrorCodeHook<UserPOST>[] = [];
    errorHook.push(new ErrorCodeHook<UserPOST>(ErrorCodeEnum.OPERATION_REQUIRES_FORCE, "User email is already in use by external contact", "Replace contact with user anyways?", useForceUpdateUserMutation));

    const userResult = useGetUserByIdQuery(props.userUuid)
    const userContext = useContext(UserContext);
    const possibleRoles = getRolesUpTo(userContext?.role)
    const getFirstRow = () => {
        return [
            new TextRenderer({
                displayText: "Organisation",
                fieldName: "organisation",
                uniqueId: props.userUuid,
                render: (organisation) => <OrganisationBadge ressource={organisation as OrganisationResponse} />
            }),
            new TextFieldRender({
                displayText: "Email",
                fieldName: "email",
                type: "text",
                uniqueId: props.userUuid
            }),
            new TextFieldRender({
                displayText: "First name",
                fieldName: "first_name",
                type: "text",
                uniqueId: props.userUuid
            }),
            new TextFieldRender({
                displayText: "Last name",
                fieldName: "last_name",
                type: "text",
                uniqueId: props.userUuid
            }),
            new AutocompleteFieldRender({
                displayText: "Role",
                fieldName: "role",
                getOptionLabelFunc: (option: Role) => roleToString(option) ?? "",
                options: possibleRoles,
                uniqueId: "no_id"
            }),
            new CheckboxFieldRender({
                disabled: true,
                displayText: "Enabled",
                fieldName: "keycloak_enabled",
                uniqueId: props.userUuid,
            }),
            new TextRenderer({
                displayText: "Latest activity",
                fieldName: "latest_activity",
                uniqueId: props.userUuid,
                render: (content) => <> {DateToFormattedString(content as Date)}</>

            }),
        ];
    }

    return (
        <EasyForm<UserResponse>
            isAllowedToEdit={(user) => ability.can(PermissionEnum.USER_WRITE, user)}
            saveMutation={useUpdateUserMutation}
            getByIdAction={useGetUserByIdQuery}
            errorToMutation={errorHook}
            initialValue={userResult.data}
            arrayPath=""
            SignupSchema={SignupSchema}
            columns={[
                new EasyFormColumn("User information", "", getFirstRow())
            ]}
            createNew={() => { throw "User should not be created" }}
            idToFetch={props.userUuid}
            getFieldRows={() => []}
            newButtonTooltip="Add new info"
            formType={FormType.INFORMATION}
            key={JSON.stringify([userResult.data])}
        />
    )
}