import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { Box, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { ConfirmationButton } from "../../../../components/ConfirmationButton";
import { CreateInCollectionButton } from "../../../../components/CreateInCollectionButton";
import { AutocompleteFieldRender } from "../../../../components/Forms/Renders/AutocompleteFieldRender";
import { FieldEditorRender } from "../../../../components/Forms/Renders/FieldEditorRender";
import { OrganisationContext } from "../../../../components/Layout/OrganisationPicker";
import Loading from "../../../../components/loading";
import { PaginationQueryPackage } from "../../../../models/API/QueryParams/PaginationQueryPackage";
import { ServiceResponse } from "../../../../models/Service";
import { ServiceWindowAddRequest } from "../../../../models/ServiceWindow";
import { useGetAllServicesQuery, useGetServicesByOrganisationIdQuery, useGetServicesByServiceWindowIdQuery } from "../../../ServiceApi/redux/serviceApiSlice";
import { ServiceList } from "../../../ServiceApi/serviceList";
import { UserContext } from "../../../UserApi/logic/FetchUser";
import { useAddServiceToServiceWindowMutation, useDeleteServiceWindowMutation, useGetServiceWindowByIdQuery, useRemoveServiceFromServiceWindowMutation } from "../../redux/serviceWindowApiSlice";
import { ServiceWindowInfoDialogProps } from "../ServiceWindowInfoButton";

export const ServiceTab = (props: ServiceWindowInfoDialogProps) => {
    const SignupSchema = Yup.object().shape({

    });
    const { t } = useTranslation()
    const orgContext = useContext(OrganisationContext);
    const userContext = useContext(UserContext);
    const deleteServiceWindow = useDeleteServiceWindowMutation();
    const deleteServiceWindowTrigger = deleteServiceWindow[0];
    const removeServiceFromServiceWindow = useRemoveServiceFromServiceWindowMutation();
    const removeServiceFromServiceWindowTrigger = removeServiceFromServiceWindow[0];
    const serviceWindowResult = useGetServiceWindowByIdQuery(props.serviceWindowId);
    const servicesForServiceWindowResult = useGetServicesByServiceWindowIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.serviceWindowId
    }, { skip: !props.serviceWindowId });

    let getServices = useGetServicesByOrganisationIdQuery
    let shouldSkip = !orgContext?.organisation?.uuid;
    if (userContext?.allowEmptyOrganisation()) {
        getServices = useGetAllServicesQuery
        shouldSkip = false
    }

    const serviceResult = getServices({
        pagination: new PaginationQueryPackage(),
        uuid: orgContext?.organisation?.uuid
    }, { skip: shouldSkip })

    const serviceWindowStartDate = new Date(serviceWindowResult.data!.start_time!);
    const now = new Date();
    const serviceWindowHasStarted = serviceWindowStartDate.getTime() < now.getTime();
    const serviceWindowHasMoreThanOneServices = servicesForServiceWindowResult.data!.length > 1

    if (serviceWindowResult.isLoading || servicesForServiceWindowResult.isLoading)
        return <Loading />

    const getRowsForCreate = (_services: ServiceWindowAddRequest[] | undefined): FieldEditorRender[] => {
        const newRow: FieldEditorRender[] = [
            new AutocompleteFieldRender({
                displayText: "Service",
                fieldName: "",
                nestedfieldName: "service",
                getOptionLabelFunc: (option: ServiceResponse) => option.name ?? "",
                options: serviceResult.data ?? [],
                loading: serviceResult.isLoading,
                uniqueId: crypto.randomUUID(),
            })
        ]
        return newRow;
    }

    return (
        <>
            <Box sx={{ marginBottom: "1rem" }}>
                <Typography variant="h5">{t("Services")}
                    {!serviceWindowHasStarted &&
                        <CreateInCollectionButton
                            validationScheme={SignupSchema}
                            ressourceOwnerId={props.serviceWindowId}
                            fieldEditorRenders={getRowsForCreate([new ServiceWindowAddRequest()])}
                            tooltip="Attach service to service window"
                            createNew={() => new ServiceWindowAddRequest()}
                            saveMutation={useAddServiceToServiceWindowMutation} />
                    }
                </Typography>
            </Box>
            <ServiceList
                getServices={useGetServicesByServiceWindowIdQuery}
                idToFetch={props.serviceWindowId}
                disableDrawer={true}
                extendedActions={(service: ServiceResponse) => {
                    if (!serviceWindowHasMoreThanOneServices && !serviceWindowHasStarted) {
                        return (
                            <ConfirmationButton
                                variant="text"
                                title={t("Delete service window")}
                                buttonText={<><DeleteOutline /></>}
                                action={async () => {
                                    await deleteServiceWindowTrigger({
                                        parentId: props.serviceWindowId,
                                        data: serviceWindowResult.data!
                                    })
                                }}
                            >
                                <Stack spacing={2}>
                                    <Typography>{t("Deleting the last service will delete entire servicewindow")}</Typography>
                                    <Typography fontWeight="bold">{t("Are you sure you wish to delete the servicewindow?")}</Typography>
                                </Stack>
                            </ConfirmationButton>
                        )
                    }

                    if (!serviceWindowHasStarted) {
                        return (
                            <ConfirmationButton
                                variant="text"
                                title={t("Remove service from service window")}
                                buttonText={<><DeleteOutline /></>}
                                action={async () => {
                                    await removeServiceFromServiceWindowTrigger({
                                        parentId: props.serviceWindowId,
                                        data: service
                                    })
                                }}
                            >
                                <Stack spacing={2}>
                                    <Typography>{t("Are you sure you wish to remove this service from the service window?")}</Typography>
                                </Stack>
                            </ConfirmationButton>
                        )
                    }

                    return (<></>)

                }

                }
            />
        </>
    )
}