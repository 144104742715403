import { useTranslation } from "react-i18next";
import { ServiceCategoryEnum } from "../../models/enums/ServiceCategoryEnum";
import { BadgeProps, BaseBadge } from "./BaseBadge";


export function ServiceCategoryBadge(props: BadgeProps<ServiceCategoryEnum>) {
    const { t } = useTranslation();

    if (props.textOverride)
        return <BaseBadge {...props} content={<>{props.textOverride}</>} color="default" />
    if (props.ressource != undefined && props.ressource.length == 0)
        return <></>

    const content = props?.ressource ?? "";
    return <BaseBadge fontSize={15} deepContent tooltip={t(getDescription(props.ressource))} {...props} content={<>{t(content)}</>} color="default" />
}

function getDescription(serviceCategoryEnum?: ServiceCategoryEnum): string {
    if (!serviceCategoryEnum)
        return "";

    const description = categoryToDescription.get(serviceCategoryEnum);
    if (description)
        return description;

    return "";

}

const categoryToDescription = new Map<ServiceCategoryEnum, string>([
    [ServiceCategoryEnum.EMERGENCY, "Emergency systems"],
    [ServiceCategoryEnum.JOURNAL, "Journal systems - Patient administrative systems"],
    [ServiceCategoryEnum.LPS, "General practice systems"],
    [ServiceCategoryEnum.LAB, "Laboratory systems and laboratory data: Labka, miba, Biochemistry.."],
    [ServiceCategoryEnum.IDP, "Identity services. ADFS, Radius, related to federations, seb,"],
    [ServiceCategoryEnum.RIS_PACS, "Radiology systems and Radiology Information Systems"],
    [ServiceCategoryEnum.REPORT, "Reporting and collection of clinical data, KMS, etc."],
    [ServiceCategoryEnum.VIDEO, "Video conference systems, VDX, Bridgit and similar."],
    [ServiceCategoryEnum.INFRASTRUCTURE, "Infrastructure. Supporting services, DNS, monitoring, scanning,"],
    [ServiceCategoryEnum.PLATFORM, "Platforms, NSP, sundhed.dk etc."],
    [ServiceCategoryEnum.SPECIALTIES, "Specialty-specific systems, DBCG, cardiology,"],
    [ServiceCategoryEnum.PHARMACY, "Pharmacy and medicine related"],
    [ServiceCategoryEnum.SHARED, "Shared care, collaboration across"],
    [ServiceCategoryEnum.TRANSPORT, "Flex traffic, taxi, helicopter."],
    [ServiceCategoryEnum.RESEARCH, "Research-oriented systems"],
]);
