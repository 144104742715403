import { Info } from "@mui/icons-material"
import { Box, Grid, Tab } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import DrawerButton from "../../components/DrawerButton"
import { CustomTabs } from "../../components/Forms/CustomTabs"
import TabPanel from "../../components/TabPanel"
import TooltipComponent from "../../components/TooltipComponent"
import Event from "../../models/Event"
import { EventButtonProps } from "./EventButtonProps"
import InfoTab from "./tabs/InfoTab"

export interface EventTabProps {
    event: Event
}

export const EventInfoButton = (props: EventButtonProps) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(props.openFromStart ?? false)
    return (
        <DrawerButton open={open} setOpen={setOpen} buttonContent={
            <TooltipComponent title={t("Details") + ""}>
                <Info />
            </TooltipComponent>
        } >

            <EventInfoContent {...props}></EventInfoContent>
        </DrawerButton>
    )
}

export const EventInfoContent = (props: EventButtonProps) => {
    const [value, setValue] = React.useState(0);
    const { t } = useTranslation()

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const labels = []
    const tabPanel = []

    labels.push(t("Info") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><InfoTab event={props.event} /></TabPanel>)

    return (
        <>
            <Box display="none">
                <Grid container>
                    <Grid item xs>
                        <CustomTabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            {labels.map(label => (<Tab key={label} label={label} />))}
                        </CustomTabs>
                    </Grid>
                </Grid>
            </Box>
            {tabPanel.map(tabPanel => tabPanel)}
        </>
    )
}