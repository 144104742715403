import { Info } from "@mui/icons-material"
import { Tab, Typography } from "@mui/material"
import React, { useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { ContactLabelAlert } from "../../../../components/Alerts/ContactLabelAlert"
import DrawerButton from "../../../../components/DrawerButton"
import { EntityMetaDataComponent } from "../../../../components/EntityMetaDataComponent"
import { CustomTabs } from "../../../../components/Forms/CustomTabs"
import UseUrlParam from "../../../../components/Logic/UseUrlProps"
import RessourceMenu from "../../../../components/RessourceMenu"
import TabPanel from "../../../../components/TabPanel"
import TooltipComponent from "../../../../components/TooltipComponent"
import { PaginationQueryPackage } from "../../../../models/API/QueryParams/PaginationQueryPackage"
import { AgreementResponse } from "../../../../models/Agreement"
import { LabelContextEnum } from "../../../../models/ContactLabel"
import { ApprovalStatus } from "../../../../models/enums/ApprovalStatus"
import { AgreementList } from "../../../AgreementApi/agreementList"
import { agreementsApiSlice, useGetAgreementsByOrganisationIdQuery } from "../../../AgreementApi/redux/agreementsApiSlice"
import { useGetContactsByOrganisationIdQuery } from "../../../ContactsApi/redux/contactsApiSlice"
import { PermissionEnum } from "../../../Permission/PermissionEnum"
import { AbilityContext, Can } from "../../../UserApi/logic/Can"
import { useDeleteOrganisationMutation, useGetOrganisationByIdQuery } from "../../redux/organisationApiSlice"
import { OrganisationButtonProps } from "../OrganisationButtonProps"
import InfoTab from "./tabs/InfoTab"
import RoutersTab from "./tabs/RoutersTab"
import { DataGridId } from "../../../../components/DatagridWrapper"

export interface OrganisationTabProps {
    organisationId: string
    
}

export const OrganisationInfoButton = (props: OrganisationButtonProps) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(props.openFromStart ?? false)
    return (
        <DrawerButton
            open={open}
            setOpen={setOpen}
            onClose={props.onClose}
            buttonContent={
                <TooltipComponent title={t("Details") + ""}>
                    <Info />
                </TooltipComponent>
            } footerContent={
                <EntityMetaDataComponent entity_uuid={props.organisationUuid} />
            }>

            <OrganisationInfoContent {...props}></OrganisationInfoContent>
        </DrawerButton>
    )
}



export const OrganisationInfoContent = (props: OrganisationButtonProps | UseUrlParam) => {

    const params = useParams();
    const urlPropsUuid = (props as UseUrlParam).shouldGetOrganisationFromUrl ? params["organisationUuid"] : "";
    const orgPropsUuid = (props as OrganisationButtonProps).organisationUuid

    const organisationUuid = orgPropsUuid ?? urlPropsUuid;

    const [value, setValue] = React.useState(0);
    const { t } = useTranslation()

    const ability = useContext(AbilityContext);
    const organisationResult = useGetOrganisationByIdQuery(organisationUuid!)
    const getAgreementsLazy = agreementsApiSlice.useLazyGetAgreementsByOrganisationIdQuery();
    const getAgreementsLazyTrigger = getAgreementsLazy[0];
    const [activeAgreements, setActiveAgreements] = useState<AgreementResponse[]>([])

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useMemo(() => {
        if (organisationResult.data != undefined && ability.can(PermissionEnum.GOD, organisationResult.data)) {
            getAgreementsLazyTrigger({
                pagination: new PaginationQueryPackage(),
                uuid: organisationUuid,
                requestData: [ApprovalStatus.APPROVED]
            }).unwrap().then(result => setActiveAgreements(result))
        }
    }, [organisationResult.isLoading])

    if (organisationResult.data == undefined)
        return <></>

    const labels = []
    const tabPanel = []

    labels.push(t("Info") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><InfoTab organisationId={organisationResult.data.uuid!} {...props} /></TabPanel>)

    if (ability.can(PermissionEnum.ORGANISATION_READ, organisationResult.data)) {
        labels.push(t("Routers") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><RoutersTab organisationId={organisationResult.data.uuid!} {...props} /></TabPanel>)
    }

    const organisationHasActiveAgreements: boolean = activeAgreements.length > 0
    return (
        <>
            <Can I={PermissionEnum.ORGANISATION_WRITE} this={organisationResult.data}>
                <ContactLabelAlert uuid={organisationUuid!} fetchContactsHook={useGetContactsByOrganisationIdQuery} labelContext={LabelContextEnum.ORGANISATION} />
            </ Can>
            <CustomTabs value={value} onChange={handleChange}
                rightMenu={
                    <RessourceMenu
                        isAllowedToDelete={(data) => ability.can(PermissionEnum.GOD, data)}
                        nameOfRessource="organisation"
                        primaryButtonText={t("Organisation") + ": " + organisationResult.data.name}
                        idToFetch={organisationResult.data.uuid}
                        deleteMutation={useDeleteOrganisationMutation}
                        getByIdAction={useGetOrganisationByIdQuery}
                        deleteButtonContent={!organisationHasActiveAgreements ? undefined :
                            <>
                                <Typography>{t("The organisation has active agreements, that needs to be terminated in order to delete organisation")}</Typography>
                                <AgreementList dataGridId={DataGridId.AGREEMENTS_SIMPLE} statusList={[ApprovalStatus.APPROVED]} simpleView={true} idToFetch={organisationResult.data.uuid} getAgreements={useGetAgreementsByOrganisationIdQuery}></AgreementList>
                            </>
                        }
                    />
                }>
                {labels.map(label => (<Tab key={label} label={label} />))}
            </CustomTabs>
            {tabPanel.map(tabPanel => tabPanel)}
        </>
    )
}