import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, Grid, IconButton } from "@mui/material";
import { DrawerButtonProps } from "./DrawerButton";

export default function DialogButton(props: DrawerButtonProps) {

    const handleClickOpen = () => {
        props.onOpen?.()
        props.setOpen(true);
    };

    const handleClose = () => {
        props.onClose?.()
        props.setOpen(false);
    };

    return (
        <>
            <Button {...props} onClick={handleClickOpen}>
                {props.buttonContent}
            </Button>
            <Dialog
                open={props.open}
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
            >
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <Box padding={2}>
                            {props.children}
                        </Box>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}