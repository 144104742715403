import { createApi } from '@reduxjs/toolkit/query/react';
import fetchDefaultBaseQuery from './BaseQuerySettings';
export const allTagTypes = [
    'clients', 'client',
    'ipAddress',
    'agreements', 'agreement',
    'services',
    'contactlabels',
    'contacts', 'client-contacts', 'service-contacts', 'organisation-contacts', 'contact',
    'events',
    'notifications',
    'authorisations', 'user_authorisations',
    'dns-suffixes',
    'hosting-sites',
    'organisations',
    'routers', 'router',
    'servicetags',
    'smokepings',
    'subnets',
    'accesscontrolists',
    'portaliases', 'portalias',
    'endpoints',
    'service-approvers',
    'service-windows', 'service-window',
    'users',
    'user-roles'
];

export const portalApiSlice = createApi({
    reducerPath: 'portalapi',
    tagTypes: allTagTypes,
    baseQuery: fetchDefaultBaseQuery(),
    endpoints: () => ({}),
})