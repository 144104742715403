import { ButtonGroup, Grid, Stack, Typography } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { OrganisationBadge } from "../components/Badges/OrganisationBadge"
import { OrganisationContext } from "../components/Layout/OrganisationPicker"

import { CreateServiceWindowButton } from "../feature/ServiceWindowApi/CreateServiceWindowButton"
import { useGetAllServiceWindowsQuery, useGetServiceWindowsByOrganisationIdQuery, useGetServiceWindowsForClientsInOrganisationByOrganisationIdQuery } from "../feature/ServiceWindowApi/redux/serviceWindowApiSlice"
import { ServiceWindowList } from "../feature/ServiceWindowApi/serviceWindowList"

export const ServiceWindowPage = () => {
    const { t } = useTranslation()
    const currentOrganisation = useContext(OrganisationContext)?.organisation;
    return (
        <>
            <Grid container spacing={5}>
                <Grid item xs={6}>
                    <Stack direction="row" spacing={2}>
                        {currentOrganisation ? <OrganisationBadge ressource={currentOrganisation} /> : <></>}
                        <Typography variant="h5">{t("Service Windows") + ""}</Typography>

                        <ButtonGroup variant="text">
                            <CreateServiceWindowButton checkedServices={[]} />
                        </ButtonGroup>
                    </Stack>
                </Grid>
                {currentOrganisation == undefined ?
                    <Grid item xs={12}>
                        <Stack>
                            <ServiceWindowList getServiceWindows={useGetAllServiceWindowsQuery} />
                        </Stack>
                    </Grid>
                    : <>
                        <Grid item xs={12}>
                            <Stack>
                                <Typography variant="h5">{t("In organisation")}</Typography>
                                <ServiceWindowList idToFetch={currentOrganisation?.uuid} getServiceWindows={currentOrganisation ? useGetServiceWindowsByOrganisationIdQuery : useGetAllServiceWindowsQuery} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack>
                                <Typography variant="h5">{t("Affects clients")}</Typography>
                                <ServiceWindowList idToFetch={currentOrganisation?.uuid} getServiceWindows={currentOrganisation ? useGetServiceWindowsForClientsInOrganisationByOrganisationIdQuery : useGetAllServiceWindowsQuery} />
                            </Stack>
                        </Grid>
                    </>
                }

            </Grid>
        </>

    )
}