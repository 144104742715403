import { useContext } from "react";
import * as Yup from 'yup';
import EasyForm, { FormType } from "../../../../components/Forms/EasyForm";
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import { ContactResponse } from "../../../../models/Contact";
import { PermissionEnum } from "../../../Permission/PermissionEnum";
import { AbilityContext } from "../../../UserApi/logic/Can";
import { useGetExternalContactByIdQuery, useUpdateExternalContactMutation } from "../../redux/contactsApiSlice";
import { ExternalContactTabProps } from "../ExternalContactInfoButton";


export default function InfoTab(props: ExternalContactTabProps) {
    const ability = useContext(AbilityContext);
    const SignupSchema = Yup.object().shape({

    });
    const externalContactResult = useGetExternalContactByIdQuery(props.uuid)

    const getFirstRow = () => {
        return [
            new TextFieldRender({
                displayText: "Email",
                fieldName: "email",
                type: "text",
                uniqueId: props.uuid
            }),
            new TextFieldRender({
                displayText: "First name",
                fieldName: "first_name",
                type: "text",
                uniqueId: props.uuid
            }),
            new TextFieldRender({
                displayText: "Last name",
                fieldName: "last_name",
                type: "text",
                uniqueId: props.uuid
            }),
        ];
    }

    return (
        <EasyForm<ContactResponse>
            isAllowedToEdit={(user) => ability.can(PermissionEnum.USER_WRITE, user)}
            saveMutation={useUpdateExternalContactMutation}
            getByIdAction={useGetExternalContactByIdQuery}
            arrayPath=""
            SignupSchema={SignupSchema}
            columns={[
                new EasyFormColumn("Contact information", "", getFirstRow())
            ]}
            createNew={() => { throw "External contact should not be created" }}
            idToFetch={props.uuid}
            getFieldRows={() => []}
            newButtonTooltip="Add new info"
            formType={FormType.INFORMATION}
            key={JSON.stringify(externalContactResult.data)}
        />
    )
}