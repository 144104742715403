import * as Yup from 'yup';
import EasyForm, { FormType } from "../../../../components/Forms/EasyForm";
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import { CheckboxFieldRender } from "../../../../components/Forms/Renders/CheckboxFieldRender";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import Portalias from "../../../../models/Portalias";
import { useGetPortaliasByIdQuery, useUpdatePortaliasMutation } from "../../redux/portaliasApiSlice";
import { PortaliasTabProps } from "../PortaliasInfoButton";
import { PermissionEnum } from '../../../Permission/PermissionEnum';
import { useContext } from 'react';
import { AbilityContext } from '../../../UserApi/logic/Can';
import { ServiceList } from '../../../ServiceApi/serviceList';
import { useGetServicesByPortaliasIdQuery } from '../../../ServiceApi/redux/serviceApiSlice';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function InfoTab(props: PortaliasTabProps) {
    const { t } = useTranslation();

    const ability = useContext(AbilityContext)
    const SignupSchema = Yup.object().shape({

    });

    return (
        <Stack>
            <EasyForm
                isAllowedToEdit={(serviceResponse) => ability.can(PermissionEnum.GOD, serviceResponse) && !props.isPortaliasInUse}
                saveMutation={useUpdatePortaliasMutation}
                getByIdAction={useGetPortaliasByIdQuery}
                arrayPath=""
                SignupSchema={SignupSchema}
                columns={[
                    new EasyFormColumn("Portalias information", "", [
                        new TextFieldRender({
                            displayText: "Name",
                            fieldName: "name",
                            uniqueId: props.portaliasId,
                            type: "text"
                        }),
                        new CheckboxFieldRender({
                            disabled: true,
                            displayText: "In use",
                            fieldName: "in_use",
                            uniqueId: props.portaliasId
                        }),
                    ])
                ]}
                idToFetch={props.portaliasId}
                getFieldRows={() => []}
                newButtonTooltip="Add new info"
                formType={FormType.INFORMATION}
                createNew={function (): Portalias {
                    throw new Error("Function not implemented.");
                }}
            />
            {props.isPortaliasInUse ?
                <Stack spacing={2} width={800}>
                    <Typography paddingTop={5} variant="h6">{t("List of services related to protalias")}</Typography>
                    <ServiceList idToFetch={props.portaliasId} getServices={useGetServicesByPortaliasIdQuery} simpleView={true} />
                </Stack>
                :
                <></>
            }
        </Stack>
    )
}