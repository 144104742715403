
import getEnvironment from "../../../env";
import { RequestMutationWrapper } from "../../../models/API/RequestMutationWrapper";
import { IpAddressRequest, IpAddress } from "../../../models/Client";
import HandleQuery from "../../../redux/EndpointQueryHandler";
import { GetManyPackage } from "../../../redux/GetManyPackage";
import handleResponse from "../../../redux/handleResponse";
import { portalApiSlice } from "../../../redux/portalApiSlice";
import {
  transformMultipleResponseToRightType,
  transformSingleResponseToRightType,
} from "../../../redux/transformResponse";

const baseurl = getEnvironment().REACT_APP_API_BASEURL;

export const ipAddressApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getIpAddressesByClient: builder.query<IpAddress[], GetManyPackage>({
      query: (pack) =>
        HandleQuery({
          url: `${baseurl}/clients/${pack.uuid}/ipaddresses`,
          responseHandler: (res) =>
            handleResponse({
              response: res,
              toastWithResult: false,
              toastErrorText: "Ip address could not be fetched",
            }),
          pagination: pack.pagination,
        }),
      transformResponse: (a: IpAddress[], b, c) =>
        transformMultipleResponseToRightType(IpAddress, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        "ipAddress",
        ...result.map(
          ({ uuid }) => ({ type: "ipAddress", uuid: uuid } as const)
        ),
      ],
    }),
    getIpAddressesByAgreement: builder.query<
      IpAddress[],
      GetManyPackage
    >({
      query: (pack) =>
        HandleQuery({
          url: `${baseurl}/agreements/${pack.uuid}/client-ip-addresses`,
          responseHandler: (res) =>
            handleResponse({
              response: res,
              toastWithResult: false,
              toastErrorText: "Ip address could not be fetched",
            }),
          pagination: pack.pagination,
        }),
      transformResponse: (a: IpAddress[], b, c) =>
        transformMultipleResponseToRightType(IpAddress, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        "ipAddress",
        ...result.map(
          ({ uuid }) => ({ type: "ipAddress", uuid: uuid } as const)
        ),
      ],
    }),
    addIpAddress: builder.mutation<
      IpAddress,
      RequestMutationWrapper<IpAddressRequest>
    >({
      query: (ipAddress) =>
        HandleQuery({
          url: `${baseurl}/clients/${ipAddress.data.client?.uuid}/ipaddresses`,
          responseHandler: (res) =>
            handleResponse({
              response: res,
              toastSuccessText: "Ip address was created!",
              toastErrorText: "Ip address could not be created",
            }),
          method: "POST",
          body: {
            ...ipAddress.data,
            subnet_uuid: ipAddress.data.subnet?.uuid,
          },
        }),
      transformResponse: (a: IpAddress, b, c) =>
        transformSingleResponseToRightType(IpAddress, a, b, c),

      invalidatesTags: ["ipAddress"],
    }),
    addIpAddressForce: builder.mutation<
      IpAddress,
      RequestMutationWrapper<IpAddressRequest>
    >({
      query: (ipAddress) =>
        HandleQuery({
          url: `${baseurl}/clients/${ipAddress.data.client?.uuid}/ipaddresses/force`,
          responseHandler: (res) =>
            handleResponse({
              response: res,
              toastSuccessText: "Ip address was created!",
              toastErrorText: "Ip address could not be created",
            }),
          method: "POST",
          body: {
            ...ipAddress.data,
            subnet_uuid: ipAddress.data.subnet?.uuid,
          },
        }),
      transformResponse: (a: IpAddress, b, c) =>
        transformSingleResponseToRightType(IpAddress, a, b, c),

      invalidatesTags: ["ipAddress"],
    }),
    deleteIpAddress: builder.mutation<
      void,
      RequestMutationWrapper<IpAddress>
    >({
      query: (ipAddress) =>
        HandleQuery({
          url: `${baseurl}/ipaddresses/${ipAddress.data.uuid}`,
          method: "DELETE",
          responseHandler: (res) =>
            handleResponse({
              response: res,
              toastSuccessText: "Ip address was deleted!",
              toastErrorText: "Ip address could not be deleted",
            }),
        }),


      invalidatesTags: (_result, _error, args) => [
        { type: "ipAddress", uuid: args.data.uuid },
      ],
    }),
    updateIpAddress: builder.mutation<
      IpAddress,
      RequestMutationWrapper<IpAddress>
    >({
      query: (ipAddress) =>
        HandleQuery({
          url: `${baseurl}/ipaddresses/${ipAddress.data.uuid}`,
          responseHandler: (res) =>
            handleResponse({
              response: res,
              toastSuccessText: "Ip address was updated!",
              toastErrorText: "Ip address could not be updated",
            }),
          method: "PATCH",
          body: {
            ipaddress: ipAddress.data.ipaddress,
            subnet_uuid: ipAddress.data.subnet?.uuid
          },
        }),
      transformResponse: (a: IpAddress, b, c) =>
        transformSingleResponseToRightType(IpAddress, a, b, c),
      invalidatesTags: (_result, _error, args) => [
        { type: "ipAddress", uuid: args.data.uuid },
      ],
    }),
  }),
})


export const {
  useAddIpAddressMutation,
  useGetIpAddressesByClientQuery,
  useAddIpAddressForceMutation,
  useGetIpAddressesByAgreementQuery,
  useDeleteIpAddressMutation,
  useUpdateIpAddressMutation,
} = ipAddressApiSlice;
