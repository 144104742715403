import { KeycloakInstance } from "keycloak-js";
import { createContext } from "react";
import { AuthUser } from "../../../models/AuthUser";

import { Role } from "../../../models/User";
import UserFactory from "./UserFactory";

export async function GetJWTToken(keycloak: KeycloakInstance): Promise<JWTToken | undefined> {
    return keycloak.tokenParsed as JWTToken;
}

export const UserContext = createContext<AuthUser | undefined>(undefined);

export function LoginBasedOnToken(token: JWTToken): AuthUser | undefined {
    const userFactory = new UserFactory();
    let user: AuthUser | undefined = undefined
    try {
        const firstName = token.given_name;
        const lastName = token.family_name;
        const role = getRoleFromString(token.role!);
        const uuid = token.sub
        const email = token.email;

        user = userFactory.createUser(uuid!, firstName!, lastName!, email!, role)

    } catch (error) {
        console.log("Error getting user info from auth token", error)
    }
    return user;
}

export function getRoleFromString(role: string | undefined): Role {

    if (role?.toLocaleLowerCase() === "admin")
        return Role.ADMIN
    if (role?.toLocaleLowerCase() === "org_admin")
        return Role.ORG_ADMIN
    if (role?.toLocaleLowerCase() === "org_super")
        return Role.ORG_SUPER
    if (role?.toLocaleLowerCase() === "org_read_only")
        return Role.ORG_READ_ONLY
    if (role?.toLocaleLowerCase() === "org_access_prospect")
        return Role.ORG_ACCESS_PROSPECT
    if (role?.toLocaleLowerCase() === "read_only")
        return Role.READ_ONLY
    if (role?.toLocaleLowerCase() === "org_user_read_only")
        return Role.ORG_USER_READ_ONLY
    if (role?.toLocaleLowerCase() === "org_user_admin")
        return Role.ORG_USER_ADMIN

        return Role.UNKNOWN;
}

export class JWTToken {
    exp?: string
    iat?: string
    auth_time?: number
    jti?: string
    iss?: string
    aud?: string
    sub?: string
    typ?: string
    azp?: string
    nonce?: string
    session_state?: string
    at_hash?: string
    acr?: string
    sid?: string
    email_verified?: string
    organisation_uuid?: string
    name?: string
    preferred_username?: string
    given_name?: string
    family_name?: string
    email?: string
    role?: string
}