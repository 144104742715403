import { AddCircle, AlternateEmail, Person } from '@mui/icons-material';
import { ListItemButton, Stack, Typography } from '@mui/material';
import { FieldArray, FormikProps } from 'formik';
import { useContext, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import EasyFormColumn from '../../../components/Forms/EasyFormColumn';
import { FormType } from '../../../components/Forms/EasyFormMany';
import { EasyFormRow } from '../../../components/Forms/FormLayout';
import { AutocompleteFieldRender } from '../../../components/Forms/Renders/AutocompleteFieldRender';
import { FieldEditorRender } from '../../../components/Forms/Renders/FieldEditorRender';
import TooltipComponent from '../../../components/TooltipComponent';
import Loading from '../../../components/loading';
import { PaginationQueryPackage } from '../../../models/API/QueryParams/PaginationQueryPackage';
import { AttachContactRequest, CombinedUserContact } from '../../../models/Contact';
import { ContactLabel, LabelContextEnum } from '../../../models/ContactLabel';
import BaseOrganisation from '../../../models/Organisation';
import { CreateResponse } from '../../../redux/CreateResponse';
import { useGetAllContactLabelsQuery } from '../../ContactsApi/redux/contactLabelApiSlice';
import { PermissionEnum } from '../../Permission/PermissionEnum';
import { AbilityContext } from '../../UserApi/logic/Can';
import { useGetAllUsersByOrganisationQuery } from '../../UserApi/redux/userApiSlice';
import { CreateExternalContactButton } from '../CreateExternalContactButton';
import { useGetExternalContactsByOrganisationIdQuery } from '../redux/contactsApiSlice';
import { OrganisationContext } from '../../../components/Layout/OrganisationPicker';


export function getContactValidationScheme(t: TFunction<"translation", undefined>) {
    return Yup.object().shape({
        contacts: Yup.array().of(Yup.object().shape({
            contact: Yup.object().nullable().required(t("Required")),
            labels: Yup.array().min(1, t("At least one label should be added"))
        }))
    });
}

interface RequestWithContacts {
    contacts: AttachContactRequest[]
}

export default function ContactStep<E extends RequestWithContacts>(props: { formikProps: FormikProps<E>, formPropsStuf: any, labelContext: LabelContextEnum, owner_organisation?: BaseOrganisation, administrating_organisation?: BaseOrganisation }) {
    const [allAllowedContacts, setAllAllowedContacts] = useState<CombinedUserContact[]>([])
    const [createContactWindowIsOpen, setCreateContactWindowIsOpen] = useState<Date | undefined>(undefined);
    const [contactUuidToPreSelect, setContactUuidToPreSelect] = useState<string | undefined>(undefined)
    const [emailForNewExternalContact, setEmailForNewExternalContact] = useState<string | undefined>(undefined);

    const { t } = useTranslation();
    const ability = useContext(AbilityContext)
    const selectedOrganisation = useContext(OrganisationContext)?.organisation
    const anOrganisationIsSelected = selectedOrganisation !== undefined
    const administratingOrgSelected = anOrganisationIsSelected && selectedOrganisation.uuid == props.administrating_organisation?.uuid
    const ownerOrgSelected = anOrganisationIsSelected && selectedOrganisation.uuid == props.owner_organisation?.uuid
    const sameOwnerAndAdministratingOrg = props.administrating_organisation?.uuid == props.owner_organisation?.uuid

    const administratingUsers = useGetAllUsersByOrganisationQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.administrating_organisation?.uuid
    }, { skip: !props.administrating_organisation || !ability.can(PermissionEnum.USER_READ, props.administrating_organisation) || (anOrganisationIsSelected && !administratingOrgSelected) });

    const ownerUsers = useGetAllUsersByOrganisationQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.owner_organisation?.uuid
    }, { skip: !props.owner_organisation || !ability.can(PermissionEnum.USER_READ, props.owner_organisation) || sameOwnerAndAdministratingOrg || (anOrganisationIsSelected && !ownerOrgSelected) });

    const administratingExternalContacts = useGetExternalContactsByOrganisationIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.administrating_organisation?.uuid
    }, { skip: !props.administrating_organisation || !ability.can(PermissionEnum.EXTERNAL_CONTACT_READ, props.administrating_organisation) || (anOrganisationIsSelected && !administratingOrgSelected) })

    const ownerExternalContacts = useGetExternalContactsByOrganisationIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.owner_organisation?.uuid
    }, { skip: !props.owner_organisation || !ability.can(PermissionEnum.EXTERNAL_CONTACT_READ, props.owner_organisation) || sameOwnerAndAdministratingOrg || (anOrganisationIsSelected && !ownerOrgSelected) })

    useMemo(() => {
        const users = [...administratingUsers.data ?? [], ...ownerUsers.data ?? []];
        const usersMapped = users.map<CombinedUserContact>(user => ({
            uuid: user.uuid,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            user_uuid: user.uuid
        }));

        const contacts = [...administratingExternalContacts.data ?? [], ...ownerExternalContacts.data ?? []];
        const contactsMapped = contacts.map<CombinedUserContact>(contact => ({
            uuid: contact.uuid,
            email: contact.email,
            first_name: undefined,
            last_name: undefined,
            user_uuid: undefined
        }));

        const allowedContacts = [...usersMapped ?? [], ...contactsMapped ?? []]
        setAllAllowedContacts(allowedContacts);

    }, [
        JSON.stringify(administratingUsers.data),
        JSON.stringify(ownerUsers.data),
        JSON.stringify(administratingExternalContacts.data),
        JSON.stringify(ownerExternalContacts.data)
    ])

    const allContactlabelsResult = useGetAllContactLabelsQuery({
        pagination: new PaginationQueryPackage()
    })

    const labelsForContext = allContactlabelsResult.data?.filter(label => {
        return label.label_contexts?.includes(props.labelContext)
    })

    if (administratingUsers.isLoading ||
        ownerUsers.isLoading ||
        administratingExternalContacts.isLoading ||
        ownerExternalContacts.isLoading ||
        allContactlabelsResult.isLoading) {
        return (<Loading />)
    }

    return (
        <>
            <FieldArray
                name="contacts"
                render={arrayHelpers => (
                    <EasyFormRow
                        confirmDeletionText={t("Are you sure you wish to remove this contact?")}
                        {...props.formikProps}
                        {...props.formPropsStuf}
                        editModeAlways={true}
                        editable={true}
                        isDeleteable={true}
                        saveMutation={() => { throw "Dont save plz" }}
                        formType={FormType.LIST}
                        createNew={() => new AttachContactRequest()}
                        editMode={true}
                        getFieldRows={(request: RequestWithContacts) => {
                            const rows: Array<FieldEditorRender[]> = []

                            const newContactNotSelected = request.contacts.filter(contact => contact.contact?.uuid === contactUuidToPreSelect).length < 1;

                            if (!newContactNotSelected) {
                                setContactUuidToPreSelect(undefined);
                            }


                            let hasAutoSelectedOnce = false;

                            request.contacts?.forEach((contact) => {
                                const shouldAutoSelect = contact.contact?.uuid === undefined && request.contacts.filter(contact => contact.contact?.uuid === contactUuidToPreSelect).length < 1

                                let autoSelectedValue: string | undefined = undefined;
                                if (shouldAutoSelect && !hasAutoSelectedOnce) {

                                    hasAutoSelectedOnce = true;
                                    autoSelectedValue = contactUuidToPreSelect;
                                }
                                const newRow: FieldEditorRender[] = [
                                    new AutocompleteFieldRender<CombinedUserContact>({
                                        displayText: t("Contact"),
                                        fieldName: "contacts",
                                        columns: true,
                                        showLabel: true,
                                        nestedfieldName: "contact",
                                        multipleSelect: false,
                                        valueToAutoSelect: allAllowedContacts.find(contact => contact.uuid === autoSelectedValue),
                                        filterOptions: (options, state) => {
                                            return options.filter(option => state.inputValue == "" ? true : option.email!.toLowerCase().includes(state.inputValue.toLowerCase()))
                                        },
                                        getOptionLabelFunc: (option: CombinedUserContact) => option.email ?? "",
                                        options: allAllowedContacts ?? [],
                                        renderOption: (_props, option) => (
                                            <Stack alignItems="center" direction="row" spacing={2}>
                                                {option.user_uuid != undefined ?
                                                    <TooltipComponent title={t("Contact with user") + ""}><Person /></TooltipComponent> :
                                                    <TooltipComponent title={t("Contact without user") + ""}><AlternateEmail /></TooltipComponent>
                                                }
                                                <Typography>{option.email}</Typography>
                                            </Stack>
                                        ),
                                        noOptionsText: (input: string) => (
                                            <ListItemButton onClick={() => {
                                                setEmailForNewExternalContact(input);
                                                setCreateContactWindowIsOpen(new Date());

                                                const newValues = props.formikProps.values;
                                                const newContacts = props.formikProps.values.contacts.map(existingContact => {
                                                    if (existingContact.contact?.uuid === contact.contact?.uuid) {
                                                        return new AttachContactRequest();
                                                    }
                                                    return existingContact;
                                                })

                                                newValues.contacts = newContacts;

                                                props.formikProps.setValues(newValues)
                                            }
                                            } >
                                                <Stack spacing={1} direction="row" alignItems="center">
                                                    <AddCircle />
                                                    <Typography alignItems="center"><>{t("Create contact")}</></Typography>
                                                </Stack>
                                            </ListItemButton >
                                        ),

                                        uniqueId: "no_id"
                                    }),
                                    new AutocompleteFieldRender({
                                        displayText: "Contact labels",
                                        fieldName: "contacts",
                                        columns: true,
                                        showLabel: true,
                                        nestedfieldName: "labels",
                                        multipleSelect: true,
                                        getOptionLabelFunc: (option: ContactLabel) => option.label ?? "",
                                        options: labelsForContext ?? [],
                                        uniqueId: "no_id",
                                    }),
                                ]

                                rows.push(newRow)
                            })
                            return rows;
                        }}
                        columns={[
                            new EasyFormColumn("Contacts", "", [])
                        ]}
                        arrayHelpers={arrayHelpers} />
                )
                }
            />
            {!createContactWindowIsOpen ? <></> :
                <CreateExternalContactButton
                    initialValue={emailForNewExternalContact ?? ""}
                    ownerOrganisationUuid={selectedOrganisation?.uuid ?? props.owner_organisation?.uuid ?? ""}
                    open={true}
                    afterSaveAction={(response) => {
                        const uuid = (response as CreateResponse).uuid;
                        setContactUuidToPreSelect(uuid)
                        setCreateContactWindowIsOpen(undefined)
                    }}
                    onClose={() => {
                        setContactUuidToPreSelect(undefined)
                        setCreateContactWindowIsOpen(undefined)
                    }
                    } />}

        </>
    )
}