import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, ButtonGroup, Grid, Step, StepLabel, Stepper } from "@mui/material";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ObjectSchema } from "yup";

export interface EasyStepperProps<E> {
    nextPage: (currentPage: number, values: E) => number,
    previousPage: (currentPage: number, values: E) => number,
    submit: (toSubmit: E) => Promise<void>,
    initialValue: E,
    children?: (formikProps: FormikProps<E>) => JSX.Element,
    stepValidations: ObjectSchema<any, any>[],
    stepTitles: string[]
    steps: ((props: { formikProps: FormikProps<E>; formPropsStuf: any; }) => JSX.Element)[]
    saveButtonOverride?: (formikProps: FormikProps<E>) => JSX.Element | undefined
}

export default function EasyStepper<E extends FormikValues>(props: EasyStepperProps<E>) {

    const [activeStep, setActiveStep] = useState(0)
    const [buttonLoading, setButtonLoading] = useState(false)

    const { t } = useTranslation()

    const formPropsStuf = {
        createNew: () => { throw "plz dont" },
        setEditModeTemp: () => true
    }

    function previousStep(formikProps: FormikProps<E>) {
        formikProps.setErrors({});
        formikProps.setTouched({});
        setActiveStep(props.previousPage(activeStep, formikProps.values))
    }
    return (

        <Formik<E>
            validationSchema={props.stepValidations[activeStep]}
            initialValues={props.initialValue}
            onSubmit={(toSubmit) => {
                if (activeStep == props.stepValidations.length - 1) {
                    setButtonLoading(true)

                    props.submit(toSubmit).finally(() => setButtonLoading(false))

                    return;
                }

                setActiveStep(props.nextPage(activeStep, toSubmit))
            }}>

            {(formikProps) => {
                const validationErrors: string = Object.entries(formikProps.errors).splice(0, 1).map(value => typeof value[1] == "string" ? `${t(value[0])} : ${value[1]}` : "").join("\n");
                return (
                    <Form>
                        <Grid container position="sticky" padding={2} top={0} sx={{ backgroundColor: "white", boxShadow: "0px 10px 15px -20px rgba(0, 0, 0, 0.45);", zIndex: 100 }}>
                            <Grid item xs={12}>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {props.stepTitles.map((title, index) => (
                                        <Step key={index + 1}>
                                            <StepLabel>{t(title)}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Grid>
                        </Grid>
                        <Grid spacing={3} columns={1} container>

                            <Grid item xs={12}>
                                {props.children ? props.children(formikProps) : <></>}
                            </Grid>
                            <Grid item xs={12}>
                                {props.steps.map((Step, index) => (
                                    <Box key={Step.name + index} display={activeStep != index ? "none" : undefined}>
                                        <Step formikProps={formikProps as unknown as FormikProps<E>} formPropsStuf={formPropsStuf} />
                                    </Box>
                                ))}
                            </Grid>

                        </Grid>



                        <Grid container position="sticky" padding={2} bottom={0} sx={{ backgroundColor: "white", boxShadow: "0px -10px 15px -20px rgba(0, 0, 0, 0.45);", zIndex: 100 }}>
                            <Grid textAlign="right" item xs={12}>

                                {Object.values(formikProps.touched).length > 0 && validationErrors.length > 0 ?
                                    <Alert sx={{ marginBottom: 2 }} key={activeStep} severity="error">{validationErrors}</Alert> :
                                    <></>
                                }
                                <ButtonGroup>

                                    <Button key="previousButton" disabled={activeStep <= 0} variant="contained" onClick={() => previousStep(formikProps)} >{t("Previous")}</Button>
                                    {props.saveButtonOverride ?
                                        <>{props.saveButtonOverride(formikProps)}</> :
                                        <LoadingButton loading={buttonLoading} key="saveButton" variant="contained" type='submit' >{t(activeStep == props.steps.length - 1 ? "Save" : "Next")}</LoadingButton>
                                    }
                                </ButtonGroup>


                            </Grid>
                        </Grid>
                    </Form>
                )
            }}


        </Formik>
    )
}

