
import * as Yup from 'yup';
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import { FieldEditorRender } from "../../../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import EasyFormMany from "../../../../components/Forms/EasyFormMany";
import { CreateInCollectionButton } from "../../../../components/CreateInCollectionButton";
import { PortaliasTabProps } from "../PortaliasInfoButton";
import { AutocompleteFieldRender } from "../../../../components/Forms/Renders/AutocompleteFieldRender";
import { DirectionEnum } from "../../../../models/enums/DirectionEnum";
import { OptionEnum } from "../../../../models/enums/OptionEnum";
import { ProtocolEnum } from "../../../../models/enums/ProtocolEnum";
import AccessControlList from "../../../../models/AccessControlList";
import { useAddACLMutation, useDeleteACLMutation, useGetACLsByPortaliasIdQuery, useUpdateACLMutation } from "../../redux/accessControlistApiSlice";
import { PermissionEnum } from '../../../Permission/PermissionEnum';
import { useContext } from 'react';
import { AbilityContext } from '../../../UserApi/logic/Can';

export default function ACLTab(props: PortaliasTabProps) {
    const ability = useContext(AbilityContext)
    const SignupSchema = Yup.object().shape({
        protocol: Yup.string().required(),
        direction: Yup.string().required(),
        source_option: Yup.string().nullable().required(),
        source_start: Yup.number().nullable().when('source_option', {
            is: (source_option: string) => source_option !== "NONE",
            then: Yup.number().required()
        }),
        source_end: Yup.number().nullable().when('source_option', {
            is: (source_option: string) => source_option == "RANGE",
            then: Yup.number().required()
        }),
        destination_option: Yup.string().nullable().required(),
        destination_start: Yup.number().nullable().when('destination_option', {
            is: (destination_option: string) => destination_option !== "NONE",
            then: Yup.number().required()
        }),
        destination_end: Yup.number().nullable().when('destination_option', {
            is: (destination_option: string) => destination_option == "RANGE",
            then: Yup.number().required()
        }),
    });

    const getACLRows = (resultData: AccessControlList[] | undefined) => {
        const rows: Array<FieldEditorRender[]> = []
        resultData?.forEach(() => {
            const newRow: FieldEditorRender[] = [
                new AutocompleteFieldRender({
                    displayText: "Protocol",
                    fieldName: "",
                    nestedfieldName: "protocol",
                    getOptionLabelFunc: (option: ProtocolEnum) => option.toString() ?? "",
                    options: Object.values(ProtocolEnum) ?? [],
                    uniqueId: props.portaliasId
                }),
                new AutocompleteFieldRender({
                    displayText: "Direction",
                    nestedfieldName: "direction",
                    fieldName: "",
                    getOptionLabelFunc: (option: DirectionEnum) => option.toString() ?? "",
                    options: Object.values(DirectionEnum) ?? [],
                    uniqueId: props.portaliasId
                }),
                new AutocompleteFieldRender({
                    displayText: "Source option",
                    nestedfieldName: "source_option",
                    fieldName: "",
                    getOptionLabelFunc: (option: OptionEnum) => option.toString() ?? "",
                    options: Object.values(OptionEnum) ?? [],
                    uniqueId: props.portaliasId
                }),
                new TextFieldRender({
                    displayText: "Source start",
                    nestedfieldName: "source_start",
                    fieldName: "",
                    type: "number",
                    uniqueId: props.portaliasId,
                    conditionFieldName: "source_option",
                    conditionShouldBeDisabledFunc: (option: OptionEnum) => option == OptionEnum.NONE
                }),
                new TextFieldRender({
                    displayText: "Source end",
                    nestedfieldName: "source_end",
                    type: "number",
                    fieldName: "",
                    uniqueId: props.portaliasId,
                    conditionFieldName: "source_option",
                    conditionShouldBeDisabledFunc: (option: OptionEnum) => option != OptionEnum.RANGE
                }),
                new AutocompleteFieldRender({
                    displayText: "Destination option",
                    fieldName: "",
                    nestedfieldName: "destination_option",
                    getOptionLabelFunc: (option: OptionEnum) => option.toString() ?? "",
                    options: Object.values(OptionEnum) ?? [],
                    uniqueId: props.portaliasId
                }),
                new TextFieldRender({
                    displayText: "Destination start",
                    nestedfieldName: "destination_start",
                    fieldName: "",
                    type: "number",
                    uniqueId: props.portaliasId,
                    conditionFieldName: "destination_option",
                    conditionShouldBeDisabledFunc: (option: OptionEnum) => option == OptionEnum.NONE
                }),
                new TextFieldRender({
                    displayText: "Destination end",
                    nestedfieldName: "destination_end",
                    type: "number",
                    fieldName: "",
                    uniqueId: props.portaliasId,
                    conditionFieldName: "destination_option",
                    conditionShouldBeDisabledFunc: (option: OptionEnum) => option != OptionEnum.RANGE
                })
            ]


            rows.push(newRow)
        })
        return rows;
    }


    return (
        <EasyFormMany
            confirmDeletionText="Are you sure you wish to delete this ACL?"
            isAllowedToDelete={(serviceResponse) => ability.can(PermissionEnum.GOD, serviceResponse) && !props.isPortaliasInUse}
            isAllowedToEdit={(serviceResponse) => ability.can(PermissionEnum.GOD, serviceResponse) && !props.isPortaliasInUse}
            isAllowedToCreate={(serviceResponse) => ability.can(PermissionEnum.GOD, serviceResponse) && !props.isPortaliasInUse}
            ressourceOwnerId={props.portaliasId}
            arrayPath=""
            getManyByIdAction={useGetACLsByPortaliasIdQuery}
            saveMutation={useUpdateACLMutation}
            deleteMutation={useDeleteACLMutation}
            createButtonJsx={
                <CreateInCollectionButton
                    validationScheme={SignupSchema}
                    ressourceOwnerId={props.portaliasId}
                    fieldEditorRenders={getACLRows([new AccessControlList()]).find(() => true)!}
                    tooltip="Create Access control list"
                    createNew={() => new AccessControlList()}
                    saveMutation={useAddACLMutation} />
            }
            editButtonJsx={(dnsSuffix: AccessControlList) =>
                <CreateInCollectionButton
                    validationScheme={SignupSchema}
                    toEdit={dnsSuffix}
                    createNew={() => { throw new Error("Dont create new here") }}
                    ressourceOwnerId={props.portaliasId}
                    fieldEditorRenders={getACLRows([new AccessControlList()]).find(() => true)!}
                    tooltip="Edit Access control list"
                    saveMutation={useUpdateACLMutation} />
            }

            SignupSchema={SignupSchema}
            idToFetch={props.portaliasId}
            columns={[
                new EasyFormColumn("ACL", "", [])
            ]}
            getFieldRows={getACLRows}
            newButtonTooltip="Add new ACL"
        />
    )
}