import { HorizontalSplit, Settings, VerticalSplit } from '@mui/icons-material';
import { Button, ButtonGroup, Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import { useTranslation } from "react-i18next";
import { BaseBadge } from '../../../components/Badges/BaseBadge';
import { OrganisationBadge } from '../../../components/Badges/OrganisationBadge';
import { UserBadge } from '../../../components/Badges/UserBadge';
import { DateToFormattedString } from '../../../components/Logic/DateLogic';
import TooltipComponent from '../../../components/TooltipComponent';
import Loading from "../../../components/loading";
import { organisationApiSlice } from '../../OrganisationApi/redux/organisationApiSlice';
import { EventTabProps } from "../EventInfoButton";

export default function InfoTab(props: EventTabProps) {
    const { t } = useTranslation()
    const organisationLazy = organisationApiSlice.useLazyGetOrganisationByIdQuery();

    const [organisationBadge, setOrganisationBadge] = useState<JSX.Element | undefined>(<></>)
    const [userBadge, setUserBadge] = useState<JSX.Element | undefined>(<></>)
    const ressourceBadge = <>
        <BaseBadge content={<>{props.event.resource}</>} />
        <BaseBadge content={<>{props.event.identifier}</>} />
    </>

    const [loading, setLoading] = useState(true)

    useMemo(() => {
        const organisationLazyTrigger = organisationLazy[0];

        let organisationPromise = new Promise(() => { /* empty */ });

        if (props.event.system_user) {
            organisationPromise = Promise.resolve([]);
            setOrganisationBadge(<></>)
            setUserBadge(<UserBadge textOverride={t("system")} />)
        } else {
            setUserBadge(<UserBadge ressource={props.event.user} />)
            organisationPromise = organisationLazyTrigger(props.event.organisation_uuid!)
                .unwrap()
                .then((res) =>
                    setOrganisationBadge(<OrganisationBadge ressource={res} />))
                .catch(() => setOrganisationBadge(<OrganisationBadge textOverride={props.event.organisation_uuid!} />))

        }
        Promise.all([organisationPromise]).then(() => setLoading(false))
    }, [])

    const [splitView, setSplitView] = useState(true);
    if (loading)
        return <Loading />

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card elevation={2}>
                    <CardHeader title={
                        <Grid container>
                            <Grid item xs={6}>
                                <Stack direction="row" spacing={2}>
                                    <ButtonGroup>
                                        <TooltipComponent title={t("Split view")}>
                                            <Button disabled={splitView} onClick={() => setSplitView(true)}> <VerticalSplit /> </Button>
                                        </TooltipComponent>
                                        <TooltipComponent title={t("Unified view")}>
                                            <Button disabled={!splitView} onClick={() => setSplitView(false)}> <HorizontalSplit /> </Button>
                                        </TooltipComponent>
                                    </ButtonGroup>
                                    {ressourceBadge}
                                    <BaseBadge content={<>{props.event.operation}</>} icon={Settings} />
                                    <BaseBadge content={<>{DateToFormattedString(props.event.audit_event_date_time)}</>} />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="row-reverse" spacing={2}>
                                    {organisationBadge}
                                    {userBadge}
                                </Stack>

                            </Grid>
                        </Grid>
                    } />
                    <CardContent>
                        <ReactDiffViewer
                            oldValue={JSON.stringify(props.event.audit_data?.before, null, 2)}
                            newValue={JSON.stringify(props.event.audit_data?.after, null, 2)}
                            compareMethod={DiffMethod.LINES}
                            splitView={splitView}
                            showDiffOnly={true}
                        />
                    </CardContent>
                </Card>



            </Grid>
        </Grid >
    )
}