import LoadingButton from '@mui/lab/LoadingButton';
import { Button, ButtonGroup, Grid, Stack, Typography } from "@mui/material";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, MutationDefinition, QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { MutationTrigger, UseMutation, UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { FieldArray, Form, Formik } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Error } from "../../models/API/Error";
import { RequestMutationWrapper } from "../../models/API/RequestMutationWrapper";
import { ConfirmationButton } from "../ConfirmationButton";
import ErrorCodeHook from "../DTO/ErrorCodeHook";
import Loading from "../loading";
import EasyFormColumn from "./EasyFormColumn";
import { EasyFormRow, EasyFormSideBySide } from "./FormLayout";
import { FieldEditorRender } from "./Renders/FieldEditorRender";

export enum FormType {
    INFORMATION,
    LIST,
}

export interface EasyFieldProps {
    values: any
    touched: any
    errors: any
    handleChange: any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    minWidth: string | number
    submitCount: number
}

export interface EasyFormProps<CustomType> {
    SignupSchema: any
    idToFetch?: string
    createNew: () => CustomType
    ressourceOwnerId?: string //If we are about to update an element in a collection owned by a specific fx organisation we set the ressourceOwnerId to the organisation-id
    editable?: boolean
    isDeleteable?: boolean
    editModeAlways?: boolean
    showResetButton?: boolean
    arrayPath: string
    isAllowedToEdit: (entity: CustomType) => boolean
    columns: EasyFormColumn[]
    errorToMutation?: ErrorCodeHook<CustomType>[]
    formType: FormType
    extraButtons?: JSX.Element
    buttomJSX?: (agreement: CustomType, saveMutation: MutationTrigger<MutationDefinition<RequestMutationWrapper<CustomType>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, CustomType, any>>) => JSX.Element
    newButtonTooltip?: string
    initialValue?: CustomType
    saveMutation: UseMutation<MutationDefinition<RequestMutationWrapper<CustomType>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, any, any>>
    getByIdAction?: UseQuery<QueryDefinition<string, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, CustomType, any>>
    afterSaveAction?: (organisation: CustomType) => void
    getFieldRows: (organisation: CustomType) => Array<FieldEditorRender[]>
}


export default function EasyForm<CustomType>(props: EasyFormProps<CustomType>) {
    let organisation: CustomType | undefined = props.initialValue
    const showResetButton = props.showResetButton ?? false;

    let isFetchingOrganisation = false
    if (props.initialValue == undefined && props.getByIdAction && props.idToFetch) {
        const { data, isLoading } = props.getByIdAction(props.idToFetch)
        organisation = data;
        isFetchingOrganisation = isLoading
    }


    const { t } = useTranslation()
    const [editModeTemp, setEditModeTemp] = useState(false);
    const [error, setError] = useState<Error | undefined>(undefined);
    const editMode = props.editModeAlways ?? editModeTemp;

    const errorCodeHook: ErrorCodeHook<CustomType> | undefined = error != undefined || props.errorToMutation ? props.errorToMutation?.find(errorCodeHook => errorCodeHook.errorCode == error?.error_code) : undefined;
    const mutation = errorCodeHook?.hook ?? props.saveMutation
    const [mutationAction, { isLoading: loadingSave }] = mutation()

    const [initialValue, setInitialValue] = useState(organisation);

    useMemo(() => {
        if (isFetchingOrganisation)
            return (<Loading />)

        if (organisation == undefined) {
            organisation = props.createNew()
        }

        setInitialValue(organisation);

    }, [])


    const isAllowedToEdit = props.isAllowedToEdit(organisation!);
    return (
        <Formik
            key={JSON.stringify(initialValue)}
            initialValues={initialValue as CustomType}
            validationSchema={props.SignupSchema}
            onSubmit={async (values) => {

                await mutationAction({ parentId: props.ressourceOwnerId, data: values }).unwrap().then(updatedOrg => {
                    setEditModeTemp(false)

                    if (props.afterSaveAction && updatedOrg)
                        props.afterSaveAction(updatedOrg);

                }).catch((error: { data: Error }) => {
                    setError(error.data);
                })
            }}
        >

            {(formikProps) => {

                return (
                    <Form
                        onResetCapture={() => setEditModeTemp(false)}
                    >
                        <FieldArray
                            name={props.arrayPath}
                            render={arrayHelpers => (
                                <Grid spacing={10} columns={props.columns.length} container>
                                    {props.formType == FormType.INFORMATION ? <EasyFormSideBySide {...formikProps} setEditModeTemp={setEditModeTemp} columns={props.columns} editMode={editMode} /> : <></>}
                                    {props.formType == FormType.LIST ? <EasyFormRow confirmDeletionText={"Are you sure you wish to delete this entity?"}  {...formikProps} setEditModeTemp={setEditModeTemp} editMode={editMode} {...props} arrayHelpers={arrayHelpers} /> : <></>}
                                </Grid>
                            )
                            }
                        />
                        {(props.editable == undefined || props.editable) && isAllowedToEdit ?
                            <Stack direction="row" paddingTop={2}>
                                {
                                    editMode ?
                                        <>
                                            <ButtonGroup>
                                                {error != undefined && errorCodeHook != undefined ?
                                                    <ConfirmationButton action={async () => { formikProps.handleSubmit() }} type="submit" variant="contained" title={t(errorCodeHook.message)} buttonText={t("Force save")}>
                                                        <Stack>
                                                            <Typography>{t(errorCodeHook.confirmMessage) + " "}</Typography>
                                                        </Stack>
                                                    </ConfirmationButton> :
                                                    <LoadingButton variant="contained" loading={loadingSave} type="submit" >
                                                        <>{t("Save") + ""}</>
                                                    </LoadingButton>
                                                }
                                                {showResetButton ? <Button type="reset">{t("Reset") + ""}</Button> : <></>}
                                                {props.extraButtons ?? <></>}
                                            </ButtonGroup>
                                        </>
                                        : <ButtonGroup>
                                            <Button key={crypto.randomUUID()} variant="contained" type="button" onClick={() => setEditModeTemp(true)}>{t("Edit") + ""}</Button>
                                        </ButtonGroup>
                                }
                            </ Stack>
                            : <></>
                        }
                        {props.buttomJSX ? props.buttomJSX(props.initialValue ?? (organisation as CustomType), mutationAction) : <></>}
                    </Form >)
            }
            }


        </Formik >
    )
}