
import getEnvironment from '../../../env';
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper';
import { ServiceEndpoint } from '../../../models/Service';
import HandleQuery from '../../../redux/EndpointQueryHandler';
import { GetManyPackage } from '../../../redux/GetManyPackage';
import handleResponse from '../../../redux/handleResponse';
import { portalApiSlice } from '../../../redux/portalApiSlice';
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse';

const baseurl = getEnvironment().REACT_APP_API_BASEURL;
// Define a endpoint using a base URL and expected endpoints
export const endpointApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllEndpoints: builder.query<ServiceEndpoint[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/endpoints`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Endpoints could not be fetched" }),
        pagination : pack.pagination
      }),
      transformResponse: (a: ServiceEndpoint[], b, c) => transformMultipleResponseToRightType(ServiceEndpoint, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'endpoints',
        ...result.map(({ uuid }) => ({ type: 'endpoints', uuid: uuid }) as const)
      ]
    }),
    getEndpointById: builder.query<ServiceEndpoint, string>({
      query: (uuid) => HandleQuery({
        url: `${baseurl}/endpoints/${uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Endpoint could not be fetched" }),
      }),
      transformResponse: (a: ServiceEndpoint, b, c) => transformSingleResponseToRightType(ServiceEndpoint, a, b, c),
      providesTags: (_result, _error, uuid) => [{ type: 'endpoints', uuid: uuid }]
    }),
    getEndpointsByServiceId: builder.query<ServiceEndpoint[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/services/${pack.uuid}/endpoints`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Endpoints could not be fetched" }),
        pagination : pack.pagination
      }),
      transformResponse: (a: ServiceEndpoint[], b, c) => transformMultipleResponseToRightType(ServiceEndpoint, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'endpoints',
        ...result.map(({ uuid }) => ({ type: 'endpoints', uuid: uuid }) as const)
      ]
    }),
    updateEndpoint: builder.mutation<ServiceEndpoint, RequestMutationWrapper<ServiceEndpoint>>({
      query: (endpoint) => HandleQuery({
        url: `${baseurl}/endpoints/${endpoint.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Endpoint was updated!", toastErrorText: "Endpoint could not be updated" }),
        method: "PATCH",
        body: {
          subnet_uuid: endpoint.data.subnet?.uuid,
          dns_suffix_uuid: endpoint.data.dns_suffix?.uuid,
          portalias_uuids : endpoint.data.ports.map(port => port.uuid),
          dns_name: endpoint.data.dns_name,
          ip: endpoint.data.ip

        },
      }),
      transformResponse: (a: ServiceEndpoint, b, c) => transformSingleResponseToRightType(ServiceEndpoint, a, b, c),
      invalidatesTags: (_result, _error, args) => [{ type: 'endpoints', uuid: args.data.uuid }]
    }),
    forceUpdateEndpoint: builder.mutation<ServiceEndpoint, RequestMutationWrapper<ServiceEndpoint>>({
      query: (endpoint) => HandleQuery({
        url: `${baseurl}/endpoints/${endpoint.data.uuid}/force`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Endpoint was updated!", toastErrorText: "Endpoint could not be updated" }),
        method: "PATCH",
        body: {
          subnet_uuid: endpoint.data.subnet?.uuid,
          dns_suffix_uuid: endpoint.data.dns_suffix?.uuid,
          portalias_uuids : endpoint.data.ports.map(port => port.uuid),
          dns_name: endpoint.data.dns_name,
          ip: endpoint.data.ip

        },
      }),
      transformResponse: (a: ServiceEndpoint, b, c) => transformSingleResponseToRightType(ServiceEndpoint, a, b, c),
      invalidatesTags: (_result, _error, args) => [{ type: 'endpoints', uuid: args.data.uuid }]
    }),
    addEndpoint: builder.mutation<ServiceEndpoint, RequestMutationWrapper<ServiceEndpoint>>({
      query: (endpoint) => HandleQuery({
        url: `${baseurl}/services/${endpoint.parentId}/endpoints`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Endpoint was created", toastErrorText: "Endpoint could not be created" }),
        method: 'POST',
        body: {
          ip: endpoint.data.ip,
          dns_name: endpoint.data.dns_name,
          portalias_uuids: endpoint.data.ports.map(port => port.uuid),
          subnet_uuid: endpoint.data.subnet?.uuid,
          dns_suffix_uuid: endpoint.data.dns_suffix?.uuid
        },
      }),
      transformResponse: (a: ServiceEndpoint, b, c) => transformSingleResponseToRightType(ServiceEndpoint, a, b, c),

      invalidatesTags: ['endpoints'],
    }),
    forceAddEndpoint: builder.mutation<ServiceEndpoint, RequestMutationWrapper<ServiceEndpoint>>({
      query: (endpoint) => HandleQuery({
        url: `${baseurl}/services/${endpoint.parentId}/endpoints/force`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Endpoint was created", toastErrorText: "Endpoint could not be created" }),
        method: 'POST',
        body: {
          ip: endpoint.data.ip,
          dns_name: endpoint.data.dns_name,
          portalias_uuids: endpoint.data.ports.map(port => port.uuid),
          subnet_uuid: endpoint.data.subnet?.uuid,
          dns_suffix_uuid: endpoint.data.dns_suffix?.uuid
        },
      }),
      transformResponse: (a: ServiceEndpoint, b, c) => transformSingleResponseToRightType(ServiceEndpoint, a, b, c),
      invalidatesTags: ['endpoints'],
    }),
    deleteEndpoint: builder.mutation<void, RequestMutationWrapper<ServiceEndpoint>>({
      query: (endpoint) => HandleQuery({
        url: `${baseurl}/endpoints/${endpoint.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Endpoint was deleted!", toastErrorText: "Endpoint could not be deleted" }),
      }),
      
      invalidatesTags: (_result, _error, args) => [{ type: 'endpoints', uuid: args.data.uuid }]
    }),
  }),
})


export const { useGetAllEndpointsQuery, useGetEndpointByIdQuery, useDeleteEndpointMutation, useUpdateEndpointMutation, useForceUpdateEndpointMutation, useGetEndpointsByServiceIdQuery, useAddEndpointMutation, useForceAddEndpointMutation } = endpointApiSlice
















