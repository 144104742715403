import BaseModel from "./BaseModels/BaseModel"

export class ContactLabel extends BaseModel {
  label?: string
  comment?: string
  required?: boolean
  label_contexts?: LabelContextEnum[]
}

export class ContactLabelResponse extends ContactLabel {
}


export class ContactLabelRequest extends ContactLabel {
}

export enum LabelContextEnum {
  SERVICE = "SERVICE",
  CLIENT = "CLIENT",
  ORGANISATION = "ORGANISATION"
}