
import getEnvironment from '../../env';
import { RequestMutationWrapper } from '../../models/API/RequestMutationWrapper';
import HostingsiteBase, { CreateHostingSiteRequest, HostingsiteResponse } from '../../models/Hostingsite';
import { CreateResponse } from '../../redux/CreateResponse';
import HandleQuery from '../../redux/EndpointQueryHandler';
import { GetManyPackage } from '../../redux/GetManyPackage';
import handleResponse from '../../redux/handleResponse';
import { portalApiSlice } from '../../redux/portalApiSlice';
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../redux/transformResponse';

const baseurl = getEnvironment().REACT_APP_API_BASEURL;


export const hostingsitesApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllHostingsites: builder.query<HostingsiteResponse[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/hosting-sites`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Hostingsites could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: HostingsiteResponse[], b, c) => transformMultipleResponseToRightType(HostingsiteResponse, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'hosting-sites',
        ...result.map(({ uuid }) => ({ type: 'hosting-sites', uuid: uuid }) as const)
      ]
    }),
    getHostingsitesByOrganisationId: builder.query<HostingsiteResponse[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/organisations/${pack.uuid}/hosting-sites`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Hostingsites could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: HostingsiteResponse[], b, c) => transformMultipleResponseToRightType(HostingsiteResponse, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'hosting-sites',
        ...result.map(({ uuid }) => ({ type: 'hosting-sites', uuid: uuid }) as const)
      ]
    }),
    getHostingsiteById: builder.query<HostingsiteResponse, string>({
      query: (uuid) => HandleQuery({
        url: `${baseurl}/hosting-sites/${uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Hostingsite could not be fetched" }),
      }),
      transformResponse: (a: HostingsiteResponse, b, c) => transformSingleResponseToRightType(HostingsiteResponse, a, b, c),
      providesTags: (_result, _error, uuid) => [{ type: 'hosting-sites', uuid: uuid }]
    }),
    updateHostingsite: builder.mutation<HostingsiteBase, RequestMutationWrapper<HostingsiteBase>>({
      query: (hostingsite) => HandleQuery({
        url: `${baseurl}/hosting-sites/${hostingsite.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Hostingsite was updated!", toastErrorText: "Hostingsite could not be updated" }),
        method: "PATCH",
        body: hostingsite.data
      }),
      transformResponse: (a: HostingsiteBase, b, c) => transformSingleResponseToRightType(HostingsiteBase, a, b, c),
      invalidatesTags: (_result, _error, args) => [{ type: 'hosting-sites', uuid: args.data.uuid }]
    }),
    moveOrganisationsHostingsite: builder.mutation<HostingsiteBase, RequestMutationWrapper<HostingsiteBase>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/hosting-sites/${request.data.uuid}/organisations`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Hostingsite was updated!", toastErrorText: "Hostingsite could not be updated" }),
        method: "PATCH",
        body: {
          administrating_organisation_uuid: request.data.administrating_organisation?.uuid,
          owner_organisation_uuid: request.data.owner_organisation?.uuid,
        }
      }),
      transformResponse: (a: HostingsiteBase, b, c) => transformSingleResponseToRightType(HostingsiteBase, a, b, c),
      invalidatesTags: (_result, _error, args) => [{ type: 'hosting-sites', uuid: args.data.uuid }]
    }),
    addHostingsite: builder.mutation<CreateResponse, RequestMutationWrapper<CreateHostingSiteRequest>>({
      query: (hostingsite) => HandleQuery({
        url: `${baseurl}/organisations/${hostingsite.data.organisation?.uuid}/hosting-sites`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Hostingsite was created!", toastErrorText: "Hostingsite could not be created" }),
        method: 'POST',
        body: hostingsite.data,
      }),
      transformResponse: (a: CreateResponse, b, c) => transformSingleResponseToRightType(CreateResponse, a, b, c),

      invalidatesTags: ['hosting-sites'],
    }),
    deleteHostingsite: builder.mutation<void, RequestMutationWrapper<HostingsiteBase>>({
      query: (hostingsite) => HandleQuery({
        url: `${baseurl}/hosting-sites/${hostingsite.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Hostingsite was deleted!", toastErrorText: "Hostingsite could not be deleted" }),
        method: 'DELETE'
      }),
      
      invalidatesTags: (_result, _error, args) => [{ type: 'hosting-sites', uuid: args.data.uuid }]
    }),
  }),
})

export const { useGetAllHostingsitesQuery ,useGetHostingsitesByOrganisationIdQuery, useDeleteHostingsiteMutation, useMoveOrganisationsHostingsiteMutation, useGetHostingsiteByIdQuery, useUpdateHostingsiteMutation, useAddHostingsiteMutation } = hostingsitesApiSlice
















