'use client';

import { LicenseInfo } from '@mui/x-license';
import getEnvironment from './env';

/*
**License key security**
The license key is checked without making any network requests—it's designed to be public. It's expected that the license key will be exposed in a JavaScript bundle; we simply ask licensed users not to actively publicize their license key.
- https://mui.com/x/introduction/licensing/#license-key
*/
LicenseInfo.setLicenseKey(getEnvironment().REACT_APP_MUI_LICENSE_KEY);

export default function MuiXLicense() {
    return null;
}