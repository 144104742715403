import { AuthUser } from "./AuthUser"
import BaseModel from "./BaseModels/BaseModel"

export default class Event extends BaseModel {
    constructor() {
        super()
        this.uuid = this.identifier ?? ""
    }
    organisation_uuid?: string
    source?: string
    resource?: string
    operation?: OperationEnum
    identifier?: string
    audit_event_date_time?: string
    user?: AuthUser
    audit_data?: auditData
    system_user?: boolean
}

export class auditData {
    before?: any
    after?: any
}

export enum OperationEnum {
    CREATE = "CREATE",
    LOGIN = "LOGIN",
    LOGIN_ERROR = "LOGIN_ERROR",
    UPDATE = "UPDATE",
    DELETE = "DELETE",
    USE = "USE",
    PROVISION = "PROVISION"
}