
import getEnvironment from '../../../env'
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper'
import { Subnet } from '../../../models/Subnet'
import HandleQuery from '../../../redux/EndpointQueryHandler'
import { GetManyPackage } from '../../../redux/GetManyPackage'
import handleResponse from '../../../redux/handleResponse'
import { portalApiSlice } from '../../../redux/portalApiSlice'
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse'

const baseurl = getEnvironment().REACT_APP_API_BASEURL;


export const subnetApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubnetById: builder.query<Subnet, RequestMutationWrapper<string>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/organisations/${request.parentId}/subnets/${request.data}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Subnet could not be fetched" })
      }),
      transformResponse: (a: Subnet, b, c) => transformSingleResponseToRightType(Subnet, a, b, c),
      providesTags: (_result, _error, uuid) => [{ type: 'subnets', uuid: uuid }]
    }),
    getSubnetsByHostingsiteId: builder.query<Subnet[], GetManyPackage>({
      query: (getManyPackage) => HandleQuery({
        url: `${baseurl}/hosting-sites/${getManyPackage.uuid}/subnets`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Subnets could not be fetched" }),
        pagination: getManyPackage.pagination
      }),
      transformResponse: (a: Subnet[], b, c) => transformMultipleResponseToRightType(Subnet, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'subnets',
        ...result.map(({ uuid }) => ({ type: 'subnets', uuid: uuid }) as const)
      ]
    }),
    updateSubnet: builder.mutation<Subnet, RequestMutationWrapper<Subnet>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/subnets/${request.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Subnet was updated!", toastErrorText: "Subnet could not be updated" }),
        method: "PATCH",
        body: request.data
      }),
      transformResponse: (a: Subnet, b, c) => transformSingleResponseToRightType(Subnet, a, b, c),
      invalidatesTags: (_result, _error, args) => [{ type: 'subnets', uuid: args.data.uuid }]
    }),
    addSubnet: builder.mutation<Subnet, RequestMutationWrapper<Subnet>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/hosting-sites/${request.data.hosting_site?.uuid}/subnets`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Subnet was created", toastErrorText: "Subnet could not be created" }),
        method: 'POST',
        body: request.data,
      }),
      transformResponse: (a: Subnet, b, c) => transformSingleResponseToRightType(Subnet, a, b, c),

      invalidatesTags: ['subnets'],
    }),
    deleteSubnet: builder.mutation<void, RequestMutationWrapper<Subnet>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/subnets/${request.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Subnet was deleted!", toastErrorText: "Subnet could not be deleted" }),
      }),
      
      invalidatesTags: (_result, _error, args) => [{ type: 'subnets', uuid: args.data.uuid }]
    }),
  }),
})


export const { useGetSubnetsByHostingsiteIdQuery, useDeleteSubnetMutation, useAddSubnetMutation, useUpdateSubnetMutation } = subnetApiSlice
















