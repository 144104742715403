import { Typography } from '@mui/material';
import { FieldArray, FormikProps } from 'formik';
import { useMemo, useState } from 'react';
import { TFunction, useTranslation } from "react-i18next";
import * as Yup from 'yup';
import EasyFormColumn from '../../../components/Forms/EasyFormColumn';
import { FormType } from '../../../components/Forms/EasyFormMany';
import { EasyFormRow } from '../../../components/Forms/FormLayout';
import { AutocompleteFieldRender } from '../../../components/Forms/Renders/AutocompleteFieldRender';
import { FieldEditorRender } from '../../../components/Forms/Renders/FieldEditorRender';
import { IpRender } from '../../../components/Forms/Renders/IpRender';
import { TextFieldRender } from '../../../components/Forms/Renders/TextFieldRender';
import { TextRenderer } from '../../../components/Forms/Renders/TextRenderer';
import { dnsRegex } from '../../../components/Forms/Validations/DNSValidation';
import { IsIpAddressInSubnet, subnetToStringArray } from '../../../components/Forms/Validations/IsIpAddressValidation';
import Loading from '../../../components/loading';
import TooltipComponent from '../../../components/TooltipComponent';
import getEnvironment from '../../../env';
import { PaginationQueryPackage } from '../../../models/API/QueryParams/PaginationQueryPackage';
import { DNSSuffix } from '../../../models/DNSSuffix';
import Portalias from '../../../models/Portalias';
import { CreateServiceRequest, ServiceEndpoint } from '../../../models/Service';
import { Subnet } from '../../../models/Subnet';
import { hostingsitesApiSlice } from '../../HostingSiteApi/hostingsitesApiSlice';
import { DnsSuffixApiSlice } from '../../OrganisationApi/redux/dnsSuffixApiSlice';
import { useGetSubnetsByHostingsiteIdQuery } from '../../OrganisationApi/redux/subnetApiSlice';
import { PortaliasSimpleDetails } from '../../PortaliasApi/PortaliasSimpleDetails';
import { useGetAllPortaliasQuery } from '../../PortaliasApi/redux/portaliasApiSlice';

export function getEndpointValidationScheme(t: TFunction<"translation", undefined>) {
    return Yup.object().shape({
        endpoints: Yup.array().of(Yup.object().shape({
            subnet: Yup.object().nullable().test('isInHostingsite', t("Subnet does not exist in hostingsite"), (value, ctx) => {
                const hostingsite = (ctx as unknown as any).from[2].value.hosting_site
                const currentSubnet = value as unknown as Subnet;
                const isInHostingsite = hostingsite.uuid == currentSubnet?.hosting_site?.uuid
                return (value != undefined && !value) || isInHostingsite; // Undefined, same if I use ctx.resolve(yup.ref('first.type'))
            }).required(t('Required')),
            dns_name: Yup.string().matches(dnsRegex, t('Invalid DNS name')).required(t('Required')),
            dns_suffix: Yup.object().nullable().required(t('Required')),
            ports: Yup.array().default([]).min(1, t('At least one port should be added')).required(t('Required')),
            ip: IsIpAddressInSubnet("subnet")
        }))
    })
}



export default function EndpointStep(props: { formikProps: FormikProps<CreateServiceRequest>, formPropsStuf: any }) {

    const portaliasesResult = useGetAllPortaliasQuery({ pagination: new PaginationQueryPackage() });
    const dnsLazy = DnsSuffixApiSlice.useLazyGetDnsSuffixsByHostingSiteIdQuery();
    const dnsLazyTrigger = dnsLazy[0];
    const hostingsitesLazy = hostingsitesApiSlice.useLazyGetHostingsitesByOrganisationIdQuery();

    const hostingSiteUuid = props.formikProps.values.hosting_site?.uuid
    const [dnsResults, setDnsResults] = useState<DNSSuffix[]>([])
    const [selectedSubnets, setSelectedSubnets] = useState<Record<string, string>>({})
    const [ipOptions, setIpOptions] = useState<Record<string, string[]>>({})

    const subnets = useGetSubnetsByHostingsiteIdQuery(
        { uuid: hostingSiteUuid, pagination: new PaginationQueryPackage() },
        { skip: hostingSiteUuid === undefined });

    const { t } = useTranslation();

    useMemo(() => {
        const updateIpOptions = { ...ipOptions }
        Object.values(selectedSubnets).forEach(subnet => {
            if (updateIpOptions[subnet] === undefined) {
                updateIpOptions[subnet] = subnetToStringArray(subnet)
            }
        })
        setIpOptions(updateIpOptions)
    }, [selectedSubnets])

    useMemo(() => {
        setDnsResults([])
        if (hostingSiteUuid) {
            dnsLazyTrigger({
                pagination: new PaginationQueryPackage(),
                uuid: hostingSiteUuid
            }).unwrap()
                .then(result => setDnsResults(result))
                .catch(() => setDnsResults([]))
        }


    }, [hostingSiteUuid])


    function getRows(
        moveServiceRequest: CreateServiceRequest,
        dnsResults: DNSSuffix[],
        portaliasResults: Portalias[]
    ): Array<FieldEditorRender[]> {

        const rows: Array<FieldEditorRender[]> = []

        moveServiceRequest.endpoints?.forEach((endpoint) => {
            const newRow: FieldEditorRender[] = [
                new TextRenderer({
                    displayText: "DNS preview",
                    fieldName: "endpoints",
                    nestedfieldName: "dns_name",
                    suffixText: ".",
                    columns: 12,
                    uniqueId: endpoint.uuid!,
                    extraText: new TextRenderer({
                        displayText: "DNS preview",
                        columns: true,
                        fieldName: "endpoints",
                        nestedfieldName: "dns_suffix.dns_suffix",
                        suffixText: endpoint.dns_suffix?.dns_suffix === "" ? "" : "." + getEnvironment().REACT_APP_DSDN_DOMAIN,
                        uniqueId: endpoint.uuid!
                    })
                }),
                new AutocompleteFieldRender({
                    displayText: "SDN Subnet",
                    fieldName: "endpoints",
                    nestedfieldName: "subnet",
                    columns: "auto",
                    minWidth: 300,
                    showLabel: true,
                    options: subnets.data ?? [],
                    onChange: (subnet: Subnet) => {
                        const updatedSelectedSubnets = { ...selectedSubnets }
                        if (subnet?.subnet === undefined) {
                            delete updatedSelectedSubnets[endpoint.uuid]
                        } else {
                            updatedSelectedSubnets[endpoint.uuid] = subnet.subnet
                        }
                        setSelectedSubnets(updatedSelectedSubnets)
                    },
                    getOptionLabelFunc: (option: Subnet) => option.subnet ?? "",
                    uniqueId: endpoint.uuid!
                }),
                new IpRender({
                    fieldName: "endpoints",
                    nestedfieldName: "ip",
                    options: ipOptions[selectedSubnets[endpoint.uuid]] ?? [],
                    uniqueId: endpoint.uuid,
                }),
                new TextFieldRender({
                    displayText: "DNS name",
                    fieldName: "endpoints",
                    showLabel: true,
                    nestedfieldName: "dns_name",
                    columns: 3,
                    type: "text",
                    uniqueId: endpoint.uuid!
                }),
                new AutocompleteFieldRender({
                    displayText: "DNS suffix",
                    fieldName: "endpoints",
                    nestedfieldName: "dns_suffix",
                    minWidth: 200,
                    showLabel: true,
                    columns: true,
                    options: dnsResults,
                    getOptionLabelFunc: (option: DNSSuffix) => option.dns_suffix ?? "",
                    uniqueId: endpoint.uuid!
                }),

                new AutocompleteFieldRender({
                    displayText: "Ports and protocols",
                    fieldName: "endpoints",
                    showLabel: true,
                    nestedfieldName: "ports",
                    columns: "auto",
                    minWidth: 300,
                    options: portaliasResults,
                    getOptionLabelFunc: (option: Portalias) => `${option.name}`,
                    multipleSelect: true,
                    uniqueId: endpoint.uuid!,
                    renderOption: (_props, option: Portalias) => (
                        <TooltipComponent title={<PortaliasSimpleDetails portalias={option} />}>
                            <Typography fontSize="10pt">
                                <>{option.name}</>
                            </Typography>
                        </TooltipComponent>
                    )
                    ,
                })
            ]

            rows.push(newRow)
        })
        return rows;
    }

    if (hostingsitesLazy[1].isLoading)
        return <Loading />

    return (
        <FieldArray
            name="endpoints"
            render={arrayHelpers => (
                <EasyFormRow
                    confirmDeletionText={t("Are you sure you wish to remove this endpoint?")}
                    {...props.formikProps}
                    {...props.formPropsStuf}
                    editModeAlways={true}
                    editable={true}
                    isDeleteable={true}
                    saveMutation={() => { throw "Dont save plz" }}
                    formType={FormType.LIST}
                    createNew={() => new ServiceEndpoint()}
                    editMode={true}
                    getFieldRows={(serviceRequest) => getRows(serviceRequest as CreateServiceRequest, dnsResults, portaliasesResult.data ?? [])}
                    columns={[
                        new EasyFormColumn("Service Endpoints", "Endpoints should be using subnets from the new hostingsite", [])
                    ]}
                    arrayHelpers={arrayHelpers} />
            )
            }
        />


    )
}