import { ListItem, Typography } from "@mui/material";
import { getIn } from "formik";
import { useTranslation } from "react-i18next";
import ToggleableSelect from "../ToggleableSelect";
import { EasyFieldProps } from "./EasyForm";

interface EasySelectProps {
    editMode: boolean
    fieldName: string
    options: string[]
    render?: (content: unknown) => JSX.Element
}

export default function EasySelect(props: EasySelectProps & EasyFieldProps): JSX.Element {
    const { t } = useTranslation()
    const value = getIn(props.values, props.fieldName)
    return (
        <>
            <ToggleableSelect
                editMode={props.editMode}
                variant="standard"
                id={props.fieldName}
                name={props.fieldName}
                render={props.render}
                helperText={(props.submitCount > 0 || getIn(props.touched, props.fieldName)) && getIn(props.errors, props.fieldName)}
                defaultValue={value}
                onChange={props.handleChange}
                error={(props.submitCount > 0 || getIn(props.touched, props.fieldName)) && Boolean(getIn(props.errors, props.fieldName))}
            >
                {props.options.map((option, index) => (
                    <ListItem key={option + index} value={option}><Typography textTransform="capitalize">{t(option).toLowerCase()}</Typography></ListItem>
                ))}

            </ToggleableSelect>
        </>

    )
}