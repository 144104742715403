
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import DrawerButton from "./DrawerButton";
import { OrganisationContext } from "./Layout/OrganisationPicker";
import TooltipComponent from "./TooltipComponent";

export interface CreateEntityButtonProps {
    children?: any
    tooltip?: string
    ignoreOrganisationContext?: boolean
    open: boolean
    setOpen: (open: boolean) => void
    onClose?: () => void
    buttonIcon: JSX.Element
    disabled?: boolean
}

export const CreateEntityButton = (props: CreateEntityButtonProps) => {
    const { t } = useTranslation()
    const currentOrganisation = useContext(OrganisationContext)?.organisation;

    const allowCreation = props.ignoreOrganisationContext == undefined ? currentOrganisation != undefined : props.ignoreOrganisationContext;

    return (
        <TooltipComponent title={currentOrganisation ? (props.tooltip ? t(props.tooltip) + "" : "") : t("Select an organisation to create")}>
            <DrawerButton
                onClose={props.onClose}
                open={props.open}
                setOpen={props.setOpen}
                disabled={props.disabled ? props.disabled : !allowCreation}
                buttonContent={props.buttonIcon} >
                {props.children}
            </DrawerButton>
        </TooltipComponent>

    )
}