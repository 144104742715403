import { LinearProgress, LinearProgressProps, Typography } from "@mui/material"
import TooltipComponent from "../../components/TooltipComponent"
import Portalias from "../../models/Portalias"
import { ApprovalStatus } from "../../models/enums/ApprovalStatus"
import { DirectionEnum } from "../../models/enums/DirectionEnum"
import { PortaliasSimpleDetails } from "../PortaliasApi/PortaliasSimpleDetails"

export function PortConnectionAnimation(props: { status: ApprovalStatus | undefined, port: Portalias } & LinearProgressProps) {

    function getProgressbar(flipped?: boolean) {
        const rotate180 = flipped == true ? { transform: "rotate(180deg)" } : {}
        switch (props.status) {
            case ApprovalStatus.APPROVED:
                return <LinearProgress key={flipped + props.port.uuid} sx={rotate180} {...props} variant="indeterminate" color="success" />
            case ApprovalStatus.DENIED:
                return <LinearProgress key={flipped + props.port.uuid} {...props} variant="determinate" value={0} color="error" sx={rotate180} />
            case ApprovalStatus.PENDING:
                return <LinearProgress key={flipped + props.port.uuid} {...props} variant="determinate" value={0} color="inherit" sx={rotate180} />
        }

    }
    const biDirectionalAcls = props.port.acls?.filter(x => x.direction == DirectionEnum.BIDIRECTIONAL) ?? []
    const srcToDstDirectionalAcls = props.port.acls?.filter(x => x.direction == DirectionEnum.SRC_TO_DEST) ?? []
    return (
        <>
            {biDirectionalAcls.length > 0 ? <>{getProgressbar(true)}{getProgressbar()}</> : srcToDstDirectionalAcls.length > 0 ? getProgressbar() : <></>}

            <TooltipComponent title={<PortaliasSimpleDetails portalias={props.port} />}>
                <Typography fontSize="10pt">
                    <>{props.port.name}</>
                </Typography>
            </TooltipComponent>
        </>
    )
}