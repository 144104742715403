
import getEnvironment from '../../../env'
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper'
import { DNSSuffix } from '../../../models/DNSSuffix'
import HandleQuery from '../../../redux/EndpointQueryHandler'
import { GetManyPackage } from '../../../redux/GetManyPackage'
import handleResponse from '../../../redux/handleResponse'
import { portalApiSlice } from '../../../redux/portalApiSlice'
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse'

const baseurl = getEnvironment().REACT_APP_API_BASEURL;


export const DnsSuffixApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDnsSuffixById: builder.query<DNSSuffix, RequestMutationWrapper<string>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/dns-suffixes/${request.data}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "DnsSuffix could not be fetched" })
      }),
      transformResponse: (a: DNSSuffix, b, c) => transformSingleResponseToRightType(DNSSuffix, a, b, c),
      providesTags: (_result, _error, uuid) => [{ type: 'dns-suffixes', uuid: uuid }]
    }),
    getDnsSuffixsByHostingSiteId: builder.query<DNSSuffix[], GetManyPackage>({
      query: (getManyPackage) => HandleQuery({
        url: `${baseurl}/hosting-sites/${getManyPackage.uuid}/dns-suffixes`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "DnsSuffixes could not be fetched" }),
        pagination: getManyPackage.pagination
      }),
      transformResponse: (a: DNSSuffix[], b, c) => transformMultipleResponseToRightType(DNSSuffix, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'dns-suffixes',
        ...result.map(({ uuid }) => ({ type: 'dns-suffixes', uuid: uuid }) as const)
      ]
    }),
    getDnsSuffixes: builder.query<DNSSuffix[], GetManyPackage>({
      query: (getManyPackage) => HandleQuery({
        url: `${baseurl}/dns-suffixes`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "DnsSuffixes could not be fetched" }),
        pagination: getManyPackage.pagination
      }),
      transformResponse: (a: DNSSuffix[], b, c) => transformMultipleResponseToRightType(DNSSuffix, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'dns-suffixes',
        ...result.map(({ uuid }) => ({ type: 'dns-suffixes', uuid: uuid }) as const)
      ]
    }),
    updateDnsSuffix: builder.mutation<DNSSuffix, RequestMutationWrapper<DNSSuffix>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/dns-suffixes/${request.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "DnsSuffix was updated!", toastErrorText: "DnsSuffix could not be updated" }),
        method: "PATCH",
        body: request.data
      }),
      transformResponse: (a: DNSSuffix, b, c) => transformSingleResponseToRightType(DNSSuffix, a, b, c),
      invalidatesTags: (_result, _error, args) => [{ type: 'dns-suffixes', uuid: args.data.uuid }]
    }),
    updateDnsSuffixForce: builder.mutation<DNSSuffix, RequestMutationWrapper<DNSSuffix>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/dns-suffixes/${request.data.uuid}/force`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "DnsSuffix was updated!", toastErrorText: "DnsSuffix could not be updated" }),
        method: "PATCH",
        body: request.data
      }),
      transformResponse: (a: DNSSuffix, b, c) => transformSingleResponseToRightType(DNSSuffix, a, b, c),
      invalidatesTags: (_result, _error, args) => [{ type: 'dns-suffixes', uuid: args.data.uuid }]
    }),
    addDnsSuffix: builder.mutation<DNSSuffix, RequestMutationWrapper<DNSSuffix>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/hosting-sites/${request.parentId}/dns-suffixes`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "DnsSuffix was created", toastErrorText: "DnsSuffix could not be created" }),
        method: 'POST',
        body: request.data,
      }),
      transformResponse: (a: DNSSuffix, b, c) => transformSingleResponseToRightType(DNSSuffix, a, b, c),

      invalidatesTags: ['dns-suffixes'],
    }),
    addDnsSuffixForce: builder.mutation<DNSSuffix, RequestMutationWrapper<DNSSuffix>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/hosting-sites/${request.parentId}/dns-suffixes/force`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "DnsSuffix was created", toastErrorText: "DnsSuffix could not be created" }),
        method: 'POST',
        body: request.data,
      }),
      transformResponse: (a: DNSSuffix, b, c) => transformSingleResponseToRightType(DNSSuffix, a, b, c),

      invalidatesTags: ['dns-suffixes'],
    }),
    deleteDnsSuffix: builder.mutation<void, RequestMutationWrapper<DNSSuffix>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/dns-suffixes/${request.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Dns suffix was deleted", toastErrorText: "DnsSuffix could not be deleted" }),
      }),
      
      invalidatesTags: (_result, _error, args) => [{ type: 'dns-suffixes', uuid: args.data.uuid }]
    }),
  }),
})


export const { useGetDnsSuffixsByHostingSiteIdQuery, useLazyGetDnsSuffixsByHostingSiteIdQuery, useDeleteDnsSuffixMutation, useAddDnsSuffixMutation, useAddDnsSuffixForceMutation, useUpdateDnsSuffixMutation, useUpdateDnsSuffixForceMutation, useGetDnsSuffixesQuery } = DnsSuffixApiSlice
















