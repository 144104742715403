import { FormikProps } from "formik";
import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import { EasyFormSideBySide } from "../../../components/Forms/FormLayout";
import { TextFieldRender } from "../../../components/Forms/Renders/TextFieldRender";
import { CreateOrganisationRequest } from "../../../models/Organisation";

export default function OrganisationInfoStep(props: { formikProps: FormikProps<CreateOrganisationRequest>, formPropsStuf: any }) {
    return (
        <EasyFormSideBySide
            validateOnMount
            {...props.formikProps}
            {...props.formPropsStuf}
            columns={[
                new EasyFormColumn("Information", "", [
                    new TextFieldRender({
                        displayText: "Name",
                        fieldName: "name",
                        type: "text",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "Description",
                        fieldName: "description",
                        type: "text",
                        multiline: true,
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "General contact email",
                        fieldName: "fallback_email",
                        type: "text",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "General contact information",
                        fieldName: "general_contact_information",
                        multiline: true,
                        type: "text",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "Service desk email",
                        fieldName: "service_desk_email",
                        type: "text",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "Service desk telephone number",
                        fieldName: "service_desk_telephone_number",
                        type: "tel",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "Hotline telephone number",
                        fieldName: "hotline_telephone_number",
                        type: "tel",
                        uniqueId: "no_id"
                    }),
                ]),
            ]}
            editMode={true} />
    )
}