export enum PermissionEnum {
    GOD = "GOD",
    ORGANISATION_READ = "ORGANISATION_READ",
    ORGANISATION_WRITE = "ORGANISATION_WRITE",
    EXTERNAL_CONTACT_READ = "EXTERNAL_CONTACT_READ",
    EXTERNAL_CONTACT_WRITE = "EXTERNAL_CONTACT_WRITE",
    AGREEMENT_APPROVAL_WRITE = "AGREEMENT_APPROVAL_WRITE",
    HOSTING_SITE_READ = "HOSTING_SITE_READ",
    HOSTING_SITE_WRITE = "HOSTING_SITE_WRITE",
    CLIENT_READ = "CLIENT_READ",
    CLIENT_WRITE = "CLIENT_WRITE",
    SERVICE_READ = "SERVICE_READ",
    SERVICE_WRITE = "SERVICE_WRITE",
    USER_READ = "USER_READ",
    USER_WRITE = "USER_WRITE",
    AUDIT_READ = "AUDIT_READ",
    INTERNAL_READ = "INTERNAL_READ",
    IP_INFO_READ = "IP_INFO_READ"
}
