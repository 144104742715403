import { AutocompleteRenderOptionState, FilterOptionsState, TextField } from "@mui/material";
import { getIn } from "formik";
import { HTMLAttributes, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseModel from "../../models/BaseModels/BaseModel";
import ToggleableAutocomplete from "../ToggleableAutocomplete";
import { EasyFieldProps } from "./EasyForm";

interface EasyAutocompleteProps<T> {
    editMode: boolean
    multiple: boolean
    fieldName: string
    displayText: string
    showLabel: boolean
    defaultToAllSelected?: boolean
    loading?: boolean
    options: T[]
    onMount?: () => void
    onChange: (newSelected: any) => void
    filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
    noOptionsText?: (input: string, setNewValue: (newValue: T) => void) => React.ReactNode;
    getOptionLabel: (option: T) => string;
    renderOption?: ((props: HTMLAttributes<HTMLLIElement>, option: T, state: AutocompleteRenderOptionState) => ReactNode)
    isOptionEqualToValue: ((option: T, value: T) => boolean)
    renderNonChangeable: (option: T) => JSX.Element
    valueToAutoSelect?: any,
    disableAutoPicker: boolean,
    disabled?: boolean,
    selectedValueInOptions?: (selected: T, options: T[]) => boolean
}

export default function EasyAutocomplete<T extends BaseModel>(props: EasyAutocompleteProps<T> & EasyFieldProps): JSX.Element {
    const { t } = useTranslation()
    const [input, setInput] = useState("")
    const [realValue, setRealValue] = useState(getIn(props.values, props.fieldName))

    useMemo(() => {
        if (props.onMount)
            props.onMount();
    }, [])

    useMemo(() => {
        if (props.editMode) {

            // If we are given a single value to auto select, we do so.
            if (props.valueToAutoSelect != undefined && !props.multiple) {
                setRealValue(props.valueToAutoSelect)
                props.setFieldValue(props.fieldName, props.valueToAutoSelect)
                return;
            }

            // If auto picker is not disabled and only one option is available and it is not a multi field, select it by default
            if (!props.disableAutoPicker && props.options?.length === 1 && props.options[0] != undefined && !props.multiple) {
                setRealValue(props.options[0])
                props.setFieldValue(props.fieldName, props.options[0])
                props.onChange(props.options[0])
                return;
            }

            const selected = getIn(props.values, props.fieldName);
            const selectedHasUuid = selected?.uuid != undefined ?? false;
            const optionUuids = props.options.filter(x => x.uuid != undefined).map(x => x.uuid);

            setRealValue(selected)
            const selectedUuidIsNotInOptions = selectedHasUuid && selected && !optionUuids.includes(selected.uuid);

            const selectedValueIsNotInOptions = props.selectedValueInOptions ? !props.selectedValueInOptions(selected, props.options) : false;

            if (selectedUuidIsNotInOptions || selectedValueIsNotInOptions) {
                setRealValue(undefined)
            }
        }
    }, [JSON.stringify(props.options)])

    return (<>
        <ToggleableAutocomplete
            key={JSON.stringify([props.options, (props as any).key])}
            multiple={props.multiple}
            id={props.fieldName}
            loading={props.loading}
            isOptionEqualToValue={props.isOptionEqualToValue}
            filterOptions={props.filterOptions}
            value={realValue}
            defaultToAllSelected={props.defaultToAllSelected}
            renderNonChangeable={props.renderNonChangeable}
            renderOption={props.renderOption}
            onChange={(_e, value) => { props.setFieldValue(props.fieldName, value); props.onChange(value) }}
            noOptionsText={props.noOptionsText ? props?.noOptionsText(input, (newValue: T) => { props.setFieldValue(props.fieldName, newValue); }) : undefined}
            renderInput={(params) => {
                return (
                    <TextField
                        label={props.showLabel ? t(props.displayText) + "" : undefined}
                        value={input}
                        onChange={x => setInput(x.currentTarget.value)}
                        {...params}
                        sx={{ minWidth: props.minWidth }}
                        error={(props.submitCount > 0 || getIn(props.touched, props.fieldName)) && Boolean(getIn(props.errors, props.fieldName))}
                        helperText={(props.submitCount > 0 || getIn(props.touched, props.fieldName)) && getIn(props.errors, props.fieldName)}
                    />
                )
            }}
            options={props.options}
            editMode={props.editMode}
            getOptionLabel={(option) => t(props.getOptionLabel(option))}
            disabled={props.disabled} />
    </>)

}