import { FieldEditorRender, FieldEditorRenderPackage } from "./FieldEditorRender";
import { FieldRenderType } from "./FieldRenderType";

export class TextFieldRenderPackage extends FieldEditorRenderPackage {
    render?: (content: unknown) => JSX.Element
    endAdornment?: string
    type!: HTMLInputTypeAttribute
    conditionFieldName?: string;
    multiline?: boolean = false;
    conditionShouldBeDisabledFunc?: (conditionFieldNameValue: any) => boolean
}

export class TextFieldRender extends FieldEditorRender {
    render?: (content: unknown) => JSX.Element
    endAdornment?: string
    type: HTMLInputTypeAttribute
    conditionFieldName?: string;
    conditionShouldBeDisabledFunc: (conditionFieldNameValue: any) => boolean
    multiline: boolean

    constructor(textFieldRenderPackage: TextFieldRenderPackage) {
        super(FieldRenderType.Textfield, textFieldRenderPackage);
        this.render = textFieldRenderPackage.render;
        this.type = textFieldRenderPackage.type;
        this.endAdornment = textFieldRenderPackage.endAdornment;
        this.conditionFieldName = textFieldRenderPackage.conditionFieldName;
        this.multiline = textFieldRenderPackage.multiline ?? false;
        this.conditionShouldBeDisabledFunc = textFieldRenderPackage.conditionShouldBeDisabledFunc ?? (() => false)
    }
}

type HTMLInputTypeAttribute =
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week'