import { Grid, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { IpInfoList } from "../feature/IPInfoApi/ipInfoList"

export const IpInfoPage = () => {
    const { t } = useTranslation()

    return (

        <Grid container spacing={5}>
            <Grid item xs={6}>
                <Stack direction="row">
                    <Typography variant="h5">{t("IP info") + ""}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <IpInfoList />
            </Grid>
        </Grid>

    )
}