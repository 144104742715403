import { useContext } from "react";
import { OrganisationBadge } from "../../../../components/Badges/OrganisationBadge";
import EasyForm, { FormType } from "../../../../components/Forms/EasyForm";
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import { TextRenderer } from "../../../../components/Forms/Renders/TextRenderer";
import { HostingsiteResponse } from "../../../../models/Hostingsite";
import { PermissionEnum } from "../../../Permission/PermissionEnum";
import { AbilityContext } from "../../../UserApi/logic/Can";
import { useGetHostingsiteByIdQuery, useUpdateHostingsiteMutation } from "../../hostingsitesApiSlice";
import { getHostingSiteValidationScheme } from "../../hostingSiteSchemeValidation";


interface HostingSiteInfoProps {
    hostingSite: HostingsiteResponse
}

export default function InfoTab(props: HostingSiteInfoProps) {
    const ability = useContext(AbilityContext)

    const getFirstRow = () => {
        return [
            new TextFieldRender({
                displayText: "Name",
                fieldName: "name",
                type: "text",
                uniqueId: props.hostingSite.uuid
            }),
            new TextFieldRender({
                displayText: "Address",
                fieldName: "address",
                type: "text",
                uniqueId: props.hostingSite.uuid
            }),
            new TextRenderer({
                displayText: "Owner organisation",
                fieldName: "owner_organisation",
                uniqueId: props.hostingSite.uuid,
                render: (content) => <OrganisationBadge ressource={content as unknown as HostingsiteResponse} />
            }),
            new TextRenderer({
                displayText: "Administration organisation",
                fieldName: "administrating_organisation",
                uniqueId: props.hostingSite.uuid,
                render: (content) => <OrganisationBadge ressource={content as unknown as HostingsiteResponse} />
            }),
            new TextFieldRender({
                displayText: "SDN V4 ID",
                fieldName: "sdn_v4_id",
                type: "text",
                uniqueId: props.hostingSite.uuid
            }),
            new TextFieldRender({
                displayText: "Theft mark",
                fieldName: "theft_mark",
                type: "text",
                uniqueId: props.hostingSite.uuid
            }),
            new TextFieldRender({
                displayText: "Vlan name",
                fieldName: "vlan_name",
                type: "text",
                uniqueId: props.hostingSite.uuid
            })
        ];
    }


    return (
        <EasyForm<HostingsiteResponse>
            isAllowedToEdit={() => ability.can(PermissionEnum.HOSTING_SITE_WRITE, props.hostingSite)}
            saveMutation={useUpdateHostingsiteMutation}
            getByIdAction={useGetHostingsiteByIdQuery}
            arrayPath=""
            SignupSchema={getHostingSiteValidationScheme()}
            columns={[
                new EasyFormColumn("Hosting site information", "", getFirstRow()),
            ]}
            createNew={() => { throw new Error("Function not implemented."); }}
            idToFetch={props.hostingSite.uuid}
            getFieldRows={() => []}
            newButtonTooltip="Add new info"
            formType={FormType.INFORMATION}
            key={JSON.stringify(props.hostingSite)}
        />
    )
}