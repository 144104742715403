import { Checkbox, CheckboxProps } from "@mui/material";
import { t } from "i18next";

class ToggleableCheckboxProps {
    editMode = false;
    render?: (checked: boolean) => JSX.Element
}

export default function ToggleableCheckbox(props: CheckboxProps & ToggleableCheckboxProps) {
    if (props.editMode)
        return (<Checkbox {...props} />)

    if (props.render)
        return props.render(props.checked ?? false);

    return <>{props.checked ? t("Yes") : t("No")}</>;
}