import { GridRenderCellParams } from '@mui/x-data-grid';
import { t } from 'i18next';
import { useContext, useState } from 'react';

import DatagridWrapper, { DataGridId } from '../../components/DatagridWrapper';
import { NotCorrectRights } from '../Permission/NotCorrectRights';
import { AbilityContext, Can } from '../UserApi/logic/Can';

import { History } from '@mui/icons-material';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { OrganisationContext } from '../../components/Layout/OrganisationPicker';
import { OpenInNewTabButton } from '../../components/OpenInNewTabButton';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import { ContactResponse } from '../../models/Contact';
import { GetManyPackage } from '../../redux/GetManyPackage';
import { PermissionEnum } from '../Permission/PermissionEnum';
import { ExternalContactInfoButton } from './externalContactInfoButton/ExternalContactInfoButton';
import { GridColDef } from '@mui/x-data-grid-pro';

const getCurrentContact = (contacts: ContactResponse[], params: GridRenderCellParams<any, any, any>): ContactResponse => {
    const contact = contacts.find(x => x.uuid === params.id)!;
    return contact;
}

class ExternalContactListProps {
    getExternalContacts!: UseQuery<QueryDefinition<GetManyPackage, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, ContactResponse[], any>>
    idToFetch?: string
}

export const ExternalContactList = (props: ExternalContactListProps) => {
    const ability = useContext(AbilityContext);
    const organisationContext = useContext(OrganisationContext)?.organisation
    const canReadList = ability.can(PermissionEnum.EXTERNAL_CONTACT_READ, organisationContext)
    if (!canReadList)
        return <NotCorrectRights />

    return (<ExternalContactListContent {...props} />)
}

export const ExternalContactListContent = (props: ExternalContactListProps) => {


    const currentResult = props.getExternalContacts({
        pagination: new PaginationQueryPackage(),
        uuid: props.idToFetch
    })

    const [clickedRow, setClickedRow] = useState<ContactResponse | undefined>(undefined)
    const [uuid, setUuid] = useState(crypto.randomUUID()) //To rerender buttons when clicking a row

    if (currentResult.data === undefined)
        currentResult.data = []


    const getColumns = (contacts: ContactResponse[]) => {

        const columns: GridColDef[] = [
            { field: "name", sortable: true, type: "string", headerName: t('Name'), valueGetter: (_, row: ContactResponse) => row.first_name + " " + row.last_name, flex: 1 },
            { field: "email", sortable: true, type: "string", headerName: t('Email'), flex: 1 },
            {
                field: 'events', sortable: false, hideable: false, type: "string", headerName: t('Actions'), minWidth: 220, renderCell: (params => {
                    const contact = getCurrentContact(contacts, params)
                    return (
                        <>
                            <Can I={PermissionEnum.EXTERNAL_CONTACT_READ} this={contact}>
                                <ExternalContactInfoButton
                                    key={JSON.stringify(uuid)}
                                    uuid={contact.uuid}
                                    onClose={() => setClickedRow(undefined)}
                                    openFromStart={contact.uuid == clickedRow?.uuid} />
                            </Can>
                            <Can I={PermissionEnum.AUDIT_READ} this={contact}>
                                <OpenInNewTabButton tooltipText='Events' icon={<History/>} url={`/events?entity_uuid=${contact.uuid}`} />
                            </Can>
                            <Can I={PermissionEnum.EXTERNAL_CONTACT_READ} this={contact}>
                                <OpenInNewTabButton url={`/contacts/${contact.uuid}`} />
                            </Can>
                        </>
                    )
                })
            }
        ];
        return columns
    }

    return (
        <DatagridWrapper
            dataGridId={DataGridId.EXTERNAL_CONTACTS}
            onRowClick={(row) => { setClickedRow(row.row); setUuid(crypto.randomUUID()) }}
            loading={currentResult.isLoading}
            rows={currentResult.data}
            columns={getColumns(currentResult.data)}
        />
    )
}

