import { Launch } from "@mui/icons-material"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import TooltipComponent from "./TooltipComponent"

export interface OpenInNewTabButtonProps {
    url: string
    tooltipText?: string
    icon?: JSX.Element
    padding?: number
}

export const OpenInNewTabButton = (props: OpenInNewTabButtonProps) => {
    const { t } = useTranslation()

    return (
        <Button sx={{ textTransform: 'none', padding: props.padding ?? 1 }} onClick={(e: any) => e.stopPropagation()} component={Link} to={props.url} target="_blank">
            <TooltipComponent title={props.tooltipText ? t(props.tooltipText + "") : t("Open in new tab")}>
                {props.icon ?? <Launch />}

            </TooltipComponent>
        </Button >

    )
}