import BaseModel from "./BaseModels/BaseModel"
import { DirectionEnum } from "./enums/DirectionEnum"
import { OptionEnum } from "./enums/OptionEnum"
import { ProtocolEnum } from "./enums/ProtocolEnum"

export default class AccessControlList extends BaseModel {
    protocol?: ProtocolEnum
    direction?: DirectionEnum
    source_option?: OptionEnum
    source_start?: number
    source_end?: number

    destination_option?: OptionEnum
    destination_start?: number
    destination_end?: number
}