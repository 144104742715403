import { ContentCopy } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { UserBadge } from "../../../components/Badges/UserBadge";
import { ConfirmationButton } from "../../../components/ConfirmationButton";
import EasyForm, { FormType } from "../../../components/Forms/EasyForm";
import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import { AutocompleteFieldRender } from "../../../components/Forms/Renders/AutocompleteFieldRender";
import { TextRenderer } from "../../../components/Forms/Renders/TextRenderer";
import { PaginationQueryPackage } from "../../../models/API/QueryParams/PaginationQueryPackage";
import { UserResponse } from "../../../models/User";
import { useGetAllUsersByOrganisationQuery, useUpdateUserFromMutation } from "../redux/userApiSlice";


export interface UserCopyProps {
    user: UserResponse
}

export const UserCopyButton = (props: UserCopyProps) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const organisationUsers = useGetAllUsersByOrganisationQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.user.organisation?.uuid
    }).data ?? []
    const possibleUsers = organisationUsers.filter(user => user.uuid !== props.user.uuid);

    return (
        <ConfirmationButton
            childrenOpen={open}
            setChildrenOpen={setOpen}
            asMenuItem
            title={t("Copy role and associations")}
            buttonText={<><ContentCopy /> {t("Copy role and associations")}</>}
            showButtons={false}
        >
            <>
                <Typography>{t("Copy role, approver- and contact associations")}</Typography>
                <EasyForm
                    isAllowedToEdit={() => true}
                    extraButtons={<Button onClick={() => setOpen(false)}>{t("Cancel")}</Button>}
                    showResetButton={false}
                    createNew={() => { throw "Nothing should be created here" }}
                    arrayPath=""
                    SignupSchema={Yup.object().shape({
                        user_from: Yup.object().required(),
                        user_to: Yup.object().required(),
                    })}
                    editable={true}
                    saveMutation={useUpdateUserFromMutation}
                    afterSaveAction={() => setOpen(false)}
                    editModeAlways={true}
                    initialValue={{
                        user_from: props.user
                    }}
                    columns={[
                        new EasyFormColumn("", "", [
                            new TextRenderer({
                                displayText: t("From"),
                                fieldName: "user_from",
                                uniqueId: "",
                                render: () => <UserBadge ressource={props.user} />
                            }),
                            new AutocompleteFieldRender({
                                displayText: t("To"),
                                fieldName: "user_to",
                                getOptionLabelFunc: (option: UserResponse) => `${option.first_name} ${option.last_name} (${option.email})`,
                                options: possibleUsers,
                                uniqueId: "",
                            })
                        ])

                    ]}
                    formType={FormType.INFORMATION}
                    getFieldRows={() => []}
                />
            </>
        </ConfirmationButton>
    )
}