import { AddCircle } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from "@mui/material";
import { FormikProps } from 'formik';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationButton } from '../../components/ConfirmationButton';
import { CreateEntityButton } from "../../components/CreateEntityButton";
import EasyStepper from '../../components/EasyStepper';
import { Error } from "../../models/API/Error";
import { ErrorCodeEnum } from '../../models/API/ErrorCodeEnum';
import { PaginationQueryPackage } from "../../models/API/QueryParams/PaginationQueryPackage";
import { AttachContactRequest } from '../../models/Contact';
import { LabelContextEnum } from '../../models/ContactLabel';
import { CreateServiceRequest, ServiceBase, ServiceEndpoint, ServiceMonitoring, ServiceResponse } from "../../models/Service";
import ContactStep, { getContactValidationScheme } from '../ContactsApi/createFlow/Contacts';
import { subnetApiSlice } from "../OrganisationApi/redux/subnetApiSlice";
import { PermissionEnum } from '../Permission/PermissionEnum';
import { useGetAllPortaliasQuery } from '../PortaliasApi/redux/portaliasApiSlice';
import { Can } from '../UserApi/logic/Can';
import { UserContext } from '../UserApi/logic/FetchUser';
import ApproversStep, { getApproverValidationScheme } from './createServiceFlow/Approvers';
import ServiceInfoStep, { getInfoValidationScheme } from './createServiceFlow/BaseInfo';
import ServiceDetailedInfoStep, { getServiceDetailedInfoValidationScheme } from './createServiceFlow/DetailedInfo';
import EndpointStep, { getEndpointValidationScheme } from './createServiceFlow/Endpoints';
import { useAddServiceMutation, useForceAddServiceMutation } from "./redux/serviceApiSlice";
import { ServiceInfoContent } from "./serviceInfoDialog/ServiceInfoButton";

export const CreateServiceButton = () => {

    const [open, setOpen] = useState(false)
    const [submittedService, setSubmittedService] = useState<ServiceBase | undefined>(undefined)
    const loggedInUser = useContext(UserContext);

    const portaliases = useGetAllPortaliasQuery({
        pagination: new PaginationQueryPackage()
    }).data;

    let disabled = true
    let tooltip = t("Create service")
    if (portaliases?.length == 0) {
        tooltip = t("No available portaliases")
    } else {
        disabled = false
    }

    return (
        <Can I={PermissionEnum.SERVICE_WRITE} a={loggedInUser} >
            <CreateEntityButton
                tooltip={tooltip}
                disabled={disabled}
                open={open} setOpen={setOpen}
                buttonIcon={<AddCircle />}
                onClose={() => setSubmittedService(undefined)} >

                {submittedService == undefined ?
                    <CreateServiceButtonContent
                        afterSaveAction={(service) => { setSubmittedService(service) }}
                        setOpen={setOpen} /> :
                    <ServiceInfoContent service={submittedService} />
                }
            </CreateEntityButton>
        </Can>
    )
}

export interface CreateServiceButtonContentProps {
    afterSaveAction: (service: ServiceBase) => void
}

function ServiceContactStep(props: { formikProps: FormikProps<CreateServiceRequest>, formPropsStuf: any }) {
    const hostingSite = props.formikProps.values.hosting_site
    return <ContactStep formikProps={props.formikProps} formPropsStuf={props.formPropsStuf} labelContext={LabelContextEnum.SERVICE} owner_organisation={hostingSite?.owner_organisation} administrating_organisation={hostingSite?.administrating_organisation} />
}

export const CreateServiceButtonContent = (props: CreateServiceButtonContentProps & { setOpen: Dispatch<SetStateAction<boolean>> }) => {

    const { t } = useTranslation()

    const addServiceMutation = useAddServiceMutation()
    const forceAddServiceMutation = useForceAddServiceMutation()

    const [initial, setInitial] = useState<CreateServiceRequest>(new CreateServiceRequest())
    const [forceRequired, setForceRequired] = useState(false)

    const subnetLazy = subnetApiSlice.useLazyGetSubnetsByHostingsiteIdQuery();
    const subnetLazyTrigger = subnetLazy[0];

    useMemo(() => {
        initial.requires_approval = true;
        initial.uses_encryption = true;
        initial.endpoints = [new ServiceEndpoint()];

        const moni = new ServiceMonitoring();
        moni.alarming = false;
        moni.http_ping = false;
        moni.icmp_ping = false;
        moni.tcp_ping = false;

        initial.monitoring = moni;
        initial.approvers = [];
        initial.contacts = [new AttachContactRequest()]

        setInitial(initial);
    }, [])

    async function createService(values: CreateServiceRequest) {
        await addServiceMutation[0]({
            data: values
        }).unwrap()
            .then(result => props.afterSaveAction(result as ServiceResponse))
            .catch((error: { data: Error }) => setForceRequired(error.data.error_code === ErrorCodeEnum.OPERATION_REQUIRES_FORCE))
    }

    async function forceCreateService(values: CreateServiceRequest) {
        await forceAddServiceMutation[0]({
            data: values
        }).unwrap()
            .then(result => props.afterSaveAction(result as ServiceResponse))


    }

    function NextStepFunc(currentPage: number, values: CreateServiceRequest) {
        subnetLazyTrigger({
            pagination: new PaginationQueryPackage(),
            uuid: values.hosting_site?.uuid
        });
        return currentPage + 1
    }
    function PreviousStepFunc(currentPage: number) {
        setForceRequired(false);
        return currentPage - 1
    }

    return (
        <>
            <Box>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("Create service") + ""}</Typography>

                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={10}>
                        <EasyStepper
                            nextPage={NextStepFunc}
                            previousPage={PreviousStepFunc}
                            submit={forceRequired ? forceCreateService : createService}
                            initialValue={initial}
                            stepValidations={[
                                getInfoValidationScheme(t),
                                getServiceDetailedInfoValidationScheme(t),
                                getApproverValidationScheme(t),
                                getEndpointValidationScheme(t),
                                getContactValidationScheme(t)
                            ]}
                            stepTitles={[
                                "Service info",
                                "Service detail info",
                                "Approvers",
                                "Endpoints",
                                "Contacts"
                            ]}
                            steps={[
                                ServiceInfoStep,
                                ServiceDetailedInfoStep,
                                ApproversStep,
                                EndpointStep,
                                ServiceContactStep
                            ]}
                            saveButtonOverride={
                                !forceRequired ? undefined :
                                    (formikProps) =>
                                        <ConfirmationButton action={async () => { formikProps.submitForm() }} variant="contained" title={t("Ip address is already in use")} buttonText={t("Force save")}>
                                            <Stack>
                                                <Typography>{t("Create service anyways?") + " "}</Typography>
                                            </Stack>
                                        </ConfirmationButton>
                            } />
                    </Grid>
                </Grid>

            </Box>
        </>
    )
}