import { History } from '@mui/icons-material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import DatagridWrapper, { DataGridId } from '../../components/DatagridWrapper';
import { OrganisationContext } from '../../components/Layout/OrganisationPicker';
import { calculateDateProgressionInPercentage, DateToFormattedString } from '../../components/Logic/DateLogic';
import { OpenInNewTabButton } from '../../components/OpenInNewTabButton';
import TimePeriod from '../../components/TimePeriod';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import { ServiceResponse } from '../../models/Service';
import ServiceWindow from '../../models/ServiceWindow';
import { GetManyPackage } from '../../redux/GetManyPackage';
import { NotCorrectRights } from '../Permission/NotCorrectRights';
import { PermissionEnum } from '../Permission/PermissionEnum';
import { AbilityContext, Can } from '../UserApi/logic/Can';
import { ServiceWindowInfoButton } from './serviceWindowInfoDialog/ServiceWindowInfoButton';


const getCurrentServiceWindow = (serviceWindows: ServiceWindow[], params: GridRenderCellParams<any, any, any>): ServiceWindow => {
    return serviceWindows.find(x => x.uuid === params.id)!;
}
class ServiceWindowListProps {
    getServiceWindows!: UseQuery<QueryDefinition<GetManyPackage, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, ServiceWindow[], any>>
    checkedServices?: ServiceResponse[]
    idToFetch?: string
}
export const ServiceWindowList = (props: ServiceWindowListProps) => {
    const ability = useContext(AbilityContext);
    const organisationContext = useContext(OrganisationContext)?.organisation;

    const canReadList = ability.can(PermissionEnum.SERVICE_READ, organisationContext)
    if (!canReadList)
        return <NotCorrectRights />
        
    return (<ServiceWindowListContent {...props} />)
}

const ServiceWindowListContent = (props: ServiceWindowListProps) => {
    const currentResult = props.getServiceWindows({
        pagination: new PaginationQueryPackage({ sort_by: "start_time", order_by: "ASC" }),
        uuid: props.idToFetch
    })

    const [clickedRow, setClickedRow] = useState<ServiceResponse | undefined>(undefined)
    const [uuid, setUuid] = useState(crypto.randomUUID()) //To rerender buttons when clicking a row

    if (currentResult.data === undefined)
        currentResult.data = []

    const getColumns = (serviceWindows: ServiceWindow[]) => {

        const columns: GridColDef[] = [
            //Data
            { field: "status", sortable: true, type: "number", valueGetter: (_, row: ServiceWindow) => calculateDateProgressionInPercentage(row.start_time, row.end_time), headerName: t('Status'), renderCell: (params: { row: ServiceWindow }) => <TimePeriod startTime={params.row.start_time} endTime={params.row.end_time} />, flex: 0.5 },
            { field: "start_time", sortable: true, type: "dateTime", align: "right", headerAlign: "right", headerName: t('Start time'), valueGetter: (startTime) => startTime && new Date(startTime), renderCell: (params: { row: ServiceWindow }) => DateToFormattedString(params.row.start_time!), flex: 1 },
            { field: "time", sortable: true, type: "number", valueGetter: (_, row: ServiceWindow) => calculateDateProgressionInPercentage(row.start_time, row.end_time), headerName: t('Time'), headerAlign: "center", renderCell: (params: { row: ServiceWindow }) => <TimePeriod progressbar startTime={params.row.start_time} endTime={params.row.end_time} />, flex: 1 },
            { field: 'end_time', sortable: true, type: "dateTime", align: "left", headerAlign: "left", headerName: t('End time'), valueGetter: (endTime) => endTime && new Date(endTime), renderCell: (params: { row: ServiceWindow }) => DateToFormattedString(params.row.end_time!), flex: 1 },
            { field: "description", sortable: true, type: "string", headerName: t('Description'), flex: 2 },
            //Buttons
            {
                field: 'events', sortable: false, hideable: false, type: "string", valueGetter: () => "", headerName: t('Actions'), minWidth: 220, renderCell: (params => {
                    const serviceWindow = getCurrentServiceWindow(serviceWindows, params)
                    return (
                        <>
                            <ServiceWindowInfoButton
                                key={JSON.stringify(uuid)}
                                openFromStart={serviceWindow.uuid == clickedRow?.uuid}
                                onClose={() => { setClickedRow(undefined) }}
                                serviceWindowId={serviceWindow.uuid} />
                            <Can I={PermissionEnum.AUDIT_READ}>
                                <OpenInNewTabButton tooltipText='Events' icon={<History/>} url={`/events?entity_uuid=${serviceWindow.uuid}`} />
                            </Can>
                        </>
                    )
                })
            }
        ];
        return columns
    }

    return (
        <>
            <DatagridWrapper
                dataGridId={DataGridId.SERVICE_WINDOWS}
                getRowId={(row) => row.uuid}
                autoHeight
                onRowClick={(row) => { setClickedRow(row.row); setUuid(crypto.randomUUID()) }}
                loading={currentResult.isLoading}
                rows={currentResult.data}
                columns={getColumns(currentResult.data)}
                rowSelectionModel={props.checkedServices?.map(x => x.uuid)}
                initialFilterModel={{items: [{ field: "time", operator: '!=', value: '100' }]}}
                initialSortModel={[{ field: 'time', sort: 'desc' }]}
            />
        </>
    )
}

