
import getEnvironment from '../../../env'
import HandleQuery from '../../../redux/EndpointQueryHandler'
import handleResponse from '../../../redux/handleResponse'
import { transformMultipleResponseToRightType } from '../../../redux/transformResponse'
import { portalApiSlice } from '../../../redux/portalApiSlice'
import UserRoleResponse from '../UserRoleResponse'

const baseurl = getEnvironment().REACT_APP_API_BASEURL;


export const userRoleApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserRoles: builder.query<UserRoleResponse[], void>({
      query: () => HandleQuery({
        url: `${baseurl}/user-roles`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Authorization could not be fetched" })
      }),
      transformResponse: (a: UserRoleResponse[], b, c) => transformMultipleResponseToRightType(UserRoleResponse, a, b, c),
      providesTags: ['user-roles']
    }),
  }),
})



export const { useGetUserRolesQuery } = userRoleApiSlice
















