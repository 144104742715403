import { ButtonGroup, Grid, Stack, Typography } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { OrganisationBadge } from "../components/Badges/OrganisationBadge"
import { OrganisationContext } from "../components/Layout/OrganisationPicker"
import Loading from "../components/loading"
import { AuthorisationList } from "../feature/AuthorisationApi/authorisationList"
import { CreateAuthorisationButton } from "../feature/AuthorisationApi/CreateAuthorisationButton"
import { useGetAllAuthorizationsQuery, useGetAuthorizationsForOrganisationQuery } from "../feature/AuthorisationApi/redux/authroisationApiSlice"
import { UserContext } from "../feature/UserApi/logic/FetchUser"

export const AuthorisationPage = () => {
    const { t } = useTranslation()
    const currentOrganisation = useContext(OrganisationContext)?.organisation;
    const userContext = useContext(UserContext);
    if (!currentOrganisation && !userContext?.allowEmptyOrganisation())
        return <Loading />

    return (

        <Grid container spacing={5}>
            <Grid item xs={6}>
                <Stack direction="row" spacing={2}>
                    {currentOrganisation ? <OrganisationBadge ressource={currentOrganisation} /> : <></>}
                    <Typography variant="h5">{t("Authorisations") + ""}</Typography>
                    <ButtonGroup variant="text">
                        <CreateAuthorisationButton />
                    </ButtonGroup>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <AuthorisationList idToFetch={currentOrganisation?.uuid} getAuthorisations={currentOrganisation ? useGetAuthorizationsForOrganisationQuery : useGetAllAuthorizationsQuery} />
            </Grid>
        </Grid>

    )
}