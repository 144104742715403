import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import ApprovalStatusChip from "../../../../components/ApprovalStatusChip";
import { ClientBadge } from "../../../../components/Badges/ClientBadge";
import { CriticalityBadge } from "../../../../components/Badges/CriticalityBadge";
import { OrganisationBadge } from "../../../../components/Badges/OrganisationBadge";
import { ServiceBadge } from "../../../../components/Badges/ServiceBadge";
import { UserBadge } from "../../../../components/Badges/UserBadge";
import EasyForm, { FormType } from "../../../../components/Forms/EasyForm";
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import { AutocompleteFieldRender } from "../../../../components/Forms/Renders/AutocompleteFieldRender";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import { TextRenderer } from "../../../../components/Forms/Renders/TextRenderer";
import { DateToFormattedString } from "../../../../components/Logic/DateLogic";
import Client from "../../../../models/Client";
import OrganisationResponse from "../../../../models/Organisation";
import { ServiceBase } from "../../../../models/Service";
import { ApprovalStatus } from "../../../../models/enums/ApprovalStatus";
import { CriticalityEnum } from "../../../../models/enums/CriticalityEnum";
import { ConnectionCard } from "../../ConnectionCard";
import { useGetAgreementByIdQuery, useUpdateAgreementMutation } from "../../redux/agreementsApiSlice";
import { AgreementTabProps } from "../AgreementInfoButton";

export default function InfoTab(props: AgreementTabProps) {
    const { t } = useTranslation()
    const SignupSchema = Yup.object().shape({

    });

    const { data } = useGetAgreementByIdQuery(props.agreementId)


    function getStatusChangedTimeDisplayText() {
        if (data?.status === ApprovalStatus.DENIED) {
            return "Denied at"
        }

        if (data?.status === ApprovalStatus.APPROVED) {
            return "Approved at"
        }

        return "Unknown";
    }

    function getStatusChangedByDisplayText() {
        if (data?.status === ApprovalStatus.DENIED) {
            return "Denied by"
        }

        if (data?.status === ApprovalStatus.APPROVED) {
            return "Approved by"
        }

        return "Unknown";
    }

    function getServiceInformation(): EasyFormColumn {
        return new EasyFormColumn("Service information", "", [
            new TextRenderer({
                displayText: "ID",
                fieldName: "service.short_id",
                uniqueId: props.agreementId
            }),
            new TextRenderer({
                displayText: "Service", fieldName: "service",
                uniqueId: props.agreementId,
                render: (content) => <ServiceBadge ressource={content as unknown as ServiceBase} />
            }),
            new TextRenderer({
                displayText: "Description",
                fieldName: "service.description",
                uniqueId: props.agreementId
            }),
            new TextRenderer({
                displayText: "SLA",
                fieldName: "service.sla",
                uniqueId: props.agreementId
            }),
            new TextRenderer({
                displayText: "SLA Description",
                fieldName: "service.sla_description",
                uniqueId: props.agreementId
            }),
            new TextRenderer({
                displayText: "Organisation",
                fieldName: "service.owner_organisation",
                uniqueId: props.agreementId,
                render: (content) => <OrganisationBadge ressource={content as unknown as OrganisationResponse} />
            }),
        ])
    }

    function getClientInformation(): EasyFormColumn {
        return new EasyFormColumn("Client information", "", [
            new TextRenderer({
                displayText: "ID",
                fieldName: "client.short_id",
                uniqueId: props.agreementId
            }),
            new TextRenderer({
                displayText: "Client",
                fieldName: "client",
                uniqueId: props.agreementId,
                render: (content) => <ClientBadge ressource={content as unknown as Client} />
            }),
            new TextRenderer({
                displayText: "Organisation",
                fieldName: "client.hosting_site.owner_organisation",
                uniqueId: props.agreementId,
                render: (content) => <OrganisationBadge ressource={content as unknown as OrganisationResponse} />
            })])
    }

    function getAgreementInformation(): EasyFormColumn {
        return new EasyFormColumn("Agreement information", "", [
            new TextRenderer({
                displayText: "ID",
                fieldName: "short_id",
                uniqueId: props.agreementId
            }),
            new TextFieldRender({
                displayText: "Status",
                fieldName: "status",
                type: "text",
                render: (content) => <ApprovalStatusChip status={content as ApprovalStatus} />,
                uniqueId: props.agreementId
            }),
            new TextFieldRender({
                displayText: "Requested by", fieldName: "created_by", type: "text",
                render: (value) => <UserBadge textOverride={value as string} />,
                uniqueId: props.agreementId
            }),
            new TextFieldRender({
                displayText: getStatusChangedByDisplayText(),
                fieldName: "status_changed_by",
                type: "text",
                render: (value) => <UserBadge textOverride={value as string} />,
                uniqueId: props.agreementId
            }),
            new TextFieldRender({
                displayText: getStatusChangedTimeDisplayText(),
                fieldName: "status_changed_time",
                type: "text",
                render: (value) => <>{DateToFormattedString(value as string)}</>,
                uniqueId: props.agreementId
            }),
            new AutocompleteFieldRender({
                displayText: t("Criticality"),
                fieldName: "criticality",
                getOptionLabelFunc: (option: CriticalityEnum) => option.toString() ?? "",
                options: Object.values(CriticalityEnum) ?? [],
                renderNonChangeable: (content) => <CriticalityBadge ressource={content as CriticalityEnum} />,
                uniqueId: "no_id"
            }),
            new TextFieldRender({
                displayText: "Description",
                fieldName: "description",
                type: "text",
                uniqueId: props.agreementId
            }),
            new TextFieldRender({
                displayText: "Approval comment",
                fieldName: "approval_comment",
                type: "text",
                uniqueId: props.agreementId
            }),
            new TextFieldRender({
                displayText: "Provision status",
                fieldName: "provision_status",
                render: (value) => <>{t(value as string)}</>,
                type: "text",
                uniqueId: props.agreementId
            }),
            new TextFieldRender({
                displayText: "Provision time",
                fieldName: "provision_time",
                type: "text",
                render: (value) => <>{DateToFormattedString(value as string)}</>,
                uniqueId: props.agreementId
            })
        ])
    }

    function getAgreementRequestInformation(): EasyFormColumn {
        return new EasyFormColumn("Agreement request information", "", [
            new TextRenderer({
                displayText: "ID",
                fieldName: "short_id",
                uniqueId: props.agreementId
            }),
            new TextFieldRender({
                displayText: "Status",
                fieldName: "status",
                type: "text",
                render: (content) => <ApprovalStatusChip status={content as ApprovalStatus} />,
                uniqueId: props.agreementId
            }),
            new TextFieldRender({
                displayText: "Requested by", fieldName: "created_by", type: "text",
                render: (value) => <UserBadge textOverride={value as string} />,
                uniqueId: props.agreementId
            }),
            new AutocompleteFieldRender({
                displayText: t("Criticality"),
                fieldName: "criticality",
                getOptionLabelFunc: (option: CriticalityEnum) => option.toString() ?? "",
                options: Object.values(CriticalityEnum) ?? [],
                renderNonChangeable: (content) => <CriticalityBadge ressource={content as CriticalityEnum} />,
                uniqueId: "no_id"
            }),
            new TextFieldRender({
                displayText: "Description",
                fieldName: "description",
                type: "text",
                uniqueId: props.agreementId
            })
        ])
    }

    return (
        <>
            <EasyForm
                isAllowedToEdit={() => false}
                createNew={() => { throw "No agreement should be created here" }}
                arrayPath=""
                getByIdAction={useGetAgreementByIdQuery}
                SignupSchema={SignupSchema}
                saveMutation={useUpdateAgreementMutation}
                editable={false}
                columns={[
                    data?.status != ApprovalStatus.PENDING ? getAgreementInformation() : getAgreementRequestInformation(),
                    getServiceInformation(),
                    getClientInformation()
                ]}
                formType={FormType.INFORMATION}
                idToFetch={props.agreementId}
                getFieldRows={() => []}
            />
            <Grid container paddingTop={2} spacing={2}>

                <Grid item xs={12}>
                    <Typography variant="h5">{t("Connections") + ""}</Typography>
                </Grid>
                <ConnectionCard agreementUuid={props.agreementId} />

            </Grid>
        </>
    )
}