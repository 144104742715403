import { Grid, Stack, Typography } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { OrganisationBadge } from "../components/Badges/OrganisationBadge"
import { OrganisationContext } from "../components/Layout/OrganisationPicker"
import Loading from "../components/loading"
import { ExternalContactList } from "../feature/ContactsApi/externalContactList"
import { useGetAllExternalContactsQuery, useGetExternalContactsByOrganisationIdQuery } from "../feature/ContactsApi/redux/contactsApiSlice"
import { UserContext } from "../feature/UserApi/logic/FetchUser"

export const ContactPage = () => {
    const { t } = useTranslation()
    const currentOrganisation = useContext(OrganisationContext)?.organisation;
    const userContext = useContext(UserContext);
    if (!currentOrganisation && !userContext?.allowEmptyOrganisation())
        return <Loading />
    return (

        <Grid container spacing={5}>
            <Grid item xs={6}>
                <Stack direction="row" spacing={2}>
                    {currentOrganisation ? <OrganisationBadge ressource={currentOrganisation} /> : <></>}
                    <Typography variant="h5">{t("Contacts") + ""}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <ExternalContactList idToFetch={currentOrganisation?.uuid} getExternalContacts={currentOrganisation ? useGetExternalContactsByOrganisationIdQuery : useGetAllExternalContactsQuery} />
            </Grid>
        </Grid>

    )
}