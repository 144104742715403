import { CircularProgress, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function BigLoading() {
    const {t} = useTranslation()
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Stack direction="row" spacing={2} alignItems="center" marginTop="20%">
                <CircularProgress />
                <Stack>
                    <img style={{ width: "7em" }} src={"/medcom_logo.png"} alt="medcom" />
                    <Typography variant="h5">{t("Agreementportal is loading")}</Typography>
                </Stack>
            </Stack>
        </div>
    )
}