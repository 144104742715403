import { ButtonGroup, Grid, Stack, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { OrganisationBadge } from "../components/Badges/OrganisationBadge"
import { OrganisationContext } from "../components/Layout/OrganisationPicker"

import { CreateServiceButton } from "../feature/ServiceApi/CreateServiceButton"
import { useGetAllServicesQuery, useGetServicesByOrganisationIdQuery } from "../feature/ServiceApi/redux/serviceApiSlice"
import { ServiceList } from "../feature/ServiceApi/serviceList"
import { CreateServiceWindowButton } from "../feature/ServiceWindowApi/CreateServiceWindowButton"
import { ServiceResponse } from "../models/Service"

export const ServicePage = () => {
    const { t } = useTranslation()
    const [checkedServices, setCheckedServices] = useState(new Array<ServiceResponse>())
    const currentOrganisation = useContext(OrganisationContext)?.organisation;
    return (

        <Grid container spacing={5}>
            <Grid item xs={6}>
                <Stack direction="row" spacing={2}>
                    {currentOrganisation ? <OrganisationBadge ressource={currentOrganisation} /> : <></>}
                    <Typography variant="h5">{t("Services") + ""}</Typography>

                    <ButtonGroup variant="text">
                        <CreateServiceButton />
                        <CreateServiceWindowButton checkedServices={checkedServices} />
                    </ButtonGroup>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <ServiceList setCheckedServices={setCheckedServices} idToFetch={currentOrganisation?.uuid} checkedServices={checkedServices} getServices={currentOrganisation ? useGetServicesByOrganisationIdQuery : useGetAllServicesQuery} />
            </Grid>
        </Grid>

    )
}