
import getEnvironment from '../../../env'
import AccessControlList from '../../../models/AccessControlList';
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper';
import HandleQuery from '../../../redux/EndpointQueryHandler';
import { GetManyPackage } from '../../../redux/GetManyPackage';
import handleResponse from '../../../redux/handleResponse';
import { portalApiSlice } from '../../../redux/portalApiSlice';
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse';

const baseurl = getEnvironment().REACT_APP_API_BASEURL;
// Define a portaliases using a base URL and expected endpoints
export const accessControlListsApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getACLsByPortaliasId: builder.query<AccessControlList[], GetManyPackage>({
      query: (getManyPackage) => HandleQuery({
        url: `${baseurl}/portaliases/${getManyPackage.uuid}/access_control_lists`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "AccessControlList could not be fetched" }),
        pagination: getManyPackage.pagination
      }),
      transformResponse: (a: AccessControlList[], b, c) => transformMultipleResponseToRightType(AccessControlList, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'accesscontrolists',
        ...result.map(({ uuid }) => ({ type: 'accesscontrolists', uuid: uuid }) as const)
      ]
    }),
    updateACL: builder.mutation<AccessControlList, RequestMutationWrapper<AccessControlList>>({
      query: (accessControlList) => HandleQuery({
        url: `${baseurl}/access_control_lists/${accessControlList.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "AccessControlList was updated!", toastErrorText: "AccessControlList could not be updated" }),
        method: "PATCH",
        body: accessControlList.data
      }),
      transformResponse: (a: AccessControlList, b, c) => transformSingleResponseToRightType(AccessControlList, a, b, c),
      invalidatesTags: (_result, _error, args) => [{ type: 'accesscontrolists', uuid: args.data.uuid }]
    }),
    addACL: builder.mutation<AccessControlList, RequestMutationWrapper<AccessControlList>>({
      query: (organisation) => HandleQuery({
        url: `${baseurl}/portaliases/${organisation.parentId}/access_control_lists`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "AccessControlList was created", toastErrorText: "AccessControlList could not be created" }),
        method: 'POST',
        body: organisation.data,
      }),
      transformResponse: (a: AccessControlList, b, c) => transformSingleResponseToRightType(AccessControlList, a, b, c),

      invalidatesTags: ['accesscontrolists'],
    }),
    deleteACL: builder.mutation<void, RequestMutationWrapper<AccessControlList>>({
      query: (accessControlList) => HandleQuery({
        url: `${baseurl}/access_control_lists/${accessControlList.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "AccessControlList was deleted!", toastErrorText: "AccessControlList could not be deleted" }),
      }),
      
      invalidatesTags: (_result, _error, args) => [{ type: 'accesscontrolists', uuid: args.data.uuid }]
    }),
  }),
})


export const { useGetACLsByPortaliasIdQuery, useUpdateACLMutation, useAddACLMutation, useDeleteACLMutation } = accessControlListsApiSlice
















