import { AddQueryParamsToUrl } from "./AddQueryParamsToUrl"
import { QueryParams } from "./QueryParams"

export class PaginationQueryPackage implements QueryParams {

    page?: number
    pagesize?: number
    sort_by?: string
    order_by?: "ASC" | "DESC"

    constructor(queryParams?: {
        page?: number
        pagesize?: number
        sort_by?: string
        order_by?: "ASC" | "DESC"
    }) {
        this.page = queryParams?.page
        this.pagesize = queryParams?.pagesize
        this.sort_by = queryParams?.sort_by
        this.order_by = queryParams?.order_by
    }

    getQuery(currentUrl: string): string {
        return AddQueryParamsToUrl(
            currentUrl,
            [
                "page",
                "limit",
                "sort_by",
                "order_by"
            ],
            [
                this.page?.toString(),
                this.pagesize?.toString(),
                this.sort_by,
                this.order_by
            ]
        );
    }

}