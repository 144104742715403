import { FieldEditorRender, FieldEditorRenderPackage } from "./FieldEditorRender";
import { FieldRenderType } from "./FieldRenderType";

export class SelectFieldRenderPackage<T> extends FieldEditorRenderPackage {
    options!: string[]
    render?: (content: T) => JSX.Element
}

export class SelectFieldRender<T extends object> extends FieldEditorRender {
    render?: (content: T) => JSX.Element
    options: string[]
    constructor(selectFieldRenderPackage: SelectFieldRenderPackage<T>) {
        super(FieldRenderType.Select, selectFieldRenderPackage);
        this.options = selectFieldRenderPackage.options;
        this.render = selectFieldRenderPackage.render;
    }
}