import LockResetIcon from '@mui/icons-material/LockReset';
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { CreateInCollectionButton } from "../../../../../components/CreateInCollectionButton";
import EasyFormColumn from "../../../../../components/Forms/EasyFormColumn";
import EasyFormMany from "../../../../../components/Forms/EasyFormMany";
import { AutocompleteFieldRender } from "../../../../../components/Forms/Renders/AutocompleteFieldRender";
import { FieldEditorRender } from "../../../../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender } from "../../../../../components/Forms/Renders/TextFieldRender";
import { TextRenderer } from "../../../../../components/Forms/Renders/TextRenderer";
import { IsIpAddressValidation } from "../../../../../components/Forms/Validations/IsIpAddressValidation";
import { OrganisationRouter } from "../../../../../models/Organisation";
import { RouterTypeEnum } from "../../../../../models/enums/RouterTypeEnum";
import { PermissionEnum } from "../../../../Permission/PermissionEnum";
import { AbilityContext } from "../../../../UserApi/logic/Can";
import { useGetOrganisationByIdQuery } from "../../../redux/organisationApiSlice";
import { useAddRouterMutation, useDeleteRouterMutation, useGetRoutersByOrganisationIdQuery, useUpdateRouterMutation, useUpdateRouterPasswordMutation } from "../../../redux/routerApiSlice";
import { OrganisationTabProps } from "../OrganisationInfoButton";

export default function RoutersTab(props: OrganisationTabProps) {
    const { t } = useTranslation()
    const organisationResult = useGetOrganisationByIdQuery(props.organisationId)

    const ability = useContext(AbilityContext)
    const SignupSchema = Yup.object().shape({
        name: Yup.string().required(),
        ip_address: IsIpAddressValidation(false),
        username: Yup.string().nullable().when('type', {
            is: (type: RouterTypeEnum) => type == RouterTypeEnum.MPLS,
            then: Yup.string().max(30).required(),
        })
    });

    const getRouterRows = (routers: OrganisationRouter[] | undefined) => {
        const rows: Array<FieldEditorRender[]> = []
        routers?.forEach((router) => {
            const newRow: FieldEditorRender[] = [

                new TextFieldRender({ displayText: "Name", fieldName: "", nestedfieldName: "name", type: "text", uniqueId: router.uuid! }),
                new TextFieldRender({ displayText: "IP-address", fieldName: "", nestedfieldName: "ip_address", type: "text", uniqueId: router.uuid! }),
                new AutocompleteFieldRender({
                    displayText: t("Type"),
                    fieldName: "",
                    nestedfieldName: "type",
                    getOptionLabelFunc: (option: RouterTypeEnum) => option.toString() ?? "",
                    options: Object.values(RouterTypeEnum) ?? [],
                    renderNonChangeable: (content) => <>{content?.toString()}</>,
                    uniqueId: router.uuid!
                }),
                new TextFieldRender({ conditionFieldName: `type`, conditionShouldBeDisabledFunc: (type: RouterTypeEnum) => type != RouterTypeEnum.MPLS, displayText: "Username", fieldName: "", nestedfieldName: "username", type: "text", uniqueId: router.uuid! }),
                new TextFieldRender({ displayText: "Remark", fieldName: "", nestedfieldName: "comment", type: "text", uniqueId: router.uuid! })
            ]
            rows.push(newRow)
        })
        return rows;
    }

    const getRouterCreatableRows = (routers: OrganisationRouter[] | undefined) => {
        const rows: Array<FieldEditorRender[]> = []
        routers?.forEach((router) => {
            const newRow: FieldEditorRender[] = [

                new TextFieldRender({ displayText: "Name", fieldName: "", nestedfieldName: "name", type: "text", uniqueId: router.uuid! }),
                new TextFieldRender({ displayText: "IP-address", fieldName: "", nestedfieldName: "ip_address", type: "text", uniqueId: router.uuid! }),
                new AutocompleteFieldRender({
                    displayText: t("Type"),
                    fieldName: "",
                    nestedfieldName: "type",
                    getOptionLabelFunc: (option: RouterTypeEnum) => option.toString() ?? "",
                    options: Object.values(RouterTypeEnum) ?? [],
                    renderNonChangeable: (content) => <>{content?.toString()}</>,
                    uniqueId: router.uuid!
                }),
                new TextFieldRender({ conditionFieldName: `type`, conditionShouldBeDisabledFunc: (type: RouterTypeEnum) => type != RouterTypeEnum.MPLS, displayText: "Username", fieldName: "", nestedfieldName: "username", type: "text", uniqueId: router.uuid! }),
                new TextFieldRender({ conditionFieldName: `type`, conditionShouldBeDisabledFunc: (type: RouterTypeEnum) => type != RouterTypeEnum.MPLS, displayText: "Password", fieldName: "", nestedfieldName: "password", type: "password", uniqueId: router.uuid! }),
                new TextFieldRender({ displayText: "Remark", fieldName: "", nestedfieldName: "comment", type: "text", uniqueId: router.uuid! })
            ]
            rows.push(newRow)
        })
        return rows;
    }

    const getRouterEditableRows = (routers: OrganisationRouter[]) => {
        const rows: Array<FieldEditorRender[]> = []
        routers?.forEach((router) => {
            const newRow: FieldEditorRender[] = []

            newRow.push(new TextFieldRender({ displayText: "Name", fieldName: "", nestedfieldName: "name", type: "text", uniqueId: router.uuid! }));
            newRow.push(new TextRenderer({ displayText: t("Type"), fieldName: "", nestedfieldName: "type", uniqueId: router.uuid!, render: (option) => <>{option}</> }));
            if (router.type == RouterTypeEnum.MPLS) {
                newRow.push(new TextFieldRender({ displayText: "Username", fieldName: "", nestedfieldName: "username", type: "text", uniqueId: router.uuid! }))
            }
            newRow.push(new TextFieldRender({ displayText: "IP-address", fieldName: "", nestedfieldName: "ip_address", type: "text", uniqueId: router.uuid! }));
            newRow.push(new TextFieldRender({ displayText: "Remark", fieldName: "", nestedfieldName: "comment", type: "text", uniqueId: router.uuid! }));
            rows.push(newRow)
        })
        return rows;
    }

    const getChangePasswordRows = (routers: OrganisationRouter[]) => {
        const rows: Array<FieldEditorRender[]> = []
        routers?.forEach((router) => {
            const newRow: FieldEditorRender[] = [
                new TextFieldRender({ displayText: "Password", fieldName: "", nestedfieldName: "password", type: "password", uniqueId: router.uuid! })
            ]
            rows.push(newRow)
        })
        return rows;
    }


    return (
        <EasyFormMany
            confirmDeletionText="Are you sure you wish to delete this router?"
            isAllowedToDelete={() => ability.can(PermissionEnum.ORGANISATION_WRITE, organisationResult.data)}
            isAllowedToEdit={() => ability.can(PermissionEnum.ORGANISATION_WRITE, organisationResult.data)}
            isAllowedToCreate={() => ability.can(PermissionEnum.GOD, organisationResult.data)}
            ressourceOwnerId={props.organisationId}
            arrayPath=""
            getManyByIdAction={useGetRoutersByOrganisationIdQuery}
            saveMutation={useUpdateRouterMutation}
            deleteMutation={useDeleteRouterMutation}
            createButtonJsx={
                <CreateInCollectionButton
                    validationScheme={SignupSchema}
                    ressourceOwnerId={props.organisationId}
                    fieldEditorRenders={getRouterCreatableRows([new OrganisationRouter()]).find(() => true)!}
                    tooltip="Create router"
                    createNew={() => new OrganisationRouter()}
                    saveMutation={useAddRouterMutation} />
            }
            editButtonJsx={(router: OrganisationRouter) =>
                <CreateInCollectionButton
                    validationScheme={SignupSchema}
                    toEdit={router}
                    createNew={() => { throw new Error("Dont create new here") }}
                    ressourceOwnerId={props.organisationId}
                    fieldEditorRenders={getRouterEditableRows([router]).find(() => true)!}
                    tooltip="Edit router"
                    saveMutation={useUpdateRouterMutation} />
            }
            jsxAtButtons={(router: OrganisationRouter) => (
                <CreateInCollectionButton
                    validationScheme={SignupSchema}
                    toEdit={router}
                    createNew={() => { throw new Error("Dont create new here") }}
                    ressourceOwnerId={props.organisationId}
                    fieldEditorRenders={getChangePasswordRows([router]).find(() => true)!}
                    tooltip="Change password"
                    saveMutation={useUpdateRouterPasswordMutation}
                    buttonJsxOverride={
                        <LockResetIcon />
                    }
                    disabled={router.type != RouterTypeEnum.MPLS} />

            )}
            SignupSchema={SignupSchema}
            idToFetch={props.organisationId}
            columns={[
                new EasyFormColumn("Routers", "", [])
            ]}
            getFieldRows={getRouterRows}
            newButtonTooltip="Add new router"
        />
    )
}