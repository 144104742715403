import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import { Box, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { useContext, useState } from "react";
import * as Yup from 'yup';
import { CreateEntityButton } from '../../components/CreateEntityButton';
import EasyForm, { FormType } from "../../components/Forms/EasyForm";
import EasyFormColumn from "../../components/Forms/EasyFormColumn";
import { AutocompleteFieldRender } from "../../components/Forms/Renders/AutocompleteFieldRender";
import { DateTimeRender } from "../../components/Forms/Renders/DateTimeRender";
import { TextFieldRender } from "../../components/Forms/Renders/TextFieldRender";
import { OrganisationContext } from '../../components/Layout/OrganisationPicker';
import Loading from '../../components/loading';
import { AbilityContext, Can } from "../../feature/UserApi/logic/Can";
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import ServiceRequest, { ServiceResponse } from "../../models/Service";
import ServiceWindow, { ServiceWindowRequest } from "../../models/ServiceWindow";
import { PermissionEnum } from '../Permission/PermissionEnum';
import { useGetServicesByOrganisationIdQuery } from '../ServiceApi/redux/serviceApiSlice';
import { UserContext } from '../UserApi/logic/FetchUser';
import { useAddServiceWindowMutation } from "./redux/serviceWindowApiSlice";
import { ServiceWindowInfoContent } from './serviceWindowInfoDialog/ServiceWindowInfoButton';

export interface CreateServiceWindowButtonProps {
    checkedServices: ServiceResponse[]
}

export const CreateServiceWindowButton = (props: CreateServiceWindowButtonProps) => {
    const [submittedServiceWindow, setSubmittedServiceWindow] = useState<ServiceWindow | undefined>(undefined)
    const [open, setOpen] = useState<boolean>(false)
    const loggedInUser = useContext(UserContext);
    return (
        <Can I={PermissionEnum.SERVICE_WRITE} a={loggedInUser} >
            <CreateEntityButton open={open} onClose={() => setSubmittedServiceWindow(undefined)} setOpen={setOpen} tooltip="Create service window" buttonIcon={<BuildCircleIcon />}>
                {submittedServiceWindow == undefined ?
                    <CreateServiceWindowButtonContent afterSaveAction={(serviceWindow) => { setSubmittedServiceWindow(serviceWindow) }} checkedServices={props.checkedServices} /> :
                    <ServiceWindowInfoContent serviceWindowId={submittedServiceWindow.uuid} afterDeleteAction={() => {
                        setSubmittedServiceWindow(undefined)
                        setOpen(false)
                    }} />
                }

            </CreateEntityButton>
        </Can >
    )
}

export interface CreateServiceWindowButtonContentProps {
    afterSaveAction: (serviceWindow: ServiceWindow) => void
    checkedServices: ServiceResponse[]
}

export const CreateServiceWindowButtonContent = (props: CreateServiceWindowButtonContentProps) => {
    const SignupSchema = Yup.object().shape({
        description: Yup.string().required(t('Required')),
        services: Yup.array().min(1, t('You have to select at least one service')).required(t('Required')),
        start_time: Yup.date().required(t('Required')).typeError(t("Not a valid date")),
        end_time: Yup.date().when("start_time", {
            is: (start_time?: Date) => start_time != null,
            then: () => Yup.date().required(t('Required'))
                .typeError(t("Not a valid date"))
                .min(Yup.ref("start_time"), t('End time must be after start time'))
        }).required(t('Required')),
    });
    const ability = useContext(AbilityContext)

    const currentOrganisation = useContext(OrganisationContext)?.organisation;

    const currentResult = useGetServicesByOrganisationIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: currentOrganisation?.uuid
    });

    if (currentResult.isLoading)
        return <Loading />

    if (currentResult.data === undefined)
        currentResult.data = []

    const filteredResult = currentResult.data.filter(service => ability.can(PermissionEnum.SERVICE_WRITE, service))
    return (
        <>
            <Box>
                <Grid container justifyContent="center">

                    <Grid item xs={6}>
                        <Typography variant="h6">{t("Create service window") + ""}</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={6}>

                        <EasyForm
                            isAllowedToEdit={() => ability.can(PermissionEnum.SERVICE_WRITE, currentOrganisation)}
                            editModeAlways={true}
                            arrayPath=""
                            SignupSchema={SignupSchema}
                            createNew={() => {
                                const initialServiceWindow = new ServiceWindowRequest();

                                if (props.checkedServices.length > 0) {
                                    initialServiceWindow.services = props.checkedServices
                                    return initialServiceWindow;
                                }

                                initialServiceWindow.services = filteredResult;
                                return initialServiceWindow;
                            }}
                            columns={[
                                new EasyFormColumn("", "", [
                                    new TextFieldRender({
                                        displayText: "Description",
                                        fieldName: "description",
                                        uniqueId: "no_id",
                                        type: "text",
                                        multiline: true
                                    }),
                                    new AutocompleteFieldRender({
                                        displayText: "Services",
                                        fieldName: "services",
                                        getOptionLabelFunc: (option: ServiceRequest) => option.name ?? "",
                                        options: filteredResult,
                                        multipleSelect: true,
                                        defaultToAllSelected: props.checkedServices.length > 0 ? false : true,
                                        uniqueId: "no_id"
                                    }),
                                    new DateTimeRender({
                                        displayText: "Start",
                                        fieldName: "start_time",
                                        uniqueId: "no_id",
                                        includeTime: true
                                    }),
                                    new DateTimeRender({
                                        displayText: "End",
                                        fieldName: "end_time",
                                        uniqueId: "no_id",
                                        includeTime: true
                                    }),
                                ])
                            ]}
                            formType={FormType.INFORMATION}
                            getFieldRows={() => []}
                            saveMutation={useAddServiceWindowMutation}
                            afterSaveAction={props.afterSaveAction}
                            showResetButton={false}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}