import getEnvironment from '../../../env'
import handleResponse from '../../../redux/handleResponse'
import { transformSingleResponseToRightType } from '../../../redux/transformResponse';
import { NotificationPatch, NotificationResponse } from '../../../models/Notification';
import HandleQuery from '../../../redux/EndpointQueryHandler';
import { portalApiSlice } from '../../../redux/portalApiSlice';
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper';
import { GetManyPackage } from '../../../redux/GetManyPackage';

const baseurl = getEnvironment().REACT_APP_API_BASEURL;


export const notificationApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateNotification: builder.mutation<void, RequestMutationWrapper<NotificationPatch>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/notifications/${request.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Notification could not be updated" }),
        method: "PATCH",
        body: request.data
      }),
      invalidatesTags: (_result, _error, args) => [{ type: 'notifications', uuid: args.data.uuid }]
    }),
    getNotificationsByUserId: builder.query<NotificationResponse, GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/notifications`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Notifications could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: NotificationResponse, b, c) => transformSingleResponseToRightType(NotificationResponse, a, b, c),
      providesTags: (_result, _error, uuid) => [{ type: 'notifications', uuid: uuid }]
    }),
  })
})


export const { useGetNotificationsByUserIdQuery, useUpdateNotificationMutation } = notificationApiSlice
















