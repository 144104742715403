import getEnvironment from '../../../env';
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper';
import Client, { CreateClientRequest } from '../../../models/Client';
import { CreateResponse } from '../../../redux/CreateResponse';
import HandleQuery from '../../../redux/EndpointQueryHandler';
import { GetManyPackage } from '../../../redux/GetManyPackage';
import handleResponse from '../../../redux/handleResponse';
import { portalApiSlice } from '../../../redux/portalApiSlice';
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse';

const baseurl = getEnvironment().REACT_APP_API_BASEURL;


export const clientsApi = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllClients: builder.query<Client[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/clients`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Clients could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: Client[], b, c) => transformMultipleResponseToRightType(Client, a, b, c),
      providesTags: ['clients']
    }),
    getClientById: builder.query<Client, string>({
      query: (uuid) => HandleQuery({
        url: `${baseurl}/clients/${uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Client could not be fetched" }),
      }),
      transformResponse: (a: Client, b, c) => transformSingleResponseToRightType(Client, a, b, c),
      providesTags: ['client']
    }),
    getClientsByOrganisationId: builder.query<Client[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/organisations/${pack.uuid}/clients`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Client could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: Client[], b, c) => transformMultipleResponseToRightType(Client, a, b, c),
      providesTags: ['clients']
    }),
    getClientsByUserContact: builder.query<Client[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/users/${pack.uuid}/contact/clients`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Clients could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: Client[], b, c) => transformMultipleResponseToRightType(Client, a, b, c),
      providesTags: ['clients', 'client-contacts']
    }),
    getClientsByExternalContactContact: builder.query<Client[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/external-contacts/${pack.uuid}/contact/clients`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Clients could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: Client[], b, c) => transformMultipleResponseToRightType(Client, a, b, c),
      providesTags: ['clients', 'client-contacts']
    }),
    updateClient: builder.mutation<Client, RequestMutationWrapper<Client>>({
      query: (client) => HandleQuery({
        url: `${baseurl}/clients/${client.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Client was updated!", toastErrorText: "Client could not be updated" }),
        method: "PATCH",
        body: client.data,
      }),
      transformResponse: (a: Client, b, c) => transformSingleResponseToRightType(Client, a, b, c),
      invalidatesTags: ['clients', 'client']
    }),
    getClientsByHostingSiteId: builder.query<Client[], GetManyPackage>({
      query: (client) => HandleQuery({
        url: `${baseurl}/hosting-sites/${client.uuid}/clients`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Clients could not be fetched" }),
        pagination: client.pagination
      }),
      transformResponse: (a: Client[], b, c) => transformMultipleResponseToRightType(Client, a, b, c),
      providesTags: ['clients']
    }),
    addClient: builder.mutation<CreateResponse, RequestMutationWrapper<CreateClientRequest>>({
      query: (client) => HandleQuery({
        url: `${baseurl}/hosting-sites/${client.data.hosting_site?.uuid}/clients`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Client was created!", toastErrorText: "Client could not be created" }),
        method: 'POST',
        body: {
          name: client.data.name,
          description: client.data.description,
          internal_description: client.data.internal_description,
          general_contact_information: client.data.general_contact_information,
          contacts: client.data.contacts.map(contact => ({
            external_contact_uuid: contact.contact?.user_uuid == undefined ? contact.contact?.uuid : undefined,
            user_uuid: contact.contact?.user_uuid,
            contact_label_uuids: contact.labels?.map(label => label.uuid)
          })),
          ip_addresses: client.data.ip_addresses.map(ip_address => ({
            subnet_uuid: ip_address.subnet?.uuid,
            ipaddress: ip_address.ipaddress
          }))
        },
      }),
      transformResponse: (a: CreateResponse, b, c) => transformSingleResponseToRightType(CreateResponse, a, b, c),

      invalidatesTags: ['clients'],
    }),
    forceAddClient: builder.mutation<CreateResponse, RequestMutationWrapper<CreateClientRequest>>({
      query: (client) => HandleQuery({
        url: `${baseurl}/hosting-sites/${client.data.hosting_site?.uuid}/clients/force`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Client was created!", toastErrorText: "Client could not be created" }),
        method: 'POST',
        body: {
          name: client.data.name,
          description: client.data.description,
          internal_description: client.data.internal_description,
          general_contact_information: client.data.general_contact_information,
          contacts: client.data.contacts.map(contact => ({
            external_contact_uuid: contact.contact?.user_uuid == undefined ? contact.contact?.uuid : undefined,
            user_uuid: contact.contact?.user_uuid,
            contact_label_uuids: contact.labels?.map(label => label.uuid)
          })),
          ip_addresses: client.data.ip_addresses.map(ip_address => ({
            subnet_uuid: ip_address.subnet?.uuid,
            ipaddress: ip_address.ipaddress
          }))
        },
      }),
      transformResponse: (a: CreateResponse, b, c) => transformSingleResponseToRightType(CreateResponse, a, b, c),

      invalidatesTags: ['clients'],
    }),
    deleteClient: builder.mutation<void, RequestMutationWrapper<Client>>({
      query: (client) => HandleQuery({
        url: `${baseurl}/clients/${client.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Client was deleted!", toastErrorText: "Client could not be deleted" }),
      }),
      invalidatesTags: ['clients'],
    }),
  })
})

export const { useGetAllClientsQuery, useGetClientsByOrganisationIdQuery, useDeleteClientMutation, useGetClientByIdQuery, useUpdateClientMutation, useGetClientsByHostingSiteIdQuery, useAddClientMutation, useForceAddClientMutation, useGetClientsByUserContactQuery, useGetClientsByExternalContactContactQuery } = clientsApi

