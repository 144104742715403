import { Divider, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { DirectionEnum } from "../../models/enums/DirectionEnum"
import Portalias from "../../models/Portalias"

export const PortaliasSimpleDetails = (props: { portalias: Portalias }) => {
    const allAcls = props.portalias.acls?.filter(x => x.direction == DirectionEnum.BIDIRECTIONAL || x.direction == DirectionEnum.SRC_TO_DEST) ?? []
    const { t } = useTranslation()
    return (
        <Stack spacing={2}>


            {allAcls.length == 0 && <>{t("No ports")}</>}
            {allAcls.length > 0 && allAcls.map(x => (
                <>
                    <Stack>
                        <Typography fontSize="8pt">{props.portalias.name}</Typography>
                        <Divider color="white" />
                        <Typography fontSize="10pt">{x.direction} ({x.protocol})</Typography>
                        <Typography fontSize="10pt"><>{t("source")}: {x.source_option} {x.source_end ? x.source_start + "-" + x.source_end : x.source_start}</></Typography>
                        <Typography fontSize="10pt"><>{t("destination")}: {x.destination_option} {x.destination_end ? x.destination_start + "-" + x.destination_end : x.destination_start}</></Typography>
                    </Stack>

                </>
            ))}
        </Stack>
    )
}