import { format } from "date-fns-tz";
import { da } from 'date-fns/locale';

export function DateToFormattedString(date: Date | string | undefined): string {
    if (date == undefined)
        return ""
    const newDate = new Date(date);
    const timeZone = 'Europe/Copenhagen'
    const dateFormat = "dd-MM-yyyy"
    const timeFormat = "HH:mm"
    try {

        const toReturn = format(newDate, `${dateFormat} ${timeFormat}`, {
            locale: da,
            timeZone: timeZone
        })
        return toReturn;
    } catch (error) {
        return date.toLocaleString()
    }
}

export function calculateDateProgressionInPercentage(start?: Date, end?: Date) {
    if (start == undefined || end == undefined)
        return -1;

    const startDate = new Date(start + "")
    const endDate = new Date(end + "")
    const now = new Date();

    const endDateIsInPast = endDate < now;
    if (endDateIsInPast)
        return 100

    const startDateIsInFuture = startDate > now;
    if (startDateIsInFuture)
        return 0

    const timeBetween = endDate.getTime() - startDate.getTime();
    const timeCompleted = now.getTime() - startDate.getTime();

    return ((timeCompleted / timeBetween) * 100).toFixed(2) as unknown as number

}