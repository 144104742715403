import { getIn } from "formik";
import ToggleableCheckbox from "../ToggleableCheckbox";
import { EasyFieldProps } from "./EasyForm";

interface EasyCheckboxProps {
    editMode: boolean
    fieldName: string
    disabled: boolean
    render?: (checked: boolean) => JSX.Element
}

export default function EasyCheckbox(props: EasyFieldProps & EasyCheckboxProps): JSX.Element {
    const checked = getIn(props.values, props.fieldName);
    return (

        <ToggleableCheckbox
            editMode={props.editMode}
            id={props.fieldName}
            name={props.fieldName}
            render={props.render}
            checked={checked}
            disabled={props.disabled}
            onChange={(_ev, checked) => props.setFieldValue(props.fieldName, checked)}
        />

    )
}