import { t } from "i18next";
import { AutocompleteFieldRender } from "./AutocompleteFieldRender";

export interface IpRenderPackage {
    fieldName?: string
    nestedfieldName?: string
    options: string[]
    uniqueId?: string
}

export class IpRender extends AutocompleteFieldRender<string> {
    constructor(ipRenderPackage: IpRenderPackage) {
        super({
            displayText: "Ip",
            fieldName: ipRenderPackage.fieldName ?? "",
            nestedfieldName: ipRenderPackage.nestedfieldName ?? "",
            filterOptions: (options, state) => {
                if (state.inputValue.length > 6 || options.length < 1000) {
                    return options.filter((item) =>
                        String(item).toLowerCase().includes(state.inputValue.toLowerCase())
                    );
                }
                return [];
            },
            noOptionsText: (input) => <>{input.length > 6 ? t("No options") : t("Start typing to get suggestions")}</>,
            columns: "auto",
            minWidth: 500,
            showLabel: true,
            getOptionLabelFunc: (ip: string) => ip,
            options: ipRenderPackage.options,
            uniqueId: ipRenderPackage.uniqueId ?? crypto.randomUUID(),
            selectedValueInOptions: (selected, options) => options.includes(selected),
            disableAutoPicker: true
        })
    }
}