import { t } from "i18next";
import * as Yup from 'yup';

export function getHostingSiteValidationScheme() {
    return Yup.object().shape({
        name: Yup.string().required(t("Required")),
        address: Yup.string().required(t("Required")),
        sdn_v4_id: Yup.string().length(8).nullable().matches(/^[a-z0-9]*$/, t("Lowercase and numbers")),
        theft_mark: Yup.string().max(50).nullable(),
        vlan_name: Yup.string().max(15).nullable().matches(/^[a-zA-Z0-9_-]*$/, t("Vlan format"))
    })
}