import { AutocompleteRenderOptionState, FilterOptionsState } from "@mui/material";
import { HTMLAttributes, ReactNode } from "react";
import { FieldEditorRender, FieldEditorRenderPackage } from "./FieldEditorRender";
import { FieldRenderType } from "./FieldRenderType";
export class AutocompleteFieldRenderPackage<T> extends FieldEditorRenderPackage {
    options!: any[]
    getOptionLabelFunc!: (option: T) => JSX.Element | string
    renderOption?: ((props: HTMLAttributes<HTMLLIElement>, option: T, state: AutocompleteRenderOptionState) => ReactNode)
    renderNonChangeable?: (option: T) => JSX.Element
    nestedfieldName?: string
    multipleSelect?: boolean
    loading?: boolean
    onMount?: () => void
    onChange?: (newSelected: any) => void
    noOptionsText?: (input: string, setNewValue: (newValue: T) => void) => React.ReactNode;
    isOptionEqualToValue?: ((option: T, value: T) => boolean)
    filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
    defaultToAllSelected?: boolean
    valueToAutoSelect?: any
    disableAutoPicker?: boolean
    selectedValueInOptions?: (selected: T, options: T[]) => boolean
}

export class AutocompleteFieldRender<T> extends FieldEditorRender {

    options: any[]
    multipleSelect: boolean
    onChange: (newSelected: any) => void
    onMount: () => void
    getOptionLabelFunc: (option: T) => JSX.Element | string;
    renderOption?: ((props: HTMLAttributes<HTMLLIElement>, option: T, state: AutocompleteRenderOptionState) => ReactNode)
    defaultToAllSelected?: boolean
    loading?: boolean
    renderNonChangeable?: (option: T) => JSX.Element;
    isOptionEqualToValue?: ((option: T, value: T) => boolean)
    filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
    noOptionsText?: (input: string, setNewValue: (newValue: T) => void) => React.ReactNode;
    valueToAutoSelect?: any;
    disbaleAutoPicker: boolean;
    selectedValueInOptions?: (selected: T, options: T[]) => boolean;

    constructor(autocompleteFieldRenderPackage: AutocompleteFieldRenderPackage<T>) {
        super(FieldRenderType.AutoComplete, autocompleteFieldRenderPackage);
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const emptyFunc = () => { }
        this.filterOptions = autocompleteFieldRenderPackage.filterOptions;
        this.renderOption = autocompleteFieldRenderPackage.renderOption;
        this.noOptionsText = autocompleteFieldRenderPackage.noOptionsText;
        this.getOptionLabelFunc = autocompleteFieldRenderPackage.getOptionLabelFunc;
        this.renderNonChangeable = autocompleteFieldRenderPackage.renderNonChangeable;
        this.loading = autocompleteFieldRenderPackage.loading;
        this.onChange = autocompleteFieldRenderPackage.onChange ?? emptyFunc;
        this.onMount = autocompleteFieldRenderPackage.onMount ?? emptyFunc;
        const isOptionEqualToValue = (option: T, value: T) => option == value;
        this.isOptionEqualToValue = autocompleteFieldRenderPackage.isOptionEqualToValue ?? isOptionEqualToValue
        this.selectedValueInOptions = autocompleteFieldRenderPackage.selectedValueInOptions;

        this.defaultToAllSelected = autocompleteFieldRenderPackage.defaultToAllSelected
        this.options = autocompleteFieldRenderPackage.options
        this.multipleSelect = autocompleteFieldRenderPackage.multipleSelect ?? false;
        this.valueToAutoSelect = autocompleteFieldRenderPackage.valueToAutoSelect;
        this.disbaleAutoPicker = autocompleteFieldRenderPackage.disableAutoPicker ?? false;
    }
}