import { Grid } from "@mui/material";
import { FormikProps } from "formik";
import { useContext, useMemo, useState } from "react";
import { OrganisationContext } from "../../../components/Layout/OrganisationPicker";
import AgreementRequest from "../../../models/Agreement";
import Client from "../../../models/Client";
import { ClientList } from "../../ClientApi/clientList";
import { useGetClientsByOrganisationIdQuery } from "../../ClientApi/redux/clientsApiSlice";

export default function PickClientsStep(props: { formikProps: FormikProps<AgreementRequest>, formPropsStuf: any }) {
    const [selected, setSelected] = useState<Client[]>([]);
    const currentOrganisation = useContext(OrganisationContext)?.organisation;
    useMemo(() => {

        props.formikProps.setFieldValue("clients", selected)
    }, [selected])
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ClientList idToFetch={currentOrganisation?.uuid} setCheckedClients={setSelected} checkedClients={selected} getClients={useGetClientsByOrganisationIdQuery} />
            </Grid>
        </Grid>
    )
}