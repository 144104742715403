import { Handshake } from "@mui/icons-material"
import { Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { OrganisationBadge } from "../../../components/Badges/OrganisationBadge"
import DrawerButton from "../../../components/DrawerButton"
import TooltipComponent from "../../../components/TooltipComponent"
import Loading from "../../../components/loading"
import { ApprovalStatus } from "../../../models/enums/ApprovalStatus"
import { AgreementList } from "../../AgreementApi/agreementList"
import { useGetAgreementsByOrganisationIdQuery } from "../../AgreementApi/redux/agreementsApiSlice"
import { useGetOrganisationByIdQuery } from "../redux/organisationApiSlice"
import { OrganisationButtonProps } from "./OrganisationButtonProps"



export const OrganisationAgreementsButton = (props: OrganisationButtonProps) => {
    const { t } = useTranslation()

    const organisationResult = useGetOrganisationByIdQuery(props.organisationUuid!)

    const [open, setOpen] = useState<boolean>(false)

    if (organisationResult.isLoading) {
        return <Loading />
    }
    return (
        <DrawerButton open={open} setOpen={setOpen} buttonContent={
            <TooltipComponent title={t("Overview of agreements belonging to") + " " + organisationResult.data?.name}>
                <Handshake />
            </TooltipComponent>
        } >
            <DrawerContent {...props} />
        </DrawerButton>
    )
}

const DrawerContent = (props: OrganisationButtonProps) => {
    const { t } = useTranslation()
    const organisationResult = useGetOrganisationByIdQuery(props.organisationUuid!)
    if (organisationResult.isLoading) {
        return <Loading />
    }
    return (

        <Stack spacing={2}>
            <Typography variant="h6">{t("Agreements belonging to")}  <OrganisationBadge ressource={organisationResult.data} /></Typography>
            <AgreementList statusList={[ApprovalStatus.PENDING]} idToFetch={props.organisationUuid} getAgreements={useGetAgreementsByOrganisationIdQuery} />
        </Stack>

    )
}