import { FieldArray, FormikProps } from "formik";
import { t } from "i18next";
import { TFunction } from "react-i18next";
import * as Yup from 'yup';
import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import { FormType } from "../../../components/Forms/EasyFormMany";
import { EasyFormRow } from "../../../components/Forms/FormLayout";
import { AutocompleteFieldRender } from "../../../components/Forms/Renders/AutocompleteFieldRender";
import { FieldEditorRender } from "../../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender } from "../../../components/Forms/Renders/TextFieldRender";
import { IsIpAddressValidation } from "../../../components/Forms/Validations/IsIpAddressValidation";
import { RouterTypeEnum } from "../../../models/enums/RouterTypeEnum";
import { CreateOrganisationRequest, OrganisationRouter } from "../../../models/Organisation";

export function getRouterStepValidation(t: TFunction<"translation", undefined>) {
    return Yup.object().shape({
        routers: Yup.array().of(Yup.object().shape({
            name: Yup.string().required(t('Required')),
            ip_address: IsIpAddressValidation(false),
            username: Yup.string().nullable().when('type', {
                is: (type: RouterTypeEnum) => type === RouterTypeEnum.MPLS,
                then: Yup.string().required(t('Required')),
            }),
            password: Yup.string().nullable().when('type', {
                is: (type: RouterTypeEnum) => type === RouterTypeEnum.MPLS,
                then: Yup.string().required(t('Required')),
            }),
            type: Yup.mixed().oneOf(Object.values(RouterTypeEnum)).required(t('Required')),
        }))
    })
}

export default function RouterStep(props: { formikProps: FormikProps<CreateOrganisationRequest>, formPropsStuf: any }) {

    function getRows(
        organisationRequest: CreateOrganisationRequest
    ): Array<FieldEditorRender[]> {
        const rows: Array<FieldEditorRender[]> = []
        organisationRequest.routers?.forEach((router) => {
            const newRow: FieldEditorRender[] = [
                new TextFieldRender({
                    displayText: "Name",
                    showLabel: true,
                    fieldName: "routers",
                    nestedfieldName: "name",
                    type: "text",
                    columns: 12,
                    uniqueId: router.uuid
                }),
                new TextFieldRender({
                    displayText: "IP-address",
                    showLabel: true,
                    fieldName: "routers",
                    nestedfieldName: "ip_address",
                    type: "text",
                    columns: 12,
                    uniqueId: router.uuid
                }),
                new AutocompleteFieldRender({
                    displayText: t("Type"),
                    fieldName: "routers",
                    nestedfieldName: "type",
                    getOptionLabelFunc: (option: RouterTypeEnum) => option.toString() ?? "",
                    options: Object.values(RouterTypeEnum) ?? [],
                    renderNonChangeable: (content) => <>{content?.toString()}</>,
                    uniqueId: router.uuid,
                    showLabel: true,
                    columns: 12
                }),
                new TextFieldRender({
                    conditionFieldName: `type`,
                    conditionShouldBeDisabledFunc: (type: RouterTypeEnum) => type !== RouterTypeEnum.MPLS,
                    displayText: "Username",
                    showLabel: true,
                    fieldName: "routers",
                    nestedfieldName: "username",
                    type: "text",
                    columns: 12,
                    uniqueId: router.uuid
                }),
                new TextFieldRender({
                    conditionFieldName: `type`,
                    conditionShouldBeDisabledFunc: (type: RouterTypeEnum) => type !== RouterTypeEnum.MPLS,
                    displayText: "Password",
                    showLabel: true,
                    fieldName: "routers",
                    nestedfieldName: "password",
                    type: "text",
                    columns: true,
                    uniqueId: router.uuid
                }),
                new TextFieldRender({
                    displayText: "Remark",
                    showLabel: true,
                    fieldName: "routers",
                    nestedfieldName: "comment",
                    type: "text",
                    columns: 12,
                    uniqueId: router.uuid
                })
            ]
            rows.push(newRow)
        })
        return rows;
    }

    return (
        <FieldArray
            name="routers"
            render={arrayHelpers => (
                <EasyFormRow
                confirmDeletionText={t("Are you sure you wish to remove this router?")}
                    {...props.formikProps}
                    {...props.formPropsStuf}
                    editModeAlways={true}
                    editable={true}
                    isDeleteable={true}
                    saveMutation={() => { throw "Dont save plz" }}
                    formType={FormType.LIST}
                    createNew={() => new OrganisationRouter()}
                    editMode={true}
                    getFieldRows={(createOrganisationRequest) => getRows(createOrganisationRequest as CreateOrganisationRequest)}
                    columns={[
                        new EasyFormColumn("Routers", "", [])
                    ]}
                    arrayHelpers={arrayHelpers} />
            )
            }
        />
    )
}