
import getEnvironment from '../../../env'
import IpInfo from '../../../models/IpInfo'
import HandleQuery from '../../../redux/EndpointQueryHandler'
import { GetManyPackage } from '../../../redux/GetManyPackage'
import handleResponse from '../../../redux/handleResponse'
import { portalApiSlice } from '../../../redux/portalApiSlice'
import { transformMultipleResponseToRightType } from '../../../redux/transformResponse'

const baseurl = getEnvironment().REACT_APP_API_BASEURL;



export const ipInfoApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getIpInfo: builder.query<IpInfo[], GetManyPackage>({
      query: () => HandleQuery({
        url: `${baseurl}/ip-info`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "IP info could not be fetched" }),
      }),
      transformResponse: (a: IpInfo[], b, c) => transformMultipleResponseToRightType(IpInfo, a, b, c),
      providesTags:  ['ipinfo']
    }),
  }),
})


export const { useGetIpInfoQuery } = ipInfoApiSlice