import { Chip } from "@mui/material"
import { t } from "i18next";

import { ApprovalStatus } from "../models/enums/ApprovalStatus";
import { ApprovalStatusToColorFunc } from "./Logic/ApprovalLogic";

class ApprovalStatusChipProps {
    status?: ApprovalStatus
}

export default function ApprovalStatusChip(props: ApprovalStatusChipProps) {
    const { chipColor: color, icon } = ApprovalStatusToColorFunc(props.status)
    return (
        <>
            <Chip color={color} icon={icon} sx={{ fontSize: 18, padding: 1, textTransform:"capitalize" }} label={t(props.status + "").toLocaleLowerCase() + ""} />
        </>
    )
}