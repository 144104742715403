import { AddCircle } from "@mui/icons-material";
import { ListItemButton, Stack, Typography } from "@mui/material";
import { TFunction, useTranslation } from "react-i18next";
import { ServiceTagBadge } from "../../../components/Badges/ServiceTagBadge";
import { AutocompleteFieldRender, AutocompleteFieldRenderPackage } from "../../../components/Forms/Renders/AutocompleteFieldRender";
import HostingsiteBase from "../../../models/Hostingsite";
import { ServiceTagRequest, ServiceTagResponse } from "../../../models/ServiceTag";
import { useAddServiceTagMutation } from "../../OrganisationApi/redux/serviceTagApiSlice";

export interface ServiceTagFieldRenderProps {
    translation: TFunction<"translation", undefined>
    serviceTags: ServiceTagResponse[]
    setNewlyCreatedServiceTag: React.Dispatch<React.SetStateAction<ServiceTagResponse | undefined>>
    newlyCreatedServiceTag?: ServiceTagResponse
    hostingSite?: HostingsiteBase
    initialServiceTag?: ServiceTagResponse
    showLabel?: boolean
}

export default function serviceTagFieldRender(props: ServiceTagFieldRenderProps): AutocompleteFieldRender<ServiceTagResponse> {
    const fieldRenderPackage: AutocompleteFieldRenderPackage<ServiceTagResponse> = {
        displayText: props.translation("Servicegroup"),
        fieldName: "servicetag",
        showLabel: props.showLabel,
        filterOptions: (options, state) => {
            return options.filter(x => state.inputValue == "" ? true : x.name!.toLowerCase().includes(state.inputValue.toLowerCase()))
        },
        getOptionLabelFunc: (option: ServiceTagResponse) => option.name ?? "",
        options: props.serviceTags,
        valueToAutoSelect: props.newlyCreatedServiceTag ?? props.initialServiceTag,
        noOptionsText: (input) => (
            <ServiceTagListItemButton input={input} setNewlyCreatedServiceTag={props.setNewlyCreatedServiceTag} hostingSite={props.hostingSite} />
        ),
        renderNonChangeable: (option) => <ServiceTagBadge ressource={option as ServiceTagRequest} />,
        uniqueId: "no_id",
        disableAutoPicker: true,
        minWidth: 250
    }
    return new AutocompleteFieldRender(fieldRenderPackage)
}

export interface ServiceTagListItemButtonProps {
    input: string
    setNewlyCreatedServiceTag: React.Dispatch<React.SetStateAction<ServiceTagResponse | undefined>>
    hostingSite?: HostingsiteBase
}

function ServiceTagListItemButton(props: ServiceTagListItemButtonProps) {
    const { t } = useTranslation()
    const addServiceTagMutation = useAddServiceTagMutation();
    const addServiceTagMutationTrigger = addServiceTagMutation[0];

    return (
        <ListItemButton disabled={props.hostingSite == undefined} onClick={() => {
            const request = new ServiceTagRequest();
            request.name = props.input;
            request.hostingsite = props.hostingSite

            addServiceTagMutationTrigger({ data: request })
                .unwrap()
                .then(createdServiceTag => props.setNewlyCreatedServiceTag(createdServiceTag))
        }}>
            <Stack direction="row" spacing={1}>

                {props.hostingSite == undefined ?
                    <Typography>{t("You have to pick a hostingsite before a servicegroup") + ""}</Typography> :
                    <>
                        {props.input == undefined || props.input.trim() == "" ?
                            <Typography>{t("Start typing to create a new servicetag") + ""}</Typography> :
                            <>
                                <AddCircle />
                                <Typography>{t("Create") + " " + props.input}</Typography>
                            </>
                        }
                    </>
                }
            </Stack>
        </ListItemButton>
    )
}