import { Autocomplete, Grid, TextField } from "@mui/material";
import { createContext, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useGetAllAllowedOrganisationsQuery } from "../../feature/OrganisationApi/redux/organisationApiSlice";
import { UserContext } from "../../feature/UserApi/logic/FetchUser";
import { PaginationQueryPackage } from "../../models/API/QueryParams/PaginationQueryPackage";
import OrganisationResponse from "../../models/Organisation";
import Loading from "../loading";

interface IOrganisationContext {
    organisation: OrganisationResponse | undefined
    setOrganisation: (newOrg: OrganisationResponse | undefined) => void
}

export const OrganisationContext = createContext<IOrganisationContext | undefined>(undefined);

export default function OrganisationPicker() {
    const { t } = useTranslation()
    const orgContext = useContext(OrganisationContext);
    const userContext = useContext(UserContext);

    const organisationsResult = useGetAllAllowedOrganisationsQuery({ pagination: new PaginationQueryPackage() }, { refetchOnMountOrArgChange: true });
    const organisations: OrganisationResponse[] = organisationsResult.data ?? [];

    const organisation = organisations.find(org => org.uuid == orgContext?.organisation?.uuid) ?? !userContext?.allowEmptyOrganisation() ? organisations.find(() => true) : undefined;

    const organisationcontextInUseInLocalStorage = localStorage.getItem('organisationContext');
    const organisationContextInUse = organisationcontextInUseInLocalStorage == null ? undefined : JSON.parse(organisationcontextInUseInLocalStorage);

    const localStorageItem = organisations.find(org => org.uuid == organisationContextInUse?.uuid)

    useMemo(() => {
        orgContext?.setOrganisation(localStorageItem ?? organisation);
    }, [organisations])



    if (organisationsResult.isLoading)
        return <Loading />

    return (
        <Grid margin={2}>

            <Autocomplete
                key={JSON.stringify([organisations, orgContext?.organisation])}
                getOptionLabel={(org) => org?.name ?? ""}
                value={orgContext?.organisation}
                readOnly={organisations.length == 1 ? true : undefined}
                onChange={(_event, newOrganisation) => {
                    if (newOrganisation == undefined && !userContext?.allowEmptyOrganisation()) {
                        orgContext?.setOrganisation(organisations.find(() => true))
                        toast.warning(t("You have to select an organisation")+"")
                        return;
                    }

                    const pickedOrganisation = newOrganisation ?? undefined;
                    orgContext?.setOrganisation(pickedOrganisation)
                    localStorage.setItem("organisationContext", JSON.stringify(pickedOrganisation));

                    if (newOrganisation == undefined) {
                        localStorage.removeItem("organisationContext")
                    }
                }}
                options={organisations}
                renderInput={(params) => {
                    return (
                        <TextField placeholder={t("Chose organisation")} {...params} />
                    )
                }}
                isOptionEqualToValue={(option, value) => option?.uuid == value?.uuid}
            />
        </Grid>
    )
}