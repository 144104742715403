
import getEnvironment from '../../../env'
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper'
import { ContactLabel, ContactLabelRequest, ContactLabelResponse } from '../../../models/ContactLabel'
import HandleQuery from '../../../redux/EndpointQueryHandler'
import { GetManyPackage } from '../../../redux/GetManyPackage'
import handleResponse from '../../../redux/handleResponse'
import { portalApiSlice } from '../../../redux/portalApiSlice'
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse'

const baseurl = getEnvironment().REACT_APP_API_BASEURL;



export const contactLabelsApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllContactLabels: builder.query<ContactLabelResponse[], GetManyPackage>({
      query: (request) => HandleQuery({
        url: `${baseurl}/contactlabels`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "ContactLabels could not be fetched" }),
        pagination: request.pagination
      }),
      transformResponse: (a: ContactLabel[], b, c) => transformMultipleResponseToRightType(ContactLabel, a, b, c),
      providesTags: (_result, _error, uuid) => [{ type: 'contactlabels', uuid: uuid }]
    }),
    getContactLabelsForContact: builder.query<ContactLabelResponse[], GetManyPackage>({
      query: (request) => HandleQuery({
        url: `${baseurl}/contacts/${request.uuid}/contactlabels`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "ContactLabels for contact could not be fetched" }),
        pagination: request.pagination
      }),
      transformResponse: (a: ContactLabel[], b, c) => transformMultipleResponseToRightType(ContactLabel, a, b, c),
      providesTags: (_result, _error, uuid) => [{ type: 'contactlabels', uuid: uuid }]
    }),
    addContactLabel: builder.mutation<ContactLabelResponse, RequestMutationWrapper<ContactLabelRequest>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/contactlabels`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Contact label was created", toastErrorText: "ContactLabel could not be created" }),
        method: 'POST',
        body: request.data,
      }),
      transformResponse: (a: ContactLabel, b, c) => transformSingleResponseToRightType(ContactLabel, a, b, c),

      invalidatesTags: ['contactlabels'],
    }),
    updateContactLabel: builder.mutation<ContactLabelResponse, RequestMutationWrapper<ContactLabelResponse>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/contactlabels/${request.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Contact label was updated", toastErrorText: "ContactLabel could not be created" }),
        method: 'PATCH',
        body: request.data,
      }),
      transformResponse: (a: ContactLabel, b, c) => transformSingleResponseToRightType(ContactLabel, a, b, c),

      invalidatesTags: ['contactlabels'],
    }),
  }),
})


export const { useGetAllContactLabelsQuery, useAddContactLabelMutation, useUpdateContactLabelMutation } = contactLabelsApiSlice