import { Close, SearchOff } from "@mui/icons-material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, ButtonGroup, Divider, Grid, Stack, Table, TableCell, TableRow, Typography } from "@mui/material";
import { FieldArrayRenderProps, FormikProps } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationButton } from "../ConfirmationButton";
import EmptyPage from "../EmptyPage";
import TooltipComponent from "../TooltipComponent";
import { EasyFormProps } from "./EasyForm";
import EasyFormColumn from "./EasyFormColumn";
import { EasyFormManyProps } from "./EasyFormMany";
import { RenderInput } from "./RenderInput";
export interface BaseLayoutProps {
    //Side by side
    editMode: boolean
    setEditModeTemp: (editMode: boolean) => void

}

export interface SideBySideLayoutProps {
    //Side by side
    columns: EasyFormColumn[]

}

export function EasyFormSideBySide<CustomType>(props: SideBySideLayoutProps & BaseLayoutProps & FormikProps<CustomType>) {
    const { t } = useTranslation()
    return (
        <>
            {props.columns.map((col, columnIndex) => (
                <Grid key={columnIndex} item xs={1}>
                    <Typography paddingBottom={2} variant="h5">{t(col.title ?? "") + ""}</Typography>
                    <Typography variant="caption">{t(col.description ?? "") + ""}</Typography>
                    <Table>
                        {col.fieldEditorRenders?.map((fieldrender, fieldIndex) => (
                            <TableRow key={fieldrender.fieldName + fieldIndex}>
                                <TableCell>{typeof fieldrender.displayText === "string" ? t(fieldrender.displayText + "") : fieldrender.displayText}</TableCell>
                                <TableCell>
                                    <>{RenderInput(fieldrender, props, props.editMode)}</>
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </Grid>
            ))}
        </>
    )
}

export interface EasyFormRowProps {
    arrayHelpers: FieldArrayRenderProps
    confirmDeletionText: string
}

export function EasyFormRow<CustomType>(props: EasyFormProps<CustomType> & EasyFormRowProps & BaseLayoutProps & FormikProps<CustomType>) {
    /**When deleting, the components are not rerendered, so we use this variable (forceRerender) to forcererender table in case of deletion. 
     * Test: Create two rows, call the first one "aaa", and the next one "bbb". Try deleting "aaa". You will notice, that "bbb" is deleted, if we dont use this variable
     **/
    const [forceRerender] = useState(0) //read comment above--^

    const fieldRows = props.getFieldRows(props.values!);
    const isDeleteable = props.isDeleteable ?? true;
    const isEditable = props.editable ?? true;
    const { t } = useTranslation()

    const getStyling = (index: number) => {
        if (index % 2 == 0) {
            return {}
        }

        return { background: "#F5F5F5" }
    }

    return (
        <>
            <Typography variant="h5">{t(props.columns[0].title ?? "") + ""}
                {isEditable ?
                    <TooltipComponent title={t(props.newButtonTooltip ?? "") + ""}>
                        <Button type="button" onClick={() => {
                            props.arrayHelpers.push(props.createNew())
                            props.setEditModeTemp(true)
                        }}>
                            <AddCircleOutlineIcon />
                        </Button>
                    </TooltipComponent> : <></>
                }
            </Typography>
            <Typography variant="caption">{t(props.columns[0].description ?? "") + ""}</Typography>
            <Stack spacing={2}>
                {fieldRows.map((row, index) => {
                    return (
                        <>
                            <Grid container paddingTop={5} paddingBottom={5} style={getStyling(index)} >
                                <Divider />
                                <Grid item xs={11} >

                                    <Grid container alignItems="start" spacing={4} key={forceRerender}>
                                        <>
                                            {row.map((fieldrender) => {
                                                return (
                                                    <>
                                                        <Grid item xs={fieldrender.columns}>
                                                            <>{RenderInput(fieldrender, props, props.editMode, index)}</>
                                                        </Grid>

                                                    </>
                                                )
                                            })}
                                        </>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    {props.editMode && isDeleteable ?

                                        <Grid item xs textAlign="right">
                                            <ConfirmationButton key={"confirmationButtonKey_" + index} variant="contained" color="primary" title={t(props.confirmDeletionText)} buttonText={<Close />} className="remove-alarm__button" action={async () => props.arrayHelpers.handleRemove(index)()}>
                                                <>{t("You will not be able to undo this action")}</>
                                            </ConfirmationButton>
                                        </Grid>

                                        : <></>
                                    }
                                </Grid>
                            </Grid>
                            {fieldRows.length - 1 == index ? <Divider /> : <></>}
                        </>
                    )
                })}
            </Stack>
        </>
    )
}


export function EasyFormRowMany<CustomType>(props: EasyFormManyProps<CustomType> & BaseLayoutProps & FormikProps<CustomType[]>) {
    /**When deleting, the components are not rerendered, so we use this variable (forceRerender) to forcererender table in case of deletion. 
     * Test: Create two rows, call the first one "aaa", and the next one "bbb". Try deleting "aaa". You will notice, that "bbb" is deleted, if we dont use this variable
     **/
    const [forceRerender] = useState(0) //read comment above--^
    const deleteTrigger = props.deleteMutation ? props.deleteMutation()[0] : undefined;
    const [values] = useState(props.values!);
    const [fieldRows] = useState(props.getFieldRows(values))

    const { t } = useTranslation()
    const headlineJsx = (
        <>
            <Typography variant="h5">{t(props.columns[0].title ?? "") + ""}
                {props.createButtonJsx && props.isAllowedToCreate && props.isAllowedToCreate(values.find(() => true)) ?
                    <>{props.createButtonJsx}</> : <></>
                }
            </Typography>
            <Typography variant="caption">{t(props.columns[0].description ?? "") + ""}</Typography>
        </>
    )

    if (values?.length == 0)
        return (<Grid item xs={12}> {headlineJsx} <EmptyPage icon={SearchOff} /></Grid>)

    return (
        <>
            <Grid item xs={12}>
                {headlineJsx}
                <Table key={forceRerender}>
                    {fieldRows.filter((_o, i) => i === 0).map((row) => {
                        return (<>

                            <TableRow>

                                {row.map((fieldrender, index) => (
                                    <TableCell key={fieldrender.fieldName + index}>
                                        {typeof fieldrender.displayText === "string" ? t(fieldrender.displayText + "") : fieldrender.displayText}
                                    </TableCell>
                                ))}

                                {!props.editMode ?

                                    <TableCell>
                                    </TableCell>
                                    : <></>
                                }

                            </TableRow>
                        </>
                        )
                    })}
                    {fieldRows.map((row, index) => {
                        return (
                            <TableRow key={JSON.stringify(row) + index}>
                                <>
                                    {row.map((fieldrender) => {
                                        return (
                                            <>
                                                <TableCell>
                                                    <>{RenderInput(fieldrender, props, props.editMode, index)}</>
                                                </TableCell>
                                            </>
                                        )
                                    })}
                                </>
                                {!props.editMode || props.isAllowedToDelete(values[index]) || props.jsxAtButtons ?

                                    <TableCell>
                                        {props.jsxAtButtons ?
                                            <>{props.jsxAtButtons(values[index])}</> : ""
                                        }
                                        <ButtonGroup>
                                            {props.isAllowedToEdit(values[index]) && props.editButtonJsx && values[index] ?
                                                <>{props.editButtonJsx(values[index])}</> : ""
                                            }
                                            {props.isAllowedToDelete(values[index]) && deleteTrigger ?

                                                <ConfirmationButton key={"confirmationButtonKey_" + index} variant="contained" color="primary" title={t(props.confirmDeletionText)} buttonText={t("Delete")} className="remove-alarm__button"
                                                    action={async () => {
                                                        await deleteTrigger({ parentId: props.ressourceOwnerId, data: values[index] }).unwrap();
                                                    }}>
                                                    <>
                                                        {t(props.confirmDeletionText) + ""}
                                                    </>
                                                </ConfirmationButton>
                                                : ""
                                            }
                                        </ButtonGroup>
                                    </TableCell>

                                    : <></>
                                }

                            </TableRow>
                        )
                    })}
                </Table>
            </Grid>
        </>
    )
}

