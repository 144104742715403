import { useContext, useMemo, useState } from "react";
import Loading from "../../../../components/loading";
import { PaginationQueryPackage } from "../../../../models/API/QueryParams/PaginationQueryPackage";
import { ContactResponse } from "../../../../models/Contact";
import { LabelContextEnum } from "../../../../models/ContactLabel";
import { UserResponse } from "../../../../models/User";
import { ContactForm } from "../../../ContactsApi/ContactForm";
import { useAddContactToClientMutation, useGetContactsByClientIdQuery, useGetExternalContactsByOrganisationIdQuery, useRemoveContactFromClientMutation } from "../../../ContactsApi/redux/contactsApiSlice";
import { PermissionEnum } from "../../../Permission/PermissionEnum";
import { AbilityContext } from "../../../UserApi/logic/Can";
import { useGetAllUsersByOrganisationQuery } from "../../../UserApi/redux/userApiSlice";
import { useGetClientByIdQuery } from "../../redux/clientsApiSlice";
import { ClientTabProps } from "../ClientInfoButton";

export default function ContactTab(props: ClientTabProps) {
    const ability = useContext(AbilityContext)
    const client = useGetClientByIdQuery(props.client_id);

    if (client.isLoading)
        return <Loading/>
    if (client.data === undefined)
        return <></>

    const administratingOrganisation = client.data?.hosting_site?.administrating_organisation
    const ownerOrganisation = client.data?.hosting_site?.owner_organisation

    const sameOwnerAndAdministratingOrg = administratingOrganisation?.uuid == ownerOrganisation?.uuid

    const administratingUsers = useGetAllUsersByOrganisationQuery({
        pagination: new PaginationQueryPackage(),
        uuid: administratingOrganisation?.uuid
    }, { skip: !ability.can(PermissionEnum.CLIENT_WRITE, administratingOrganisation) });

    const ownerUsers = useGetAllUsersByOrganisationQuery({
        pagination: new PaginationQueryPackage(),
        uuid: ownerOrganisation?.uuid
    }, { skip: !ability.can(PermissionEnum.CLIENT_WRITE, ownerOrganisation) || sameOwnerAndAdministratingOrg });


    const administratingExternalContacts = useGetExternalContactsByOrganisationIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: administratingOrganisation?.uuid
    }, { skip: !ability.can(PermissionEnum.CLIENT_WRITE, administratingOrganisation) })

    const ownerExternalContacts = useGetExternalContactsByOrganisationIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: ownerOrganisation?.uuid
    }, { skip: !ability.can(PermissionEnum.CLIENT_WRITE, ownerOrganisation) || sameOwnerAndAdministratingOrg })

    const [allUsers, setAllUsers] = useState<UserResponse[]>([])
    const [allExternalContacts, setAllExternalContacts] = useState<ContactResponse[]>([])

    useMemo(() => {
        setAllUsers([...administratingUsers.data ?? [], ...ownerUsers.data ?? []])
    }, [administratingUsers.data, ownerUsers.data])

    useMemo(() => {
        setAllExternalContacts([...administratingExternalContacts.data ?? [], ...ownerExternalContacts.data ?? []])
    }, [administratingExternalContacts.data, ownerExternalContacts.data])

    return (
        <> {
            ownerOrganisation?.uuid &&
            <ContactForm
                key={JSON.stringify(allUsers)}
                ownerOrganisationUuid={ownerOrganisation?.uuid}
                administratingOrganisationUuid={administratingOrganisation?.uuid}
                entityUuid={props.client_id}
                users={allUsers}
                externalContacts={allExternalContacts}
                useRemoveContactFromEntity={useRemoveContactFromClientMutation}
                useAttachContact={useAddContactToClientMutation}
                useGetContactsByEntityUuid={useGetContactsByClientIdQuery}
                context={LabelContextEnum.CLIENT}
            />
        }
        </>
    )
}

