import { Restore } from "@mui/icons-material"
import { Box } from "@mui/material"
import { t } from "i18next"
import { useContext } from "react"
import { useGetAllEventsQuery } from "../feature/EventApi/redux/eventApiSlice"
import { PermissionEnum } from "../feature/Permission/PermissionEnum"
import { AbilityContext } from "../feature/UserApi/logic/Can"
import { AuditEventQueryPackage } from "../models/API/QueryParams/AuditEventQueryPackage"
import Event from "../models/Event"
import { UserBadge } from "./Badges/UserBadge"
import Loading from "./loading"
import { DateToFormattedString } from "./Logic/DateLogic"
import { OpenInNewTabButton } from "./OpenInNewTabButton"

export interface EntityMetaDataProps {
    entity_uuid?: string
}

export const EntityMetaDataComponent = (props: EntityMetaDataProps) => {

    const queryParams = new AuditEventQueryPackage();
    queryParams.entity_uuid = props.entity_uuid
    queryParams.page = 1;
    queryParams.pagesize = 1;
    queryParams.sort_by = "EVENT_TIME"
    queryParams.order_by = "DESC"

    const ability = useContext(AbilityContext);

    const canGetEvents = ability.can(PermissionEnum.AUDIT_READ)

    const events = useGetAllEventsQuery({
        pagination: queryParams
    }, { skip: !canGetEvents })

    if (events.isLoading)
        return <Loading />

    const latestEvent: Event | undefined = events.data?.find(() => true);
    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '20px' }}>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box>{latestEvent ? <UserBadge chipSize="small" fontSize={"1.1em" as any} textOverride={latestEvent.system_user ? t("system") : undefined} ressource={latestEvent.user} /> : <></>}</Box>
                <Box style={{fontSize: "0.7em"}} >{latestEvent ? DateToFormattedString(latestEvent.audit_event_date_time) : ""}</Box>
            </Box>
            <OpenInNewTabButton tooltipText='Latest changes' icon={<Restore />} url={`/events?entity_uuid=${props.entity_uuid}`} />
        </Box>
    )

}