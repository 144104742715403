import { t } from 'i18next';
import DatagridWrapper, { DataGridId } from '../../components/DatagridWrapper';
import IpStatusChip from '../../components/IpStatusChip';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import { IpStatus } from '../../models/enums/IpStatus';
import IpInfo from '../../models/IpInfo';
import { useGetIpInfoQuery } from './redux/ipInfoApiSlice';
import { useContext } from 'react';
import { NotCorrectRights } from '../Permission/NotCorrectRights';
import { PermissionEnum } from '../Permission/PermissionEnum';
import { AbilityContext } from '../UserApi/logic/Can';
import { GridColDef } from '@mui/x-data-grid-pro';

export const IpInfoList = () => {
    return (<IpInfoListContent />)
}

const IpInfoListContent = () => {
    const ability = useContext(AbilityContext);

    const canReadList = ability.can(PermissionEnum.IP_INFO_READ)
    if (!canReadList)
        return <NotCorrectRights />

    const currentResult = useGetIpInfoQuery({
        pagination: new PaginationQueryPackage()
    });

    if (currentResult.data === undefined)
        currentResult.data = []


    const getColumns = () => {

        const columns: GridColDef[] = [
            { field: "ipaddress", sortable: true, type: "string", headerName: t('IP'), flex: 1 },
            { field: "scope", sortable: true, type: "string", headerName: t('Scope'), flex: 1 },
            { field: "organisation", sortable: true, type: "string", headerName: t('Organisation'), flex: 1 },
            { field: "hosting_site", sortable: true, type: "string", headerName: t('Hosting site'), flex: 1 },
            { field: "sdn_v4_id", sortable: true, type: "string", headerName: t('SDN v4'), renderCell: (params: { row: IpInfo }) => params.row.sdn_v4_id , flex: 1 },
            { field: "service_names", sortable: true, type: "string", headerName: t('Services'), flex: 2 },
            { field: "client_names", sortable: true, type: "string", headerName: t('Clients'), flex: 2 },
            { field: 'status', sortable: true, type: "singleSelect", valueOptions: Object.values(IpStatus).map(status => t(status) + ""), headerName: t('Status'), flex: 1, valueGetter: (status) => t(status + ""), renderCell: (params) => (<IpStatusChip status={params.row.status} />) },
        ];
        return columns
    }

    return (
        <DatagridWrapper
            dataGridId={DataGridId.IP_INFO}
            loading={currentResult.isLoading}
            rows={currentResult.data}
            columns={getColumns()}
        />
    )
}