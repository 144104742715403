import BaseModel from "./BaseModels/BaseModel"
import OrganisationResponse, { SimpleBaseOrganisation } from "./Organisation"

export enum Role {
    ORG_ACCESS_PROSPECT = "ORG_ACCESS_PROSPECT",
    ORG_USER_READ_ONLY = "ORG_USER_READ_ONLY",
    ORG_READ_ONLY = "ORG_READ_ONLY",
    ORG_USER_ADMIN = "ORG_USER_ADMIN",
    ORG_SUPER = "ORG_SUPER",
    ORG_ADMIN = "ORG_ADMIN",
    READ_ONLY = "READ_ONLY",
    ADMIN = "ADMIN",
    UNKNOWN = "UNKNOWN",
}

export class UserPOST extends BaseModel {

    email?: string
    last_name?: string
    first_name?: string
    organisation?: OrganisationResponse
    role?: Role
}


export class UserBase extends BaseModel {
    role?: Role
}

export class UserResponse extends UserBase {
    parent_user_uuid?: string
    email?: string
    first_name?: string
    last_name?: string
    keycloak_uuid?: string
    keycloak_enabled?: boolean
    ssl_vpn_access?: boolean
    latest_activity?: Date
    organisation?: SimpleBaseOrganisation
    welcome_mail_sent?: Date
}

export class UserCopyRequest {
    user_from?: UserResponse
    user_to?: UserResponse
}

export class SelfResponse extends UserResponse {
    authorisations: UserAuthorisationResponse[] = []
}

export class UserAuthorisationResponse extends UserBase {
    organisation_name?: string
    organisation_uuid?: string
    parent_user_organisation_name?: string
    parent_user_organisation_uuid?: string
    parent_user_uuid?: string
    parent_user_email?: string
    parent_user_first_name?: string
    parent_user_last_name?: string
    latest_activity?: Date
}

export function roleToString(role: Role | undefined): string {
    switch (role) {
        case Role.ADMIN:
            return "Administrator"
        case Role.ORG_SUPER:
            return "(Org) Superuser"
        case Role.ORG_ADMIN:
            return "(Org) Administrator"
        case Role.ORG_READ_ONLY:
            return "(Org) Read Only"
        case Role.ORG_ACCESS_PROSPECT:
            return "(Org) Access Prospect"
        case Role.READ_ONLY:
            return "Read Only"
        case Role.ORG_USER_READ_ONLY:
            return "(Org) User Read Only"
        case Role.ORG_USER_ADMIN:
            return "(Org) User Administrator"
        default:
            return "Unknown"
    }
}

export function getRolesUpTo(role?: Role): Role[] {
    if(role === undefined) {
        return [];
    }
    const roles: Role[] = Object.values(Role);
    const index = roles.indexOf(role);
    return roles.slice(0, index + 1);
}