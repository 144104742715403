import { ArrowDropDown, Cancel, CheckCircle, DeleteOutline } from "@mui/icons-material";
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuList, Paper, Popper, Stack, Typography } from "@mui/material";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, MutationDefinition, QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { UseMutation, UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { PermissionEnum } from "../feature/Permission/PermissionEnum";
import { useGetServiceApproversByServiceIdQuery } from "../feature/ServiceApi/redux/serviceApproversApiSlice";
import { AbilityContext, Can } from "../feature/UserApi/logic/Can";
import { UserContext } from "../feature/UserApi/logic/FetchUser";
import { PaginationQueryPackage } from "../models/API/QueryParams/PaginationQueryPackage";
import { RequestMutationWrapper } from "../models/API/RequestMutationWrapper";
import { AgreementResponse } from "../models/Agreement";
import { ApprovalStatus } from "../models/enums/ApprovalStatus";
import ApprovalStatusChip from "./ApprovalStatusChip";
import { ConfirmationButton } from "./ConfirmationButton";
import EasyForm from "./Forms/EasyForm";
import EasyFormColumn from "./Forms/EasyFormColumn";
import { FormType } from "./Forms/EasyFormMany";
import { TextFieldRender } from "./Forms/Renders/TextFieldRender";
import { ApprovalStatusToColorFunc, IsInEndApprovalStatus } from "./Logic/ApprovalLogic";
import { useGetServiceByIdQuery } from "../feature/ServiceApi/redux/serviceApiSlice";

export interface SplitButtonProps<CustomType> {
    primaryButtonText: string
    idToFetch: string
    nameOfRessource: string
    afterDeleteAction?: () => void
    approveMutation: UseMutation<MutationDefinition<RequestMutationWrapper<CustomType>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, CustomType, any>>
    terminateMutation: UseMutation<MutationDefinition<RequestMutationWrapper<CustomType>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, any, any>>
    denyMutation: UseMutation<MutationDefinition<RequestMutationWrapper<CustomType>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, CustomType, any>>
    getByIdAction: UseQuery<QueryDefinition<string, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, CustomType, any>>
}



export default function IApprovalRessourceMenu(props: SplitButtonProps<AgreementResponse>) {
    const { t } = useTranslation()

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);


    const { data } = props.getByIdAction!(props.idToFetch)
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const loggedInUser = useContext(UserContext);
    const serviceApproversResult = useGetServiceApproversByServiceIdQuery({
        uuid: data?.service?.uuid,
        pagination: new PaginationQueryPackage()
    })

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement))
            return;

        setOpen(false);
    };
    const ability = useContext(AbilityContext);
    const deleteMutation = props.terminateMutation()
    const deleteTrigger = deleteMutation[0];

    const agreement = data as AgreementResponse

    const serviceUuid = agreement.service?.uuid
    const service = serviceUuid ? useGetServiceByIdQuery(serviceUuid).data : undefined

    const { buttonColor: color, icon } = ApprovalStatusToColorFunc(agreement?.status)
    const typeString = agreement.status == ApprovalStatus.APPROVED ? "agreement" : "agreement request"
    const spacing = 2
    const loggedInUserIsServiceapprover = serviceApproversResult.data?.find(x => x.uuid == loggedInUser?.uuid) != undefined
    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Button
                    size="medium"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    color={color}
                    onClick={handleToggle}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        {icon}
                        <Typography>{props.primaryButtonText}</Typography>
                    </Stack>
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem>
                                    {IsInEndApprovalStatus(agreement.status!) || !service ? <></> :
                                        <>
                                            <Can I={PermissionEnum.AGREEMENT_APPROVAL_WRITE} this={service} passThrough={loggedInUserIsServiceapprover}>
                                                <ConfirmationButton
                                                    fullWidth
                                                    title={<> {t(`Approve agreement request`)}</>}
                                                    buttonText={
                                                        <Stack direction="row" spacing={spacing}>
                                                            <CheckCircle color="success" />
                                                            <Typography>{t(`Approve agreement request`)}</Typography>
                                                        </Stack>}
                                                    asMenuItem={true}
                                                    showButtons={false}>
                                                    <>


                                                        <Typography>{t("Are you sure you wish to approve this agreement?") + ""}</Typography>

                                                        <EasyForm
                                                            isAllowedToEdit={() => true}
                                                            extraButtons={<Button onClick={() => setOpen(false)}>{t("Cancel")}</Button>}
                                                            createNew={() => { throw "No agreement should be created here" }}
                                                            arrayPath=""
                                                            SignupSchema={Yup.object().shape({

                                                            })}
                                                            editable={true}
                                                            showResetButton={false}
                                                            saveMutation={props.approveMutation}
                                                            afterSaveAction={() => setOpen(false)}
                                                            editModeAlways={true}
                                                            initialValue={agreement}
                                                            columns={[
                                                                new EasyFormColumn("", "", [
                                                                    new TextFieldRender({
                                                                        displayText: "Comment", fieldName: "approval_comment", type: "text",
                                                                        render: (content) => <ApprovalStatusChip status={content as ApprovalStatus} />,
                                                                        uniqueId: agreement.uuid
                                                                    })
                                                                ])

                                                            ]}
                                                            formType={FormType.INFORMATION}
                                                            getFieldRows={() => []}
                                                        />
                                                    </>
                                                </ConfirmationButton>
                                                <ConfirmationButton
                                                    fullWidth
                                                    title={<> {t(`Dismiss agreement request`)}</>}
                                                    buttonText={
                                                        <Stack direction="row" spacing={spacing}>
                                                            <Cancel color="error" />
                                                            <Typography>{t(`Dismiss agreement request`)}</Typography>
                                                        </Stack>}
                                                    asMenuItem={true}
                                                    showButtons={false}>
                                                    <>
                                                        <Typography>{t("Are you sure you wish to deny this agreement?") + ""}</Typography>
                                                        <EasyForm
                                                            isAllowedToEdit={() => true}
                                                            extraButtons={<Button onClick={() => setOpen(false)}>{t("Cancel")}</Button>}
                                                            showResetButton={false}
                                                            createNew={() => { throw "No agreement should be created here" }}
                                                            arrayPath=""
                                                            SignupSchema={Yup.object().shape({

                                                            })}
                                                            editable={true}
                                                            saveMutation={props.denyMutation}
                                                            afterSaveAction={() => setOpen(false)}
                                                            editModeAlways={true}
                                                            initialValue={agreement}
                                                            columns={[
                                                                new EasyFormColumn("", "", [
                                                                    new TextFieldRender({
                                                                        displayText: "Comment",
                                                                        fieldName: "approval_comment",
                                                                        type: "text",
                                                                        render: (content) => <ApprovalStatusChip status={content as ApprovalStatus} />,
                                                                        uniqueId: agreement.uuid
                                                                    })
                                                                ])

                                                            ]}
                                                            formType={FormType.INFORMATION}
                                                            getFieldRows={() => []}
                                                        />
                                                    </>
                                                </ConfirmationButton>
                                            </Can>
                                        </>
                                    }
                                    {
                                        IsInEndApprovalStatus(agreement.status) ?
                                            (ability.can(PermissionEnum.SERVICE_WRITE) || ability.can(PermissionEnum.CLIENT_WRITE) || loggedInUserIsServiceapprover) &&
                                            <ConfirmationButton
                                                title={<>{t(`Delete ${typeString}`)}</>}
                                                buttonText={
                                                    <Stack direction="row" spacing={spacing}>
                                                        <DeleteOutline color="error" />
                                                        <Typography>{t(`Delete ${typeString}`)}</Typography>
                                                    </Stack>}
                                                asMenuItem={true}
                                                action={async () => {
                                                    const toDelete = JSON.parse(JSON.stringify(data)) as AgreementResponse
                                                    await deleteTrigger({
                                                        data: toDelete
                                                    }).unwrap();
                                                    if (props.afterDeleteAction)
                                                        props.afterDeleteAction()
                                                }}>
                                                <>{t(`Are you sure you wish to delete the ${typeString}?`)}</>
                                            </ConfirmationButton>
                                            :
                                            <Can I={PermissionEnum.CLIENT_WRITE} this={agreement.client}>
                                                <ConfirmationButton
                                                    title={<>{t(`Delete ${typeString}`)}</>}
                                                    buttonText={
                                                        <Stack direction="row" spacing={spacing}>
                                                            <DeleteOutline color="error" />
                                                            <Typography>{t(`Delete ${typeString}`)}</Typography>
                                                        </Stack>}
                                                    asMenuItem={true}
                                                    action={async () => {
                                                        const toDelete = JSON.parse(JSON.stringify(data)) as AgreementResponse
                                                        await deleteTrigger({
                                                            data: toDelete
                                                        }).unwrap();
                                                        if (props.afterDeleteAction)
                                                            props.afterDeleteAction()
                                                    }}>
                                                    <>{t(`Are you sure you wish to delete the ${typeString}?`)}</>
                                                </ConfirmationButton>
                                            </Can>
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

