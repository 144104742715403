import { CheckCircle, Report, Warning } from "@mui/icons-material";
import { Chip } from "@mui/material"
import { t } from "i18next";
import { IpStatus } from "../models/enums/IpStatus";

class IpStatusChipProps {
    status?: IpStatus
}

export default function IpStatusChip(props: IpStatusChipProps) {
    const { chipColor: color, icon } = ipStatusToColor(props.status)
    return (
        <>
            <Chip color={color} icon={icon} sx={{ fontSize: 18, padding: 1, textTransform:"capitalize" }} label={t(props.status + "").toLocaleLowerCase().replaceAll("_", " ") + ""} />
        </>
    )
}



function ipStatusToColor(status: IpStatus | undefined)
    : {
        buttonColor: "success" | "info" | "warning" | "inherit" | undefined,
        chipColor: "success" | "info" | "warning" | "default" | undefined,
        icon: JSX.Element
    } {
    let buttonColor: "success" | "info" | "warning" | "inherit" | undefined = "inherit"
    let chipColor: "success" | "info" | "warning" | "default" | undefined = "default"
    let icon = (<></>)

    switch (status) {
        case (IpStatus.AVAILABLE):
            icon = (<CheckCircle fontSize='small' />)
            buttonColor = "success"
            chipColor = "success"
            break;
        case (IpStatus.IN_USE):
            icon = (<Warning fontSize='small' />)
            buttonColor = "warning"
            chipColor = "warning"
            break;
        case (IpStatus.ASSIGNED):
            icon = (<Report fontSize='small' />)
            buttonColor = "info"
            chipColor = "info"
            break;
    }
    return {
        icon: icon,
        chipColor: chipColor,
        buttonColor: buttonColor
    }
}