import { Autocomplete, AutocompleteRenderInputParams, AutocompleteRenderOptionState, FilterOptionsState, FormControl, Grid, ListItem } from "@mui/material";
import { HTMLAttributes, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface ToggleableAutocompleteProps<T> {
    options: T[]
    editMode: boolean
    getOptionLabel: (option: T) => string | JSX.Element
    renderOption?: ((props: HTMLAttributes<HTMLLIElement>, option: T, state: AutocompleteRenderOptionState) => ReactNode)
    renderNonChangeable?: (option: T) => JSX.Element
    onChange?: (e: any, value: any) => void
    renderInput: (params: AutocompleteRenderInputParams) => ReactNode
    isOptionEqualToValue: ((option: T, value: T) => boolean)
    value?: T[] | T
    noOptionsText?: React.ReactNode;
    filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
    id?: string
    loading?: boolean,
    defaultToAllSelected?: boolean
    multiple: boolean
    title?: string
    disabled?: boolean
}


export default function ToggleableSelect<T>(props: ToggleableAutocompleteProps<T>) {

    const { t } = useTranslation();
    let toString = props.getOptionLabel;
    if (toString === undefined)
        toString = () => ""


    // MUI's Autocomplete has a bug where if two label's have the exact same value it will behave strangely
    // A way arround this bug is to make sure all options have a key
    // Therefore we make sure we always have a "randerOption" function that sets these keys
    // https://github.com/mui/material-ui/issues/26492
    const renderOptionFunc: ((props: HTMLAttributes<HTMLLIElement>, option: T, state: AutocompleteRenderOptionState) => ReactNode) = (renderProps, option, state) => (
        <ListItem {...renderProps} key={JSON.stringify(option)} divider >
            {props.renderOption ?
                props.renderOption(renderProps, option, state) :
                props.getOptionLabel(option)}
        </ListItem>
    )


    if (props.editMode)
        return (
            <FormControl fullWidth>

                <Autocomplete
                    title={props.title}
                    loading={props.loading}
                    loadingText={t("Loading...")}
                    limitTags={5}
                    getOptionLabel={props.getOptionLabel as (option: T) => string}
                    renderOption={renderOptionFunc}
                    filterOptions={(options, state) => props.filterOptions != undefined ? props.filterOptions(options, state) : options.filter(option => JSON.stringify(option).toLowerCase().includes(state.inputValue.toLowerCase()))}
                    id={props.id}
                    defaultValue={props.defaultToAllSelected === true ? props.options : props.value}
                    onChange={props.onChange}
                    options={props.loading ? [] : props.options}
                    multiple={props.multiple}
                    renderInput={props.renderInput}
                    isOptionEqualToValue={props.isOptionEqualToValue}
                    noOptionsText={props.noOptionsText}
                    disableListWrap
                    disabled={props.disabled}

                />
            </FormControl>
        )

    if (props.multiple && props.value) {
        const values = props.value as T[]

        if (props.renderNonChangeable)
            return <Grid container spacing={1}>{values.map(x => <Grid item key={crypto.randomUUID()}>{props.renderNonChangeable!(x)}</Grid>)}</Grid>

        return <>{values.map(x => props.getOptionLabel(x)).join(", ")}</>
    }

    if (props.renderNonChangeable) {
        return props.renderNonChangeable(props.value as T)
    }

    const value = props.value as T
    return <>{props.value ? props.getOptionLabel(value) : ""}</>;
}