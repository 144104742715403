import { CheckCircle, Warning, Report } from "@mui/icons-material";
import { ApprovalStatus } from "../../models/enums/ApprovalStatus";

export function ApprovalStatusToColorFunc(status: ApprovalStatus | undefined)
    : {
        buttonColor: "success" | "error" | "warning" | "inherit" | undefined,
        chipColor: "success" | "error" | "warning" | "default" | undefined,
        icon: JSX.Element
    } {
    let buttonColor: "success" | "error" | "warning" | "inherit" | undefined
    let chipColor: "success" | "error" | "warning" | "default" | undefined
    let icon = (<></>)

    switch (status) {
        case (ApprovalStatus.APPROVED):
            icon = (<CheckCircle fontSize='small' />)
            buttonColor = "success"
            chipColor = "success"
            break;
        case (ApprovalStatus.DENIED):
            icon = (<Warning fontSize='small' />)
            buttonColor = "error"
            chipColor = "error"
            break;
        case (ApprovalStatus.PENDING):
            icon = (<Report fontSize='small' />)
            buttonColor = "warning"
            chipColor = "warning"
            break;
    }
    return {
        icon: icon,
        chipColor: chipColor,
        buttonColor: buttonColor
    }
}

export function IsInEndApprovalStatus(status: ApprovalStatus) {
    switch (status) {
        case ApprovalStatus.APPROVED:
        case ApprovalStatus.DENIED:
            return true;
        default:
            return false
    }
}