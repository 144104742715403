import { SxProps, Theme, Tooltip } from "@mui/material";

interface TooltipComponentProps {
    title: NonNullable<React.ReactNode>
    children: JSX.Element
    sx?: SxProps<Theme>;
    placement?: "bottom" | "left" | "right" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start" | undefined
}

export default function TooltipComponent(props: TooltipComponentProps) {
    return (
        <Tooltip sx={props.sx} placement={props.placement ?? "bottom"} title={props.title}>
            <span>
                {props.children}
            </span>
        </Tooltip>
    );
}