import { GridRenderCellParams } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import { useState } from 'react';
import DatagridWrapper, { DataGridId } from '../../components/DatagridWrapper';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import Portalias from '../../models/Portalias';
import { PortaliasInfoButton } from './portaliasInfoDialog/PortaliasInfoButton';
import { useGetAllPortaliasQuery } from './redux/portaliasApiSlice';


const getCurrentPortalias = (portaliases: Portalias[], params: GridRenderCellParams<any, any, any>): Portalias => {
    const portalias = portaliases.find(x => x.uuid === params.id)!;
    return portalias;
}

export const PortaliasList = () => {
    return (<PortaliasListContent />)
}

const PortaliasListContent = () => {
    const currentResult = useGetAllPortaliasQuery({
        pagination: new PaginationQueryPackage()
    });

    const [clickedRow, setClickedRow] = useState<Portalias | undefined>(undefined)
    const [uuid, setUuid] = useState(crypto.randomUUID()) //To rerender buttons when clicking a row

    if (currentResult.data === undefined)
        currentResult.data = []

    const getColumns = (portaliases: Portalias[]) => {

        const columns: GridColDef[] = [
            { field: "name", sortable: true, type: "string", headerName: t('Name'), flex: 10 },
            { field: "in_use", sortable: true, type: "boolean", headerName: t('In use'), flex: 10 },
            {
                field: 'events', sortable: false, hideable: false, type: "string", valueGetter: () => "", headerName: t('Actions'), minWidth: 220, renderCell: (params => {
                    const portalias = getCurrentPortalias(portaliases, params)
                    return (
                        <PortaliasInfoButton key={JSON.stringify(uuid)} openFromStart={portalias.uuid == clickedRow?.uuid} portalias={portalias} />
                    )
                })
            }
        ];
        return columns
    }

    return (
        <DatagridWrapper
            dataGridId={DataGridId.PORTALIASES}
            onRowClick={(row) => { setClickedRow(row.row); setUuid(crypto.randomUUID()) }}
            loading={currentResult.isLoading}
            rows={currentResult.data}
            columns={getColumns(currentResult.data)}
        />
    )
}

