import { Person } from "@mui/icons-material";
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { UserResponse } from "../../models/User";
import { OpenInNewTabButton } from "../OpenInNewTabButton";
import { BadgeProps, BaseBadge } from "./BaseBadge";

export function UserBadge(props: BadgeProps<UserResponse>) {
    if (props.textOverride) {
        return <BaseBadge {...props} content={<>{props.textOverride}</>} tooltip={props.textOverride} icon={Person} />
    }

    const isDeleted = props.ressource && props.ressource.email == null
    if (isDeleted)
        return <BaseBadge deepContent {...props} content={<>{props.ressource?.uuid}</>} tooltip={props.ressource?.uuid} icon={NoAccountsIcon} />

    const content = props?.ressource?.email
    const isAuthorisation = props.ressource && props.ressource.parent_user_uuid !== null
    console.log(isAuthorisation)
    if (isAuthorisation) {
        const linkIcon = <BaseBadge deepContent {...props} content={<>{content}</>} icon={VerifiedUserIcon} />
        return <OpenInNewTabButton url={`/authorisations/${props?.ressource?.uuid}`} icon={linkIcon} padding={0} />
    }

    const linkIcon = <BaseBadge deepContent {...props} content={<>{content}</>} icon={Person} />
    return <OpenInNewTabButton url={`/users/${props?.ressource?.uuid}`} icon={linkIcon} padding={0} />
}