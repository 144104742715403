import { Box, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import EasyForm, { FormType } from "../../components/Forms/EasyForm";
import EasyFormColumn from "../../components/Forms/EasyFormColumn";
import { TextFieldRender } from "../../components/Forms/Renders/TextFieldRender";

import { PersonAddAlt1 } from "@mui/icons-material";
import { OrganisationBadge } from "../../components/Badges/OrganisationBadge";
import { CreateEntityButton } from "../../components/CreateEntityButton";
import ErrorCodeHook from "../../components/DTO/ErrorCodeHook";
import { AutocompleteFieldRender } from "../../components/Forms/Renders/AutocompleteFieldRender";
import { TextRenderer } from "../../components/Forms/Renders/TextRenderer";
import { OrganisationContext } from "../../components/Layout/OrganisationPicker";
import { AbilityContext, Can } from "../../feature/UserApi/logic/Can";
import { ErrorCodeEnum } from "../../models/API/ErrorCodeEnum";
import { Role, UserPOST, getRolesUpTo, roleToString } from "../../models/User";
import { PermissionEnum } from "../Permission/PermissionEnum";
import { UserContext } from "./logic/FetchUser";
import { useAddUserMutation, useForceAddUserMutation } from "./redux/userApiSlice";
import { UserDetailsContent } from "./userDetailsButton/UserDetailsButton";




export const CreateUserButton = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(false)
    const organisationContext = useContext(OrganisationContext)?.organisation

    return (
        <Can I={PermissionEnum.USER_WRITE} a={organisationContext}>
            <CreateEntityButton tooltip={t('Create user')} open={open} setOpen={setOpen} buttonIcon={<PersonAddAlt1 />}>
                <DrawerContent />
            </CreateEntityButton>
        </Can >
    )
}
export const DrawerContent = () => {
    const [submittedUser, setSubmittedUser] = useState<UserPOST | undefined>(undefined)
    return (
        <>
            {submittedUser == undefined ?
                <CreateUserButtonContent afterSaveAction={(user) => setSubmittedUser(user)} /> :
                <UserDetailsContent userUuid={submittedUser.uuid} />
            }
        </>
    )
}

export interface CreateUserButtonContentProps {
    afterSaveAction: (user: UserPOST) => void
}
export const CreateUserButtonContent = (props: CreateUserButtonContentProps) => {
    const ability = useContext(AbilityContext);
    const { t } = useTranslation();
    const SignupSchema = Yup.object().shape({
        first_name: Yup.string().required(t("Required")).min(1, t("Minimum one character")),
        last_name: Yup.string().required(t("Required")).min(1, t("Minimum one character")),
        email: Yup.string().email(t("Email is invalid")).required(t("Required")),
    });

    const errorHook: ErrorCodeHook<UserPOST>[] = [];
    errorHook.push(new ErrorCodeHook<UserPOST>(ErrorCodeEnum.OPERATION_REQUIRES_FORCE, "User email is already in use by external contact", "Replace contact with user anyways?", useForceAddUserMutation));

    const userContext = useContext(UserContext);
    const possibleRoles = userContext?.role === Role.ORG_USER_ADMIN ? [Role.ORG_READ_ONLY] : getRolesUpTo(userContext?.role)
    const organisationContext = useContext(OrganisationContext);
    const organisation = organisationContext?.organisation ?? userContext?.organisation
    return (
        <>

            <Box>
                <Grid container justifyContent="center">

                    <Grid item xs={6}>
                        <Typography variant="h6">{t("Create user") + ""}</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={6}>

                        <EasyForm
                            isAllowedToEdit={() => ability.can(PermissionEnum.USER_WRITE, userContext)}
                            editModeAlways={true}
                            arrayPath=""
                            SignupSchema={SignupSchema}
                            createNew={() => {
                                const postBody = new UserPOST();
                                postBody.organisation = organisation;
                                return postBody;
                            }}
                            errorToMutation={errorHook}
                            columns={[
                                new EasyFormColumn("", "", [
                                    new TextRenderer({
                                        displayText: "Organisation",
                                        fieldName: "organisation",
                                        uniqueId: "no_id",
                                        render: () => <OrganisationBadge ressource={organisation} />
                                    }),
                                    new TextFieldRender({
                                        displayText: "First name",
                                        fieldName: "first_name",
                                        uniqueId: "no_id",
                                        type: "text"
                                    }),
                                    new TextFieldRender({
                                        displayText: "Last name",
                                        fieldName: "last_name",
                                        uniqueId: "no_id",
                                        type: "text"
                                    }),
                                    new TextFieldRender({
                                        displayText: "Email",
                                        fieldName: "email",
                                        uniqueId: "no_id",
                                        type: "email"
                                    }),
                                    new AutocompleteFieldRender({
                                        displayText: "Role",
                                        fieldName: "role",
                                        getOptionLabelFunc: (option: Role) => roleToString(option) ?? "",
                                        options: possibleRoles,
                                        uniqueId: "no_id"
                                    })
                                ])
                            ]}
                            formType={FormType.INFORMATION}
                            idToFetch={"no_id"}
                            getFieldRows={() => []}
                            saveMutation={useAddUserMutation}
                            afterSaveAction={props.afterSaveAction}
                        />
                    </Grid>
                </Grid>

            </Box>
        </>
    )
}