import { PersonSearch } from '@mui/icons-material';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { UserBadge } from '../../../../components/Badges/UserBadge';
import { CreateInCollectionButton } from '../../../../components/CreateInCollectionButton';
import EasyFormColumn from '../../../../components/Forms/EasyFormColumn';
import EasyFormMany from '../../../../components/Forms/EasyFormMany';
import { AutocompleteFieldRender } from '../../../../components/Forms/Renders/AutocompleteFieldRender';
import { FieldEditorRender } from '../../../../components/Forms/Renders/FieldEditorRender';
import { TextRenderer } from '../../../../components/Forms/Renders/TextRenderer';
import { OpenInNewTabButton } from '../../../../components/OpenInNewTabButton';
import { UserResponse } from '../../../../models/User';
import { authorizationApiSlice } from '../../../AuthorisationApi/redux/authroisationApiSlice';
import { PermissionEnum } from '../../../Permission/PermissionEnum';
import { AbilityContext } from '../../../UserApi/logic/Can';
import { useLazyGetAllUsersByOrganisationQuery } from '../../../UserApi/redux/userApiSlice';
import { GetServiceApprovers } from '../../Logic/getServiceApprovers';
import { useGetServiceByIdQuery } from '../../redux/serviceApiSlice';
import { useAddServiceApproverMutation, useDeleteServiceApproverMutation, useGetServiceApproversByServiceIdQuery, useUpdateServiceApproverMutation } from '../../redux/serviceApproversApiSlice';
import { ServiceTabProps } from "../ServiceInfoButton";

export default function ServiceApproversTab(props: ServiceTabProps) {
    const { t } = useTranslation()
    const SignupSchema = Yup.object().shape({
        approver: Yup.object().nullable().required()
    });
    const ability = useContext(AbilityContext)
    const serviceResult = useGetServiceByIdQuery(props.serviceId)


    const [availableUsers, setAvailableUsers] = useState<UserResponse[]>([])

    const lazyGetAllUsersByOrganisationTrigger = useLazyGetAllUsersByOrganisationQuery()[0];

    const lazyGetAuthorisationsByOrganisation = authorizationApiSlice.useLazyGetAuthorizationsForOrganisationQuery();
    const lazyGetAuthorisationsByOrganisationTrigger = lazyGetAuthorisationsByOrganisation[0];
    useMemo(() => {
        const hostingSite = serviceResult.data?.hosting_site
        GetServiceApprovers(ability, hostingSite, lazyGetAllUsersByOrganisationTrigger, lazyGetAuthorisationsByOrganisationTrigger)
            .then(result => setAvailableUsers(result))
    }, [serviceResult.data?.hosting_site])


    const getServiceApproversRows = (users: UserResponse[] | undefined) => {
        const rows: Array<FieldEditorRender[]> = []
        users?.forEach((service_approver) => {
            const newRow: FieldEditorRender[] = [
                new TextRenderer({
                    displayText: "",
                    fieldName: "",
                    nestedfieldName: "uuid",
                    uniqueId: service_approver.uuid!,
                    render: (uuid) => <OpenInNewTabButton icon={<PersonSearch/>} tooltipText={t("Open user in another tab")} url={`/users/${uuid}`} />
                }),
                new TextRenderer({
                    displayText: "First name",
                    fieldName: "",
                    nestedfieldName: "first_name",
                    uniqueId: service_approver.uuid!,
                }),
                new TextRenderer({
                    displayText: "Last name",
                    fieldName: "",
                    nestedfieldName: "last_name",
                    uniqueId: service_approver.uuid!,
                }),
                new TextRenderer({
                    displayText: "Email",
                    fieldName: "",
                    nestedfieldName: "email",
                    uniqueId: service_approver.uuid!,
                }),

            ]

            rows.push(newRow)
        })
        return rows;
    }

    const getServiceApproversCreateRows = (users: UserResponse[] | undefined) => {
        const rows: Array<FieldEditorRender[]> = []
        users?.forEach((service_approver) => {
            const newRow: FieldEditorRender[] = [
                new AutocompleteFieldRender({
                    displayText: "New approver",
                    fieldName: "",
                    nestedfieldName: "approver",
                    getOptionLabelFunc: (option: UserResponse) => option?.email ?? "",
                    renderOption: (_props, option) => <UserBadge asText textOverride={option.email} />,
                    options: availableUsers ?? [],
                    uniqueId: service_approver.uuid!,
                })
            ]

            rows.push(newRow)
        })
        return rows;
    }


    return (
        <EasyFormMany
            confirmDeletionText="Are you sure you wish to delete this service approver?"
            isAllowedToDelete={() => ability.can(PermissionEnum.SERVICE_WRITE, serviceResult.data)}
            isAllowedToEdit={() => false}
            isAllowedToCreate={() => ability.can(PermissionEnum.SERVICE_WRITE, serviceResult.data)}
            ressourceOwnerId={props.serviceId}
            arrayPath=""
            getManyByIdAction={useGetServiceApproversByServiceIdQuery}
            saveMutation={useUpdateServiceApproverMutation}
            deleteMutation={useDeleteServiceApproverMutation}

            createButtonJsx={
                <CreateInCollectionButton
                    validationScheme={SignupSchema}
                    ressourceOwnerId={props.serviceId}
                    fieldEditorRenders={getServiceApproversCreateRows([new UserResponse()]).find(() => true)!}
                    tooltip={t("Create approver")}
                    createNew={() => new UserResponse()}
                    saveMutation={useAddServiceApproverMutation} />
            }
            editButtonJsx={(serviceapprover: UserResponse) =>
                <CreateInCollectionButton
                    validationScheme={SignupSchema}
                    toEdit={serviceapprover}
                    ressourceOwnerId={props.serviceId}
                    fieldEditorRenders={getServiceApproversRows([new UserResponse()]).find(() => true)!}
                    tooltip={t("Edit approver")}
                    createNew={() => { throw new Error("Dont create new here") }}
                    saveMutation={useUpdateServiceApproverMutation} />
            }
            SignupSchema={SignupSchema}
            idToFetch={props.serviceId}
            columns={[
                new EasyFormColumn("Approvers", "If no approvers are selected, the administrators for the service will be selected as default.", [])
            ]}
            getFieldRows={getServiceApproversRows}
            newButtonTooltip="Add new approver"
        />
    )
}