import { Stack } from "@mui/material";
import EasyAutocomplete from "./EasyAutocomplete";
import EasyCheckbox from "./EasyCheckbox";
import EasyDateTime from "./EasyDateTime";
import EasySelect from "./EasySelect";
import EasyTextfied from "./EasyTextfied";
import { AutocompleteFieldRender } from "./Renders/AutocompleteFieldRender";
import { CheckboxFieldRender } from "./Renders/CheckboxFieldRender";
import { DateTimeRender } from "./Renders/DateTimeRender";
import { FieldEditorRender } from "./Renders/FieldEditorRender";
import { FieldRenderType } from "./Renders/FieldRenderType";
import { SelectFieldRender } from "./Renders/SelectFieldRender";
import { TextFieldRender } from "./Renders/TextFieldRender";
import { TextRenderer } from "./Renders/TextRenderer";

export function RenderInput(fieldrender: FieldEditorRender, formikProps: any, editMode: boolean, elementIndex?: number) {

    const actualFieldName = fieldrender.getActualFieldname(elementIndex);
    if (fieldrender.renderType === FieldRenderType.Textfield && fieldrender instanceof TextFieldRender) {
        const actualConditionalFieldName = fieldrender.getActualConditionalFieldname(fieldrender.conditionFieldName, elementIndex);
        return (<EasyTextfied {...fieldrender} {...formikProps} multiline={fieldrender.multiline} type={fieldrender.type} conditionFieldName={actualConditionalFieldName} conditionShouldBeDisabledFunc={fieldrender.conditionShouldBeDisabledFunc} key={actualFieldName + elementIndex + fieldrender.uniqueId} endAdornment={fieldrender.endAdornment} render={fieldrender.render} editMode={editMode} fieldName={actualFieldName} />)
    }
    if (fieldrender.renderType === FieldRenderType.Select && fieldrender instanceof SelectFieldRender)
        return (<EasySelect {...formikProps} {...fieldrender} options={fieldrender.options!} key={actualFieldName + elementIndex + fieldrender.uniqueId} render={fieldrender.render} editMode={editMode} fieldName={actualFieldName} />)

    if (fieldrender.renderType === FieldRenderType.Checkbox && fieldrender instanceof CheckboxFieldRender)
        return (<EasyCheckbox {...formikProps} {...fieldrender} render={fieldrender.render} key={actualFieldName + elementIndex + fieldrender.uniqueId} editMode={editMode} fieldName={actualFieldName} />)

    if (fieldrender.renderType === FieldRenderType.AutoComplete && fieldrender instanceof AutocompleteFieldRender)
        return (<EasyAutocomplete disableAutoPicker={fieldrender.disbaleAutoPicker} {...formikProps} {...fieldrender} selectedValueInOptions={fieldrender.selectedValueInOptions} disabled={fieldrender.disabled} multiple={fieldrender.multipleSelect} getOptionLabel={fieldrender.getOptionLabelFunc} key={actualFieldName + fieldrender.uniqueId} editMode={editMode} fieldName={actualFieldName} />)

    if (fieldrender.renderType === FieldRenderType.DateTime && fieldrender instanceof DateTimeRender)
        return (<EasyDateTime {...formikProps} {...fieldrender} includeTime={fieldrender.includeTime} key={actualFieldName + elementIndex + fieldrender.uniqueId} editMode={editMode} fieldName={actualFieldName} />)

    if (fieldrender.renderType === FieldRenderType.TextOnly && fieldrender instanceof TextRenderer) {
        const result = []
        let currentRenderer: undefined | TextRenderer = fieldrender;
        let counter = 0; //Just a safe, so we dont end up in infty loop
        while (currentRenderer != undefined && counter <= 10) {
            const actualFieldName = currentRenderer.getActualFieldname(elementIndex);
            result.push((<><EasyTextfied  {...formikProps} {...fieldrender} key={actualFieldName + elementIndex + fieldrender.uniqueId} render={fieldrender.render} editMode={false} fieldName={actualFieldName} />{currentRenderer.suffixText}</>))
            currentRenderer = currentRenderer.extraText
            counter++;
        }

        return (<Stack alignItems="end" direction="row" minWidth={currentRenderer?.minWidth}>{result.map(x => x)}</Stack>);
    }
    return (<></>)
}