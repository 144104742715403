import * as Yup from 'yup';
import { ServiceBase } from '../../../../models/Service';
import EasyFormColumn from '../../../../components/Forms/EasyFormColumn';
import { AgreementResponse } from '../../../../models/Agreement';
import { useGetAgreementsByClientIdQuery, useDeleteAgreementMutation, useUpdateAgreementMutation } from '../../../AgreementApi/redux/agreementsApiSlice';
import Client from '../../../../models/Client';
import { FieldEditorRender } from '../../../../components/Forms/Renders/FieldEditorRender';
import { TextFieldRender } from '../../../../components/Forms/Renders/TextFieldRender';
import { ApprovalStatus } from '../../../../models/enums/ApprovalStatus';

import { ClientBadge } from '../../../../components/Badges/ClientBadge';
import EasyFormMany from '../../../../components/Forms/EasyFormMany';
import { TextRenderer } from '../../../../components/Forms/Renders/TextRenderer';
import { ServiceBadge } from '../../../../components/Badges/ServiceBadge';
import { ClientTabProps } from '../ClientInfoButton';
import { AgreementInfoButton } from '../../../AgreementApi/agreementInfoDialog/AgreementInfoButton';
import { PermissionEnum } from '../../../Permission/PermissionEnum';
import { useContext } from 'react';
import { AbilityContext } from '../../../UserApi/logic/Can';
import { useTranslation } from 'react-i18next';

export default function AgreementsTab(props: ClientTabProps) {
    const { t } = useTranslation();

    const SignupSchema = Yup.object().shape({
        clients: Yup.array().required().min(1, t("You have to select at least one client")),
        expiration_date: Yup.date().min(new Date())
    });

    const ability = useContext(AbilityContext)
    const getAgreementRows = (agreements: AgreementResponse[] | undefined) => {
        const rows: Array<FieldEditorRender[]> = []
        agreements?.forEach((agreement) => {
            const newRow: FieldEditorRender[] = [
                new TextRenderer({
                    fieldName: "",
                    nestedfieldName: "service",
                    render: (service) => (<ServiceBadge ressource={service as unknown as ServiceBase} />),
                    displayText: "Service",
                    uniqueId: agreement.uuid
                }),
                new TextRenderer({
                    fieldName: "",
                    nestedfieldName: "client",
                    render: (service) => (<ClientBadge ressource={service as unknown as Client} />),
                    displayText: "Client",
                    uniqueId: agreement.uuid
                }),

                new TextFieldRender({
                    displayText: "Description",
                    fieldName: "",
                    nestedfieldName: "description",
                    type: "text",
                    uniqueId: "no_id"
                })
            ]

            rows.push(newRow)
        })
        return rows;
    }


    return (
        <EasyFormMany
            isAllowedToDelete={(serviceResponse) => ability.can(PermissionEnum.CLIENT_WRITE, serviceResponse)}
            isAllowedToEdit={(serviceResponse) => ability.can(PermissionEnum.CLIENT_WRITE, serviceResponse)}
            isAllowedToCreate={(serviceResponse) => ability.can(PermissionEnum.CLIENT_WRITE, serviceResponse)}
            ressourceOwnerId={props.client_id}
            arrayPath=""
            idToFetch={props.client_id}
            requestData={[ApprovalStatus.APPROVED]}
            getManyByIdAction={useGetAgreementsByClientIdQuery}
            saveMutation={useUpdateAgreementMutation}
            jsxAtButtons={(agreement) => (
                <AgreementInfoButton agreement={agreement} />
            )}
            confirmDeletionText="Are you sure you wish to delete this agreement?"
            deleteMutation={useDeleteAgreementMutation}
            SignupSchema={SignupSchema}
            columns={[
                new EasyFormColumn("Agreements", "", [])
            ]}
            getFieldRows={getAgreementRows}
            newButtonTooltip="Add new service approver"
        />
    )
}