import { Tab } from "@mui/material";
import React, { useContext, useState } from "react";

import InfoIcon from '@mui/icons-material/Info';


import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ClientAssociationsComponent from "../../../components/ClientAssociationsComponent";
import DrawerButton from "../../../components/DrawerButton";
import { EntityMetaDataComponent } from "../../../components/EntityMetaDataComponent";
import { CustomTabs } from "../../../components/Forms/CustomTabs";
import Loading from "../../../components/loading";
import UseUrlParam from "../../../components/Logic/UseUrlProps";
import RessourceMenu from "../../../components/RessourceMenu";
import ServiceAssociationsComponent from "../../../components/ServiceAssociationsComponent";
import TabPanel from "../../../components/TabPanel";
import TooltipComponent from "../../../components/TooltipComponent";
import { useGetClientsByExternalContactContactQuery } from "../../ClientApi/redux/clientsApiSlice";
import { PermissionEnum } from "../../Permission/PermissionEnum";
import { useGetServicesByExternalContactContactQuery } from "../../ServiceApi/redux/serviceApiSlice";
import { AbilityContext } from "../../UserApi/logic/Can";
import { useDeleteExternalContactMutation, useGetExternalContactByIdQuery } from "../redux/contactsApiSlice";
import InfoTab from "./tabs/InfoTab";


export interface ExternalContactTabProps {
    uuid: string
    openFromStart?: boolean
    onClose?: () => void
}

export const ExternalContactInfoButton = (props: ExternalContactTabProps) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(props.openFromStart ?? false)
    return (
        <DrawerButton
            open={open}
            setOpen={setOpen}
            onClose={props.onClose}
            buttonContent={
                <TooltipComponent title={t("Details") + ""}>
                    <InfoIcon />
                </TooltipComponent>
            }
            footerContent={
                <EntityMetaDataComponent entity_uuid={props.uuid} />
            }>

            <ExternalContactInfoContent {...props}></ExternalContactInfoContent>
        </DrawerButton>
    )
}

export const ExternalContactInfoContent = (props: ExternalContactTabProps | UseUrlParam) => {

    const params = useParams();
    const urlPropsUuid = (props as UseUrlParam).shouldGetOrganisationFromUrl ? params["contactUuid"] : "";
    const contactPropsUuid = (props as ExternalContactTabProps).uuid
    const contactUuid = contactPropsUuid ?? urlPropsUuid;

    const [value, setValue] = React.useState(0);
    const { t } = useTranslation()
    const { data } = useGetExternalContactByIdQuery(contactUuid);
    const ability = useContext(AbilityContext);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (data == undefined)
        return <Loading />

    const labels = []
    const tabPanel = []

    labels.push(t("Info") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><InfoTab uuid={data.uuid!} {...props} /></TabPanel>)
    if (ability.can(PermissionEnum.SERVICE_READ, data)) {
        labels.push(t("Service associations") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><ServiceAssociationsComponent
            getContactsByIdAction={useGetServicesByExternalContactContactQuery} contactsTitle="Services with external-contact as contact"
            uuid={data.uuid!} {...props} /></TabPanel>)
    }
    if (ability.can(PermissionEnum.CLIENT_READ, data)) {
        labels.push(t("Client associations") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><ClientAssociationsComponent
            getContactsByIdAction={useGetClientsByExternalContactContactQuery} contactsTitle="Clients with external-contact as contact"
            uuid={data.uuid!} {...props} /></TabPanel>)
    }

    return (
        <>
            <CustomTabs
                value={value}
                onChange={handleChange}
                rightMenu={
                    <RessourceMenu
                        isAllowedToDelete={(data) => ability.can(PermissionEnum.EXTERNAL_CONTACT_WRITE, data)}
                        nameOfRessource="external contact"
                        primaryButtonText={t("Contact") + ": " + data.email}
                        idToFetch={data.uuid}
                        deleteMutation={useDeleteExternalContactMutation}
                        getByIdAction={useGetExternalContactByIdQuery} />
                }>
                {labels.map(label => (<Tab key={label} label={label} />))}
            </CustomTabs>
            {tabPanel.map(tabPanel => tabPanel)}
        </>
    )
}