import { AnyAbility } from "@casl/ability";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { useContext } from "react";
import { OrganisationContext } from "../../../components/Layout/OrganisationPicker";
import { PaginationQueryPackage } from "../../../models/API/QueryParams/PaginationQueryPackage";
import HostingsiteBase from "../../../models/Hostingsite";
import { UserAuthorisationResponse, UserResponse } from "../../../models/User";
import { GetManyPackage } from "../../../redux/GetManyPackage";
import { PermissionEnum } from "../../Permission/PermissionEnum";
import { mapAuthorisationToUser } from "./mappers";

export async function GetServiceApprovers(
    ability: AnyAbility,
    hostingSite: HostingsiteBase | undefined,
    getUsersByOrganisationTrigger: LazyQueryTrigger<QueryDefinition<GetManyPackage<object>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, string, UserResponse[], "portalapi">>,
    getAuthorisationsByOrganisationTrigger: LazyQueryTrigger<QueryDefinition<GetManyPackage<object>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, string, UserAuthorisationResponse[], "portalapi">>

): Promise<UserResponse[]> {

    if (!hostingSite) {
        return [];
    }

    const selectedOrganisation = useContext(OrganisationContext)?.organisation
    const anOrganisationIsSelected = selectedOrganisation !== undefined

    const ownerOrganisation = hostingSite.owner_organisation;
    const administratingOrganisation = hostingSite.administrating_organisation;
    const sameOwnerAndAdministratingOrg = administratingOrganisation?.uuid == ownerOrganisation?.uuid
    const administratingOrgSelected = anOrganisationIsSelected && selectedOrganisation.uuid == administratingOrganisation?.uuid
    const ownerOrgSelected = anOrganisationIsSelected && selectedOrganisation.uuid == ownerOrganisation?.uuid

    let ownerUsers = Promise.resolve([] as (UserResponse[] | undefined));
    let administratingUsers = Promise.resolve([] as (UserResponse[] | undefined));

    let ownerUsersAuthorisations = Promise.resolve([] as (UserResponse[] | undefined));
    let administratingAuthroisations = Promise.resolve([] as (UserResponse[] | undefined));

    if (ability.can(PermissionEnum.USER_READ, ownerOrganisation) && (!anOrganisationIsSelected || ownerOrgSelected)) {
        ownerUsers = getUsersByOrganisationTrigger({
            pagination: new PaginationQueryPackage(),
            uuid: ownerOrganisation?.uuid
        }).then(result => result.data);
    }
    if (ability.can(PermissionEnum.ORGANISATION_READ, ownerOrganisation) && (!anOrganisationIsSelected || ownerOrgSelected)) {
        ownerUsersAuthorisations = getAuthorisationsByOrganisationTrigger({
            pagination: new PaginationQueryPackage(),
            uuid: ownerOrganisation?.uuid
        }).then(result => result.data?.map(authorisation => mapAuthorisationToUser(authorisation)))
    }

    if (ability.can(PermissionEnum.USER_READ, administratingOrganisation) && !sameOwnerAndAdministratingOrg && (!anOrganisationIsSelected || administratingOrgSelected)) {
        administratingUsers = getUsersByOrganisationTrigger({
            pagination: new PaginationQueryPackage(),
            uuid: administratingOrganisation?.uuid
        }).then(result => result.data);
    }
    if (ability.can(PermissionEnum.ORGANISATION_READ, administratingOrganisation) && !sameOwnerAndAdministratingOrg && (!anOrganisationIsSelected || administratingOrgSelected)) {
        administratingAuthroisations = getAuthorisationsByOrganisationTrigger({
            pagination: new PaginationQueryPackage(),
            uuid: administratingOrganisation?.uuid
        }).then(result => result.data?.map(authorisation => mapAuthorisationToUser(authorisation)))
    }

    const serviceApprovers = await Promise.all([ownerUsers, ownerUsersAuthorisations, administratingUsers, administratingAuthroisations]);
    return [
        ...serviceApprovers[0] ?? [],
        ...serviceApprovers[1] ?? [],
        ...serviceApprovers[2] ?? [],
        ...serviceApprovers[3] ?? [],
    ]
}