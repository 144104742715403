export function AddQueryParamsToUrl(url: string, keys: string[], values: (string | undefined)[]) {
    const queryParamsArray = [];

    let urlInProgress = url;

    for (let i = 0; i < keys.length; i++) {
        if (values[i] != undefined)
            queryParamsArray.push(`${keys[i]}=${values[i]}`)
    }

    if (!urlInProgress.includes("?") && queryParamsArray.length > 0)
        urlInProgress += "?"


    return urlInProgress + queryParamsArray.join("&")

}




