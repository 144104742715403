

import AddCircle from "@mui/icons-material/AddCircle";
import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateInCollectionButtonProps, normaliseFieldrenders } from "./CreateButtonProps";
import DialogButton from "./DialogButton";
import EasyForm from "./Forms/EasyForm";
import EasyFormColumn from "./Forms/EasyFormColumn";
import { FormType } from "./Forms/EasyFormMany";
import TooltipComponent from "./TooltipComponent";

export function CreateInCollectionButton<T>(props: CreateInCollectionButtonProps<T>) {

    const { t } = useTranslation()
    const weAreCreating = props.toEdit == undefined;
    const [open, setOpen] = useState<boolean>(props.open ?? false)
    return (
        <DialogButton disabled={props.disabled} onClose={props.onClose} onOpen={props.onOpen} open={open} setOpen={setOpen} color="primary" variant={weAreCreating ? undefined : "contained"} buttonContent={
            <>
                {props.buttonJsxOverride ?
                    <TooltipComponent title={t(props.tooltip) + ""}>
                        {props.buttonJsxOverride}
                    </TooltipComponent> :
                    <>
                        {weAreCreating ?
                            <TooltipComponent title={t(props.tooltip) + ""}>

                                {props.icon ? <props.icon /> : <AddCircle />}
                            </TooltipComponent> :
                            <TooltipComponent title={t(props.tooltip) + ""}>

                                <Typography>
                                    {t(props.buttonText ?? "Edit")}
                                </Typography >
                            </TooltipComponent>
                        }
                    </>
                }
            </>
        } >

            <CreateInCollectionButtonContent afterSaveAction={(response) => {
                setOpen(false);
                if (props.afterSaveAction)
                    props.afterSaveAction(response)

            }}  {...props} />
        </DialogButton >
    )
}

export interface CreateInCollectionButtonContentProps<T> {
    afterSaveAction: (Contact: T) => void

}

export function CreateInCollectionButtonContent<T>(props: CreateInCollectionButtonContentProps<T> & CreateInCollectionButtonProps<T>) {
    const { t } = useTranslation()
    return (
        <Box>
            <Grid container justifyContent="center">

                <Grid item xs={6}>
                    <Typography variant="h6">{t(props.tooltip)}</Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={6}>

                    <EasyForm
                        isAllowedToEdit={() => true}
                        editModeAlways={true}
                        arrayPath=""
                        errorToMutation={props.errorToMutation}
                        ressourceOwnerId={props.ressourceOwnerId}
                        SignupSchema={props.validationScheme}
                        initialValue={props.toEdit}
                        columns={[
                            new EasyFormColumn("", "", normaliseFieldrenders(props.fieldEditorRenders))
                        ]}
                        formType={FormType.INFORMATION}
                        getFieldRows={() => []}
                        saveMutation={props.saveMutation}
                        afterSaveAction={props.afterSaveAction}
                        createNew={props.createNew}
                    />
                </Grid>
            </Grid>

        </Box>
    )
}