
import getEnvironment from '../../../env'
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper'
import { RouterTypeEnum } from '../../../models/enums/RouterTypeEnum'
import { OrganisationRouter } from '../../../models/Organisation'
import HandleQuery from '../../../redux/EndpointQueryHandler'
import { GetManyPackage } from '../../../redux/GetManyPackage'
import handleResponse from '../../../redux/handleResponse'
import { portalApiSlice } from '../../../redux/portalApiSlice'
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse'

const baseurl = getEnvironment().REACT_APP_API_BASEURL;


export const routerApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRouterById: builder.query<OrganisationRouter, RequestMutationWrapper<string>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/organisations/${request.parentId}/routers/${request.data}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Router could not be fetched" })
      }),
      transformResponse: (a: OrganisationRouter, b, c) => transformSingleResponseToRightType(OrganisationRouter, a, b, c),
      providesTags: ['router']
    }),
    getRoutersByOrganisationId: builder.query<OrganisationRouter[], GetManyPackage>({
      query: (getManyPackage) => HandleQuery({
        url: `${baseurl}/organisations/${getManyPackage.uuid}/routers`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Routers could not be fetched" }),
        pagination: getManyPackage.pagination
      }),
      transformResponse: (a: OrganisationRouter[], b, c) => transformMultipleResponseToRightType(OrganisationRouter, a, b, c),
      providesTags: ['routers']
    }),
    updateRouter: builder.mutation<OrganisationRouter, RequestMutationWrapper<OrganisationRouter>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/routers/${request.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Router was updated!", toastErrorText: "Router could not be updated" }),
        method: "PATCH",
        body: {
          ip_address: request.data.ip_address,
          name: request.data.name,
          type: request.data.type,
          username: request.data.type === RouterTypeEnum.MPLS ? request.data.username : "",
          password: request.data.type === RouterTypeEnum.MPLS ? request.data.password : "",
          comment: request.data.comment
        }
      }),
      transformResponse: (a: OrganisationRouter, b, c) => transformSingleResponseToRightType(OrganisationRouter, a, b, c),
      invalidatesTags: ['routers', 'router']
    }),
    addRouter: builder.mutation<OrganisationRouter, RequestMutationWrapper<OrganisationRouter>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/organisations/${request.parentId}/routers`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Router was created", toastErrorText: "Router could not be created" }),
        method: 'POST',
        body: {
          ip_address: request.data.ip_address,
          name: request.data.name,
          type: request.data.type,
          username: request.data.type === RouterTypeEnum.MPLS ? request.data.username : "",
          password: request.data.type === RouterTypeEnum.MPLS ? request.data.password : "",
          comment: request.data.comment
        },
      }),
      transformResponse: (a: OrganisationRouter, b, c) => transformSingleResponseToRightType(OrganisationRouter, a, b, c),

      invalidatesTags: ['routers'],
    }),
    deleteRouter: builder.mutation<void, RequestMutationWrapper<OrganisationRouter>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/routers/${request.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Router was deleted!", toastErrorText: "Router could not be deleted" }),
      }),
      
      invalidatesTags: ['routers', 'router']
    }),
    updateRouterPassword: builder.mutation<OrganisationRouter, RequestMutationWrapper<OrganisationRouter>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/routers/${request.data.uuid}/password`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Router password updated!", toastErrorText: "Router password could not be updated" }),
        method: "PATCH",
        body: {
          password: request.data.password,
        }
      }),
      transformResponse: (a: OrganisationRouter, b, c) => transformSingleResponseToRightType(OrganisationRouter, a, b, c),
      invalidatesTags: ['routers', 'router']
    })
  }),
})


export const { useGetRoutersByOrganisationIdQuery, useDeleteRouterMutation, useAddRouterMutation, useUpdateRouterMutation, useUpdateRouterPasswordMutation } = routerApiSlice
















