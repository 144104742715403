import { FieldEditorRender, FieldEditorRenderPackage } from "./FieldEditorRender";
import { FieldRenderType } from "./FieldRenderType";

export class DateTimeRenderPackage extends FieldEditorRenderPackage {
    includeTime?: boolean
}

export class DateTimeRender extends FieldEditorRender {
    includeTime: boolean
    constructor(dateTimeRenderPackage: DateTimeRenderPackage) {
        super(FieldRenderType.DateTime, dateTimeRenderPackage);
        this.includeTime = dateTimeRenderPackage.includeTime ?? false;
    }
}