import { Card, CardContent, Grid, Stack, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useTranslation } from "react-i18next";

interface EmptyPageProps {
    icon: OverridableComponent<SvgIconTypeMap<object, "svg">> & { muiName: string; }
    overrideText?: string
    typographyVariant?: "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "subtitle1" | "subtitle2" | "body1" | "body2" | "overline" | undefined
    elevation?: number
}

export default function EmptyPage(props: EmptyPageProps) {
    const { t } = useTranslation()
    const text = props.overrideText ?? "No results was found";
    return (
        <Grid xs={12} paddingTop={2}>
            <Card elevation={props.elevation}>
                <CardContent>
                    <Stack alignItems="center" textAlign="center" direction="row" spacing={2}>
                        <Typography>{<props.icon color="primary" fontSize="large" />}</Typography>
                        <Typography variant={props.typographyVariant ?? "h6"}>{t(text)}</Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Grid >
    )
}