import { Typography } from '@mui/material';
import { t } from 'i18next';
import { useContext, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { CreateInCollectionButton } from '../../../../components/CreateInCollectionButton';
import ErrorCodeHook from '../../../../components/DTO/ErrorCodeHook';
import EasyFormColumn from '../../../../components/Forms/EasyFormColumn';
import EasyFormMany from '../../../../components/Forms/EasyFormMany';
import { AutocompleteFieldRender } from '../../../../components/Forms/Renders/AutocompleteFieldRender';
import { FieldEditorRender } from '../../../../components/Forms/Renders/FieldEditorRender';
import { IpRender } from '../../../../components/Forms/Renders/IpRender';
import { TextFieldRender } from '../../../../components/Forms/Renders/TextFieldRender';
import { TextRenderer } from '../../../../components/Forms/Renders/TextRenderer';
import { dnsRegex } from '../../../../components/Forms/Validations/DNSValidation';
import { IsIpAddressInSubnet, subnetToStringArray } from '../../../../components/Forms/Validations/IsIpAddressValidation';
import Loading from '../../../../components/loading';
import TooltipComponent from '../../../../components/TooltipComponent';
import getEnvironment from '../../../../env';
import { ErrorCodeEnum } from '../../../../models/API/ErrorCodeEnum';
import { PaginationQueryPackage } from '../../../../models/API/QueryParams/PaginationQueryPackage';
import { DNSSuffix } from '../../../../models/DNSSuffix';
import Portalias from '../../../../models/Portalias';
import { ServiceEndpoint } from '../../../../models/Service';
import { Subnet } from '../../../../models/Subnet';
import { DnsSuffixApiSlice } from '../../../OrganisationApi/redux/dnsSuffixApiSlice';
import { useGetSubnetsByHostingsiteIdQuery } from '../../../OrganisationApi/redux/subnetApiSlice';
import { PermissionEnum } from '../../../Permission/PermissionEnum';
import { PortaliasSimpleDetails } from '../../../PortaliasApi/PortaliasSimpleDetails';
import { useGetAllPortaliasQuery } from '../../../PortaliasApi/redux/portaliasApiSlice';
import { AbilityContext } from '../../../UserApi/logic/Can';
import { useAddEndpointMutation, useDeleteEndpointMutation, useForceAddEndpointMutation, useForceUpdateEndpointMutation, useGetEndpointsByServiceIdQuery, useUpdateEndpointMutation } from '../../redux/endpointApiSlice';
import { useGetServiceByIdQuery } from "../../redux/serviceApiSlice";
import { ServiceTabProps } from "../ServiceInfoButton";

export default function EndpointsTab(props: ServiceTabProps) {
    const portaliasesResult = useGetAllPortaliasQuery({ pagination: new PaginationQueryPackage() });
    const ability = useContext(AbilityContext)
    const serviceResult = useGetServiceByIdQuery(props.serviceId);

    const dnsLazy = DnsSuffixApiSlice.useLazyGetDnsSuffixsByHostingSiteIdQuery();
    const dnsLazyTrigger = dnsLazy[0];

    const [dnsResult, setDnsResult] = useState<DNSSuffix[]>([])
    const [selectedSubnet, setSelectedSubnet] = useState<string | undefined>(undefined)

    const hostingSiteUuid = serviceResult.data?.hosting_site?.uuid
    const subnets = useGetSubnetsByHostingsiteIdQuery(
        { uuid: hostingSiteUuid, pagination: new PaginationQueryPackage() },
        { skip: hostingSiteUuid === undefined });

    const ipOptions = selectedSubnet ? subnetToStringArray(selectedSubnet) : [];

    useMemo(() => {
        if (ability.can(PermissionEnum.HOSTING_SITE_READ, serviceResult.data?.hosting_site)) {
            dnsLazyTrigger({
                pagination: new PaginationQueryPackage(),
                uuid: serviceResult.data?.hosting_site?.uuid
            }).unwrap().then(res => setDnsResult(res)).catch(() => { /* empty*/ })
        }
    }, [])

    const getEndpointRow = (endpoint: ServiceEndpoint, showDnsResults: boolean) => {
        return [
            new TextRenderer({
                displayText: "DNS preview",
                fieldName: "",
                nestedfieldName: "dns_name",
                columns: 12,
                suffixText: ".",
                uniqueId: endpoint.uuid!,
                extraText: new TextRenderer({
                    displayText: "DNS preview",
                    columns: "auto",
                    fieldName: "",
                    nestedfieldName: "dns_suffix.dns_suffix",
                    suffixText: endpoint.dns_suffix?.dns_suffix === "" ? "" : "." + getEnvironment().REACT_APP_DSDN_DOMAIN,
                    uniqueId: endpoint.uuid!
                })
            }),
            new AutocompleteFieldRender({
                displayText: "SDN Subnet",
                fieldName: "",
                nestedfieldName: "subnet",
                columns: "auto",
                minWidth: 300,
                showLabel: true,
                options: subnets.data ?? [],
                onChange: (subnet: Subnet) => setSelectedSubnet(subnet?.subnet),
                getOptionLabelFunc: (option: Subnet) => option.subnet ?? "",
                uniqueId: endpoint.uuid
            }),
            new IpRender({
                nestedfieldName: "ip",
                options: ipOptions,
                uniqueId: endpoint.uuid,
            }),
            new TextFieldRender({
                displayText: "DNS name",
                fieldName: "",
                nestedfieldName: "dns_name",
                columns: "auto",
                type: "text",
                uniqueId: endpoint.uuid!
            }),
            new AutocompleteFieldRender({
                displayText: "DNS suffix",
                fieldName: "",
                columns: "auto",
                nestedfieldName: "dns_suffix",
                options: showDnsResults ? dnsResult ?? [] : [endpoint.dns_suffix],
                getOptionLabelFunc: (option: DNSSuffix) => option.dns_suffix ?? "",
                uniqueId: endpoint.uuid!
            }),

            new AutocompleteFieldRender({
                displayText: "Ports and protocols",
                fieldName: "",
                columns: "auto",
                nestedfieldName: "ports",
                options: portaliasesResult.data ?? [],
                getOptionLabelFunc: (option: Portalias) => `${option.name}`,
                renderOption: (_props, option: Portalias) => (
                    <TooltipComponent title={<PortaliasSimpleDetails portalias={option} />}>
                        <Typography fontSize="10pt">
                            <>{option.name}</>
                        </Typography>
                    </TooltipComponent>
                ),
                renderNonChangeable: (option: Portalias) => (
                    <TooltipComponent title={<PortaliasSimpleDetails portalias={option} />}>
                        <Typography fontSize="10pt">
                            <>{option.name}</>
                        </Typography>
                    </TooltipComponent>
                ),
                multipleSelect: true,
                uniqueId: endpoint.uuid!
            })
        ]
    }

    const errorToAddHook: ErrorCodeHook<ServiceEndpoint>[] = [];
    errorToAddHook.push(new ErrorCodeHook<ServiceEndpoint>(ErrorCodeEnum.OPERATION_REQUIRES_FORCE, "Ip address is already in use", "Create endpoint anyways?", useForceAddEndpointMutation));
    const errorToUpdateHook: ErrorCodeHook<ServiceEndpoint>[] = [];
    errorToUpdateHook.push(new ErrorCodeHook<ServiceEndpoint>(ErrorCodeEnum.OPERATION_REQUIRES_FORCE, "Ip address is already in use", "Update endpoint anyways?", useForceUpdateEndpointMutation));

    const SignupSchema = Yup.object().shape({
        subnet: Yup.object().nullable().required(),

        dns_name: Yup.string().matches(dnsRegex, t('Invalid DNS name')).required(t('Required')),
        dns_suffix: Yup.object().nullable().required(t('Required')),
        ports: Yup.array().min(1, t('At least one port should be added')),
        ip: IsIpAddressInSubnet("subnet")
    });

    const getEndpointRows = (endpoints: ServiceEndpoint[] | undefined) => {
        const rows: Array<FieldEditorRender[]> = []
        endpoints?.forEach((endpoint) => {
            const newRow: FieldEditorRender[] = [...getEndpointRow(endpoint, false)]
            rows.push(newRow)
        })
        return rows;
    }

    const easyFormMany = <EasyFormMany
        confirmDeletionText="Are you sure you wish to delete this endpoint?"
        ressourceOwnerId={props.serviceId}
        arrayPath=""
        isAllowedToDelete={() => ability.can(PermissionEnum.SERVICE_WRITE, serviceResult.data)}
        isAllowedToEdit={() => ability.can(PermissionEnum.SERVICE_WRITE, serviceResult.data)}
        isAllowedToCreate={() => ability.can(PermissionEnum.SERVICE_WRITE, serviceResult.data)}
        getManyByIdAction={useGetEndpointsByServiceIdQuery}
        saveMutation={useUpdateEndpointMutation}
        deleteMutation={useDeleteEndpointMutation}
        createButtonJsx={
            <CreateInCollectionButton
                validationScheme={SignupSchema}
                ressourceOwnerId={props.serviceId}
                fieldEditorRenders={getEndpointRow(new ServiceEndpoint(), true)}
                tooltip="Create endpoint"
                createNew={() => new ServiceEndpoint()}
                saveMutation={useAddEndpointMutation}
                errorToMutation={errorToAddHook} />
        }
        editButtonJsx={(endpoint: ServiceEndpoint) =>
            <CreateInCollectionButton
                validationScheme={SignupSchema}
                toEdit={endpoint}
                onOpen={() => setSelectedSubnet(endpoint.subnet?.subnet)}
                ressourceOwnerId={props.serviceId}
                fieldEditorRenders={getEndpointRow(endpoint, true)}
                tooltip="Edit endpoint"
                createNew={() => { throw new Error("Dont create new here") }}
                saveMutation={useUpdateEndpointMutation}
                errorToMutation={errorToUpdateHook} />
        }
        SignupSchema={SignupSchema}
        idToFetch={props.serviceId}
        columns={[
            new EasyFormColumn(
                "Endpoints",
                "Select the subnet of the health data network (SDN) IP addresses from which this service endpoint is to be displayed. This is your organization's external subnet on the health data network and not the organization's internal IP. If your organization has only one subnet on SDN, this is pre-filled.",
                []
            )
        ]}
        getFieldRows={getEndpointRows}
        newButtonTooltip="Add new endpoint"
    />

    if (dnsLazy[1].isLoading || serviceResult.isLoading) {
        return (<Loading />)
    } else {
        return (easyFormMany)
    }
}