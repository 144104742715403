
import getEnvironment from '../../../env'
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper';
import Portalias from '../../../models/Portalias';
import HandleQuery from '../../../redux/EndpointQueryHandler';
import { GetManyPackage } from '../../../redux/GetManyPackage';
import handleResponse from '../../../redux/handleResponse';
import { portalApiSlice } from '../../../redux/portalApiSlice';
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse';

const baseurl = getEnvironment().REACT_APP_API_BASEURL;
// Define a portaliases using a base URL and expected endpoints
export const portaliasesApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPortalias: builder.query<Portalias[], GetManyPackage>({
      query: (getManyPackage) => HandleQuery({
        url: `${baseurl}/portaliases`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Portaliases could not be fetched" }),
        pagination: getManyPackage.pagination
      }),
      transformResponse: (a: Portalias[], b, c) => transformMultipleResponseToRightType(Portalias, a, b, c),
      providesTags: ['portaliases']
    }),
    getPortaliasById: builder.query<Portalias, string>({
      query: (uuid) => HandleQuery({
        url: `${baseurl}/portaliases/${uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Portalias could not be fetched" }),
      }),
      transformResponse: (a: Portalias, b, c) => transformSingleResponseToRightType(Portalias, a, b, c),
      providesTags: ['portalias']
    }),
    updatePortalias: builder.mutation<Portalias, RequestMutationWrapper<Portalias>>({
      query: (organisation) => HandleQuery({
        url: `${baseurl}/portaliases/${organisation.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Portalias was updated!", toastErrorText: "Portalias could not be updated" }),
        method: "PATCH",
        body: organisation.data
      }),
      transformResponse: (a: Portalias, b, c) => transformSingleResponseToRightType(Portalias, a, b, c),
      invalidatesTags: ['portaliases','portalias']
    }),
    addPortalias: builder.mutation<Portalias, RequestMutationWrapper<Portalias>>({
      query: (organisation) => HandleQuery({
        url: `${baseurl}/portaliases`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Portalias was created", toastErrorText: "Portalisa could not be created" }),
        method: 'POST',
        body: organisation.data,
      }),
      transformResponse: (a: Portalias, b, c) => transformSingleResponseToRightType(Portalias, a, b, c),

      invalidatesTags: ['portaliases'],
    }),
    deletePortalias: builder.mutation<void, RequestMutationWrapper<Portalias>>({
      query: (agreement) => HandleQuery({
        url: `${baseurl}/portaliases/${agreement.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Portalias was deleted!", toastErrorText: "Portalias could not be deleted" }),
      }),

      invalidatesTags: ['portaliases']
    }),
  }),
})


export const { useGetAllPortaliasQuery, useGetPortaliasByIdQuery, useDeletePortaliasMutation, useUpdatePortaliasMutation, useAddPortaliasMutation } = portaliasesApiSlice
















