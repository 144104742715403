
import BaseModel from "./BaseModels/BaseModel"
import { ServiceResponse } from "./Service"

export default class ServiceWindow extends BaseModel {
    start_time?: Date
    end_time?: Date
    description?: string
    created_time?: Date
    created_by?: string
}
export class ServiceWindowRequest extends ServiceWindow {
    services: ServiceResponse[] = []
}
export class ServiceWindowAddRequest {
    service?: ServiceResponse
}