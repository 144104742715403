import { Grid, Stack, Typography } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { OrganisationBadge } from "../components/Badges/OrganisationBadge"
import { OrganisationContext } from "../components/Layout/OrganisationPicker"
import Loading from "../components/loading"
import { CreateHostingSiteButton } from "../feature/HostingSiteApi/CreateHostingSiteButton"
import { HostingSiteList } from "../feature/HostingSiteApi/hostingSiteList"
import { useGetAllHostingsitesQuery, useGetHostingsitesByOrganisationIdQuery } from "../feature/HostingSiteApi/hostingsitesApiSlice"
import { UserContext } from "../feature/UserApi/logic/FetchUser"


export const HostingSitePage = () => {
    const { t } = useTranslation()
    const currentOrganisation = useContext(OrganisationContext)?.organisation;
    const userContext = useContext(UserContext);
    if (!currentOrganisation && !userContext?.allowEmptyOrganisation())
        return <Loading />
        
    return (

        <Grid container spacing={5}>
            <Grid item xs={6}>
                <Stack direction="row" spacing={2}>
                    {currentOrganisation ? <OrganisationBadge ressource={currentOrganisation} /> : <></>}
                    <Typography variant="h5">{t("Hosting sites") + ""}</Typography>
                    <CreateHostingSiteButton />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <HostingSiteList idToFetch={currentOrganisation?.uuid} getHostingSites={currentOrganisation == undefined ? useGetAllHostingsitesQuery : useGetHostingsitesByOrganisationIdQuery} />
            </Grid>
        </Grid>

    )
}