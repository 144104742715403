import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import EasyForm, { FormType } from "../../../../components/Forms/EasyForm";
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import { DateTimeRender } from "../../../../components/Forms/Renders/DateTimeRender";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import Loading from '../../../../components/loading';
import { PaginationQueryPackage } from '../../../../models/API/QueryParams/PaginationQueryPackage';
import { PermissionEnum } from '../../../Permission/PermissionEnum';
import { useGetServicesByServiceWindowIdQuery } from '../../../ServiceApi/redux/serviceApiSlice';
import { AbilityContext } from '../../../UserApi/logic/Can';
import { useGetServiceWindowByIdQuery, useUpdateServiceWindowMutation } from "../../redux/serviceWindowApiSlice";
import { ServiceWindowTabProps } from '../ServiceWindowInfoButton';

export const ServiceWindowTab = (props: ServiceWindowTabProps) => {
    const { t } = useTranslation()
    const servicesForWindowResult = useGetServicesByServiceWindowIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.serviceWindow.uuid
    });

    const SignupSchema = Yup.object().shape({
        description: Yup.string().required(t('Required')),
        start_time: Yup.date().required(t('Required')).typeError(t("Not a valid date")),
        end_time: Yup.date().required(t('Required')).when("start_time", {
            is: (start_time?: Date) => start_time != null,
            then: () => Yup.date().typeError(t("Not a valid date")).min(Yup.ref("start_time"), t('End time must be after start time'))
        }),
    });

    const ability = useContext(AbilityContext)
    const getFirstRow = () => {
        return [
            new TextFieldRender({
                displayText: "Description",
                fieldName: "description",
                uniqueId: props.serviceWindow.uuid!,
                type: "text",
                multiline: true
            }),
            new DateTimeRender({
                displayText: "Start",
                fieldName: "start_time",
                uniqueId: "no_id",
                includeTime: true
            }),
            new DateTimeRender({
                displayText: "End",
                fieldName: "end_time",
                uniqueId: "no_id",
                includeTime: true
            })
        ];
    }
    if (servicesForWindowResult.isLoading)
        return <Loading />

    const allOrganisationsForServices = new Set(servicesForWindowResult.data?.map(service => service.owner_organisation?.uuid));
    const organisationForAllService = allOrganisationsForServices.size == 1 ? servicesForWindowResult.data?.find(() => true) : undefined
    return (
        <EasyForm
            isAllowedToEdit={() => ability.can(PermissionEnum.SERVICE_WRITE, organisationForAllService)}
            saveMutation={useUpdateServiceWindowMutation}
            getByIdAction={useGetServiceWindowByIdQuery}
            arrayPath=""
            SignupSchema={SignupSchema}
            columns={[
                new EasyFormColumn("Service window", "", getFirstRow())
            ]}
            idToFetch={props.serviceWindow.uuid!}
            getFieldRows={() => []}
            newButtonTooltip="Add new info"
            formType={FormType.INFORMATION}
            createNew={() => { throw "No new Servicewindow should be created here" }}
        />
    )
}