import BaseModel from "./BaseModels/BaseModel"
import { ContactLabel } from "./ContactLabel"

export class ContactResponse extends BaseModel {
  email?: string
  organisation_uuid?: string
  user_uuid?: string
  external_contact_uuid?: string
  first_name?: string
  last_name?: string
  created_by?: string
  created_time?: Date
}

export class ContactUpdateResponse extends BaseModel {
  labels?: ContactLabel[]
}

export class ContactRequest extends BaseModel {
  email?: string
  first_name?: string
  last_name?: string
}

export class CombinedUserContact extends BaseModel {
  email?: string
  first_name?: string
  last_name?: string
  user_uuid?: string
  external_contact_uuid?: string
}

export class AttachContactRequest extends BaseModel {
  contact?: ContactResponse
  labels?: ContactLabel[]
}


