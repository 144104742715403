import { useContext, useMemo, useState } from "react";
import { PaginationQueryPackage } from "../../../models/API/QueryParams/PaginationQueryPackage";
import HostingsiteBase from "../../../models/Hostingsite";
import { ServiceTagResponse } from "../../../models/ServiceTag";
import { useLazyGetServiceTagsByHostingsiteIdQuery } from "../../OrganisationApi/redux/serviceTagApiSlice";
import { PermissionEnum } from "../../Permission/PermissionEnum";
import { AbilityContext } from "../../UserApi/logic/Can";

export default function useServiceTag(hostingSite?: HostingsiteBase): ServiceTagResponse[] {
    const [serviceTagResult, setServiceTagResult] = useState<ServiceTagResponse[]>([])

    const getServicetagsLazy = useLazyGetServiceTagsByHostingsiteIdQuery();
    const getServicetagsLazyTrigger = getServicetagsLazy[0];

    const ability = useContext(AbilityContext);
    useMemo(() => {
        if (hostingSite?.uuid && ability.can(PermissionEnum.HOSTING_SITE_READ, hostingSite)) {
            getServicetagsLazyTrigger({
                pagination: new PaginationQueryPackage(),
                uuid: hostingSite?.uuid
            }, true).unwrap()
                .then(result => setServiceTagResult(result))
                .catch(() => setServiceTagResult([]))
        }
    }, [hostingSite, getServicetagsLazy[1].data])

    return serviceTagResult
}