import { ExpandMore } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Accordion, AccordionDetails, Button, Chip, Grid, Stack, TextField, Typography } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import { GridColDef } from '@mui/x-data-grid-pro';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { t } from 'i18next';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserBadge } from '../../components/Badges/UserBadge';
import DatagridWrapper, { DataGridId } from '../../components/DatagridWrapper';
import { DateToFormattedString } from '../../components/Logic/DateLogic';
import ToggleableAutocomplete from '../../components/ToggleableAutocomplete';
import ToggleableDesktopDatePicker from '../../components/ToggleableDesktopDatePicker';
import { AuditEventQueryPackage } from '../../models/API/QueryParams/AuditEventQueryPackage';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import Event, { OperationEnum } from '../../models/Event';
import { UserResponse } from '../../models/User';
import { GetManyPackage } from '../../redux/GetManyPackage';
import { NotCorrectRights } from '../Permission/NotCorrectRights';
import { PermissionEnum } from '../Permission/PermissionEnum';
import { AbilityContext, Can } from '../UserApi/logic/Can';
import { UserContext } from '../UserApi/logic/FetchUser';
import { useGetAllUserQuery, useGetAllUsersByOrganisationQuery } from '../UserApi/redux/userApiSlice';
import { EventInfoButton } from './EventInfoButton';

class EventsListProps {
    getEvents!: UseLazyQuery<QueryDefinition<GetManyPackage<object>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, Event[], any>>
    idToFetch?: string
}

export const EventsList = (props: EventsListProps) => {
    const ability = useContext(AbilityContext);
    if (!ability.can(PermissionEnum.AUDIT_READ))
        return <NotCorrectRights />

    return (<EventsListContent {...props} />)
}

const EventsListContent = (props: EventsListProps) => {

    const ability = useContext(AbilityContext);
    const userContext = useContext(UserContext);
    const [changesSinceLastEdit, setChangesSinceLastEdit] = useState(false)

    const getEventLazy = props.getEvents();
    const getEventLazyTrigger = getEventLazy[0];

    let getUsers = useGetAllUsersByOrganisationQuery;
    if (ability.can(PermissionEnum.GOD))
        getUsers = useGetAllUserQuery;

    const allUsersResult = getUsers({
        pagination: new PaginationQueryPackage(),
        uuid: userContext?.organisation?.uuid
    });

    const [clickedRow, setClickedRow] = useState<Event | undefined>(undefined)
    const [uuid, setUuid] = useState(crypto.randomUUID()) //To rerender buttons when clicking a row



    const getColumns = (events: Event[]) => {

        const columns: GridColDef[] = [
            //Data
            { field: 'resource', sortable: true, type: "string", headerName: t('Resource'), flex: 2 },
            { field: 'operation', sortable: true, type: "singleSelect", valueOptions: Object.values(OperationEnum).map(e => t(e) + ""), headerName: t('Operation'), valueGetter: (operation) => t(operation + ""), flex: 2 },
            { field: 'audit_event_date_time', sortable: true, type: "dateTime", headerName: t('Time'), valueGetter: (time) => time && new Date(time), renderCell: (params: { row: Event }) => DateToFormattedString(params.row.audit_event_date_time), flex: 2 },
            { field: 'user', sortable: true, headerName: t('User'), type: "string", valueGetter: (_, row: Event) => row.system_user ? "system" : row.user?.email, renderCell: (params: { row: Event }) => params.row.system_user ? <UserBadge textOverride={t("system")} /> : <UserBadge textOverride={params.row.user?.email} />, flex: 2 },
            //Buttons
            {
                field: 'events', sortable: false, hideable: false, type: "string", valueGetter: () => "", headerName: t('Actions'), minWidth: 220, renderCell: (params => {
                    const event = events.find(x => x.uuid === params.id)!;

                    return (
                        <Can I={PermissionEnum.AUDIT_READ} this={event}>
                            <EventInfoButton key={JSON.stringify(uuid)} event={event} openFromStart={event.uuid == clickedRow?.uuid} />
                        </Can>
                    )
                })
            }
        ];
        return columns
    }

    const lastWeek = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    const getDefaultSearch = () => {
        return new AuditEventQueryPackage({
            from: lastWeek,
            to: new Date(),
            entity_uuid: entity_uuid == null ? undefined : entity_uuid,
            sort_by: "EVENT_TIME",
            order_by: "DESC",
            audit_user: audit_user_uuid == null ? undefined : allUsersResult.data?.find((user) => user.uuid == audit_user_uuid),
            search_query: ""
        })
    }

    const [events, setEvents] = useState<Event[]>([])
    const query = useQuery();
    const entity_uuid = query.get("entity_uuid");
    const audit_user_uuid = query.get("audit_user_uuid");


    const [queryPackage, setQueryPackage] = useState(getDefaultSearch())

    function updateQueryPackage(queryPackage: AuditEventQueryPackage): void {
        setQueryPackage(queryPackage)
        setClickedRow(undefined)
        setChangesSinceLastEdit(true)
    }

    useEffect(() => {
        const delayedSearch = setTimeout(() => {
            if (ability.can(PermissionEnum.AUDIT_READ)) {
                getEventLazyTrigger({
                    uuid: props.idToFetch,
                    pagination: queryPackage
                }).unwrap().then((result) => {
                    setEvents(result);
                    setChangesSinceLastEdit(false)
                })
            }
        }, 500)

        return () => clearTimeout(delayedSearch)
    }, [queryPackage, props.idToFetch])

    useMemo(() => {
        const query = Object.create(queryPackage) as AuditEventQueryPackage
        query.audit_user = audit_user_uuid == null ? undefined : allUsersResult.data?.find((user) => user.uuid == audit_user_uuid);
        updateQueryPackage(query)
    }, [allUsersResult.data])


    return (
        <>
            <Stack direction="row" spacing={2} paddingBottom={2}>
                <ToggleableDesktopDatePicker
                    label={t("Start date") + ""}
                    shouldDisableTime={() => false}
                    // key={props.fieldName + value}
                    ampm={false}
                    ampmInClock={false}
                    value={queryPackage.from == undefined ? null : queryPackage.from}
                    onChange={(newDate) => {
                        const query = Object.create(queryPackage) as AuditEventQueryPackage
                        query.from = newDate == null ? undefined : newDate;
                        updateQueryPackage(query)
                    }}
                    inputFormat={"dd-MM-yyyy HH:mm"}
                    renderInput={(params) => <TextField{...params} />}
                    editMode={true} />
                <ToggleableDesktopDatePicker
                    label={t("End date") + ""}
                    shouldDisableTime={() => false}
                    // key={props.fieldName + value}
                    ampm={false}
                    ampmInClock={false}
                    value={queryPackage.to == undefined ? null : queryPackage.to}
                    onChange={(newDate) => {
                        const query = Object.create(queryPackage) as AuditEventQueryPackage
                        query.to = newDate == null ? undefined : newDate;
                        updateQueryPackage(query)
                    }}
                    inputFormat={"dd-MM-yyyy HH:mm"}
                    renderInput={(params) => <TextField{...params} />}
                    editMode={true} />
            </Stack>

            <TextField
                fullWidth
                label={t("Search") + ""}
                value={queryPackage.search_query}
                onChange={(e) => {
                    const query = Object.create(queryPackage) as AuditEventQueryPackage
                    query.search_query = e.target.value
                    updateQueryPackage(query)
                }}
                InputProps={{ startAdornment: <SearchIcon sx={{ marginRight: 1 }} /> }}
            />

            <Accordion
                sx={{ marginBottom: 2 }}
                elevation={0}
                disableGutters>
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        flexDirection: "row-reverse",
                        '& .MuiAccordionSummary-content': {
                            marginLeft: 2,
                        },
                        '& .MuiAccordionSummary-content.Mui-expanded': {
                            marginLeft: 2,
                        },
                    }}
                    expandIcon={<ExpandMore />}
                >
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs="auto">
                            <Typography>{t("Advanced search") + ""}</Typography>
                        </Grid>
                        <Grid item xs>
                            <SearchParamater title='Page' value={queryPackage.page == undefined ? undefined : queryPackage.page + ""} />
                            <SearchParamater title='Pagesize' value={queryPackage.pagesize == undefined ? undefined : queryPackage.pagesize + ""} />
                            <SearchParamater title='Operation' value={queryPackage.operation} />
                            <SearchParamater title='Order by' value={queryPackage.order_by} />
                            <SearchParamater title='Sort by' value={queryPackage.sort_by} />
                            <SearchParamater title='Resource' value={queryPackage.resource} />
                            <SearchParamater title='User' value={queryPackage.audit_user?.email} />
                            <SearchParamater title='Entity' value={queryPackage.entity_uuid} />
                            <Button
                                variant='outlined'
                                onClick={(e) => { updateQueryPackage(getDefaultSearch()); e.stopPropagation() }}>
                                {t("Reset search") + ""}
                            </Button>
                        </Grid>

                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <QueryFilter<OperationEnum>
                                        value={queryPackage.operation}
                                        label={t("Operation")}
                                        options={Object.values(OperationEnum)}
                                        onChange={(value) => {
                                            const query = Object.create(queryPackage) as AuditEventQueryPackage
                                            query.operation = value;
                                            updateQueryPackage(query)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <QueryFilter<string>
                                        value={queryPackage.resource}
                                        label={t("Resource")}
                                        options={[...new Set(getEventLazy[1].data?.map(x => x.resource ?? "").filter(x => x != "") ?? [])]}
                                        onChange={(value) => {
                                            const query = Object.create(queryPackage) as AuditEventQueryPackage
                                            query.resource = value;
                                            updateQueryPackage(query)
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <QueryFilter<"ORGANISATION" | "OPERATION" | "EVENT_TIME" | "USER" | "ENTITY" | "RESOURCE">
                                        value={queryPackage.sort_by}
                                        label={t("Sort by")}
                                        options={["ORGANISATION", "OPERATION", "EVENT_TIME", "USER", "ENTITY", "RESOURCE"]}
                                        onChange={(value) => {
                                            const query = Object.create(queryPackage) as AuditEventQueryPackage
                                            query.sort_by = value;
                                            updateQueryPackage(query)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <QueryFilter<"ASC" | "DESC">
                                        value={queryPackage.order_by}
                                        label={t("Order by")}
                                        options={["ASC", "DESC"]}
                                        onChange={(value) => {
                                            const query = Object.create(queryPackage) as AuditEventQueryPackage
                                            query.order_by = value;
                                            updateQueryPackage(query)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <QueryFilter<UserResponse>
                                        value={queryPackage.audit_user}
                                        label={t("User")}
                                        options={allUsersResult.data ?? []}
                                        getOptionLabel={(value) => value.email ?? ""}
                                        onChange={(value) => {
                                            const query = Object.create(queryPackage) as AuditEventQueryPackage
                                            query.audit_user = value;
                                            updateQueryPackage(query)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <DatagridWrapper
                dataGridId={DataGridId.EVENTS}
                onRowClick={(row) => { setClickedRow(row.row); setUuid(crypto.randomUUID()) }}
                disableColumnFilter
                loading={getEventLazy[1].isLoading || allUsersResult.isLoading || changesSinceLastEdit}
                rows={events}
                columns={getColumns(events)}
            />
        </>
    )
}

export function QueryFilter<T>(props: { loading?: boolean, label: string, value?: T, options: T[], onChange: (value: T) => void, getOptionLabel?: (option: T) => string | JSX.Element }) {
    let optionlabel = props.getOptionLabel;
    if (optionlabel == undefined)
        optionlabel = (option) => option + "";

    return (
        <ToggleableAutocomplete
            key={props.value + ""}
            options={props.options}
            editMode={true}
            value={props.value}
            onChange={(_e, value) => props.onChange(value)}
            getOptionLabel={optionlabel}
            renderInput={(params) => (
                <TextField label={props.label}{...params} />)}
            isOptionEqualToValue={(option, value) => option == value}
            multiple={false} />
    )
}
function SearchParamater(props: { title: string, value: string | undefined }) {

    if (props.value == undefined)
        return <></>

    return (
        <Chip key={props.title + props.value} sx={{ padding: "1em", marginRight: "1em" }} label={
            <Stack alignItems="center" direction="row" spacing={2}>
                <Typography variant='caption'>{t(props.title) + ""}</Typography>
                <Typography>{t(props.value) + ""}</Typography>
            </Stack>
        } />

    )
}


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}