import { Grid, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import { CreateOrganisationButton } from "../feature/OrganisationApi/CreateOrganisationButton"
import { OrganisationList } from "../feature/OrganisationApi/organisationList"

export const OrganisationPage = () => {
    const { t } = useTranslation()
    return (

        <Grid container spacing={5}>
            <Grid item xs={6}>
                <Stack direction="row">
                    <Typography variant="h5">{t("Organisations") + ""}</Typography>
                    <CreateOrganisationButton />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <OrganisationList></OrganisationList>
            </Grid>
        </Grid>

    )
}