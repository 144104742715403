import { t } from "i18next";
import { AbstractIPNum, AbstractIPRange, IPv4, IPv4CidrRange, IPv4Prefix, IPv6, IPv6CidrRange, IPv6Prefix } from "ip-num";
import * as Yup from 'yup';
import { AnyObject } from "yup/lib/types";
import getEnvironment from "../../../env";
import { Subnet } from "../../../models/Subnet";

export function IsIpAddressValidation(shouldBeCidr: boolean): Yup.StringSchema<string | undefined, AnyObject, string | undefined> {
    //let message = "Not a valid IP-Address"
    //message = "Not a valid CIDR"

    let validationScheme = Yup.string().test(t("Not a valid IP-Address"), t("Not a valid IP-Address"), (val) => getIp(val) != undefined);

    if (shouldBeCidr)
        validationScheme = Yup.string().test(t("Not a valid CIDR"), t("Not a valid CIDR"), (val) => getIpRanges(val) != undefined)

    return validationScheme;
}

export function IsIpAddressInSubnet(subnetFieldName: string): Yup.StringSchema<string | undefined, AnyObject, string | undefined> {

    return Yup.string()
        .when(subnetFieldName, (subnet: Subnet) =>
            IsIpAddressValidation(false)
                .test(t("ipAddress is not in subnet"), t("ipAddress is not in subnet"), (ipAddress) => {
                    if (!ipAddress || !subnet)
                        return false

                    const ipAddressObj = getIp(ipAddress)
                    const range = getIpRanges(subnet.subnet);

                    return ipIsInSubnet(range, ipAddressObj)
                })
        )

}

export function subnetToStringArray(subnet: string | undefined): string[] {
    if (subnet == undefined)
        return []

    const ips: (IPv4 | IPv6)[] = [];
    const ipRange = getIpRanges(subnet);
    let current: IPv4 | IPv6 | undefined = ipRange?.getFirst();
    const lastIp = ipRange?.getLast().nextIPNumber();
    while (current && lastIp && current.toString() != lastIp.toString()) {
        ips.push(current)
        current = current?.nextIPNumber();
    }
    const result = ips.map(x => x?.toString());
    return result;
}

export function ipIsInSubnet(subnet: AbstractIPRange<IPv4 | IPv6, IPv4Prefix | IPv6Prefix> | undefined, ipAddress: AbstractIPNum | undefined) {
    if (subnet == undefined)
        return false;
    if (ipAddress == undefined)
        return false

    const from = subnet.getFirst();
    const to = subnet.getLast();

    //It is decided that we should allow the first and the last ipaddress, even though it is considered bad practise
    //The system allow theese addresses to be used today
    //if (ipAddressObj instanceof IPv4)
    //    return ipAddressObj.isGreaterThan(from) && ipAddressObj.isLessThan(to)

    const supportIpV6 = getEnvironment().GET_SUPPORT_IPV6()
    if (ipAddress instanceof IPv6 && !supportIpV6)
        return false

    return ipAddress.isGreaterThanOrEquals(from) && ipAddress.isLessThanOrEquals(to)
}

function getIp(IpAddres: string | undefined): AbstractIPNum | undefined {
    if (IpAddres == undefined)
        return undefined

    let ip: AbstractIPNum | undefined = undefined;

    try { ip = new IPv4(IpAddres); } catch (error) { /* empty */ }
    try { ip = new IPv6(IpAddres); } catch (error) { /* empty */ }
    return ip;
}

export function getIpRanges(subnet: string | undefined) {
    if (subnet == undefined)
        return undefined

    let range: AbstractIPRange<IPv4 | IPv6, IPv4Prefix | IPv6Prefix> | undefined = undefined;

    try { range = IPv4CidrRange.fromCidr(subnet) } catch (error) { console.log(error) }

    const supportIpV6 = getEnvironment().GET_SUPPORT_IPV6()
    if (supportIpV6) {
        try { range = IPv6CidrRange.fromCidr(subnet) } catch (error) { console.log(error) }
    }

    return range;
}