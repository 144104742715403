import InfoIcon from '@mui/icons-material/Info';
import { ButtonGroup, Stack, Tab, Typography } from '@mui/material';
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ContactLabelAlert } from '../../../components/Alerts/ContactLabelAlert';
import { ConfirmationButton } from '../../../components/ConfirmationButton';
import { DataGridId } from '../../../components/DatagridWrapper';
import DrawerButton from "../../../components/DrawerButton";
import { EntityMetaDataComponent } from '../../../components/EntityMetaDataComponent';
import { CustomTabs } from '../../../components/Forms/CustomTabs';
import UseUrlParam from "../../../components/Logic/UseUrlProps";
import RessourceMenu from "../../../components/RessourceMenu";
import TabPanel from "../../../components/TabPanel";
import TooltipComponent from '../../../components/TooltipComponent';
import { PaginationQueryPackage } from '../../../models/API/QueryParams/PaginationQueryPackage';
import Client from "../../../models/Client";
import { LabelContextEnum } from '../../../models/ContactLabel';
import { ApprovalStatus } from '../../../models/enums/ApprovalStatus';
import { AgreementList } from '../../AgreementApi/agreementList';
import { useDeleteAgreementMutation, useGetAgreementsByClientIdQuery } from '../../AgreementApi/redux/agreementsApiSlice';
import { useGetContactsByClientIdQuery } from '../../ContactsApi/redux/contactsApiSlice';
import { PermissionEnum } from '../../Permission/PermissionEnum';
import { AbilityContext, Can } from '../../UserApi/logic/Can';
import { useDeleteClientMutation, useGetClientByIdQuery } from "../redux/clientsApiSlice";
import AgreementsTab from "./tabs/AgreementsTab";
import ContactTab from './tabs/ContactsTab';
import InfoTab from "./tabs/InfoTab";
import IpAddressesTab from "./tabs/IpAddressesTab";

interface ClientInfoButtonProps {
    client: Client
    openFromStart?: boolean
    onClose?: () => void
}

export interface ClientTabProps {
    client_id: string
}

export const ClientInfoButton = (props: ClientInfoButtonProps) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(props.openFromStart ?? false)

    return (
        <DrawerButton
            open={open}
            setOpen={setOpen}
            onClose={props.onClose}
            buttonContent={
                <TooltipComponent title={t("Details") + ""}>
                    <InfoIcon />
                </TooltipComponent>
            }
            footerContent={
                <EntityMetaDataComponent entity_uuid={props.client.uuid} />
            } >

            <ClientInfoContent client_uuid={props.client.uuid} afterDeleteAction={() => setOpen(false)}></ClientInfoContent>
        </DrawerButton>
    )
}

interface ClientInfoDialogProps {
    client_uuid: string
    afterDeleteAction: () => void
}

export const ClientInfoContent = (props: ClientInfoDialogProps | UseUrlParam) => {

    const params = useParams();
    const deleteAgreementMutation = useDeleteAgreementMutation();
    const deleteAgreementTrigger = deleteAgreementMutation[0];
    const urlPropsUuid = (props as UseUrlParam).shouldGetOrganisationFromUrl ? params["clientUuid"] : "";
    const clientPropsUuid = (props as ClientInfoDialogProps).client_uuid

    const clientUuid = clientPropsUuid ?? urlPropsUuid;

    const [value, setValue] = React.useState(0);
    const { t } = useTranslation();
    const clientResult = useGetClientByIdQuery(clientUuid!)
    const ability = useContext(AbilityContext);

    const { data: agreementsData } = useGetAgreementsByClientIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: clientUuid,
        requestData: [ApprovalStatus.APPROVED, ApprovalStatus.DENIED, ApprovalStatus.PENDING]
    })

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (clientResult.data == undefined)
        return <></>

    const labels = []
    const tabPanel = []

    labels.push(t("Info") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><InfoTab client_id={clientResult.data!.uuid!} {...props} /></TabPanel>)
    if (ability.can(PermissionEnum.CLIENT_READ, clientResult.data)) {
        labels.push(t("Ip Addresses") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><IpAddressesTab client_id={clientResult.data!.uuid!} {...props} /></TabPanel>)
    }
    if (ability.can(PermissionEnum.CLIENT_READ, clientResult.data)) {
        labels.push(t("Agreements") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><AgreementsTab client_id={clientResult.data!.uuid!} {...props} /></TabPanel>)
    }
    if (ability.can(PermissionEnum.CLIENT_READ, clientResult.data)) {
        labels.push(t("Contacts") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><ContactTab client_id={clientResult.data!.uuid!} {...props} /></TabPanel>)
    }

    const clientHasAgreements: boolean = agreementsData?.some(x => x.status === ApprovalStatus.APPROVED) ? true : false;

    return (
        <>
            <Can I={PermissionEnum.CLIENT_WRITE} this={clientResult.data}>
                <ContactLabelAlert uuid={clientUuid!} fetchContactsHook={useGetContactsByClientIdQuery} labelContext={LabelContextEnum.CLIENT} />
            </ Can>
            <CustomTabs value={value} onChange={handleChange}
                rightMenu={
                    <RessourceMenu
                        isAllowedToDelete={(data) => ability.can(PermissionEnum.CLIENT_WRITE, data)}
                        nameOfRessource="client"
                        primaryButtonText={t("Client") + ": " + clientResult.data!.name}
                        idToFetch={clientResult.data!.uuid}
                        deleteMutation={useDeleteClientMutation}
                        getByIdAction={useGetClientByIdQuery}
                        afterDeleteAction={(props as ClientInfoDialogProps).afterDeleteAction}
                        deleteDialogMaxWidth="lg"
                        deleteButtonContent={!clientHasAgreements ? undefined :
                            <Stack spacing={2}>
                                <Typography>{t("The client has agreements, that needs to be terminated in order to delete client")}</Typography>
                                <AgreementList dataGridId={DataGridId.AGREEMENTS_SIMPLE} statusList={[ApprovalStatus.APPROVED]} simpleView={true} idToFetch={clientResult.data!.uuid} getAgreements={useGetAgreementsByClientIdQuery}></AgreementList>
                                <ButtonGroup>
                                    <ConfirmationButton
                                        maxWidth="lg"
                                        action={async () => {
                                            const promises = agreementsData?.map(async (agreement) => deleteAgreementTrigger({ data: agreement }));
                                            if (promises == undefined)
                                                return;

                                            return await Promise.all(promises) as unknown as Promise<void>;
                                        }}
                                        title={t("Delete all agreements")}
                                        buttonText={t("Delete all agreements")}>
                                        <>
                                            <Typography>{t("Are you sure you wish to delete all agreements on this client?", {"numberOfAgreements": agreementsData?.length})}</Typography>
                                            <Typography fontWeight="bold">{t("You will not be able to undo this action")}</Typography>
                                        </>
                                    </ConfirmationButton>
                                </ButtonGroup>
                            </Stack>
                        } />
                }>
                {labels.map(label => (<Tab key={label} label={label} />))}
            </CustomTabs>
            {tabPanel.map(tabPanel => tabPanel)}
        </>
    )
}