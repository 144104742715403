import { FieldEditorRender } from "./Renders/FieldEditorRender";


export default class EasyFormColumn {
    description?: string
    title?: string
    fieldEditorRenders?: FieldEditorRender[]
    constructor(title: string, description: string, fieldEditorRenders: FieldEditorRender[]) {
        this.title = title;
        this.description = description;
        this.fieldEditorRenders = fieldEditorRenders
    }
}