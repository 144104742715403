import { Grid, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { EventsList } from "../feature/EventApi/eventList"
import { eventsApiSlice } from "../feature/EventApi/redux/eventApiSlice"

export const EventsPage = () => {

    const { t } = useTranslation()
    return (


        <Grid container spacing={5}>
            <Grid item xs={6}>
                <Stack direction="row" spacing={2}>
                    <Typography variant="h5">{t("Events") + ""}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <EventsList getEvents={eventsApiSlice.useLazyGetAllEventsQuery} />
            </Grid>
        </Grid>

    )
}