import { t } from "i18next";
import { toast } from "react-toastify";
import { SetAuthorisationToUseInHeader } from "../App";
import ToastError from "../components/ToastError";
import { Error } from "../models/API/Error";
import { ErrorCodeEnum } from "../models/API/ErrorCodeEnum";

export class ResponsePackage {
    response!: Response
    toastSuccessText?: string
    toastErrorText?: string
    toastWithResult?: boolean
}

export default async function handleResponse(
    pack: ResponsePackage
) {

    const shouldShowToast = pack.toastWithResult ?? true
    const isSuccess = pack.response.status > 199 && pack.response.status < 300;
    if (isSuccess && shouldShowToast) {
        toast.success(pack.toastSuccessText ? t(pack.toastSuccessText) + "" : t("Your change has been saved") + "")
    }

    if (pack.response.status == 204)
        return {};

    let json: any = undefined
    try {
        json = await pack.response.json()
    } catch (e) { /* empty */ }

    if (isSuccess) {
        return json
    }

    const error = new Error(pack.response.status, pack.response.url, json.timestamp, json.error_code);

    if (error.error_code &&
        error.error_code == ErrorCodeEnum.USER_NOT_ALLOWED_TO_USE_PROVIDED_AUTHORISATION) {
        SetAuthorisationToUseInHeader(undefined);
    }

    if (!isSuccess) {
        toast.error(<ToastError error={error} />)
    }

    return json;
}