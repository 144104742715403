import { PlaylistAddCheckCircle, PlaylistAddCircle } from "@mui/icons-material";
import { LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CircularProgressWithLabel from "./CircluarProgressWithLabel";
import { calculateDateProgressionInPercentage } from "./Logic/DateLogic";
import TooltipComponent from "./TooltipComponent";

interface Props {
    startTime?: Date
    endTime?: Date
    progressbar?: boolean
}

export default function TimePeriod(props: Props) {
    const { t } = useTranslation()
    const startDate = new Date(props.startTime + "")
    const endDate = new Date(props.endTime + "")


    const [percent, setPercentage] = useState(0);

    useMemo(() => {
        const percentage = calculateDateProgressionInPercentage(startDate, endDate);
        setPercentage(percentage)
    }, [new Date().getMinutes(), props.startTime, props.endTime])

    let progressbar = <></>
    switch (percent) {
        case 100:
            progressbar = (<PlaylistAddCheckCircle fontSize="large" color="success" />)
            if (props.progressbar)
                progressbar = <CustomLinearProgress color="success" value={percent} />
            break;
        case 0:
            progressbar = (<PlaylistAddCircle fontSize="large" color="info" />)
            if (props.progressbar)
                progressbar = <CustomLinearProgress color="info" value={percent} />
            break;
        default:
            progressbar = (<CircularProgressWithLabel tooltip={t("Servicewindow is in progress")} key={new Date().getTime()} size={35} value={percent} color="success" />)
            if (props.progressbar)
                progressbar = <CustomLinearProgress color="warning" value={percent} />
            break;

    }
    return (
        <TooltipComponent sx={{ border: "1px solid black" }} title={percent == 100 ? t("Servicewindow is completed") : percent == 0 ? t("Servicewindow is planned") : t("Servicewindow is in progress") + ` (${percent}%)`}>

            {progressbar}
        </TooltipComponent>
    )

}

function CustomLinearProgress(props: { color: 'info' | 'success' | 'warning', value: number }) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }}>

            <LinearProgress
                color={props.color}
                variant="determinate"
                sx={{
                    width: "80%",
                    height: 10,
                    borderRadius: 2
                }}
                value={props.value}
            />
        </Box>
    )
}