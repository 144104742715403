import { AppBar, Grid, Stack, Toolbar, Typography } from "@mui/material"
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NotificationView } from "../../feature/NotificationApi/NotificationView";
import { AuthorisationMenu } from "./AuthorisationMenu";
import { AuthorisationContext } from "./AuthorisationSelector";
import { UserMenu } from "./UserMenu";

interface TopBarProps {
    drawerWidth: number
}
export const TopBar = (props: TopBarProps) => {

    const { t } = useTranslation()
    const authorisationContext = useContext(AuthorisationContext)

    return (
        <>
            <title>{t("Agreementportal") + ""}</title>
            <AppBar
                color={authorisationContext?.userAuthorisationInUse ? "secondary" : undefined}
                position="fixed"
                sx={{ width: `calc(100% - ${props.drawerWidth}px)`, ml: `${props.drawerWidth}px` }}
            >
                <Toolbar>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <NotificationView />
                                <Typography variant="h6">{t("Agreementportal") + ""}</Typography>
                            </Stack>
                        </Grid>

                        <Grid textAlign="right" item xs="auto">
                            <Stack direction="row">
                                <AuthorisationMenu />
                                <UserMenu />
                            </Stack>
                        </Grid>

                    </Grid>
                </Toolbar>
            </AppBar>

        </>
    )
}