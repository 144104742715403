import getEnvironment from '../../../env'
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper'
import OrganisationResponse, { CreateOrganisationRequest, DetailedOrganisationResponse } from '../../../models/Organisation'
import { CreateResponse } from '../../../redux/CreateResponse'
import HandleQuery from '../../../redux/EndpointQueryHandler'
import { GetManyPackage } from '../../../redux/GetManyPackage'
import handleResponse from '../../../redux/handleResponse'
import { portalApiSlice } from '../../../redux/portalApiSlice'
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse'

const baseurl = getEnvironment().REACT_APP_API_BASEURL;


export const organisationApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrganisations: builder.query<DetailedOrganisationResponse[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/organisations`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Organisations could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: OrganisationResponse[], b, c) => transformMultipleResponseToRightType(DetailedOrganisationResponse, a, b, c),
      providesTags: ['organisations']
    }),
    getAllAllowedOrganisations: builder.query<DetailedOrganisationResponse[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/organisations/allowed`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Organisations could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: OrganisationResponse[], b, c) => transformMultipleResponseToRightType(DetailedOrganisationResponse, a, b, c),
      providesTags: ['organisations']
    }),
    getOrganisationById: builder.query<OrganisationResponse, string>({
      query: (uuid) => HandleQuery({
        url: `${baseurl}/organisations/${uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Organisation could not be fetched" }),
      }),
      transformResponse: (a: OrganisationResponse, b, c) => transformSingleResponseToRightType(OrganisationResponse, a, b, c),
      providesTags: ['organisation']
    }),
    updateOrganisation: builder.mutation<OrganisationResponse, RequestMutationWrapper<OrganisationResponse>>({
      query: (organisation) => HandleQuery({
        url: `${baseurl}/organisations/${organisation.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Organisation was updated!", toastErrorText: "Organisation could not be updated" }),
        method: "PATCH",
        body: organisation.data
      }),
      transformResponse: (a: OrganisationResponse, b, c) => transformSingleResponseToRightType(OrganisationResponse, a, b, c),
      invalidatesTags: ['organisations', 'organisation'],
    }),
    addOrganisation: builder.mutation<CreateResponse, RequestMutationWrapper<CreateOrganisationRequest>>({
      query: (organisation) => HandleQuery({
        url: `${baseurl}/organisations`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Organisation was created", toastErrorText: "Organisation could not be created" }),
        method: 'POST',
        body: {
          name: organisation.data.name,
          description: organisation.data.description,
          general_contact_information: organisation.data.general_contact_information,
          fallback_email: organisation.data.fallback_email,
          hosting_sites: organisation.data.hosting_sites,
          routers: organisation.data.routers,
          contacts: [],
          service_desk_email: organisation.data.service_desk_email,
          service_desk_telephone_number: organisation.data.service_desk_telephone_number,
          hotline_telephone_number: organisation.data.hotline_telephone_number
        }
      }),
      transformResponse: (a: CreateOrganisationRequest, b, c) => transformSingleResponseToRightType(CreateOrganisationRequest, a, b, c),
      invalidatesTags: ['organisations'],
    }),
    deleteOrganisation: builder.mutation<void, RequestMutationWrapper<OrganisationResponse>>({
      query: (agreement) => HandleQuery({
        url: `${baseurl}/organisations/${agreement.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Organisation was deleted!", toastErrorText: "Organisation could not be deleted" }),
      }),
      invalidatesTags: ['organisations']
    }),
  }),
})


export const { useGetAllOrganisationsQuery, useGetAllAllowedOrganisationsQuery, useGetOrganisationByIdQuery, useLazyGetOrganisationByIdQuery, useDeleteOrganisationMutation, useUpdateOrganisationMutation, useAddOrganisationMutation } = organisationApiSlice