import { Close } from "@mui/icons-material";
import { Box, Dialog, Grid, IconButton, MenuItem } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";

interface DialogMenuItemProps {
    buttonContent: JSX.Element,
    children: any
    open: boolean
    disabled?: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
    variant?: "text" | "outlined" | "contained",
}

export default function DialogMenuItem(props: DialogMenuItemProps) {
    const [open, setOpen] = React.useState(props.open ?? false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <MenuItem {...props} onClick={handleClickOpen}>
                {props.buttonContent}
            </MenuItem>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
            >
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <Box padding={2}>
                            {props.children}
                        </Box>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}