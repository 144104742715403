import { LoadingButton } from '@mui/lab';
import { Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import TooltipComponent from './TooltipComponent';
import { Dispatch, SetStateAction } from "react";

export interface ConfirmationButtonProps {
    action?: () => Promise<void>
    title: string | JSX.Element
    type?: "button" | "submit" | "reset"
    buttonText: JSX.Element | string
    fullWidth: boolean
    disabled?: boolean
    maxWidth?: false | Breakpoint | undefined
    children?: JSX.Element
    variant: "outlined" | "contained" | "text"
    asMenuItem?: boolean
    showButtons?: boolean
    childrenOpen?: boolean
    setChildrenOpen?: Dispatch<SetStateAction<boolean>>

}

export interface State {
    openConfirmationBox: boolean
    doingAction: boolean
}

export const ConfirmationButton = (props: ConfirmationButtonProps) => {

    const { t } = useTranslation()
    const [openConfirmationBox, setOpenConfirmationBox] = React.useState(false)
    const [doingAction, setDoingAction] = React.useState(false)

    async function doAction(): Promise<void> {
        setDoingAction(true)

        try {
            if (props.action)
                await props.action();

            setOpenConfirmationBox(false)
        } catch (error) {
            console.error(error)
        }

        setDoingAction(false)
    }

    function OpenVerificationBox(): void {
        setOpenConfirmationBox(true)
        props.setChildrenOpen?.(true)
    }
    function CloseVerificationBox(): void {
        setOpenConfirmationBox(false)
        props.setChildrenOpen?.(false)
    }

    const buttonProps = {
        disabled: props.disabled,
        fullWidth: props.fullWidth,
        onClick: () => OpenVerificationBox(),
        color: "warning" as const,

    }

    return (
        <>
            <TooltipComponent placement="left" title={props.title}>
                {props.asMenuItem ?
                    <MenuItem {...buttonProps} color="">{props.buttonText}</MenuItem> :
                    <Button variant={props.variant}{...buttonProps} >{props.buttonText}</Button>
                }
            </TooltipComponent>
            <Dialog
                fullWidth={props.fullWidth}

                open={openConfirmationBox && (props.childrenOpen === undefined || props.childrenOpen)}
                onClose={() => CloseVerificationBox()}
                aria-labelledby="alert-dialog-title"
                maxWidth={props.maxWidth}
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.children}
                    </DialogContentText>
                </DialogContent>
                {props.showButtons == undefined || props.showButtons ?
                    <DialogActions>
                        <Button className="decline__button" onClick={() => CloseVerificationBox()} autoFocus>{t("No") + ""}</Button>
                        <LoadingButton loading={doingAction} type={props.type} className="accept__button" color="primary" variant="contained" onClick={async () => await doAction()} >
                            {t("Yes") + ""}
                        </LoadingButton>
                    </DialogActions> : <></>
                }
            </Dialog>
        </>
    )
}

ConfirmationButton.defaultProps = {
    color: "inherit",
    variant: "outlined",
    className: "",
    fullWidth: false
};