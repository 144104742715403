import { SvgIconTypeMap } from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, MutationDefinition } from "@reduxjs/toolkit/dist/query"
import { UseMutation } from "@reduxjs/toolkit/dist/query/react/buildHooks"
import { RequestMutationWrapper } from "../models/API/RequestMutationWrapper"
import ErrorCodeHook from "./DTO/ErrorCodeHook"
import { FieldEditorRender } from "./Forms/Renders/FieldEditorRender"
import { TextRenderer } from "./Forms/Renders/TextRenderer"

export class CreateButtonProps<T> {
    organisation_id!: string
    fieldEditorRenders!: FieldEditorRender[]
    toEdit?: T
}

export class CreateInCollectionButtonProps<T> {
    open?: boolean
    buttonJsxOverride?: JSX.Element
    ressourceOwnerId!: string
    fieldEditorRenders!: FieldEditorRender[]
    toEdit?: T
    createNew!: () => T
    afterSaveAction?: (response: unknown) => void
    onOpen?: () => void
    onClose?: () => void
    tooltip!: string
    buttonText?: string
    validationScheme: any
    disabled?: boolean
    icon?: OverridableComponent<SvgIconTypeMap<object, "svg">>
    errorToMutation?: ErrorCodeHook<T>[]
    saveMutation!: UseMutation<MutationDefinition<RequestMutationWrapper<T>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, any, any>>
}

export function normaliseFieldrenders(fieldEditorRenders: FieldEditorRender[]): FieldEditorRender[] {
    const modifiedFieldRenders: FieldEditorRender[] = [];
    fieldEditorRenders?.forEach(fieldRender => {
        const modified = fieldRender;
        modified.fieldName = modified.nestedfieldName ?? modified.fieldName;
        modified.nestedfieldName = undefined;
        if (modified instanceof TextRenderer && modified.extraText) {
            modified.extraText.fieldName = modified.extraText.nestedfieldName ?? modified.extraText.fieldName;
            modified.extraText.nestedfieldName = undefined
        }
        modifiedFieldRenders.push(modified);
    })
    return modifiedFieldRenders;
}