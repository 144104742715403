import { Grid, Stack, Typography } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { OrganisationBadge } from "../components/Badges/OrganisationBadge"
import { OrganisationContext } from "../components/Layout/OrganisationPicker"
import Loading from "../components/loading"
import { ClientList } from "../feature/ClientApi/clientList"
import { CreateClientButton } from "../feature/ClientApi/CreateClientButton"
import { useGetAllClientsQuery, useGetClientsByOrganisationIdQuery } from "../feature/ClientApi/redux/clientsApiSlice"
import { UserContext } from "../feature/UserApi/logic/FetchUser"

export const ClientsPage = () => {
    const { t } = useTranslation();
    const currentOrganisation = useContext(OrganisationContext)?.organisation;
    const userContext = useContext(UserContext);
    if (!currentOrganisation && !userContext?.allowEmptyOrganisation())
        return <Loading />

    return (

        <Grid container spacing={5}>
            <Grid item xs={6}>
                <Stack direction="row" spacing={2}>
                    {currentOrganisation ? <OrganisationBadge ressource={currentOrganisation} /> : <></>}
                    <Typography variant="h5">{t("Clients") + ""}</Typography>
                    <CreateClientButton />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <ClientList idToFetch={currentOrganisation?.uuid} getClients={currentOrganisation ? useGetClientsByOrganisationIdQuery : useGetAllClientsQuery} />
            </Grid>
        </Grid>

    )
}