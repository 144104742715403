import { Alert, Autocomplete, Backdrop, Button, Chip, Stack, TextField, Typography } from "@mui/material";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUseAuthorizationMutation } from "../../feature/AuthorisationApi/redux/authroisationApiSlice";
import { UserContext } from "../../feature/UserApi/logic/FetchUser";
import { UserAuthorisationResponse } from "../../models/User";
import TooltipComponent from "../TooltipComponent";
import Loading from "../loading";
import { getRoleIcon } from "./AuthorisationMenu";

interface IUserAuthorisationContext {
    userAuthorisationInUse: UserAuthorisationResponse | undefined
    setUserAuthorisationInUse: (newAuthorisation: UserAuthorisationResponse | undefined) => void
}

export const AuthorisationContext = createContext<IUserAuthorisationContext | undefined>(undefined);

export const AuthorisationSelector = () => {
    const [isLoadingAuthorisation, setIsLoadingAuthorisation] = useState(false);
    const initialUserLoggedIn = useContext(UserContext);
    const useAuthorisation = useUseAuthorizationMutation()[0]
    const authorisationContext = useContext(AuthorisationContext);
    const { t } = useTranslation();

    async function changeAuthorisation(newAuthorisation: UserAuthorisationResponse | null) {
        const newAuth = newAuthorisation == null ? undefined : newAuthorisation
        if (newAuth) {
            setIsLoadingAuthorisation(true)
            await useAuthorisation({ data: newAuth.uuid })
                .unwrap()
                .then(() => authorisationContext?.setUserAuthorisationInUse(newAuth))
                .finally(() => setIsLoadingAuthorisation(false))
        }
    }

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoadingAuthorisation}>
                <Alert icon={<></>} severity="info"><Stack direction="row" spacing={2} alignItems="center"><Loading /> <Typography>{t("Loading authorisation...")}</Typography></Stack></Alert>
            </Backdrop>

            <Stack direction="row">
                <Autocomplete
                    disabled={authorisationContext?.userAuthorisationInUse != undefined}
                    fullWidth
                    renderOption={(props, option) => (
                        <li {...props}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <TooltipComponent title={t(`${option.role}`)}>
                                    <Chip label={option.role && getRoleIcon(option.role)} />
                                </TooltipComponent>
                                <Typography>{option.organisation_name}</Typography>
                            </Stack>

                        </li>
                    )}
                    noOptionsText={t("You dont have any authorisations")}
                    getOptionLabel={(org) => org?.organisation_name ?? ""}
                    defaultValue={authorisationContext?.userAuthorisationInUse}
                    onChange={(_event, newAuthorisation) => changeAuthorisation(newAuthorisation)}
                    options={initialUserLoggedIn?.authorisations ?? []}
                    renderInput={(params) => {
                        return (
                            <TextField variant="standard" placeholder={t("Select authorisation")} {...params} />
                        )
                    }}
                    isOptionEqualToValue={(option, value) => option.uuid == value.uuid}
                />
                {authorisationContext?.userAuthorisationInUse && <Button onClick={() => { authorisationContext?.setUserAuthorisationInUse(undefined) }}>{t("Reset")}</Button>}

            </Stack>
        </>

    )
}