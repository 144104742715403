import { createTheme } from "@mui/material";

export const theme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: 5,
                    borderBottom: "1px solid #efefef"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: () => ({
                    "&.Mui-disabled": {
                        pointerEvents: "all"
                    },
                }),
            },
        },
    },
});