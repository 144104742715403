import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { AuthorisationToUseInHeader } from "../App";
import keycloak from "../feature/Keycloak/Keycloak";
import { UserAuthorisationResponse } from "../models/User";

export default function FetchDefaultBaseQuery() {



    return fetchBaseQuery({
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = keycloak.token
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
                headers.set('content-type', `application/json`);
            }

            const authorisationInUse = AuthorisationToUseInHeader as UserAuthorisationResponse
            if (authorisationInUse) {
                headers.set('authorisationUuid', authorisationInUse.uuid);
            }

            return headers;
        }
    })
}