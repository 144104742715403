import { Ability, AbilityBuilder } from "@casl/ability";
import { AuthUser } from "../../../models/AuthUser";

const defineAbility = (user: AuthUser) => {
    const { can, build } = new AbilityBuilder(Ability);
    
    user.permissions?.own_organisation_permissions?.forEach(permission => {
        //User
        can(permission, "all", { "uuid": user.uuid })
        can(permission, "all", { "organisation_uuid": user.organisation?.uuid })

        //Organisation
        can(permission, "all", { "uuid": user.organisation?.uuid })

        //Agreement
        can(permission, "all", { "client.organisation.uuid": user.organisation?.uuid })
        can(permission, "all", { "service.owner_organisation.uuid": user.organisation?.uuid })
        can(permission, "all", { "service.administrating_organisation.uuid": user.organisation?.uuid })

        //Service & Client
        can(permission, "all", { "owner_organisation.uuid": user.organisation?.uuid })
        can(permission, "all", { "organisation.uuid": user.organisation?.uuid })
        can(permission, "all", { "hosting_site.administrating_organisation.uuid": user.organisation?.uuid })
        can(permission, "all", { "hosting_site.owner_organisation.uuid": user.organisation?.uuid })

        //Hosting site
        can(permission, "all", { "administrating_organisation.uuid": user.organisation?.uuid })
    });

    user.permissions?.any_organisation_permissions?.forEach(permission => {
        can(permission, "all")
    });

    //can(PermissionEnum.UNKNOWN, "all")

    return build({
        detectSubjectType: obj => {
            const type = Object.getPrototypeOf(obj).constructor
            return type;
        }
    })
}

export default defineAbility;