import { FormikProps } from "formik";
import { t } from "i18next";

import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import { AutocompleteFieldRender } from "../../../components/Forms/Renders/AutocompleteFieldRender";
import { TextFieldRender } from "../../../components/Forms/Renders/TextFieldRender";
import { CriticalityEnum } from "../../../models/enums/CriticalityEnum";
import AgreementRequest from "../../../models/Agreement";
import { EasyFormSideBySide } from "../../../components/Forms/FormLayout";

export default function AgreementInfoStep(props: { formikProps: FormikProps<AgreementRequest>, formPropsStuf: any }) {


    return (
        <EasyFormSideBySide
            validateOnMount
            {...props.formikProps}
            {...props.formPropsStuf}
            columns={[
                new EasyFormColumn("", "", [
                    new AutocompleteFieldRender({
                        displayText: t("Criticality"),
                        fieldName: "criticality",
                        getOptionLabelFunc: (option: CriticalityEnum) => option.toString() ?? "",
                        options: Object.values(CriticalityEnum) ?? [],
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "Description", fieldName: "description", type: "text",
                        uniqueId: "no_id"
                    })
                ])
            ]}
            editMode={true}
        />
    )
}