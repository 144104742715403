import { Category } from "@mui/icons-material";
import { ServiceBase } from "../../models/Service";
import { OpenInNewTabButton } from "../OpenInNewTabButton";
import { BadgeProps, BaseBadge } from "./BaseBadge";


export function ServiceBadge(props: BadgeProps<ServiceBase>) {
    if (props.textOverride)
        return <BaseBadge {...props} content={<>{props.textOverride}</>} icon={Category} />

    const content = props?.ressource?.name
    const linkIcon = <BaseBadge deepContent {...props} content={<>{content}</>} icon={Category} />
    return <OpenInNewTabButton url={`/services/${props?.ressource?.uuid}`} icon={linkIcon} padding={0} />
}