import { GppMaybe } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { CriticalityEnum } from "../../models/enums/CriticalityEnum";
import { BadgeProps, BaseBadge } from "./BaseBadge";

export function CriticalityBadge(props: BadgeProps<CriticalityEnum>) {
    const { t } = useTranslation()

    const content = props.textOverride ?? props?.ressource
    return <BaseBadge {...props} content={<>{t(content+"")}</>} icon={GppMaybe} />
}