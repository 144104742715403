
import getEnvironment from '../../../env'
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper';
import { ServiceTagRequest, ServiceTagResponse } from '../../../models/ServiceTag';
import HandleQuery from '../../../redux/EndpointQueryHandler';
import { GetManyPackage } from '../../../redux/GetManyPackage';
import handleResponse from '../../../redux/handleResponse';
import { portalApiSlice } from '../../../redux/portalApiSlice';
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse';

const baseurl = getEnvironment().REACT_APP_API_BASEURL;

export const serviceTagApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServiceTagsByHostingsiteId: builder.query<ServiceTagResponse[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/hosting-sites/${pack.uuid}/servicetags`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Service tags could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: ServiceTagResponse[], b, c) => transformMultipleResponseToRightType(ServiceTagResponse, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'servicetags',
        ...result.map(({ uuid }) => ({ type: 'servicetags', uuid: uuid }) as const)
      ]
    }),
    updateServiceTag: builder.mutation<ServiceTagResponse, RequestMutationWrapper<ServiceTagRequest>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/servicetags/${request.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Service tag was updated!", toastErrorText: "Service tag could not be updated" }),
        method: "PATCH",
        body: request.data
      }),
      transformResponse: (a: ServiceTagResponse, b, c) => transformSingleResponseToRightType(ServiceTagResponse, a, b, c),
      invalidatesTags: (_result, _error, args) => [{ type: 'servicetags', uuid: args.data.uuid }]
    }),
    addServiceTag: builder.mutation<ServiceTagResponse, RequestMutationWrapper<ServiceTagRequest>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/hosting-sites/${request.data.hostingsite?.uuid}/servicetags`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Service tag was created", toastErrorText: "Service tag could not be created" }),
        method: 'POST',
        body: request.data,
      }),
      transformResponse: (a: ServiceTagResponse, b, c) => transformSingleResponseToRightType(ServiceTagResponse, a, b, c),

      invalidatesTags: ['servicetags'],
    }),
    deleteServiceTag: builder.mutation<void, RequestMutationWrapper<ServiceTagRequest>>({
      query: (service) => HandleQuery({
        url: `${baseurl}/hosting-sites/${service.data.hostingsite?.uuid}/servicetags`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Service tag was deleted!", toastErrorText: "Service tag could not be deleted" }),
      }),
      
      invalidatesTags: (_result, _error, args) => [{ type: 'servicetags', uuid: args.data.uuid }]
    }),
  }),
})


export const { useDeleteServiceTagMutation, useUpdateServiceTagMutation, useGetServiceTagsByHostingsiteIdQuery, useLazyGetServiceTagsByHostingsiteIdQuery, useAddServiceTagMutation } = serviceTagApiSlice
















