import { Box } from '@mui/material';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useTranslation } from 'react-i18next';
import { ClientInfoButton } from '../feature/ClientApi/clientInfoDialog/ClientInfoButton';
import Client from '../models/Client';
import { OrganisationResponse } from '../models/Organisation';
import { GetManyPackage } from '../redux/GetManyPackage';
import { ClientBadge } from './Badges/ClientBadge';
import { OrganisationBadge } from './Badges/OrganisationBadge';
import EasyFormColumn from './Forms/EasyFormColumn';
import EasyFormMany from './Forms/EasyFormMany';
import { FieldEditorRender } from './Forms/Renders/FieldEditorRender';
import { TextRenderer } from './Forms/Renders/TextRenderer';


interface ClientAssociationsComponentPackage {
    uuid: string
    getContactsByIdAction: UseQuery<QueryDefinition<GetManyPackage, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, Client[], any>>
    contactsTitle: string
}

export default function ClientAssociationsComponent(clientAssociationsComponentPackage: ClientAssociationsComponentPackage) {
    const { t } = useTranslation()

    const getClientRows = (clients: Client[]) => {
        const rows: Array<FieldEditorRender[]> = []
        clients?.forEach((client) => {
            const newRow: FieldEditorRender[] = [
                new TextRenderer({
                    displayText: t("Name"),
                    fieldName: "",
                    nestedfieldName: "",
                    uniqueId: client.uuid,
                    render: () => <ClientBadge ressource={client} />
                }),
                new TextRenderer({
                    displayText: "Organisation",
                    fieldName: "",
                    nestedfieldName: "hosting_site.owner_organisation",
                    uniqueId: client.uuid,
                    render: (organisation) => <OrganisationBadge ressource={organisation as OrganisationResponse} />
                })
            ]

            rows.push(newRow)
        })
        return rows;
    }

    return (
        <Box sx={{ width: "48%" }}>
            <EasyFormMany
                confirmDeletionText=""
                isAllowedToDelete={() => false}
                isAllowedToEdit={() => false}
                isAllowedToCreate={() => false}
                ressourceOwnerId={clientAssociationsComponentPackage.uuid}
                arrayPath=""
                idToFetch={clientAssociationsComponentPackage.uuid}
                getManyByIdAction={clientAssociationsComponentPackage.getContactsByIdAction}
                jsxAtButtons={(client) => (
                    <ClientInfoButton client={client} />
                )}
                SignupSchema={undefined}
                columns={[
                    new EasyFormColumn(clientAssociationsComponentPackage.contactsTitle, "", []),
                ]}
                getFieldRows={getClientRows}
            />
        </Box>
    )
}