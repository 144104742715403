import { Drawer, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { Dispatch, SetStateAction } from "react";

interface DrawerMenuItemProps {
    buttonContent: JSX.Element,
    children: any
    open: boolean
    disabled?: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
    variant?: "text" | "outlined" | "contained",
}

export default function DrawerMenuItem(props: DrawerMenuItemProps) {

    const handleClickOpen = () => {
        props.setOpen(true);
    };

    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <>
            <MenuItem {...props} onClick={handleClickOpen}>
                {props.buttonContent}
            </MenuItem>
            <Drawer
                anchor="bottom"
                PaperProps={{ style: { minHeight: "70%", maxHeight: "90%" } }}

                open={props.open}
                onClose={handleClose}>
                <Box padding={2}>
                    {props.children}
                </Box>


            </Drawer>

        </>
    )
}