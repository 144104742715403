import { ErrorCodeEnum } from "./ErrorCodeEnum"
import { ErrorCodeMessages } from "./ErrorCodeMessages"

export class Error {
    status: number
    path: string
    timestamp?: Date
    error_code?: ErrorCodeEnum
    message: string

    constructor(status: number, path: string, timestamp: Date, error_code?: ErrorCodeEnum) {
        this.status = status;
        this.path = path;
        this.timestamp = timestamp;
        this.error_code = error_code;
        this.message = ErrorCodeMessages[this.error_code ?? ErrorCodeEnum.GENERIC]
    }
}