import { Box } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationBadge } from '../../../../components/Badges/OrganisationBadge';
import EasyFormColumn from '../../../../components/Forms/EasyFormColumn';
import EasyFormMany from '../../../../components/Forms/EasyFormMany';
import { FieldEditorRender } from '../../../../components/Forms/Renders/FieldEditorRender';
import { TextRenderer } from '../../../../components/Forms/Renders/TextRenderer';
import { Role, roleToString, UserAuthorisationResponse } from '../../../../models/User';
import { AuthorisationDetailsButton } from '../../../AuthorisationApi/authorisationInfoDialog/AuthorisationDetailsButton';
import { useDeleteAuthorizationMutation, useGetAuthorizationsForUserQuery } from '../../../AuthorisationApi/redux/authroisationApiSlice';
import { PermissionEnum } from '../../../Permission/PermissionEnum';
import { AbilityContext } from '../../../UserApi/logic/Can';


interface AuthorisationsTabProps {
    userUuid: string
}

export default function AuthorisationsTab(props: AuthorisationsTabProps) {
    const { t } = useTranslation()
    const ability = useContext(AbilityContext)
    const getAuthorisationRows = (authorisations: UserAuthorisationResponse[] | undefined) => {
        const rows: Array<FieldEditorRender[]> = []
        authorisations?.forEach((authorisation) => {
            const newRow: FieldEditorRender[] = [
                new TextRenderer({
                    displayText: "Organisation",
                    fieldName: "",
                    nestedfieldName: "organisation_name",
                    uniqueId: authorisation.uuid,
                    render: (organisationName) => <OrganisationBadge ressource={{ name: organisationName as string, uuid: "" }} />
                }),
                new TextRenderer({
                    displayText: t("Role"),
                    fieldName: "",
                    nestedfieldName: "role",
                    uniqueId: authorisation.uuid,
                    render: (role) => <>{t(roleToString(role as Role))}</>
                }),
            ]

            rows.push(newRow)
        })
        return rows;
    }


    return (
        <Box sx={{ width: "48%" }}>
            <EasyFormMany
                confirmDeletionText="Are you sure you wish to delete this authorisation?"
                isAllowedToDelete={(authorisationResponse) => ability.can(PermissionEnum.ORGANISATION_WRITE, authorisationResponse)}
                isAllowedToEdit={() => false}
                isAllowedToCreate={() => false}
                ressourceOwnerId={props.userUuid}
                arrayPath=""
                idToFetch={props.userUuid}
                getManyByIdAction={useGetAuthorizationsForUserQuery}
                deleteMutation={useDeleteAuthorizationMutation}
                jsxAtButtons={(authorisation) => (
                    <AuthorisationDetailsButton authorisation_uuid={authorisation.uuid} />
                )}
                SignupSchema={undefined}
                columns={[
                    new EasyFormColumn("Authorisations", "", [])
                ]}
                getFieldRows={getAuthorisationRows}
                newButtonTooltip=""
            />
        </Box>
    )
}