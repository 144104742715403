import { FieldEditorRender, FieldEditorRenderPackage } from "./FieldEditorRender";
import { FieldRenderType } from "./FieldRenderType";

export class CheckboxFieldRenderPackage extends FieldEditorRenderPackage {
    render?: (checked: boolean) => JSX.Element
}
export class CheckboxFieldRender extends FieldEditorRender {
    render?: (checked: boolean) => JSX.Element

    constructor(checkboxFieldRenderPackage: CheckboxFieldRenderPackage) {
        super(FieldRenderType.Checkbox, checkboxFieldRenderPackage);
        this.render = checkboxFieldRenderPackage.render
    }
}