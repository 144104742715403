import { FormControlLabel, Switch } from "@mui/material";
import { FieldArray, FormikProps } from "formik";
import { TFunction, useTranslation } from "react-i18next";
import * as yup from 'yup';
import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import { FormType } from "../../../components/Forms/EasyFormMany";
import { EasyFormRow } from "../../../components/Forms/FormLayout";
import { FieldEditorRender } from "../../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender } from "../../../components/Forms/Renders/TextFieldRender";
import { DNSSuffix } from "../../../models/DNSSuffix";
import { CreateHostingSiteRequest } from "../../../models/Hostingsite";

export function getDnsSuffixStepValidation(allDnsSuffixes: DNSSuffix[], t: TFunction<"translation", undefined>, allowExistingDnsSuffixes: boolean) {
    const existingSuffixes = allDnsSuffixes.map(suffix => suffix.dns_suffix)
    return yup.object().shape({
        dns_suffixes: yup.array().of(yup.object().shape({
            dns_suffix: yup.string()
                .required(t('Required'))
                .test("unique_suffix", t("DNS suffix is already in use at another hosting site"), suffix => allowExistingDnsSuffixes || !existingSuffixes.includes(suffix))
        })).test("unique_suffixes", t("No duplicates allowed"), list => !list || list.length === new Set(list.map(suffix => suffix.dns_suffix)).size)
    })
}

export default function DnsSuffixStep(props: { formikProps: FormikProps<CreateHostingSiteRequest>, formPropsStuf: any }, allowExistingSuffixes: boolean, allowExistingSuffixesOnChange: (value: boolean) => void) {
    const { t } = useTranslation();

    function getRows(hostingSiteRequest: CreateHostingSiteRequest): Array<FieldEditorRender[]> {
        const rows: Array<FieldEditorRender[]> = []
        hostingSiteRequest.dns_suffixes?.forEach((dnsSuffix) => {
            const newRow: FieldEditorRender[] = [
                new TextFieldRender({
                    displayText: "DNS suffix",
                    showLabel: true,
                    fieldName: "dns_suffixes",
                    nestedfieldName: "dns_suffix",
                    type: "text",
                    columns: true,
                    uniqueId: dnsSuffix.uuid
                })
            ]
            rows.push(newRow)
        })
        return rows;
    }

    return (
        <>
            <FieldArray
                name="dns_suffixes"
                render={arrayHelpers => (
                    <EasyFormRow
                        confirmDeletionText={t("Are you sure you wish to remove this dns suffix?")}
                        {...props.formikProps}
                        {...props.formPropsStuf}
                        editModeAlways={true}
                        editable={true}
                        isDeleteable={true}
                        saveMutation={() => { throw "Dont save plz" }}
                        formType={FormType.LIST}
                        createNew={() => new DNSSuffix()}
                        editMode={true}
                        getFieldRows={(createHostingSiteRequest) => getRows(createHostingSiteRequest as CreateHostingSiteRequest)}
                        columns={[
                            new EasyFormColumn("DNS Suffixes", "", [])
                        ]}
                        arrayHelpers={arrayHelpers} />
                )
                }
            />
            <FormControlLabel
                control={<Switch />}
                checked={allowExistingSuffixes}
                onChange={(_, checked) => allowExistingSuffixesOnChange(checked)}
                label={t("Allow existing DNS suffixes")}
                labelPlacement="start" />
        </>
    )
}