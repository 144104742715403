import { Button, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../feature/UserApi/logic/FetchUser";
import { roleToString } from "../../models/User";

export const UserMenu = () => {

    const { t } = useTranslation()

    const keycloak = useKeycloak();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const initialUserLoggedIn = useContext(UserContext);

    const loggedInOrg = initialUserLoggedIn;


    return (
        <>

            <Button
                style={{ textTransform: "inherit" }}
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <Stack direction="row" spacing={2} alignItems="center" textAlign="left">
                    <Stack>
                        <Typography>{initialUserLoggedIn?.email}</Typography>
                        <Typography fontStyle="italic" variant="caption">{loggedInOrg?.organisation?.name ?? t("No organisation")}</Typography>
                    </Stack>
                </Stack>
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem>
                    <Typography variant="caption">{t(roleToString(initialUserLoggedIn?.role))}</Typography>
                </MenuItem>
                <MenuItem onClick={async () => await keycloak.keycloak.logout()}>{t("Log out") + ""} </MenuItem>
            </Menu>
        </>
    )
}