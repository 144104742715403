import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { OrganisationBadge } from "../../../../components/Badges/OrganisationBadge";
import EasyForm, { FormType } from "../../../../components/Forms/EasyForm";
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import { AutocompleteFieldRender } from "../../../../components/Forms/Renders/AutocompleteFieldRender";
import { TextRenderer } from "../../../../components/Forms/Renders/TextRenderer";
import { Role, UserAuthorisationResponse, getRolesUpTo, roleToString } from "../../../../models/User";
import { PermissionEnum } from "../../../Permission/PermissionEnum";
import { AbilityContext } from "../../../UserApi/logic/Can";
import { UserContext } from "../../../UserApi/logic/FetchUser";
import { useGetAuthorizationByIdQuery, useUpdateAuthorizationMutation } from "../../redux/authroisationApiSlice";
import { DateToFormattedString } from "../../../../components/Logic/DateLogic";


export interface InfoTabProps {
    authorisation_uuid: string
}

export default function InfoTab(props: InfoTabProps) {
    const { t } = useTranslation();
    const authorisation = useGetAuthorizationByIdQuery(props.authorisation_uuid).data
    const ability = useContext(AbilityContext);

    const userContext = useContext(UserContext);
    const possibleRoles = getRolesUpTo(userContext?.role)

    const SignupSchema = Yup.object().shape({
        role: Yup.string().nullable().required(t("Required"))
    });

    const getFirstRow = () => {
        return [
            new TextRenderer({
                displayText: "Organisation",
                fieldName: "organisation",
                uniqueId: props.authorisation_uuid,
                render: () => <OrganisationBadge ressource={{ uuid: authorisation?.organisation_uuid ?? '', name: authorisation?.organisation_name }} />
            }),
            new TextRenderer({
                displayText: "User organisation",
                fieldName: "user_organisation",
                uniqueId: props.authorisation_uuid,
                render: () => <OrganisationBadge ressource={{ uuid: authorisation?.parent_user_organisation_uuid ?? '', name: authorisation?.parent_user_organisation_name}} />
            }),
            new TextRenderer({
                displayText: "First name",
                fieldName: "firstname",
                uniqueId: props.authorisation_uuid,
                render: () => <>{authorisation?.parent_user_first_name}</>
            }),
            new TextRenderer({
                displayText: "Last name",
                fieldName: "lastname",
                uniqueId: props.authorisation_uuid,
                render: () => <>{authorisation?.parent_user_last_name}</>
            }),
            new TextRenderer({
                displayText: "Email",
                fieldName: "parent_user_email",
                uniqueId: props.authorisation_uuid,
                render: () => <>{authorisation?.parent_user_email}</>
            }),
            new AutocompleteFieldRender({
                displayText: "Role",
                fieldName: "role",
                getOptionLabelFunc: (option: Role) => roleToString(option) ?? "",
                options: possibleRoles,
                uniqueId: props.authorisation_uuid,
            }),
            new TextRenderer({
                displayText: "Latest activity",
                fieldName: "latest_activity",
                render: (content) => <> {DateToFormattedString(content as Date)}</>,
                uniqueId: props.authorisation_uuid,
            })
        ];
    }

    return (
        <EasyForm<UserAuthorisationResponse>
            isAllowedToEdit={(authorisation) => ability.can(PermissionEnum.ORGANISATION_WRITE, { uuid: authorisation?.organisation_uuid })}
            saveMutation={useUpdateAuthorizationMutation}
            getByIdAction={useGetAuthorizationByIdQuery}
            initialValue={authorisation}
            arrayPath=""
            SignupSchema={SignupSchema}
            columns={[
                new EasyFormColumn("Authorisation information", "", getFirstRow())
            ]}
            createNew={() => { throw "Authorisation should not be created" }}
            idToFetch={props.authorisation_uuid}
            getFieldRows={() => []}
            newButtonTooltip="Add new info"
            formType={FormType.INFORMATION}
            key={JSON.stringify([authorisation])}
        />
    )
}