import BaseModel from "./BaseModels/BaseModel"
import { DNSSuffix } from "./DNSSuffix"
import OrganisationResponse from "./Organisation"
import { Subnet } from "./Subnet"


export default class HostingsiteBase extends BaseModel {
    name?: string
    address?: string
    administrating_organisation?: OrganisationResponse
    owner_organisation?: OrganisationResponse
    sdn_v4_id?: string
    theft_mark?: string
    vlan_name?: string
}

export class CreateHostingSiteRequest extends BaseModel {
    name?: string
    address?: string
    subnets: Subnet[] = []
    dns_suffixes: DNSSuffix[] = []
    organisation?: OrganisationResponse
    sdn_v4_id?: string
    theft_mark?: string
    vlan_name?: string
}

export class HostingsiteResponse extends HostingsiteBase {

}