import { PlaylistAddCheckCircle } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import { Stack, Tab, Typography } from "@mui/material"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { ConfirmationButton } from '../../../components/ConfirmationButton'
import DrawerButton from "../../../components/DrawerButton"
import { EntityMetaDataComponent } from '../../../components/EntityMetaDataComponent'
import { CustomTabs } from '../../../components/Forms/CustomTabs'
import RessourceMenu from '../../../components/RessourceMenu'
import TabPanel from "../../../components/TabPanel"
import TooltipComponent from '../../../components/TooltipComponent'
import { PaginationQueryPackage } from "../../../models/API/QueryParams/PaginationQueryPackage"
import ServiceWindow from '../../../models/ServiceWindow'
import { PermissionEnum } from '../../Permission/PermissionEnum'
import { useGetServicesByServiceWindowIdQuery } from '../../ServiceApi/redux/serviceApiSlice'
import { AbilityContext } from '../../UserApi/logic/Can'
import { useDeleteServiceWindowMutation, useGetServiceWindowByIdQuery, useUpdateServiceWindowMutation } from '../redux/serviceWindowApiSlice'
import { ServiceTab } from './tabs/ServiceTab'
import { ServiceWindowTab } from './tabs/ServiceWindowTab'

export interface ServiceWindowInfoButttonProps {
    serviceWindowId: string
    openFromStart?: boolean
    onClose?: () => void
}

export interface ServiceWindowTabProps {
    serviceWindow: ServiceWindow
}

export const ServiceWindowInfoButton = (props: ServiceWindowInfoButttonProps) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(props.openFromStart ?? false)

    const getServiceWindowsResult = useGetServicesByServiceWindowIdQuery({ uuid: props.serviceWindowId!, pagination: new PaginationQueryPackage() })
    const numberOfPlanedServiceWindows = getServiceWindowsResult.data?.length;

    return (
        <DrawerButton
            open={open}
            setOpen={setOpen}
            onClose={props.onClose}
            buttonContent={
                <TooltipComponent title={(numberOfPlanedServiceWindows ?? "?") + " " + t("services effected")}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <InfoIcon />
                    </Stack >
                </TooltipComponent>} footerContent={
                    <EntityMetaDataComponent entity_uuid={props.serviceWindowId} />
                }>
            <ServiceWindowInfoContent serviceWindowId={props.serviceWindowId} afterDeleteAction={() => setOpen(false)} />
        </DrawerButton>
    )
}

export interface ServiceWindowInfoDialogProps {
    serviceWindowId: string
    afterDeleteAction: () => void
}

export const ServiceWindowInfoContent = (props: ServiceWindowInfoDialogProps) => {

    const servicesForWindowResult = useGetServicesByServiceWindowIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: props.serviceWindowId
    });

    const [value, setValue] = React.useState(0);
    const { t } = useTranslation()
    const ability = useContext(AbilityContext)
    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const labels = []
    const tabPanel = []
    const updateServicewindow = useUpdateServiceWindowMutation();
    const updateServicewindowTrigger = updateServicewindow[0];
    const { data } = useGetServiceWindowByIdQuery(props.serviceWindowId);

    if (data == undefined)
        return <></>

    labels.push(t("Info") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><ServiceWindowTab serviceWindow={data!} {...props} /></TabPanel>)

    labels.push(t("Services") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><ServiceTab  {...props} /></TabPanel>)

    const allOrganisationsForServices = new Set(servicesForWindowResult.data?.map(service => service.owner_organisation?.uuid));
    const organisationForAllService = allOrganisationsForServices.size == 1 ? servicesForWindowResult.data?.find(() => true) : undefined
    const startTime = new Date(data.start_time ?? 0).getTime();
    const endTime = new Date(data.end_time ?? 0).getTime();
    const serviceWindowIsInProgress = Date.now() > startTime && Date.now() < endTime;
    return (
        <>
            <CustomTabs value={value} onChange={handleChange} rightMenu={
                <RessourceMenu
                    isAllowedToDelete={() => ability.can(PermissionEnum.SERVICE_WRITE, organisationForAllService)}
                    nameOfRessource="service window"
                    primaryButtonText={t("Service window")}
                    idToFetch={data.uuid}
                    deleteMutation={useDeleteServiceWindowMutation}
                    getByIdAction={useGetServiceWindowByIdQuery}
                    afterDeleteAction={props.afterDeleteAction}>
                    {serviceWindowIsInProgress && <ConfirmationButton
                        asMenuItem
                        title={t("Finish service window")}
                        buttonText={<><PlaylistAddCheckCircle /> {t(`Finish service window`)}</>}
                        action={async () => {
                            await updateServicewindowTrigger({
                                parentId: props.serviceWindowId, data: {
                                    uuid: props.serviceWindowId,
                                    end_time: new Date()
                                }
                            })
                        }}
                    >
                        <Stack spacing={2}>
                            <Typography fontWeight="bold">{t("Are you sure you wish to finish the service window?")}</Typography>
                        </Stack>
                    </ConfirmationButton>}
                </RessourceMenu>
            }>
                {labels.map(label => (<Tab key={label} label={label} />))}
            </CustomTabs>
            {tabPanel.map(tabPanel => tabPanel)}
        </>
    )
}