import { useContext } from "react";
import EasyForm, { FormType } from "../../../../../components/Forms/EasyForm";
import EasyFormColumn from "../../../../../components/Forms/EasyFormColumn";
import { CheckboxFieldRender } from "../../../../../components/Forms/Renders/CheckboxFieldRender";
import { TextFieldRender } from "../../../../../components/Forms/Renders/TextFieldRender";
import OrganisationResponse from "../../../../../models/Organisation";
import { PermissionEnum } from "../../../../Permission/PermissionEnum";
import { AbilityContext } from "../../../../UserApi/logic/Can";
import { getOrganisationValidationScheme } from "../../../organisationSchemeValidation";
import { useGetOrganisationByIdQuery, useUpdateOrganisationMutation } from "../../../redux/organisationApiSlice";
import { OrganisationTabProps } from "../OrganisationInfoButton";

export default function InfoTab(props: OrganisationTabProps) {
    const ability = useContext(AbilityContext)
    return (
        <EasyForm
            isAllowedToEdit={(organisation) => ability.can(PermissionEnum.ORGANISATION_WRITE, organisation)}
            arrayPath=""
            SignupSchema={getOrganisationValidationScheme}
            saveMutation={useUpdateOrganisationMutation}
            getByIdAction={useGetOrganisationByIdQuery}
            columns={[
                new EasyFormColumn("Organisation information", "", [
                    new TextFieldRender({ displayText: "Name", fieldName: "name", type: "text", uniqueId: props.organisationId! }),
                    new TextFieldRender({ displayText: "Description", fieldName: "description", multiline: true, type: "text", uniqueId: props.organisationId! }),
                    new CheckboxFieldRender({ displayText: "BGP Activated", fieldName: "bgp_activated", uniqueId: props.organisationId! }),
                    new TextFieldRender({ displayText: "General contact email", fieldName: "fallback_email", type: "text", uniqueId: props.organisationId! }),
                    new TextFieldRender({ displayText: "General contact information", fieldName: "general_contact_information", multiline: true, type: "text", uniqueId: props.organisationId! }),
                    new TextFieldRender({ displayText: "Service desk email", fieldName: "service_desk_email", type: "text", uniqueId: props.organisationId! }),
                    new TextFieldRender({ displayText: "Service desk telephone number", fieldName: "service_desk_telephone_number", type: "tel",uniqueId: props.organisationId! }),
                    new TextFieldRender({ displayText: "Hotline telephone number", fieldName: "hotline_telephone_number", type: "tel", uniqueId: props.organisationId! }),
                ]),
                new EasyFormColumn("Service monitorering", "", [
                    new TextFieldRender({ displayText: "Email", fieldName: "service_monitoring_email", type: "email", uniqueId: props.organisationId! })
                ])
            ]}
            formType={FormType.INFORMATION}

            idToFetch={props.organisationId}
            getFieldRows={() => []}
            newButtonTooltip="Add new info"
            createNew={function (): OrganisationResponse {
                throw new Error("Function not implemented.");
            }}
        />
    )
}