
import getEnvironment from '../../../env'
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper'
import { AttachContactRequest, ContactRequest, ContactResponse, ContactUpdateResponse } from '../../../models/Contact'
import { CreateResponse } from '../../../redux/CreateResponse'
import HandleQuery from '../../../redux/EndpointQueryHandler'
import { GetManyPackage } from '../../../redux/GetManyPackage'
import handleResponse from '../../../redux/handleResponse'
import { portalApiSlice } from '../../../redux/portalApiSlice'
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse'

const baseurl = getEnvironment().REACT_APP_API_BASEURL;

export const contactApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getContactsByOrganisationId: builder.query<ContactResponse[], GetManyPackage>({
      query: (getManyPackage) => HandleQuery({
        url: `${baseurl}/organisations/${getManyPackage.uuid}/contacts`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Contacts could not be fetched" }),
        pagination: getManyPackage.pagination
      }),
      transformResponse: (a: ContactResponse[], b, c) => transformMultipleResponseToRightType(ContactResponse, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'organisation-contacts',
        ...result.map(({ uuid }) => ({ type: 'organisation-contacts', uuid: uuid }) as const)
      ]
    }),
    getAllContacts: builder.query<ContactResponse[], GetManyPackage>({
      query: (getManyPackage) => HandleQuery({
        url: `${baseurl}/contacts`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Contacts could not be fetched" }),
        pagination: getManyPackage.pagination
      }),
      transformResponse: (a: ContactResponse[], b, c) => transformMultipleResponseToRightType(ContactResponse, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'contacts',
        ...result.map(({ uuid }) => ({ type: 'contacts', uuid: uuid }) as const)
      ]
    }),
    getContactsByServiceId: builder.query<ContactResponse[], GetManyPackage>({
      query: (getManyPackage) => HandleQuery({
        url: `${baseurl}/services/${getManyPackage.uuid}/contacts`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Contacts could not be fetched" }),
        pagination: getManyPackage.pagination
      }),
      transformResponse: (a: ContactResponse[], b, c) => transformMultipleResponseToRightType(ContactResponse, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'service-contacts',
        ...result.map(({ uuid }) => ({ type: 'service-contacts', uuid: uuid }) as const)
      ]
    }),
    getContactsByClientId: builder.query<ContactResponse[], GetManyPackage>({
      query: (getManyPackage) => HandleQuery({
        url: `${baseurl}/clients/${getManyPackage.uuid}/contacts`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Contacts could not be fetched" }),
        pagination: getManyPackage.pagination
      }),
      transformResponse: (a: ContactResponse[], b, c) => transformMultipleResponseToRightType(ContactResponse, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'client-contacts',
        ...result.map(({ uuid }) => ({ type: 'client-contacts', uuid: uuid }) as const)
      ]
    }),
    getExternalContactsByOrganisationId: builder.query<ContactResponse[], GetManyPackage>({
      query: (getManyPackage) => HandleQuery({
        url: `${baseurl}/organisations/${getManyPackage.uuid}/external-contacts`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "External contacts could not be fetched" }),
        pagination: getManyPackage.pagination
      }),
      transformResponse: (a: ContactResponse[], b, c) => transformMultipleResponseToRightType(ContactResponse, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'organisation-contacts', 'contacts',
        ...result.map(({ uuid }) => ({ type: 'organisation-contacts', uuid: uuid }) as const)
      ]
    }),
    getAllExternalContacts: builder.query<ContactResponse[], GetManyPackage>({
      query: (getManyPackage) => HandleQuery({
        url: `${baseurl}/external-contacts`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "External contacts could not be fetched" }),
        pagination: getManyPackage.pagination
      }),
      transformResponse: (a: ContactResponse[], b, c) => transformMultipleResponseToRightType(ContactResponse, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'contacts',
        ...result.map(({ uuid }) => ({ type: 'contacts', uuid: uuid }) as const)
      ]
    }),
    getExternalContactById: builder.query<ContactResponse, string>({
      query: (uuid) => HandleQuery({
        url: `${baseurl}/external-contacts/${uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "External contact could no be fetched" }),
      }),
      transformResponse: (a: ContactResponse, b, c) => transformSingleResponseToRightType(ContactResponse, a, b, c),
      providesTags: (_result, _error, uuid) => [{ type: 'contact', uuid: uuid }]
    }),
    updateExternalContact: builder.mutation<ContactResponse, RequestMutationWrapper<ContactResponse>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/external-contacts/${request.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "External contact was updated!", toastErrorText: "External contact could not be updated" }),
        method: "PATCH",
        body: request.data
      }),
      transformResponse: (a: ContactResponse, b, c) => transformSingleResponseToRightType(ContactResponse, a, b, c),
      invalidatesTags: ['contact', 'contacts']
    }),
    deleteExternalContact: builder.mutation<void, RequestMutationWrapper<ContactResponse>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/external-contacts/${request.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "External contact was deleted!", toastErrorText: "External contact could not be deleted" }),
      }),
      invalidatesTags: ['contacts', 'contact']
    }),
    updateOrganisationContact: builder.mutation<ContactResponse, RequestMutationWrapper<ContactUpdateResponse>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/organisation-contacts/${request.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Contact was updated!", toastErrorText: "Contact could not be updated" }),
        method: "PATCH",
        body: {
          contact_label_uuids: request.data.labels?.map(label => label.uuid) ?? []
        }
      }),
      transformResponse: (a: ContactResponse, b, c) => transformSingleResponseToRightType(ContactResponse, a, b, c),
      invalidatesTags: ["organisation-contacts", 'contact']
    }),
    updateServiceContact: builder.mutation<ContactResponse, RequestMutationWrapper<ContactUpdateResponse>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/service-contacts/${request.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Contact was updated!", toastErrorText: "Contact could not be updated" }),
        method: "PATCH",
        body: {
          contact_label_uuids: request.data.labels?.map(label => label.uuid) ?? []
        }
      }),
      transformResponse: (a: ContactResponse, b, c) => transformSingleResponseToRightType(ContactResponse, a, b, c),
      invalidatesTags: ["service-contacts", 'contact']
    }),
    updateClientContact: builder.mutation<ContactResponse, RequestMutationWrapper<ContactUpdateResponse>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/client-contacts/${request.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Contact was updated!", toastErrorText: "Contact could not be updated" }),
        method: "PATCH",
        body: {
          contact_label_uuids: request.data.labels?.map(label => label.uuid) ?? []
        }
      }),
      transformResponse: (a: ContactResponse, b, c) => transformSingleResponseToRightType(ContactResponse, a, b, c),
      invalidatesTags: ["client-contacts", 'contact']
    }),
    addContact: builder.mutation<CreateResponse, RequestMutationWrapper<ContactRequest>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/organisations/${request.parentId}/external-contacts`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Contact was created", toastErrorText: "Contact could not be created" }),
        method: 'POST',
        body: request.data
      }),
      transformResponse: (a: CreateResponse, b, c) => transformSingleResponseToRightType(CreateResponse, a, b, c),
      invalidatesTags: ['organisation-contacts', 'contacts'],
    }),
    deleteContact: builder.mutation<void, RequestMutationWrapper<ContactResponse>>({
      query: (request) => HandleQuery({
        url: `organisations/${request.parentId}/contacts/${request.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Contact was deleted!", toastErrorText: "Contact could not be deleted" }),
      }),
      invalidatesTags: ['service-contacts', 'organisation-contacts', 'client-contacts', 'contacts', 'contact']
    }),
    addContactToService: builder.mutation<CreateResponse, RequestMutationWrapper<AttachContactRequest>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/services/${request.parentId}/contacts`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Contact was attached", toastErrorText: "Contact could not be attached" }),
        method: 'POST',
        body: {
          external_contact_uuid: request.data.contact?.user_uuid == undefined ? request.data.contact?.uuid : undefined,
          user_uuid: request.data.contact?.user_uuid,
          contact_label_uuids: request.data.labels?.map(label => label.uuid)
        }
      }),
      transformResponse: (a: CreateResponse, b, c) => transformSingleResponseToRightType(CreateResponse, a, b, c),
      invalidatesTags: ['service-contacts', 'contact']
    }),
    addContactToClient: builder.mutation<CreateResponse, RequestMutationWrapper<AttachContactRequest>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/clients/${request.parentId}/contacts`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Contact was attached", toastErrorText: "Contact could not be attached" }),
        method: 'POST',
        body: {
          external_contact_uuid: request.data.contact?.user_uuid == undefined ? request.data.contact?.uuid : undefined,
          user_uuid: request.data.contact?.user_uuid,
          contact_label_uuids: request.data.labels?.map(label => label.uuid)
        }
      }),
      transformResponse: (a: CreateResponse, b, c) => transformSingleResponseToRightType(CreateResponse, a, b, c),
      invalidatesTags: ['client-contacts', 'contact']
    }),
    addContactToOrganisation: builder.mutation<CreateResponse, RequestMutationWrapper<AttachContactRequest>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/organisations/${request.parentId}/contacts`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Contact was attached", toastErrorText: "Contact could not be attached" }),
        method: 'POST',
        body: {
          external_contact_uuid: request.data.contact?.user_uuid == undefined ? request.data.contact?.uuid : undefined,
          user_uuid: request.data.contact?.user_uuid,
          contact_label_uuids: request.data.labels?.map(label => label.uuid)
        }
      }),
      transformResponse: (a: CreateResponse, b, c) => transformSingleResponseToRightType(CreateResponse, a, b, c),
      invalidatesTags: ['organisation-contacts', 'contact']
    }),
    removeContactFromService: builder.mutation<void, RequestMutationWrapper<ContactResponse>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/service-contacts/${request.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Contact was removed from service!", toastErrorText: "Contact could not be removed" }),
      }),
      invalidatesTags: ['service-contacts', 'contact']
    }),
    removeContactFromOrganisation: builder.mutation<void, RequestMutationWrapper<ContactResponse>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/organisation-contacts/${request.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Contact was removed from organisation!", toastErrorText: "Contact could not be removed" }),
      }),
      invalidatesTags: ['organisation-contacts', 'contact']
    }),
    removeContactFromClient: builder.mutation<void, RequestMutationWrapper<ContactResponse>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/client-contacts/${request.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Contact was removed from client!", toastErrorText: "Contact could not be removed" }),
      }),
      invalidatesTags: ['client-contacts', 'contact']
    }),
  }),
})


export const { useGetContactsByOrganisationIdQuery, useAddContactToClientMutation, useRemoveContactFromOrganisationMutation, useGetExternalContactsByOrganisationIdQuery, useGetAllExternalContactsQuery, useGetExternalContactByIdQuery, useUpdateExternalContactMutation, useDeleteExternalContactMutation, useAddContactToOrganisationMutation, useAddContactToServiceMutation, useGetAllContactsQuery, useGetContactsByClientIdQuery, useGetContactsByServiceIdQuery, useDeleteContactMutation, useAddContactMutation, useRemoveContactFromClientMutation, useRemoveContactFromServiceMutation, useUpdateClientContactMutation, useUpdateOrganisationContactMutation, useUpdateServiceContactMutation } = contactApiSlice