import { Grid, Stack, Typography } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { OrganisationBadge } from "../components/Badges/OrganisationBadge"
import { DataGridId } from "../components/DatagridWrapper"
import { OrganisationContext } from "../components/Layout/OrganisationPicker"
import Loading from "../components/loading"

import { AgreementList } from "../feature/AgreementApi/agreementList"
import { CreateAgreementButton } from "../feature/AgreementApi/CreateAgreementButton"
import { useGetAgreementsByOrganisationIdAndServiceApproverQuery, useGetAgreementsByOrganisationIdQuery, useGetAllAgreementsQuery } from "../feature/AgreementApi/redux/agreementsApiSlice"
import { PermissionEnum } from "../feature/Permission/PermissionEnum"
import { AbilityContext, Can } from "../feature/UserApi/logic/Can"
import { UserContext } from "../feature/UserApi/logic/FetchUser"
import { ApprovalStatus } from "../models/enums/ApprovalStatus"
import BaseOrganisation from "../models/Organisation"

export const AgreementRequestsPage = () => {

    const { t } = useTranslation()
    const currentOrganisation = useContext(OrganisationContext)?.organisation;
    const userContext = useContext(UserContext);
    if (!currentOrganisation && !userContext?.allowEmptyOrganisation())
        return <Loading />
    
    return (
        <Grid container spacing={5}>
            <Grid item xs={6}>
                <Stack direction="row" spacing={2}>
                    {currentOrganisation ? <OrganisationBadge ressource={currentOrganisation} /> : <></>}
                    <Typography variant="h5">{t("Agreement requests") + ""}</Typography>
                    <Can I={PermissionEnum.CLIENT_WRITE} this={currentOrganisation}>
                        <CreateAgreementButton />
                    </Can>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <AgreementList dataGridId={DataGridId.AGREEMENT_REQUESTS_PENDING} defaultPagesize={10} statusList={[ApprovalStatus.PENDING]} idToFetch={currentOrganisation?.uuid} getAgreements={chooseAgreementQuery(currentOrganisation)} />
            </Grid>
            <Grid item xs={6}>
                <Stack direction="row" spacing={2}>
                    {currentOrganisation ? <OrganisationBadge ressource={currentOrganisation} /> : <></>}
                    <Typography variant="h5">{t("Dismissed agreement requests") + ""}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <AgreementList dataGridId={DataGridId.AGREEMENT_REQUESTS_DENIED} defaultPagesize={3} statusList={[ApprovalStatus.DENIED]} idToFetch={currentOrganisation?.uuid} getAgreements={chooseAgreementQuery(currentOrganisation)} />
            </Grid>
        </Grid>


    )
}

function chooseAgreementQuery(currentOrganisation: BaseOrganisation | undefined) {
    const ability = useContext(AbilityContext);
    const canReadFullList = ability.can(PermissionEnum.SERVICE_READ) || ability.can(PermissionEnum.CLIENT_READ)

    if (!canReadFullList) {
        return useGetAgreementsByOrganisationIdAndServiceApproverQuery
    }

    if (currentOrganisation) {
        return useGetAgreementsByOrganisationIdQuery
    }

    return useGetAllAgreementsQuery
}