import { ConnectWithoutContact, Warning } from "@mui/icons-material";
import { Card, CardContent, CardHeader, Divider, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EmptyPage from "../../components/EmptyPage";
import TooltipComponent from "../../components/TooltipComponent";
import Loading from "../../components/loading";
import getEnvironment from "../../env";
import { AgreementResponse } from "../../models/Agreement";
import { PortConnectionAnimation } from "./PortConnectionAnimation";
import { useGetAgreementByIdQuery } from "./redux/agreementsApiSlice";
import { PaginationQueryPackage } from "../../models/API/QueryParams/PaginationQueryPackage";
import { useGetEndpointsByServiceIdQuery } from "../ServiceApi/redux/endpointApiSlice";

interface ConnectionCardProps {
    agreementUuid: string
}
export function ConnectionCard(props: ConnectionCardProps) {
    const agreement = useGetAgreementByIdQuery(props.agreementUuid)
    if (agreement.isLoading)
        return <Loading />

    return (
        <ConnectionCardContent agreement={agreement.data!} />
    )
}

interface ConnectionCardContentProps {
    agreement: AgreementResponse
}
function ConnectionCardContent(props: ConnectionCardContentProps) {
    const { t } = useTranslation()

    const agreement = props.agreement;

    const serviceEndpoints = useGetEndpointsByServiceIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: agreement?.service?.uuid
    })    
    
    const ipAddresses = agreement.client_ip_addresses;

    if (serviceEndpoints.isLoading)
        return <Loading />

    const serviceEndpointsWithPorts = serviceEndpoints.data;
    if (serviceEndpointsWithPorts == undefined || serviceEndpointsWithPorts?.length == 0)
        return <EmptyPage icon={ConnectWithoutContact} overrideText="No endpoints in service" />

    return (
        <>
            {serviceEndpointsWithPorts.map(endpoint => {

                const serviceHasActivePorts = endpoint.ports.length != 0;
                return (
                    <Grid key={endpoint.uuid} item xs="auto">
                        <Card elevation={3} variant="outlined">
                            <CardHeader title={
                                <Stack direction="row" spacing={2}>
                                    {serviceHasActivePorts ? <></> : <TooltipComponent title={t("No allowed ports on service")}><Warning fontSize="large" color="warning" /></TooltipComponent>}
                                    <Typography variant="h5">
                                        {endpoint.dns_name + "." + endpoint.dns_suffix?.dns_suffix + "." + getEnvironment().REACT_APP_DSDN_DOMAIN}
                                    </Typography>
                                </Stack>
                            } />
                            <Divider />
                            <CardContent>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Stack textAlign="right">
                                        <Typography>{agreement?.client?.name}</Typography>
                                        {ipAddresses?.map(ip => (
                                            <Typography key={crypto.randomUUID()} fontSize="10pt">{ip}</Typography>
                                        ))}
                                    </Stack>
                                    <Stack minWidth={200} textAlign="center">
                                        <>
                                            {endpoint.ports.map(port => <PortConnectionAnimation key={port.uuid} status={agreement?.status} port={port} />)}

                                            {!serviceHasActivePorts ? <>{t("No allowed ports on service")}</> : <> </>}
                                        </>

                                    </Stack>
                                    <Stack textAlign="left">
                                        <Typography>{agreement?.service?.name}</Typography>
                                        <Typography fontSize="10pt">{endpoint.ip}</Typography>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                )
            })}
        </>
    )
}

