import { TextField, TextFieldProps, Typography } from "@mui/material";
import { t } from "i18next";

class ToggleableTextfieldProps {
    editMode = false;
    multiline = false;
    minWidth?: string | number = undefined;
    render?: (content: unknown) => JSX.Element
}

export default function ToggleableTextfield(props: TextFieldProps & ToggleableTextfieldProps) {
    if (props.editMode)
        return (<TextField variant={props.multiline ? "outlined" : "standard"} {...props} />)

    if (props.render)
        return props.render(props.defaultValue)
    return <>
        {props.defaultValue ?
            <Typography style={{ whiteSpace: 'pre-line', fontSize: '0.875rem' }}><>{props.defaultValue}</></Typography> :
            <Typography fontStyle="italic" variant="caption">{t("Missing") + ""}</Typography>
        }
    </>;
}