import * as Yup from 'yup';
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import { FieldEditorRender } from "../../../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import { IpAddress } from "../../../../models/Client";
import EasyFormMany from "../../../../components/Forms/EasyFormMany";
import { IsIpAddressInSubnet } from "../../../../components/Forms/Validations/IsIpAddressValidation";
import { AgreementTabProps } from "../AgreementInfoButton";
import { TextRenderer } from "../../../../components/Forms/Renders/TextRenderer";
import { useDeleteIpAddressMutation, useGetIpAddressesByAgreementQuery, useUpdateIpAddressMutation } from "../../../ClientApi/redux/ipAddressApiSlice";

export default function IpAddressesTab(props: AgreementTabProps) {


    const SignupSchema = Yup.object().shape({
        subnet: Yup.object().nullable().required(),
        ipaddress: IsIpAddressInSubnet("subnet")
    });
    const getRows = (ipAddresses: IpAddress[]) => {
        const rows: Array<FieldEditorRender[]> = []
        ipAddresses?.forEach((ipaddress) => {
            const newRow: FieldEditorRender[] = [
                new TextRenderer({
                    displayText: "Subnet",
                    fieldName: "",
                    nestedfieldName: "subnet",
                    uniqueId: ipaddress.uuid!
                }),
                new TextFieldRender({ displayText: "Address", fieldName: "", nestedfieldName: "ip_address", type: "text", uniqueId: ipaddress.uuid! })
            ]

            rows.push(newRow)
        })
        return rows;
    }


    return (
        <>
            <EasyFormMany
                confirmDeletionText="Are you sure you wish to delete this ip address?"
                isAllowedToDelete={() => false}
                isAllowedToEdit={() => false}
                isAllowedToCreate={() => false}
                ressourceOwnerId={props.agreementId}
                arrayPath=""
                getManyByIdAction={useGetIpAddressesByAgreementQuery}
                saveMutation={useUpdateIpAddressMutation}
                deleteMutation={useDeleteIpAddressMutation}
                SignupSchema={SignupSchema}
                idToFetch={props.agreementId}
                columns={[
                    new EasyFormColumn("Client ip-addresses", "", [])
                ]}
                getFieldRows={getRows}
            />
        </>
    )
}