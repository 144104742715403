import { FieldArray, FormikProps } from 'formik';
import { useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import EasyFormColumn from '../../../components/Forms/EasyFormColumn';
import { FormType } from '../../../components/Forms/EasyFormMany';
import { EasyFormRow } from '../../../components/Forms/FormLayout';
import { AutocompleteFieldRender } from '../../../components/Forms/Renders/AutocompleteFieldRender';
import { FieldEditorRender } from '../../../components/Forms/Renders/FieldEditorRender';
import { IpRender } from '../../../components/Forms/Renders/IpRender';
import { IsIpAddressInSubnet, subnetToStringArray } from '../../../components/Forms/Validations/IsIpAddressValidation';
import { PaginationQueryPackage } from '../../../models/API/QueryParams/PaginationQueryPackage';
import { CreateClientRequest, IpAddress } from '../../../models/Client';
import { Subnet } from '../../../models/Subnet';
import { useGetSubnetsByHostingsiteIdQuery } from '../../OrganisationApi/redux/subnetApiSlice';

export function getIpAddressValidationScheme(t: TFunction<"translation", undefined>) {
    return Yup.object().shape({
        ip_addresses: Yup.array().min(1, t("At least one ip address should be added")).of(Yup.object().shape({
            subnet: Yup.object().nullable().required(t("Required")),
            ipaddress: IsIpAddressInSubnet("subnet")
        }))
    });

}

export default function IpAddressStep(props: { formikProps: FormikProps<CreateClientRequest>, formPropsStuf: any }) {
    const { t } = useTranslation();
    const [selectedSubnets, setSelectedSubnets] = useState<Record<string, string>>({})
    const [ipOptions, setIpOptions] = useState<Record<string, string[]>>({})

    const hostingSiteUuid = props.formikProps.values.hosting_site?.uuid
    const subnets = useGetSubnetsByHostingsiteIdQuery(
        { uuid: hostingSiteUuid, pagination: new PaginationQueryPackage() },
        { skip: hostingSiteUuid === undefined });

    useMemo(() => {
        const updateIpOptions = { ...ipOptions }
        Object.values(selectedSubnets).forEach(subnet => {
            if (updateIpOptions[subnet] === undefined) {
                updateIpOptions[subnet] = subnetToStringArray(subnet)
            }
        })
        setIpOptions(updateIpOptions)
    }, [selectedSubnets])

    function getRows(createClientRequest: CreateClientRequest): Array<FieldEditorRender[]> {
        const rows: Array<FieldEditorRender[]> = []

        createClientRequest.ip_addresses?.forEach((ipAddress) => {
            const newRow: FieldEditorRender[] = [
                new AutocompleteFieldRender({
                    displayText: "SDN Subnet",
                    fieldName: "ip_addresses",
                    nestedfieldName: "subnet",
                    columns: "auto",
                    minWidth: 300,
                    showLabel: true,
                    options: subnets.data ?? [],
                    onChange: (subnet: Subnet) => {
                        const updatedSelectedSubnets = { ...selectedSubnets }
                        if (subnet?.subnet === undefined) {
                            delete updatedSelectedSubnets[ipAddress.uuid]
                        } else {
                            updatedSelectedSubnets[ipAddress.uuid] = subnet.subnet
                        }
                        setSelectedSubnets(updatedSelectedSubnets)
                    },
                    getOptionLabelFunc: (option: Subnet) => option.subnet ?? "",
                    uniqueId: ipAddress.uuid
                }),
                new IpRender({
                    fieldName: "ip_addresses",
                    nestedfieldName: "ipaddress",
                    options: ipOptions[selectedSubnets[ipAddress.uuid]] ?? [],
                    uniqueId: ipAddress.uuid,
                }),
            ]
            rows.push(newRow)
        })
        return rows;
    }

    return (
        <FieldArray
            name="ip_addresses"
            render={arrayHelpers => (
                <EasyFormRow
                    confirmDeletionText={t("Are you sure you wish to remove this ip address?")}
                    {...props.formikProps}
                    {...props.formPropsStuf}
                    editModeAlways={true}
                    editable={true}
                    isDeleteable={true}
                    saveMutation={() => { throw "Dont save plz" }}
                    formType={FormType.LIST}
                    createNew={() => new IpAddress()}
                    editMode={true}
                    getFieldRows={getRows}
                    columns={[
                        new EasyFormColumn("IP addresses", "", [])
                    ]}
                    arrayHelpers={arrayHelpers} />
            )
            }
        />
    )
}
