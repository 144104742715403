import { Gite } from "@mui/icons-material";
import { BadgeProps, BaseBadge } from "./BaseBadge";
import HostingsiteBase from "../../models/Hostingsite";
import { OpenInNewTabButton } from "../OpenInNewTabButton";


export function HostingsiteBadge(props: BadgeProps<HostingsiteBase>) {
    if (props.textOverride)
        return <BaseBadge {...props} content={<>{props.textOverride}</>} icon={Gite} />

    const content = props?.ressource?.name
    const linkIcon = <BaseBadge deepContent {...props} content={<>{content}</>} icon={Gite} />
    return <OpenInNewTabButton url={`/hosting-sites/${props?.ressource?.uuid}`} icon={linkIcon} padding={0} />
}