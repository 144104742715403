import { AlternateEmail, Article, Build, Category, Contacts, ContentPasteGo, Gite, Laptop, Person, Restore, Router, SettingsEthernet } from "@mui/icons-material";
import BusinessIcon from '@mui/icons-material/Business';
import HandshakeIcon from '@mui/icons-material/Handshake';
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import { Button, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import getEnvironment from "../../env";
import { PermissionEnum } from "../../feature/Permission/PermissionEnum";
import { useGetServicesByOrganisationAndApproverQuery } from "../../feature/ServiceApi/redux/serviceApiSlice";
import { AbilityContext, Can } from "../../feature/UserApi/logic/Can";
import { UserContext } from "../../feature/UserApi/logic/FetchUser";
import { PaginationQueryPackage } from "../../models/API/QueryParams/PaginationQueryPackage";
import OrganisationPicker, { OrganisationContext } from "./OrganisationPicker";

interface TopBarProps {
    drawerWidth: number
}

export const SideMenu = (props: TopBarProps) => {
    const { t } = useTranslation()
    const organisationContext = useContext(OrganisationContext)?.organisation
    const userContext = useContext(UserContext);
    const ability = useContext(AbilityContext);
    
    const isSdnReadOrAdmin = userContext?.allowEmptyOrganisation();


    const approverServicesResult = useGetServicesByOrganisationAndApproverQuery({
        pagination: new PaginationQueryPackage(),
        uuid: organisationContext?.uuid
    }, { skip: organisationContext === undefined });

    const approverServices = approverServicesResult.data ?? [];

    return (
        <Drawer
            sx={{
                width: props.drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: props.drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent"
            anchor="left"
        >

            <Button component={Link} to="/">
                <img style={{ height: "3.6em" }} src={"/medcom_logo.png"} alt="medcom" />
            </Button>

            <Divider />
            <List>
                <OrganisationPicker />
                <Can I={PermissionEnum.SERVICE_READ} a={organisationContext} >
                    <ListItem>
                        <ListItemButton component={Link} color="inherit" to="/services">
                            <ListItemIcon>
                                <Category />
                            </ListItemIcon>
                            <ListItemText primary={t("Services") + ""} />
                        </ListItemButton>
                    </ListItem>
                </Can>
                <Can I={PermissionEnum.CLIENT_READ} a={organisationContext} >
                    <ListItem>
                        <ListItemButton component={Link} color="inherit" to="/clients">
                            <ListItemIcon>
                                <Laptop />
                            </ListItemIcon>
                            <ListItemText primary={t("Clients") + ""} />
                        </ListItemButton>
                    </ListItem>
                </Can>
                {
                    (ability.can(PermissionEnum.SERVICE_READ, organisationContext) || ability.can(PermissionEnum.CLIENT_READ, organisationContext) || approverServices.length > 0) && 
                    <>
                    <ListItem>
                        <ListItemButton component={Link} color="inherit" to="/agreementrequests">
                            <ListItemIcon>
                                <ContentPasteGo />
                            </ListItemIcon>
                            <ListItemText primary={t("Agreement requests") + ""} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton component={Link} color="inherit" to="/agreements">
                            <ListItemIcon>
                                <HandshakeIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("Agreements") + ""} />
                        </ListItemButton>
                    </ListItem>
                    </>
                }
            
            <Can I={PermissionEnum.SERVICE_READ} a={organisationContext} >
                <ListItem>
                    <ListItemButton component={Link} color="inherit" to="/service-windows">
                        <ListItemIcon>
                            <Build />
                        </ListItemIcon>
                        <ListItemText primary={t("Service Windows") + ""} />
                    </ListItemButton>
                </ListItem>
            </Can>
            <Can I={PermissionEnum.HOSTING_SITE_READ} >
                <ListItem>
                    <ListItemButton component={Link} color="inherit" to="/hosting-sites">
                        <ListItemIcon>
                            <Gite />
                        </ListItemIcon>
                        <ListItemText primary={t("Hosting sites") + ""} />
                    </ListItemButton>
                </ListItem>
            </Can>
            <Can I={PermissionEnum.USER_READ} a={organisationContext} >
                <ListItem>
                    <ListItemButton component={Link} color="inherit" to="/users">
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary={t("Users") + ""} />
                    </ListItemButton>
                </ListItem>
            </Can>
            <Can I={PermissionEnum.ORGANISATION_READ} a={organisationContext} >
                <ListItem>
                    <ListItemButton component={Link} color="inherit" to="/authorisations">
                        <ListItemIcon>
                            <VerifiedUser />
                        </ListItemIcon>
                        <ListItemText primary={t("Authorisations") + ""} />
                    </ListItemButton>
                </ListItem>
            </Can>
            <Can I={PermissionEnum.EXTERNAL_CONTACT_READ} a={organisationContext} >
                <ListItem>
                    <ListItemButton component={Link} color="inherit" to="/contacts">
                        <ListItemIcon>
                            <AlternateEmail />
                        </ListItemIcon>
                        <ListItemText primary={t("Contacts") + ""} />
                    </ListItemButton>
                </ListItem>
            </Can>
            <Divider />
            <Can I={PermissionEnum.AUDIT_READ} >
                <ListItem>
                    <ListItemButton component={Link} color="inherit" to="/events">
                        <ListItemIcon>
                            <Restore />
                        </ListItemIcon>
                        <ListItemText primary={t("Events") + ""} />
                    </ListItemButton>
                </ListItem>
            </Can>
            {isSdnReadOrAdmin &&
                <ListItem>
                    <ListItemButton component={Link} color="inherit" to="/organisations">
                        <ListItemIcon>
                            <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("Organisations") + ""} />
                    </ListItemButton>
                </ListItem>
            }
            {isSdnReadOrAdmin &&
                <ListItem>
                    <ListItemButton component={Link} color="inherit" to="/portaliases">
                        <ListItemIcon>
                            <SettingsEthernet />
                        </ListItemIcon>
                        <ListItemText primary={t("Portaliases") + ""} />
                    </ListItemButton>
                </ListItem>
            }
            {isSdnReadOrAdmin &&
                <ListItem>
                    <ListItemButton component={Link} color="inherit" to="/ipinfo">
                        <ListItemIcon>
                            <Router />
                        </ListItemIcon>
                        <ListItemText primary={t("IP info") + ""} />
                    </ListItemButton>
                </ListItem>
            }
            {isSdnReadOrAdmin &&
                <ListItem>
                    <ListItemButton component={Link} color="inherit" to="/contactlabels">
                        <ListItemIcon>
                            <Contacts />
                        </ListItemIcon>
                        <ListItemText primary={t("Contactlabels") + ""} />
                    </ListItemButton>
                </ListItem>
            }
            <Divider />
            <>
                <ListItem>
                    <ListItemButton component={Link} color="inherit" to={getEnvironment().REACT_APP_SDN_DOCUMENTATION_LINK} target='_blank'>
                        <ListItemIcon>
                            <Article />
                        </ListItemIcon>
                        <ListItemText primary={t("Agreementportal documentation")} />
                    </ListItemButton>
                </ListItem>
            </>
        </List>
        </Drawer >
    )
}