import { GridColDef } from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import DatagridWrapper, { DataGridId } from '../../components/DatagridWrapper';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import { ContactLabel, LabelContextEnum } from '../../models/ContactLabel';
import { EditContactlabelButton } from './EditContactLabelButton';
import { useGetAllContactLabelsQuery } from './redux/contactLabelApiSlice';

export const ContactlabelList = () => {
    return (<ContactlabelListContent />)
}

const ContactlabelListContent = () => {
    const currentResult = useGetAllContactLabelsQuery({
        pagination: new PaginationQueryPackage()
    });

    if (currentResult.data === undefined)
        currentResult.data = []


    const getColumns = () => {

        const columns: GridColDef[] = [
            { field: "required", sortable: true, type: "boolean", headerName: t('Required'), flex: 0.5 },
            { field: "label", sortable: true, type: "string", headerName: t('Label'), flex: 1 },
            { field: "comment", sortable: true, type: "string", headerName: t('Comment'), flex: 2 },
            { field: "label_contexts", sortable: true, type: "string", headerName: t('Show at'), valueGetter: (label_contexts: LabelContextEnum[]) => label_contexts?.join(", "), renderCell: (params: { row: ContactLabel }) => params.row.label_contexts?.join(", "), flex: 3 },
            {
                field: 'events', sortable: false, hideable: false, type: "string", valueGetter: () => "", headerName: t('Actions'), minWidth: 220, renderCell: (params => {
                    return (
                        <EditContactlabelButton contactLabel={params.row} />)
                })
            }
        ];
        return columns
    }

    return (
        <DatagridWrapper
            dataGridId={DataGridId.CONTACT_LABELS}
            loading={currentResult.isLoading}
            rows={currentResult.data}
            columns={getColumns()}
        />
    )
}

