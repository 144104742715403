import { Grid } from "@mui/material";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, MutationDefinition, QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { UseMutation, UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { FieldArray, Form, Formik } from "formik";
import { useState } from "react";
import { PaginationQueryPackage } from "../../models/API/QueryParams/PaginationQueryPackage";
import { RequestMutationWrapper } from "../../models/API/RequestMutationWrapper";
import BaseModel from "../../models/BaseModels/BaseModel";
import { GetManyPackage } from "../../redux/GetManyPackage";
import Loading from "../loading";
import EasyFormColumn from "./EasyFormColumn";
import { EasyFormRowMany } from "./FormLayout";
import { FieldEditorRender } from "./Renders/FieldEditorRender";

export enum FormType {
    INFORMATION,
    LIST
}

export interface EasyFieldProps {
    values: any
    touched: any
    errors: any
    handleChange: any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export interface EasyFormManyProps<CustomType> {
    SignupSchema: any
    idToFetch: string
    requestData?: any
    ressourceOwnerId: string //If we are about to update an element in a collection owned by a specific fx organisation we set the ressourceOwnerId to the organisation-id
    editModeAlways?: boolean
    arrayPath: string
    columns: EasyFormColumn[]
    isAllowedToEdit: (entity: CustomType | undefined) => boolean
    isAllowedToCreate: (entity: CustomType | undefined) => boolean
    isAllowedToDelete: (entity: CustomType | undefined) => boolean

    getFieldRows: (organisation: CustomType[]) => Array<FieldEditorRender[]>
    createButtonJsx?: JSX.Element
    editButtonJsx?: (element: CustomType) => JSX.Element
    jsxAtButtons?: (element: CustomType) => JSX.Element

    newButtonTooltip?: string
    initialValue?: CustomType[]
    confirmDeletionText: string

    saveMutation?: UseMutation<MutationDefinition<RequestMutationWrapper<CustomType>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, CustomType, any>>
    deleteMutation?: UseMutation<MutationDefinition<RequestMutationWrapper<CustomType>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, void, any>>
    getManyByIdAction?: UseQuery<QueryDefinition<GetManyPackage, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, CustomType[], any>>
    afterSaveAction?: (organisation: CustomType) => void
    afterDeleteAction?: () => void

}


export default function EasyFormMany<CustomType extends BaseModel>(props: EasyFormManyProps<CustomType>) {
    let fetchedOrganisation: CustomType[] | undefined = undefined
    let isFetchingOrganisation = false
    if (props.initialValue == undefined) {
        const { data, isLoading } = props.getManyByIdAction!({
            pagination: new PaginationQueryPackage(),
            uuid: props.idToFetch,
            requestData: props.requestData
        })
        fetchedOrganisation = data;
        isFetchingOrganisation = isLoading
    }

    const [editModeTemp, setEditModeTemp] = useState(false);
    const [saveAction] = props.saveMutation ? props.saveMutation() : [undefined]
    const editMode = props.editModeAlways ?? editModeTemp;

    if (isFetchingOrganisation)
        return (<Loading />)

    return (
        <Formik
            key={JSON.stringify(fetchedOrganisation)}
            initialValues={props.initialValue ?? fetchedOrganisation as CustomType[]}
            validationSchema={props.SignupSchema}
            onSubmit={saveAction ? async (list) => {
                list.forEach(async element => {
                    await saveAction({ data: element, parentId: props.idToFetch }).unwrap().then(updatedOrg => {
                        setEditModeTemp(false)

                        if (props.afterSaveAction && updatedOrg)
                            props.afterSaveAction(updatedOrg);
                    })
                })
            } : () => {throw "Dont save plz"}}
        >

            {(formikProps) => {

                return (
                    <Form
                        onResetCapture={() => setEditModeTemp(false)}
                    >
                        <FieldArray
                            name={props.arrayPath}
                            render={() => (
                                <Grid spacing={10} columns={props.columns.length} container>
                                    <EasyFormRowMany {...formikProps} setEditModeTemp={setEditModeTemp} editMode={editMode} {...props} />
                                </Grid>
                            )
                            }
                        />
                    </Form >)
            }
            }


        </Formik >
    )
}