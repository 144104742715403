import { FormikProps } from "formik";
import * as Yup from 'yup';
import { ObjectShape, TypeOfShape } from "yup/lib/object";
import { t } from "i18next";
import { useContext } from "react";
import { OrganisationBadge } from "../../../components/Badges/OrganisationBadge";
import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import { EasyFormSideBySide } from "../../../components/Forms/FormLayout";
import { AutocompleteFieldRender } from "../../../components/Forms/Renders/AutocompleteFieldRender";
import { TextFieldRender } from "../../../components/Forms/Renders/TextFieldRender";
import { TextRenderer } from "../../../components/Forms/Renders/TextRenderer";
import { OrganisationContext } from "../../../components/Layout/OrganisationPicker";
import { PaginationQueryPackage } from "../../../models/API/QueryParams/PaginationQueryPackage";
import { CreateClientRequest } from "../../../models/Client";
import HostingsiteBase from "../../../models/Hostingsite";
import { useGetHostingsitesByOrganisationIdQuery } from "../../HostingSiteApi/hostingsitesApiSlice";
import { TFunction } from "react-i18next";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { GetManyPackage } from "../../../redux/GetManyPackage";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { subnetApiSlice } from "../../OrganisationApi/redux/subnetApiSlice";
import { Subnet } from "../../../models/Subnet";


function validateSubnets(input: TypeOfShape<ObjectShape>, subnetLazyTrigger: LazyQueryTrigger<QueryDefinition<GetManyPackage<object>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, string, Subnet[], "portalapi">>): Promise<boolean> {
    if (!input) {
        return new Promise((resolve, _) => resolve(true))
    }
    const hostingSite = input as unknown as HostingsiteBase

    return subnetLazyTrigger({
        pagination: new PaginationQueryPackage(),
        uuid: hostingSite?.uuid
    }, true).unwrap().then((result) => result.length != 0)
}

export function getInfoValidationScheme(t: TFunction<"translation", undefined>) {
    const subnetLazyTrigger = subnetApiSlice.useLazyGetSubnetsByHostingsiteIdQuery()[0]
    return Yup.object().shape({
        name: Yup.string().required(t("Required")),
        description: Yup.string().required(t("Required")),
        hosting_site: Yup.object().typeError(t("Required")).required(t("Required"))
            .test("has_subnets", t("Hosting site has no subnets"), (input) => validateSubnets(input, subnetLazyTrigger))
    });
}

export default function ClientInfoStep(props: { formikProps: FormikProps<CreateClientRequest>, formPropsStuf: any }) {
    const organisation = useContext(OrganisationContext)!.organisation;


    const allHostingsiteResults = useGetHostingsitesByOrganisationIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: organisation?.uuid
    });

    return (
        <EasyFormSideBySide
            validateOnMount
            {...props.formPropsStuf}
            {...props.formikProps}
            columns={[
                new EasyFormColumn("Information", "", [
                    new TextFieldRender({
                        displayText: "Client name", fieldName: "name", type: "text",
                        uniqueId: "no_id"
                    }),
                    new TextRenderer({
                        displayText: "Organisation",
                        fieldName: "organisation",
                        uniqueId: "no_id",
                        render: () => <OrganisationBadge ressource={organisation} />
                    }),
                    new AutocompleteFieldRender({
                        displayText: t("Hostingsite"),
                        fieldName: "hosting_site",
                        getOptionLabelFunc: (option: HostingsiteBase) => option.name ?? "",
                        options: allHostingsiteResults?.data ?? [],
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "Description",
                        fieldName: "description",
                        type: "text",
                        multiline: true,
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "Internal description",
                        fieldName: "internal_description",
                        multiline: true,
                        type: "text",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "General contact information",
                        fieldName: "general_contact_information",
                        type: "text",
                        multiline: true,
                        uniqueId: "no_id"
                    })
                ]),
            ]}
            editMode={true} />
    )
}