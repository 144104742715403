import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { CreateInCollectionButton } from "../../../../components/CreateInCollectionButton";
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import EasyFormMany from "../../../../components/Forms/EasyFormMany";
import { FieldEditorRender } from "../../../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import { IsIpAddressValidation } from "../../../../components/Forms/Validations/IsIpAddressValidation";
import { HostingsiteResponse } from "../../../../models/Hostingsite";
import { Subnet } from "../../../../models/Subnet";
import { useAddSubnetMutation, useDeleteSubnetMutation, useGetSubnetsByHostingsiteIdQuery, useUpdateSubnetMutation } from "../../../OrganisationApi/redux/subnetApiSlice";
import { PermissionEnum } from "../../../Permission/PermissionEnum";
import { AbilityContext } from "../../../UserApi/logic/Can";

interface SubnetTabProps {
    hostingSite: HostingsiteResponse
}

export default function SubnetTab(props: SubnetTabProps) {

    const { t } = useTranslation()
    const ability = useContext(AbilityContext)

    const SignupSchema = Yup.object().shape({
        subnet: IsIpAddressValidation(true),
        hosting_site: Yup.object().nullable().required(t("You must pick a hostingsite"))
    });

    const editSchema = Yup.object().shape({
        subnet: IsIpAddressValidation(true)
    });

    const getSubnetRows = (subnets: Subnet[] | undefined) => {
        const rows: Array<FieldEditorRender[]> = []
        subnets?.forEach((subnet) => {
            const newRow: FieldEditorRender[] = [

                new TextFieldRender({
                    displayText: "IP scope",
                    fieldName: "",
                    nestedfieldName: "subnet",
                    type: "text",
                    uniqueId: subnet.uuid!
                })
            ]

            rows.push(newRow)
        })
        return rows;
    }

    const getEditableSubnetRows = (subnets: Subnet[] | undefined) => {
        const rows: Array<FieldEditorRender[]> = []
        subnets?.forEach((subnet) => {
            const newRow: FieldEditorRender[] = [

                new TextFieldRender({
                    displayText: "IP scope",
                    fieldName: "",
                    nestedfieldName: "subnet",
                    type: "text",
                    uniqueId: subnet.uuid!
                })
            ]

            rows.push(newRow)
        })
        return rows;
    }

    return (
        <EasyFormMany
            confirmDeletionText="Are you sure you wish to delete this subnet?"
            isAllowedToDelete={() => ability.can(PermissionEnum.HOSTING_SITE_WRITE, props.hostingSite)}
            isAllowedToEdit={() => ability.can(PermissionEnum.HOSTING_SITE_WRITE, props.hostingSite)}
            isAllowedToCreate={() => ability.can(PermissionEnum.HOSTING_SITE_WRITE, props.hostingSite)}
            ressourceOwnerId={props.hostingSite.uuid}
            arrayPath=""
            getManyByIdAction={useGetSubnetsByHostingsiteIdQuery}
            saveMutation={useUpdateSubnetMutation}
            deleteMutation={useDeleteSubnetMutation}
            createButtonJsx={
                <CreateInCollectionButton
                    validationScheme={SignupSchema}
                    ressourceOwnerId={props.hostingSite.uuid}
                    fieldEditorRenders={getSubnetRows([new Subnet()]).find(() => true)!}
                    tooltip="Create subnet"
                    createNew={() => {
                        const newSubnet = new Subnet();
                        newSubnet.hosting_site = props.hostingSite!;
                        return newSubnet
                    }}
                    saveMutation={useAddSubnetMutation} />
            }
            editButtonJsx={(subnet: Subnet) =>
                <CreateInCollectionButton
                    validationScheme={editSchema}
                    toEdit={subnet}
                    createNew={() => { throw new Error("Dont create new here") }}
                    ressourceOwnerId={props.hostingSite.uuid}
                    fieldEditorRenders={getEditableSubnetRows([new Subnet()]).find(() => true)!}
                    tooltip="Edit subnet"
                    saveMutation={useUpdateSubnetMutation} />
            }

            SignupSchema={SignupSchema}
            idToFetch={props.hostingSite.uuid}
            columns={[
                new EasyFormColumn("Subnets", "", [])
            ]}
            getFieldRows={getSubnetRows}
            newButtonTooltip="Add new subnet"
        />
    )
}