import { Grid, Tabs, TabsProps } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/ban-types
export function CustomTabs(props: TabsProps & { rightMenu?: JSX.Element }) {
    return (
        <Grid container position="sticky" padding={2} top={0} sx={{ backgroundColor: "white", boxShadow: "0px 10px 15px -20px rgba(0, 0, 0, 0.45);", zIndex: 100 }}>
            <Grid item xs>
                <Tabs {...props}>
                    {props.children}
                </Tabs>
            </Grid>
            <Grid textAlign="right" item xs="auto">
                {props.rightMenu}
            </Grid>
        </Grid>
    )
}