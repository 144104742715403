import { FormikProps } from "formik";
import { TFunction } from "react-i18next";
import * as Yup from 'yup';
import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import { EasyFormSideBySide } from "../../../components/Forms/FormLayout";
import { CheckboxFieldRender } from "../../../components/Forms/Renders/CheckboxFieldRender";
import { TextFieldRender } from "../../../components/Forms/Renders/TextFieldRender";
import { CreateServiceRequest } from "../../../models/Service";

export function getServiceDetailedInfoValidationScheme(t: TFunction<"translation", undefined>) {
    return Yup.object().shape({
        sla: Yup.number().required(t('Required')).min(0).max(100),
        sla_description: Yup.string(),
        requires_approval: Yup.bool().required().default(false),
        service_window_rules: Yup.string().required(t('Required')),
        uses_encryption: Yup.boolean().default(false).required(),
        no_encryption_reason: Yup.string().when('uses_encryption', {
            is: (uses_encryption: boolean) => uses_encryption == false,
            then: Yup.string().required(t('Required'))
        })
    })
}

export default function ServiceDetailedInfoStep(props: { formikProps: FormikProps<CreateServiceRequest>, formPropsStuf: any }) {

    return (
        <EasyFormSideBySide
            {...props.formikProps}
            {...props.formPropsStuf}
            columns={[
                new EasyFormColumn("Service", "", [
                    new CheckboxFieldRender({
                        displayText: "Requires approval",
                        fieldName: "requires_approval",
                        uniqueId: "no_id"
                    }),

                    new TextFieldRender({
                        displayText: "SLA",
                        fieldName: "sla",
                        type: "number",
                        endAdornment: "%",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "SLA description",
                        fieldName: "sla_description",
                        type: "text",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "Service window rules",
                        fieldName: "service_window_rules",
                        type: "text",
                        uniqueId: "no_id"
                    }),
                    new CheckboxFieldRender({
                        displayText: "Uses encryption approval",
                        fieldName: "uses_encryption",
                        uniqueId: "no_id"
                    }),
                    new TextFieldRender({
                        displayText: "Reason for no encryption",
                        fieldName: "no_encryption_reason",
                        type: "text",
                        multiline: true,
                        uniqueId: "no_id",
                        conditionFieldName: "uses_encryption",
                        conditionShouldBeDisabledFunc: (uses_encryption: boolean) => uses_encryption == true
                    })
                ]),
            ]}
            editMode={true} />
    )
}