import InfoIcon from '@mui/icons-material/Info';
import { Tab } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import DrawerButton from "../../../components/DrawerButton";
import { EntityMetaDataComponent } from '../../../components/EntityMetaDataComponent';
import { CustomTabs } from '../../../components/Forms/CustomTabs';
import RessourceMenu from "../../../components/RessourceMenu";
import TabPanel from "../../../components/TabPanel";
import TooltipComponent from '../../../components/TooltipComponent';
import Portalias from "../../../models/Portalias";
import { PermissionEnum } from '../../Permission/PermissionEnum';
import { AbilityContext } from '../../UserApi/logic/Can';
import { useDeletePortaliasMutation, useGetPortaliasByIdQuery } from "../redux/portaliasApiSlice";
import ACLTab from "./tabs/ACLTab";
import InfoTab from "./tabs/InfoTab";

interface PortaliasInfoDialogProps {
    portalias: Portalias
    openFromStart?: boolean
}

export interface PortaliasTabProps {
    portaliasId: string,
    isPortaliasInUse: boolean
}

export const PortaliasInfoButton = (props: PortaliasInfoDialogProps) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(props.openFromStart ?? false)
    return (
        <DrawerButton open={open} setOpen={setOpen} buttonContent={
            <TooltipComponent title={t("Details") + ""}>
                <InfoIcon />
            </TooltipComponent>
        } footerContent={
            <EntityMetaDataComponent entity_uuid={props.portalias.uuid} />
        } >

            <PortaliasInfoContent {...props}></PortaliasInfoContent>
        </DrawerButton>
    )
}

export const PortaliasInfoContent = (props: PortaliasInfoDialogProps) => {

    const [value, setValue] = React.useState(0);
    const { t } = useTranslation()
    const { data } = useGetPortaliasByIdQuery(props.portalias.uuid!)
    const ability = useContext(AbilityContext)
    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (data == undefined)
        return <></>

    const labels = []
    const tabPanel = []

    labels.push(t("Info") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><InfoTab portaliasId={data.uuid!} isPortaliasInUse={data.in_use!} {...props} /></TabPanel>)
    labels.push(t("ACL") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><ACLTab portaliasId={data.uuid!} isPortaliasInUse={data.in_use!} {...props} /></TabPanel>)

    return (
        <>

            <CustomTabs value={value} onChange={handleChange} rightMenu={<RessourceMenu
                isAllowedToDelete={(data) => ability.can(PermissionEnum.GOD, data) && !data.in_use}
                nameOfRessource="portalias"
                primaryButtonText={t("Portalias") + ": " + data.name}
                idToFetch={data.uuid}
                deleteMutation={useDeletePortaliasMutation}
                getByIdAction={useGetPortaliasByIdQuery} />}>
                {labels.map(label => (<Tab key={label} label={label} />))}
            </CustomTabs>

            {tabPanel.map(tabPanel => tabPanel)}
        </>
    )
}