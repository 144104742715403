import { LockOpen, LockOutlined, LockReset, MailOutlined, Password, VpnLock } from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
import { Stack, Tab, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { UserBadge } from "../../../components/Badges/UserBadge";
import ClientAssociationsComponent from "../../../components/ClientAssociationsComponent";
import { ConfirmationButton } from "../../../components/ConfirmationButton";
import DrawerButton from "../../../components/DrawerButton";
import { EntityMetaDataComponent } from "../../../components/EntityMetaDataComponent";
import { CustomTabs } from "../../../components/Forms/CustomTabs";
import Loading from "../../../components/loading";
import { DateToFormattedString } from "../../../components/Logic/DateLogic";
import UseUrlParam from "../../../components/Logic/UseUrlProps";
import RessourceMenu from "../../../components/RessourceMenu";
import ServiceAssociationsComponent from "../../../components/ServiceAssociationsComponent";
import TabPanel from "../../../components/TabPanel";
import TooltipComponent from "../../../components/TooltipComponent";
import { useGetClientsByUserContactQuery } from "../../ClientApi/redux/clientsApiSlice";
import { PermissionEnum } from "../../Permission/PermissionEnum";
import { useGetServicesByApproverQuery, useGetServicesByUserContactQuery } from "../../ServiceApi/redux/serviceApiSlice";
import { AbilityContext } from "../logic/Can";
import { useDeleteUserMutation, useForceNewMFAForUserMutation, useForceNewPasswordForUserMutation, useGetUserByIdQuery, useSendWelcomeMailMutation, useUpdateSslVpnAccessForUserMutation, useUpdateUserMutation } from "../redux/userApiSlice";
import AuthorisationsTab from "./tabs/AuthorisationsTab";
import InfoTab from "./tabs/InfoTab";
import { UserCopyButton } from "./UserCopyButton";





export interface UserTabProps {
    userUuid: string
    openFromStart?: boolean
    onClose?: () => void
}

export const UserDetailsButton = (props: UserTabProps) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(props.openFromStart ?? false)
    return (
        <DrawerButton
            open={open}
            setOpen={setOpen}
            buttonContent={
                <TooltipComponent title={t("Details") + ""}>
                    <InfoIcon />
                </TooltipComponent>
            }
            footerContent={
                <EntityMetaDataComponent entity_uuid={props.userUuid} />
            }
            onClose={props.onClose}>

            <UserDetailsContent {...props}></UserDetailsContent>
        </DrawerButton>
    )
}

export const UserDetailsContent = (props: UserTabProps | UseUrlParam) => {

    const params = useParams();
    const urlPropsUuid = (props as UseUrlParam).shouldGetOrganisationFromUrl ? params["userUuid"] : "";
    const userPropsUuid = (props as UserTabProps).userUuid
    const userUuid = userPropsUuid ?? urlPropsUuid;

    const forceNewMFA = useForceNewMFAForUserMutation();
    const forceNewMFATrigger = forceNewMFA[0];


    const forceNewPassword = useForceNewPasswordForUserMutation();
    const forceNewPasswordTrigger = forceNewPassword[0];

    const updateUserKeycloakEnabledStatus = useUpdateUserMutation();
    const updateUserKeycloakEnabledStatusTrigger = updateUserKeycloakEnabledStatus[0];

    const updateSslVpnForUser = useUpdateSslVpnAccessForUserMutation();
    const updateSslVpnForUserTrigger = updateSslVpnForUser[0];

    const sendWelcomeMail = useSendWelcomeMailMutation();
    const sendWelcomeMailTrigger = sendWelcomeMail[0];

    const [value, setValue] = React.useState(0);
    const { t } = useTranslation()
    const { data } = useGetUserByIdQuery(userUuid);
    const ability = useContext(AbilityContext)

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (data == undefined)
        return <Loading />

    const labels = []
    const tabPanel = []

    labels.push(t("Info") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><InfoTab userUuid={data.uuid!} {...props} /></TabPanel>)
    if (ability.can(PermissionEnum.ORGANISATION_READ, data)) {
        labels.push(t("Authorisations") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><AuthorisationsTab userUuid={data.uuid!} {...props} /></TabPanel>)
    }
    if (ability.can(PermissionEnum.SERVICE_READ, data)) {
        labels.push(t("Service associations") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><ServiceAssociationsComponent
            getContactsByIdAction={useGetServicesByUserContactQuery} contactsTitle="Services with user as contact"
            getApproversByIdAction={useGetServicesByApproverQuery} approversTitle="Services with user as approver"
            uuid={data.uuid!} {...props} /></TabPanel>)
    }
    if (ability.can(PermissionEnum.CLIENT_READ, data)) {
        labels.push(t("Client associations") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><ClientAssociationsComponent
            getContactsByIdAction={useGetClientsByUserContactQuery} contactsTitle="Clients with user as contact"
            uuid={data.uuid!} {...props} /></TabPanel>)
    }

    return (
        <>
            <CustomTabs value={value} onChange={handleChange} rightMenu={
                <RessourceMenu
                    isAllowedToDelete={(data) => ability.can(PermissionEnum.USER_WRITE, data)}
                    nameOfRessource="user"
                    primaryButtonText={t("User") + ": " + data.email}
                    idToFetch={data.uuid}
                    deleteMutation={useDeleteUserMutation}
                    getByIdAction={useGetUserByIdQuery} >
                    <>
                        {ability.can(PermissionEnum.USER_WRITE, data) && <ConfirmationButton
                            asMenuItem
                            title={data.keycloak_enabled == null ? t("User does not exist in keycloak") : t("New password")}
                            disabled={data.keycloak_enabled == null}
                            buttonText={<><Password /> {t(`New password`)}</>}
                            action={async () => {
                                await forceNewPasswordTrigger({
                                    uuid: userUuid
                                }).unwrap()
                            }}
                        >
                            <Stack spacing={2}>

                                <Stack direction="row" spacing={2}>
                                    <Typography>{t("User")}:</Typography>
                                    <UserBadge ressource={data} />
                                </Stack>

                                <Typography>{t("Are you sure you wish to force the user to change password?")}</Typography>
                                <Typography fontSize={12}>{t("The user wil be prompted next time the user logs in, or in his current session, if the user is already logged in")}</Typography>
                            </Stack>
                        </ConfirmationButton>}

                        {ability.can(PermissionEnum.USER_WRITE, data) && <ConfirmationButton
                            asMenuItem
                            title={data.keycloak_enabled == null ? t("User does not exist in keycloak") : t("New two-factor")}
                            disabled={data.keycloak_enabled == null}
                            buttonText={<><LockReset /> {t(`New two-factor`)}</>}
                            action={async () => {
                                await forceNewMFATrigger({
                                    uuid: userUuid
                                }).unwrap()
                            }}
                        >
                            <Stack spacing={2}>

                                <Stack direction="row" spacing={2}>
                                    <Typography>{t("User")}:</Typography>
                                    <UserBadge ressource={data} />
                                </Stack>

                                <Typography>{t("Are you sure you wish to force the user to reconfigure two-factor?")}</Typography>
                                <Typography fontSize={12}>{t("The user wil be prompted next time the user logs in, or in his current session, if the user is already logged in")}</Typography>
                            </Stack>
                        </ConfirmationButton>}

                        {data.keycloak_enabled !== undefined && ability.can(PermissionEnum.USER_WRITE, data) && <ConfirmationButton
                            asMenuItem
                            title={data.keycloak_enabled == null ? t("User does not exist in keycloak") : data.keycloak_enabled ? t("Disable user") : t("Enable user")}
                            disabled={data.keycloak_enabled == null}
                            buttonText={data.keycloak_enabled ?
                                <><LockOutlined /> {t("Disable user")}</>
                                :
                                <><LockOpen /> {t("Enable user")}</>
                            }
                            action={async () => {
                                await updateUserKeycloakEnabledStatusTrigger({
                                    data: {
                                        uuid: userUuid,
                                        keycloak_enabled: !data.keycloak_enabled
                                    }
                                }).unwrap()
                            }}
                        >
                            <Stack spacing={2}>

                                <Stack direction="row" spacing={2}>
                                    <Typography>{t("User")}:</Typography>
                                    <UserBadge ressource={data} />
                                </Stack>

                                <Typography>{data.keycloak_enabled ? t("Are you sure you wish to disable the user?") : t("Are you sure you wish to enable the user?")}</Typography>

                            </Stack>
                        </ConfirmationButton>}

                        {data.ssl_vpn_access !== undefined && ability.can(PermissionEnum.GOD, data) && <ConfirmationButton
                            asMenuItem
                            title={data.keycloak_enabled == null ? t("User does not exist in keycloak") : data.ssl_vpn_access ? t("Disable SSL VPN") : t("Enable SSL VPN")}
                            disabled={data.keycloak_enabled == null}
                            buttonText={data.ssl_vpn_access ?
                                <><VpnLock /> {t("Disable SSL VPN")}</>
                                :
                                <><VpnLock /> {t("Enable SSL VPN")}</>
                            }
                            action={async () => {
                                await updateSslVpnForUserTrigger({
                                    uuid: userUuid,
                                    access: !data.ssl_vpn_access
                                }).unwrap()
                            }
                            }
                        >
                            <Stack spacing={2}>

                                <Stack direction="row" spacing={2}>
                                    <Typography>{t("User")}:</Typography>
                                    <UserBadge ressource={data} />
                                </Stack>

                                <Typography>{data.ssl_vpn_access ? t("Are you sure you wish to disable SSL VPN for the user?") : t("Are you sure you wish to enable SSL VPN for the user?")}</Typography>

                            </Stack>
                        </ConfirmationButton>}

                        {ability.can(PermissionEnum.USER_WRITE, data) && <ConfirmationButton
                            asMenuItem
                            title={data.welcome_mail_sent == null ? t("Send welcome mail") : t("Resend welcome mail")}
                            buttonText={<><MailOutlined /> {data.welcome_mail_sent == null ? t("Send welcome mail") : t("Resend welcome mail")}</>}
                            action={async () => {
                                await sendWelcomeMailTrigger(userUuid).unwrap()
                            }}
                        >
                            <Stack spacing={2}>

                                <Stack direction="row" spacing={2}>
                                    <Typography>{t("User")}:</Typography>
                                    <UserBadge ressource={data} />
                                </Stack>

                                <Typography>
                                    {data.welcome_mail_sent == null ?
                                        t("Are you sure you wish to send a welcome mail to the user?")
                                        :
                                        t("Are you sure you wish to resend a welcome mail to the user?")
                                    }
                                </Typography>
                                {data.welcome_mail_sent != null ?
                                    <Typography fontSize={12}>{t("Welcome mail was sent") + ": " + DateToFormattedString(data.welcome_mail_sent)}</Typography>
                                    :
                                    <></>
                                }
                            </Stack>
                        </ConfirmationButton>}

                        {ability.can(PermissionEnum.USER_WRITE, data) && <UserCopyButton user={data} />}
                    </>
                </RessourceMenu>
            }>
                {labels.map(label => (<Tab key={label} label={label} />))}
            </CustomTabs>
            {tabPanel.map(tabPanel => tabPanel)}
        </>
    )
}