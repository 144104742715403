import { FieldRenderType } from "./FieldRenderType"

export class FieldRenderPackage {
    displayText!: string
    uniqueId!: string
    columns?: "auto" | boolean | number
}

export default abstract class FieldRender {
    renderType: FieldRenderType
    displayText: string
    columns?: "auto" | boolean | number
    uniqueId: string

    constructor(renderType: FieldRenderType, pack: FieldRenderPackage) {
        this.renderType = renderType
        this.displayText = pack.displayText
        this.uniqueId = pack.uniqueId
        this.columns = pack.columns
    }
}