import BaseModel from "./BaseModels/BaseModel"

import HostingsiteBase from "./Hostingsite"

export class ServiceTagRequest extends BaseModel {
    name?: string
    hostingsite?: HostingsiteBase
}

export class ServiceTagResponse extends BaseModel {
    name?: string
}