import { ArrowRightAltOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { FormikProps } from "formik";
import { ClientBadge } from "../../../components/Badges/ClientBadge";
import { ServiceBadge } from "../../../components/Badges/ServiceBadge";
import AgreementRequest from "../../../models/Agreement";

export default function AgreementSimpleVisualiser(props: { formikprops: FormikProps<AgreementRequest> }) {
    const services = props.formikprops.values.services
    const clients = props.formikprops.values.clients
    const hideArrow = services == undefined || clients == undefined || clients.length == 0 || services.length == 0;
    return (
        <Stack alignItems="center" direction="row" spacing={1}>
            {clients?.map(client => (
                <ClientBadge key={client.uuid} ressource={client} />
            ))}
            {hideArrow ? <></> : <ArrowRightAltOutlined />}
            {services?.map(service => (
                <ServiceBadge key={service.uuid} ressource={service} />
            ))}
        </Stack>
    )
}
