import { TextField } from "@mui/material";
import { getIn } from "formik";
import ToggleableDesktopDatePicker from "../ToggleableDesktopDatePicker";
import { EasyFieldProps } from "./EasyForm";

interface EasyDateTimeProps {
    editMode: boolean
    fieldName: string
    includeTime: boolean
}

export default function EasyDateTime(props: EasyDateTimeProps & EasyFieldProps): JSX.Element {
    const value = getIn(props.values, props.fieldName)
    let inputFormat = "dd-MM-yyyy"
    if (props.includeTime)
        inputFormat += " HH:mm"

    return (
        <>
            <ToggleableDesktopDatePicker
                value={value ?? null} //If value is undefined, date will still show - If null, date will not show.. Jesus...
                shouldDisableTime={() => !props.includeTime}
                ampm={false}
                ampmInClock={false}
                onChange={(value) => props.setFieldValue(props.fieldName, value, true)}
                inputFormat={inputFormat}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        error={(props.submitCount > 0 || getIn(props.touched, props.fieldName)) && Boolean(getIn(props.errors, props.fieldName))}
                        helperText={(props.submitCount > 0 || getIn(props.touched, props.fieldName)) && getIn(props.errors, props.fieldName)}
                    />}
                editMode={props.editMode} />
        </>

    )
}