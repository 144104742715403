import { DataGridPro, DataGridProProps, GridColDef, GridColumnResizeParams, GridColumnVisibilityModel, GridDensityState, GridFilterModel, GridPinnedColumnFields, GridSortModel, GridToolbar } from "@mui/x-data-grid-pro";
import { daDK } from "@mui/x-data-grid/locales";
import { useMemo } from "react";

interface DatrgridWrapperProps {
    defaultPagesize?: number
    onRowClick?: (row: any) => void;
    columns: GridColDef[]
    dataGridId: DataGridId
    initialSortModel?: GridSortModel
    initialColumnVisibilityModel?: GridColumnVisibilityModel
    initialFilterModel?: GridFilterModel
}

export default function DatagridWrapper<R extends { uuid: string }>(props: DataGridProProps<R> & DatrgridWrapperProps & React.RefAttributes<HTMLDivElement>) {
    const currentDataGridConf = useMemo(() => {
        return loadDataGridConf().get(props.dataGridId) ?? new DataGridConf()
    }, [])

    const initialPageSize = currentDataGridConf.pageSize ?? props.defaultPagesize ?? 25
    const initialSortModel = currentDataGridConf.sortModel ?? props.initialSortModel
    const initialColumnVisibilityModel = currentDataGridConf.columnVisibilityModel ?? { uuid: false, ...props.initialColumnVisibilityModel }
    const initialPinnedColumns = currentDataGridConf.pinnedColumns
    const initialDensity = currentDataGridConf.density ?? "compact"

    const cols: GridColDef[] = [{ field: 'uuid', hide: true, flex: 3, width: undefined }, ...props.columns]
        .map((col) => ({
            ...col,
            flex: currentDataGridConf.columnWidths[col.field] ? undefined : col.flex,
            width: currentDataGridConf.columnWidths[col.field] ?? col.width
        }))

    return (
        <DataGridPro
            {...props}
            initialState={{
                pagination: {
                    paginationModel: { pageSize: initialPageSize, page: 0 },
                },
                sorting: {
                    sortModel: initialSortModel
                },
                columns: {
                    columnVisibilityModel: initialColumnVisibilityModel,
                },
                pinnedColumns: initialPinnedColumns,
                filter: {
                    filterModel: props.initialFilterModel
                },
                density: initialDensity
            }}
            disableRowSelectionOnClick
            pagination={true}
            pageSizeOptions={[25, 100, 200]}
            getRowId={(row) => row.uuid}
            autoHeight
            slots={{ toolbar: GridToolbar }}
            localeText={daDK.components.MuiDataGrid.defaultProps.localeText}
            slotProps={{
                toolbar: {
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: false, delimiter: ';', utf8WithBom: true, allColumns: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },

            }}
            columns={cols}
            onRowClick={(row) => {
                const selection = window.getSelection();
                if (selection?.type != "Range" && props.onRowClick) {
                    props.onRowClick(row);
                }
            }}
            onStateChange={(state) => {
                const previousConf = JSON.stringify(currentDataGridConf)
                currentDataGridConf.pageSize = state.pagination?.paginationModel?.pageSize
                currentDataGridConf.sortModel = state.sorting?.sortModel
                currentDataGridConf.columnVisibilityModel = state.columns?.columnVisibilityModel
                currentDataGridConf.pinnedColumns = state.pinnedColumns
                currentDataGridConf.density = state.density

                const confChanged = JSON.stringify(currentDataGridConf) != previousConf
                if (confChanged) {
                    saveDataGridConf(props.dataGridId, currentDataGridConf)
                }
            }}
            onColumnWidthChange={(widths: GridColumnResizeParams) => {
                const widthChanged = widths.width !== currentDataGridConf.columnWidths[widths.colDef.field]
                if (widthChanged) {
                    currentDataGridConf.columnWidths[widths.colDef.field] = widths.width
                    saveDataGridConf(props.dataGridId, currentDataGridConf)
                }
            }}

        />
    )
}

class DataGridConf {
    columnWidths: Record<string, number> = {}
    pageSize?: number
    sortModel?: GridSortModel
    columnVisibilityModel?: GridColumnVisibilityModel
    pinnedColumns?: GridPinnedColumnFields
    density?: GridDensityState
}

export enum DataGridId {
    AGREEMENTS = "AGREEMENTS",
    AGREEMENTS_SIMPLE = "AGREEMENTS_SIMPLE",
    AGREEMENT_REQUESTS_PENDING = "AGREEMENT_REQUESTS_PENDING",
    AGREEMENT_REQUESTS_DENIED = "AGREEMENT_REQUESTS_DENIED",
    AUTHORISATIONS = "AUTHORISATIONS",
    CLIENTS = "CLIENTS",
    CONTACT_LABELS = "CONTACT_LABELS",
    EXTERNAL_CONTACTS = "EXTERNAL_CONTACTS",
    EVENTS = "EVENTS",
    HOSTING_SITES = "HOSTING_SITES",
    IP_INFO = "IP_INFO",
    ORGANISATIONS = "ORGANISATIONS",
    PORTALIASES = "PORTALIASES",
    SERVICES = "SERVICES",
    SERVICES_SIMPLE = "SERVICES_SIMPLE",
    SERVICE_WINDOWS = "SERVICE_WINDOWS",
    USERS = "USERS",
}

function loadDataGridConf(): Map<DataGridId, DataGridConf> {
    const storedDataGridConf = localStorage.getItem("dataGridConf")
    const storedDataGrigConfObject = storedDataGridConf === null ? [] : JSON.parse(storedDataGridConf)
    const allDataGridConf: Map<DataGridId, DataGridConf> = new Map(storedDataGrigConfObject)
    return allDataGridConf
}

function saveDataGridConf(dataGridId: DataGridId, newConf: DataGridConf) {
    const allDataGridConf = loadDataGridConf();
    allDataGridConf.set(dataGridId, newConf);
    localStorage.setItem("dataGridConf", JSON.stringify(Array.from(allDataGridConf.entries())));
}
