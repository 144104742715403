import { AddCircle } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { t } from "i18next";
import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { CreateEntityButton } from "../../components/CreateEntityButton";
import EasyStepper from "../../components/EasyStepper";
import { OrganisationContext } from "../../components/Layout/OrganisationPicker";
import Loading from "../../components/loading";
import { Can } from "../../feature/UserApi/logic/Can";
import { PaginationQueryPackage } from "../../models/API/QueryParams/PaginationQueryPackage";
import { DNSSuffix } from "../../models/DNSSuffix";
import { CreateHostingSiteRequest } from "../../models/Hostingsite";
import { Subnet } from "../../models/Subnet";
import { CreateResponse } from "../../redux/CreateResponse";
import { useGetDnsSuffixesQuery } from '../OrganisationApi/redux/dnsSuffixApiSlice';
import { PermissionEnum } from "../Permission/PermissionEnum";
import HostingSiteInfoStep from "./createHostingSiteFlow/BaseInfo";
import DnsSuffixStep, { getDnsSuffixStepValidation } from "./createHostingSiteFlow/DnsSuffixStep";
import SubnetsStep, { getSubnetsValidationScheme } from "./createHostingSiteFlow/Subnets";
import { HostingSiteInfoContent } from "./hostingSiteInfoDialog/HostingSiteInfoButton";
import { useAddHostingsiteMutation } from "./hostingsitesApiSlice";
import { getHostingSiteValidationScheme } from "./hostingSiteSchemeValidation";

export const CreateHostingSiteButton = () => {

    const [open, setOpen] = useState(false)
    const [submittedHostingSite, setSubmittedHostingSite] = useState<CreateResponse | undefined>(undefined)
    const organisationContext = useContext(OrganisationContext)?.organisation

    return (
        <Can I={PermissionEnum.HOSTING_SITE_WRITE} a={organisationContext} >
            <CreateEntityButton tooltip="Create hosting site" open={open} setOpen={setOpen} onClose={() => setSubmittedHostingSite(undefined)} buttonIcon={<AddCircle />} >
                {submittedHostingSite == undefined ?

                    <CreateHostingSiteButtonContent afterSaveAction={(hostingSite) => {
                        setSubmittedHostingSite(hostingSite);
                    }} setOpen={setOpen} />
                    :
                    <HostingSiteInfoContent hostingSiteUuid={submittedHostingSite.uuid!} afterDeleteAction={() => setOpen(false)} />
                }
            </CreateEntityButton>
        </Can >
    )
}

export interface CreateHostingSiteButtonContentProps {
    afterSaveAction: (hostingSite: CreateResponse) => void
}

export const CreateHostingSiteButtonContent = (props: CreateHostingSiteButtonContentProps & { setOpen: Dispatch<SetStateAction<boolean>> }) => {
    const organisationContext = useContext(OrganisationContext);
    const addHostingSiteMutation = useAddHostingsiteMutation();
    const [initial, setInitial] = useState<CreateHostingSiteRequest>(new CreateHostingSiteRequest())
    const allDnsSuffixes = useGetDnsSuffixesQuery({
        pagination: new PaginationQueryPackage()
    });
    const [allowExistingDnsSuffixes, setAllowExistingDnsSuffixes] = useState<boolean>(false)


    useMemo(() => {
        const newHostingSite = new CreateHostingSiteRequest();
        newHostingSite.organisation = organisationContext!.organisation
        newHostingSite.subnets = [new Subnet()]
        newHostingSite.dns_suffixes = [new DNSSuffix()]
        setInitial(newHostingSite);
    }, [])

    if(allDnsSuffixes.isLoading || allDnsSuffixes.data === undefined) {
        return (<Loading />)
    }

    async function createHostingSite(values: CreateHostingSiteRequest) {
        await addHostingSiteMutation[0]({
            data: values
        }).unwrap()
            .then(result => props.afterSaveAction(result as CreateResponse))
    }

    function NextStepFunc(currentPage: number, _values: CreateHostingSiteRequest) {
        return currentPage + 1
    }
    function PreviousStepFunc(currentPage: number) {
        return currentPage - 1
    }

    return (
        <>
            <Box>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("Create hosting site") + ""}</Typography>

                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={10}>
                        <EasyStepper<CreateHostingSiteRequest>
                            nextPage={NextStepFunc}
                            previousPage={PreviousStepFunc}
                            submit={createHostingSite}
                            initialValue={initial}
                            stepValidations={[
                                getHostingSiteValidationScheme(),
                                getSubnetsValidationScheme(t),
                                getDnsSuffixStepValidation(allDnsSuffixes.data, t, allowExistingDnsSuffixes)
                            ]}
                            stepTitles={[
                                "Information",
                                "Subnets",
                                "DNS",
                            ]}
                            steps={[
                                HostingSiteInfoStep,
                                SubnetsStep,
                                (props: { formikProps: FormikProps<CreateHostingSiteRequest>; formPropsStuf: any; }) => DnsSuffixStep(props, allowExistingDnsSuffixes, setAllowExistingDnsSuffixes)
                            ]} />
                    </Grid>
                </Grid>

            </Box>
        </>
    )
}