import { InputAdornment } from "@mui/material";
import { getIn } from "formik";
import { HTMLInputTypeAttribute, useState } from "react";
import { useTranslation } from "react-i18next";
import ToggleableTextfield from "../ToggleableTextfield";
import { EasyFieldProps } from "./EasyForm";
interface EasyTextfieldProps {
    editMode: boolean
    fieldName: string
    key: string
    endAdornment?: string
    displayText?: string
    showLabel: boolean
    type: HTMLInputTypeAttribute
    render?: (content: unknown) => JSX.Element
    conditionFieldName?: string;
    conditionShouldBeDisabledFunc: (conditionFieldNameValue: any) => boolean
    multiline: boolean;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export default function EasyTextfied(props: EasyTextfieldProps & EasyFieldProps): JSX.Element {
    const value = getIn(props.values, props.fieldName)
    const [localValue, setLocalValue] = useState(value);
    const { t } = useTranslation();
    let disabled = false;
    if (props.conditionFieldName) {
        const disabledConditionFieldValue = getIn(props.values, props.conditionFieldName)
        disabled = props.conditionShouldBeDisabledFunc(disabledConditionFieldValue)
    }
    if (disabled && value !== "") {
        props.setFieldValue(props.fieldName, "");
    }

    function handleLocalChange(inputValue: string): void {
        if (inputValue != localValue) {
            setLocalValue(inputValue);
        }
    }

    return (
        <>
            <ToggleableTextfield
                editMode={props.editMode}
                InputProps={{
                    endAdornment: props.endAdornment ? <InputAdornment position="end">{props.endAdornment}</InputAdornment> : undefined,
                }}
                disabled={disabled}
                fullWidth
                minWidth={props.minWidth}
                key={props.key}
                label={props.showLabel ? t(props.displayText ?? "") + "" : undefined}
                type={props.type}
                id={props.fieldName}
                name={props.fieldName}
                render={props.render}
                defaultValue={disabled ? "" : value}
                onChange={(e) => handleLocalChange(e.target.value)}
                onBlur={() => props.setFieldValue(props.fieldName, localValue)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        props.setFieldValue(props.fieldName, localValue);
                    }
                }}
                multiline={props.multiline}
                error={disabled ? false : (props.submitCount > 0 || getIn(props.touched, props.fieldName)) && Boolean(getIn(props.errors, props.fieldName))}
                helperText={disabled ? undefined : (props.submitCount > 0 || getIn(props.touched, props.fieldName)) && getIn(props.errors, props.fieldName)}
            />
        </>

    )
}