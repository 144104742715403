import { Alert, Typography } from "@mui/material";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContactResponseView } from "../../feature/ContactsApi/ContactForm";
import { contactLabelsApiSlice, useGetAllContactLabelsQuery } from "../../feature/ContactsApi/redux/contactLabelApiSlice";
import { PaginationQueryPackage } from "../../models/API/QueryParams/PaginationQueryPackage";
import { ContactResponse } from "../../models/Contact";
import { LabelContextEnum } from "../../models/ContactLabel";
import { GetManyPackage } from "../../redux/GetManyPackage";

class ContactLabelAlertProps {
    uuid!: string;
    fetchContactsHook!: UseQuery<QueryDefinition<GetManyPackage<object>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, ContactResponse[], "portalapi">>
    labelContext!: LabelContextEnum;
}

export function ContactLabelAlert(props: ContactLabelAlertProps) {

    const allContactlabelsResult = useGetAllContactLabelsQuery({
        pagination: new PaginationQueryPackage()
    })

    const allContactsForEntityResult = props.fetchContactsHook({
        pagination: new PaginationQueryPackage(),
        uuid: props.uuid
    });

    const { t } = useTranslation();
    const labelsForContactLazy = contactLabelsApiSlice.useLazyGetContactLabelsForContactQuery();
    const labelsForContactLazyTrigger = labelsForContactLazy[0];

    const [contacts, setContacts] = useState<ContactResponseView[]>([])
    const [loading, setLoading] = useState(false)
    useMemo(() => {

        let allPromses: Promise<ContactResponseView>[] = []
        allPromses = allContactsForEntityResult.data?.map(async contact => {
            const contactView = contact as ContactResponseView;

            const labels = await labelsForContactLazyTrigger({
                pagination: new PaginationQueryPackage(),
                uuid: contact.uuid
            }).unwrap();
            contactView.labels = labels ?? [];

            return contactView;
        }) ?? []

        setLoading(true)
        Promise.all(allPromses).then(contactViews => setContacts(contactViews)).finally(() => setLoading(false))

    }, [allContactsForEntityResult.data])


    if (allContactlabelsResult.isLoading || loading || labelsForContactLazy[1].isLoading) {
        return <></>
    }

    const requiredLabels = allContactlabelsResult.data?.filter(label => {
        const isCorrectContext = label?.label_contexts?.some(context => context === props.labelContext)
        return label.required === true && isCorrectContext
    }
    );

    const contactLabels = contacts.flatMap(x => x.labels);

    const missingContactLabels = requiredLabels?.filter(required => !contactLabels.map(label => label?.uuid).includes(required.uuid));


    if (!missingContactLabels || missingContactLabels.length < 1) {
        return <></>
    }

    return (
        <Alert severity="error" sx={{ marginTop: 2 }}>
            <Typography>{`${t("Missing contact labels")} : ${missingContactLabels?.map(label => label.label).join(", ")}`}</Typography>
        </Alert>

    )
}