import { useContext } from "react";
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import EasyFormMany from "../../../../components/Forms/EasyFormMany";
import { DateTimeRender } from "../../../../components/Forms/Renders/DateTimeRender";
import { FieldEditorRender } from "../../../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import { ApprovalStatus } from "../../../../models/enums/ApprovalStatus";
import ServiceWindow from "../../../../models/ServiceWindow";
import { PermissionEnum } from "../../../Permission/PermissionEnum";
import { useGetServiceWindowsByServiceIdQuery, useRemoveServiceFromServiceWindowMutation, useUpdateServiceWindowMutation } from "../../../ServiceWindowApi/redux/serviceWindowApiSlice";
import { ServiceWindowInfoButton } from "../../../ServiceWindowApi/serviceWindowInfoDialog/ServiceWindowInfoButton";
import { AbilityContext } from "../../../UserApi/logic/Can";
import { ServiceTabProps } from "../ServiceInfoButton";

export default function ServiceWindowsTab(props: ServiceTabProps) {
    const ability = useContext(AbilityContext)
    const getServiceWindowRows = (serviceWindows: ServiceWindow[] | undefined) => {
        const rows: Array<FieldEditorRender[]> = []

        serviceWindows?.forEach((serviceWindow) => {

            const newRow: FieldEditorRender[] = [
                new TextFieldRender({
                    fieldName: "",
                    nestedfieldName: "description",
                    displayText: "Description",
                    uniqueId: serviceWindow.uuid,
                    type: "text"
                }),
                new DateTimeRender({
                    fieldName: "",
                    nestedfieldName: "start_time",
                    displayText: "Start",
                    uniqueId: serviceWindow.uuid
                }),
                new DateTimeRender({
                    fieldName: "",
                    nestedfieldName: "end_time",
                    displayText: "End",
                    uniqueId: serviceWindow.uuid
                })
            ]

            rows.push(newRow)
        })
        return rows;
    }


    return (
        <EasyFormMany
            confirmDeletionText="Are you sure you wish to remove this service from the service window?"
            isAllowedToDelete={() => false}
            isAllowedToEdit={(serviceResponse) => ability.can(PermissionEnum.SERVICE_WRITE, serviceResponse)}
            isAllowedToCreate={() => false}
            ressourceOwnerId={props.serviceId}
            arrayPath=""
            idToFetch={props.serviceId}
            requestData={[ApprovalStatus.APPROVED]}
            getManyByIdAction={useGetServiceWindowsByServiceIdQuery}
            saveMutation={useUpdateServiceWindowMutation}
            jsxAtButtons={(serviceWindow) => (
                <ServiceWindowInfoButton serviceWindowId={serviceWindow.uuid} />
            )}
            deleteMutation={useRemoveServiceFromServiceWindowMutation}
            SignupSchema={undefined}
            columns={[
                new EasyFormColumn("Service windows", "", [])
            ]}
            getFieldRows={getServiceWindowRows}
            newButtonTooltip="Add new service approver"
        />
    );

}