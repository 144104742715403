import EasyForm, { FormType } from "../../../../components/Forms/EasyForm";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import { CheckboxFieldRender } from "../../../../components/Forms/Renders/CheckboxFieldRender";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import { PermissionEnum } from "../../../Permission/PermissionEnum";
import { AbilityContext } from "../../../UserApi/logic/Can";
import { useGetServiceByIdQuery, useUpdateServiceMutation } from "../../redux/serviceApiSlice";
import { ServiceTabProps } from "../ServiceInfoButton";

export default function MonitoreringTab(props: ServiceTabProps) {
    const { t } = useTranslation()
    const ability = useContext(AbilityContext)
    const tcpMaxPort = 65535; //https://www.pico.net/kb/what-is-the-highest-tcp-port-number-allowed/

    const SignupSchema = Yup.object().shape({
        monitoring: Yup.object().shape({
            email: Yup.string().email(),
            http_url: Yup.string().url(t("Not a valid url")).when('http_ping', {
                is: (http_ping: boolean) => http_ping == true,
                then: Yup.string().required(t("Required"))
            }),
            tcp_portnr: Yup.number().min(0).max(tcpMaxPort).when('tcp_ping', {
                is: (tcp_ping: boolean) => tcp_ping == true,
                then: Yup.number().required(t("Required"))
            })
        })
    })
    const getFirstRow = () => {
        return [
            new TextFieldRender({
                displayText: "Email",
                fieldName: "monitoring.email",
                type: "email",
                uniqueId: props.serviceId
            }),
            new CheckboxFieldRender({
                displayText: "ICMP-Ping",
                fieldName: "monitoring.icmp_ping",
                uniqueId: props.serviceId
            }),
            new CheckboxFieldRender({
                displayText: "TCP-Ping",
                fieldName: "monitoring.tcp_ping",
                uniqueId: props.serviceId
            }),
            new TextFieldRender({
                displayText: "TCP Port",
                fieldName: "monitoring.tcp_portnr",
                type: "number",
                conditionFieldName: "monitoring.tcp_ping",
                conditionShouldBeDisabledFunc: (tcp_ping: boolean) => tcp_ping == false,
                uniqueId: props.serviceId
            }),
            new CheckboxFieldRender({
                displayText: "HTTP-Ping",
                fieldName: "monitoring.http_ping",
                uniqueId: props.serviceId
            }),
            new TextFieldRender({
                displayText: "HTTP URL",
                fieldName: "monitoring.http_url",
                type: "url",
                conditionFieldName: "monitoring.http_ping",
                conditionShouldBeDisabledFunc: (http_ping: boolean) => http_ping == false,
                uniqueId: props.serviceId
            }),
            new CheckboxFieldRender({
                displayText: "Alarming",
                fieldName: "monitoring.alarming",
                uniqueId: props.serviceId
            })
        ];
    }

    return (
        <EasyForm
            isAllowedToEdit={(servicewindow) => ability.can(PermissionEnum.SERVICE_WRITE, servicewindow)}
            formType={FormType.INFORMATION}
            arrayPath=""
            SignupSchema={SignupSchema}
            saveMutation={useUpdateServiceMutation}
            getByIdAction={useGetServiceByIdQuery}
            columns={[
                new EasyFormColumn("Monitoring", "", getFirstRow())
            ]}
            idToFetch={props.serviceId}
            getFieldRows={() => []}
            newButtonTooltip="Add new info"
            createNew={function () {
                throw new Error("Function not implemented.");
            }}
        />
    )
}