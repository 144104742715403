import { ButtonGroup, Grid, Stack, Typography } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { OrganisationBadge } from "../components/Badges/OrganisationBadge"
import { OrganisationContext } from "../components/Layout/OrganisationPicker"
import Loading from "../components/loading"
import { CreateUserButton } from "../feature/UserApi/CreateUserButton"
import { UserContext } from "../feature/UserApi/logic/FetchUser"
import { useGetAllUserQuery, useGetAllUsersByOrganisationQuery } from "../feature/UserApi/redux/userApiSlice"
import { UserList } from "../feature/UserApi/usersList"

export const UserPage = () => {
    const { t } = useTranslation()
    const currentOrganisation = useContext(OrganisationContext)?.organisation;
    const userContext = useContext(UserContext);
    if (!currentOrganisation && !userContext?.allowEmptyOrganisation())
        return <Loading />

    return (

        <Grid container spacing={5}>
            <Grid item xs={6}>
                <Stack direction="row" spacing={2}>
                    {currentOrganisation ? <OrganisationBadge ressource={currentOrganisation} /> : <></>}
                    <Typography variant="h5">{t("Users") + ""}</Typography>
                    <ButtonGroup variant="text">
                        <CreateUserButton />
                    </ButtonGroup>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <UserList idToFetch={currentOrganisation?.uuid} getUsers={currentOrganisation ? useGetAllUsersByOrganisationQuery : useGetAllUserQuery} />
            </Grid>
        </Grid>

    )
}