import { ArrowDropDown, DeleteOutline } from "@mui/icons-material";
import { Breakpoint, Button, ButtonGroup, ClickAwayListener, Grow, MenuList, Paper, Popper, Stack, Typography } from "@mui/material";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, MutationDefinition, QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { UseMutation, UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { RequestMutationWrapper } from "../models/API/RequestMutationWrapper";
import { ConfirmationButton } from "./ConfirmationButton";


export interface SplitButtonProps<CustomType> {
    primaryButtonText: string
    isAllowedToDelete: (entitiy: CustomType) => boolean
    idToFetch: string
    nameOfRessource: string
    children?: any
    deleteDialogMaxWidth?: false | Breakpoint | undefined;
    deleteMutation: UseMutation<MutationDefinition<RequestMutationWrapper<CustomType>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, void, any>>
    getByIdAction: UseQuery<QueryDefinition<string, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, CustomType, any>>
    deleteButtonContent?: JSX.Element
    deleteButtonConfirmationText?: string
    afterDeleteAction?: () => void
}



export default function RessourceMenu<CustomType>(props: SplitButtonProps<CustomType>) {
    const { t } = useTranslation()

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const { data } = props.getByIdAction(props.idToFetch)
    const [deleteAction] = props.deleteMutation()

    async function Delete() {
        const toDelete = JSON.parse(JSON.stringify(data)) as CustomType
        await deleteAction({ data: toDelete }).unwrap()
        if (props.afterDeleteAction)
            props.afterDeleteAction();
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement))
            return;

        setOpen(false);
    };
    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Button
                    size="medium"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography>{props.primaryButtonText}</Typography>
                    </Stack>
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {props.children}
                                    {props.isAllowedToDelete && data && props.isAllowedToDelete(data) ?

                                        <ConfirmationButton
                                            title={<>{t(`Delete ${props.nameOfRessource}`)}</>}
                                            buttonText={<><DeleteOutline /> {t(`Delete ${props.nameOfRessource}`)}</>}
                                            asMenuItem={true}
                                            fullWidth
                                            maxWidth={props.deleteDialogMaxWidth}
                                            showButtons={props.deleteButtonContent ? false : true}
                                            action={async () => await Delete()}>
                                            <>
                                                {props.deleteButtonContent ?
                                                    <>{props.deleteButtonContent}</> :
                                                    <>{props.deleteButtonConfirmationText? 
                                                        props.deleteButtonConfirmationText : 
                                                        t(`Are you sure you wish to delete this ${props.nameOfRessource}?`)}</>
                                                }
                                            </>
                                        </ConfirmationButton>
                                        : <></>}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}