import { FormControl, FormHelperText, Select, SelectProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

class ToggleableSelectProps {
    editMode = false;
    render?: (content: unknown) => JSX.Element
    helperText?: string
}

export default function ToggleableSelect(props: SelectProps & ToggleableSelectProps) {
    const { t } = useTranslation()
    if (props.editMode)
        return (
            <FormControl fullWidth>
                <Select {...props}>
                    {props.children}
                </Select>
                <FormHelperText error={props.helperText != undefined}>
                    {props.helperText}
                </FormHelperText>
            </FormControl>
        )
    if (props.render)
        return props.render(props.defaultValue)

    return <Typography textTransform="capitalize">{t(props.defaultValue as string).toLowerCase() + ""}</Typography>;
}