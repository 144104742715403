import { SimpleBaseOrganisation } from "../../../models/Organisation";
import { UserAuthorisationResponse, UserResponse } from "../../../models/User";

export function mapAuthorisationToUser(authorisation: UserAuthorisationResponse): UserResponse {
    const userResponse = new UserResponse();
    userResponse.email = authorisation.parent_user_email;
    userResponse.role = authorisation.role;
    userResponse.organisation = new SimpleBaseOrganisation();
    userResponse.organisation.uuid = authorisation.organisation_uuid ?? "";
    userResponse.organisation.name = authorisation.organisation_name;
    userResponse.uuid = authorisation.uuid;
    userResponse.first_name = authorisation.parent_user_first_name;
    userResponse.last_name = authorisation.parent_user_last_name;
    return userResponse;
}