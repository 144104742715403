import Box from '@mui/material/Box';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import TooltipComponent from './TooltipComponent';

export default function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number, tooltip: string },
) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <TooltipComponent title={props.tooltip}>

                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                    >{`${Math.floor(props.value)}%`}</Typography>
                </TooltipComponent>
            </Box>
        </Box>
    );
}