import Stack from '@mui/material/Stack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as React from 'react';
import { DateToFormattedString } from './Logic/DateLogic';



class ToggleableDesktopDatePickerProps {
    editMode = false;
}

export default function ToggleableDesktopDatePicker(props: ToggleableDesktopDatePickerProps & DateTimePickerProps<Date, Date> & React.RefAttributes<HTMLDivElement>) {
    if (props.editMode)
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                    <DateTimePicker
                        {...props}
                    />
                </Stack>
            </LocalizationProvider>
        )
    return <>{DateToFormattedString(props.value!)}</>;
}