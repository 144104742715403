import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { CriticalityBadge } from "../../../../components/Badges/CriticalityBadge";
import { CriticalityScoreBadge } from "../../../../components/Badges/CriticalityScoreBadge";
import { HostingsiteBadge } from "../../../../components/Badges/HostingsiteBadge";
import { OrganisationBadge } from "../../../../components/Badges/OrganisationBadge";
import { PropagationBadge } from "../../../../components/Badges/PropagationBadge";
import { ServiceBadge } from "../../../../components/Badges/ServiceBadge";
import EasyForm, { FormType } from "../../../../components/Forms/EasyForm";
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import { AutocompleteFieldRender } from "../../../../components/Forms/Renders/AutocompleteFieldRender";
import { CheckboxFieldRender } from "../../../../components/Forms/Renders/CheckboxFieldRender";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import { TextRenderer } from "../../../../components/Forms/Renders/TextRenderer";
import Loading from "../../../../components/loading";
import { PaginationQueryPackage } from "../../../../models/API/QueryParams/PaginationQueryPackage";
import HostingsiteBase from "../../../../models/Hostingsite";
import OrganisationResponse from "../../../../models/Organisation";
import { ServiceResponseDetails } from "../../../../models/Service";
import { ServiceTagResponse } from "../../../../models/ServiceTag";
import { CriticalityEnum } from "../../../../models/enums/CriticalityEnum";
import { PropagationEnum } from "../../../../models/enums/PropagationEnum";
import { ServiceCategoryEnum } from "../../../../models/enums/ServiceCategoryEnum";
import { useGetHostingsitesByOrganisationIdQuery } from "../../../HostingSiteApi/hostingsitesApiSlice";
import { PermissionEnum } from "../../../Permission/PermissionEnum";
import { AbilityContext } from "../../../UserApi/logic/Can";
import useServiceTag from "../../Hooks/serviceTagHook";
import ServiceTagFieldRender from "../../Renders/ServiceTagFieldRender";
import { useGetServiceByIdQuery, useUpdateServiceMutation } from "../../redux/serviceApiSlice";
import { ServiceTabProps } from "../ServiceInfoButton";
import { ServiceCategoryBadge } from "../../../../components/Badges/ServiceCategoryBadge";

export default function InfoTab(props: ServiceTabProps) {
    const [newlyCreatedServiceTag, setNewlyCreatedServiceTag] = useState<ServiceTagResponse | undefined>(undefined)

    const ability = useContext(AbilityContext);
    const serviceResult = useGetServiceByIdQuery(props.serviceId)
    const allHostingsiteResults = useGetHostingsitesByOrganisationIdQuery({
        pagination: new PaginationQueryPackage(),
        uuid: serviceResult.data?.hosting_site?.owner_organisation?.uuid
    }, { skip: !ability.can(PermissionEnum.HOSTING_SITE_READ, serviceResult) })

    const { t } = useTranslation()
    const serviceTags = useServiceTag(serviceResult.data?.hosting_site)

    if (serviceResult.isLoading || allHostingsiteResults.isLoading)
        return <Loading />

    const SignupSchema = Yup.object().shape({
        name: Yup.string().required(t('Required')),
        sla: Yup.number().required(t('Required')).min(0).max(100),
        hosting_site: Yup.object().nullable().required(),
        servicetag: Yup.mixed().nullable(),
        uses_encryption: Yup.boolean().default(false).required(),
        no_encryption_reason: Yup.string().when('uses_encryption', {
            is: (uses_encryption: boolean) => uses_encryption == false,
            then: Yup.string().required(t('Required'))
        })
    });

    const getFirstRow = () => {
        return [
            new TextRenderer({
                displayText: "ID",
                fieldName: "short_id",
                uniqueId: props.serviceId
            }),
            new TextFieldRender({
                displayText: "Name",
                fieldName: "name",
                type: "text",
                render: (content) => <ServiceBadge textOverride={content as string} />,
                uniqueId: props.serviceId
            }),
            new TextRenderer({
                displayText: "Organisation",
                fieldName: "hosting_site.owner_organisation",
                uniqueId: props.serviceId,
                render: (content) => <OrganisationBadge ressource={content as unknown as OrganisationResponse} />
            }),
            new TextRenderer({
                displayText: "Hostingsite",
                fieldName: "hosting_site",
                render: (option) => <HostingsiteBadge ressource={option as HostingsiteBase} />,
                uniqueId: props.serviceId
            }),
            ServiceTagFieldRender({
                translation: t,
                serviceTags: serviceTags,
                setNewlyCreatedServiceTag: setNewlyCreatedServiceTag,
                newlyCreatedServiceTag: newlyCreatedServiceTag,
                hostingSite: serviceResult.data?.hosting_site
            }),
            new TextFieldRender({
                displayText: "Description",
                fieldName: "description",
                type: "text",
                uniqueId: props.serviceId,
                multiline: true
            }),
            new TextFieldRender({
                displayText: "Internal description",
                fieldName: "internal_description",
                type: "text",
                uniqueId: props.serviceId,
                multiline: true
            }),
            new CheckboxFieldRender({
                displayText: "Requires approval",
                fieldName: "requires_approval",
                uniqueId: props.serviceId
            }),
            new TextFieldRender({
                displayText: "SLA",
                fieldName: "sla",
                type: "number",
                render: (content) => <>{content} %</>,
                endAdornment: "%",
                uniqueId: props.serviceId
            }),
            new TextFieldRender({
                displayText: "SLA description",
                fieldName: "sla_description",
                type: "text",
                uniqueId: props.serviceId
            }),
            new CheckboxFieldRender({
                displayText: "Uses encryption",
                fieldName: "uses_encryption",
                uniqueId: props.serviceId
            }),
            new TextFieldRender({
                displayText: "Reason for no encryption",
                fieldName: "no_encryption_reason",
                type: "text",
                multiline: true,
                uniqueId: "no_id",
                conditionFieldName: "uses_encryption",
                conditionShouldBeDisabledFunc: (uses_encryption: boolean) => uses_encryption == true
            }),
            new TextFieldRender({
                displayText: "Service window rules",
                fieldName: "service_window_rules",
                type: "text",
                uniqueId: props.serviceId
            }),
            new TextFieldRender({
                displayText: "General contact information",
                fieldName: "general_contact_information",
                multiline: true,
                type: "text",
                uniqueId: props.serviceId
            })
        ];
    }

    const getCriticalityRow = () => {
        return [
            new TextRenderer({
                displayText: "Criticality score",
                fieldName: "criticality_score",
                uniqueId: props.serviceId,
                render: (content) => <CriticalityScoreBadge textOverride={content as string} />
            }),
            new AutocompleteFieldRender({
                displayText: t("Criticality"),
                fieldName: "criticality",
                getOptionLabelFunc: (option: CriticalityEnum) => option.toString() ?? "",
                options: Object.values(CriticalityEnum) ?? [],
                renderNonChangeable: (content) => <CriticalityBadge ressource={content as CriticalityEnum} />,
                uniqueId: "no_id"
            }),
            new AutocompleteFieldRender({
                displayText: t("Propagation"),
                fieldName: "propagation",
                getOptionLabelFunc: (option: PropagationEnum) => option.toString() ?? "",
                options: Object.values(PropagationEnum) ?? [],
                renderNonChangeable: (content) => <PropagationBadge ressource={content as PropagationEnum} />,
                uniqueId: "no_id"
            }),
            new AutocompleteFieldRender({
                displayText: t("Categories"),
                fieldName: "service_categories",
                multipleSelect: true,
                getOptionLabelFunc: (option: ServiceCategoryEnum) => option.toString() ?? "",
                renderNonChangeable: (option: ServiceCategoryEnum) => <ServiceCategoryBadge ressource={option}/>,
                options: Object.values(ServiceCategoryEnum) ?? [],
                uniqueId: "no_id"
            }),

        ];
    }



    return (
        <EasyForm<ServiceResponseDetails>
            isAllowedToEdit={(serviceResponse) => ability.can(PermissionEnum.SERVICE_WRITE, serviceResponse)}
            saveMutation={useUpdateServiceMutation}
            getByIdAction={useGetServiceByIdQuery}
            arrayPath=""
            SignupSchema={SignupSchema}
            columns={[
                new EasyFormColumn("Service information", "", getFirstRow()),
                new EasyFormColumn("Criticality information", "", getCriticalityRow()),
            ]}
            idToFetch={props.serviceId}
            getFieldRows={() => []}
            newButtonTooltip="Add new info"
            formType={FormType.INFORMATION}
            createNew={function () {
                throw new Error("Function not implemented.");
            }}
            key={JSON.stringify([serviceResult.data])}
        />
    )
}