import { Breadcrumbs, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as reactLink, useLocation } from "react-router-dom";

export default function AutoBreadcrump() {
    const { t } = useTranslation()

    const urlSegmentToDisplayName: any = {};
    urlSegmentToDisplayName["clients"] = { displayName: t("Clients") }
    urlSegmentToDisplayName["organisations"] = { displayName: t("Organisations") }
    urlSegmentToDisplayName["services"] = { displayName: t("Services") }
    urlSegmentToDisplayName["agreements"] = { displayName: t("Agreements") }

    const currentLocation = useLocation();
    const urlSegments = currentLocation.pathname.split("/")

    const urlSegmentsLength = urlSegments.length
    if (urlSegmentsLength == 2)
        return <></>

    let totalUrlIncremental = ""

    return (
        <Breadcrumbs aria-label="breadcrumb">
            {urlSegments.slice(1, urlSegmentsLength).map((seg, index) => {
                totalUrlIncremental += "/" + seg;
                return (
                    <Link
                        key={seg + index}
                        component={reactLink}
                        underline="hover"
                        to={totalUrlIncremental}>
                        {urlSegmentToDisplayName[seg] !== undefined ? urlSegmentToDisplayName[seg].displayName : seg}
                    </Link>
                )
            })}

        </Breadcrumbs>

    )
}