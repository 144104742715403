import InfoIcon from '@mui/icons-material/Info';
import { Tab } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import DrawerButton from "../../../components/DrawerButton";
import TabPanel from "../../../components/TabPanel";
import Service, { ServiceResponse } from "../../../models/Service";
import { useDeleteServiceMutation, useGetServiceByIdQuery } from "../redux/serviceApiSlice";
import AgreementsTab from "./tabs/AgreementsTab";
import EndpointsTab from "./tabs/EndpointsTab";
import InfoTab from "./tabs/InfoTab";

import { useParams } from "react-router-dom";
import { ContactLabelAlert } from '../../../components/Alerts/ContactLabelAlert';
import { EntityMetaDataComponent } from '../../../components/EntityMetaDataComponent';
import { CustomTabs } from '../../../components/Forms/CustomTabs';
import UseUrlParam from "../../../components/Logic/UseUrlProps";
import RessourceMenu from "../../../components/RessourceMenu";
import TooltipComponent from '../../../components/TooltipComponent';
import Loading from '../../../components/loading';
import { LabelContextEnum } from '../../../models/ContactLabel';
import { useGetContactsByServiceIdQuery } from '../../ContactsApi/redux/contactsApiSlice';
import { PermissionEnum } from '../../Permission/PermissionEnum';
import { AbilityContext, Can } from "../../UserApi/logic/Can";
import MoveServiceMenuItem from "./MoveServiceMenuItem";
import ContactTab from './tabs/ContactTab';
import MonitoreringTab from "./tabs/MonitoreringTab";
import ServiceApproversTab from './tabs/ServiceApproversTab';
import ServiceWindowsTab from './tabs/ServiceWindowsTab';


interface ServiceInfoDialogProps {
    service: ServiceResponse
    openFromStart?: boolean
    onClose?: () => void
}

export interface ServiceTabProps {
    serviceId: string
}

export const ServiceInfoButton = (props: ServiceInfoDialogProps) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(props.openFromStart ?? false)
    return (
        <DrawerButton
            open={open}
            setOpen={setOpen}
            onClose={props.onClose}
            buttonContent={
                <TooltipComponent title={t("Details") + ""}>
                    <InfoIcon />
                </TooltipComponent>
            } footerContent={
                <EntityMetaDataComponent entity_uuid={props.service.uuid} />
            } >

            <ServiceInfoContent {...props}></ServiceInfoContent>
        </DrawerButton>
    )
}

export const ServiceInfoContent = (props: ServiceInfoDialogProps | UseUrlParam) => {

    const params = useParams();
    const urlPropsUuid = (props as UseUrlParam).shouldGetOrganisationFromUrl ? params["serviceUuid"] : "";
    const servicePropsUuid = (props as ServiceInfoDialogProps).service?.uuid

    const serviceUuid = servicePropsUuid ?? urlPropsUuid;

    const [value, setValue] = React.useState(0);
    const { t } = useTranslation()
    const { data: service } = useGetServiceByIdQuery(serviceUuid!)
    const ability = useContext(AbilityContext);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (service == undefined) {
        return <Loading></Loading>
    }

    const labels = []
    const tabPanel = []

    labels.push(t("Info") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><InfoTab serviceId={service.uuid!} {...props} /></TabPanel>)

    if (ability.can(PermissionEnum.SERVICE_READ, service)) {
        labels.push(t("Endpoints") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><EndpointsTab serviceId={service.uuid!} {...props} /></TabPanel>)
    }

    if (ability.can(PermissionEnum.SERVICE_READ, service)) {
        labels.push(t("Monitoring") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><MonitoreringTab key={JSON.stringify(service.monitoring)} serviceId={service.uuid!} {...props} /></TabPanel>)
    }

    if (ability.can(PermissionEnum.SERVICE_READ, service)) {
        labels.push(t("Agreements") + "")
        tabPanel.push(<TabPanel value={value} index={tabPanel.length}><AgreementsTab serviceId={service.uuid!} {...props} /></TabPanel>)
    }

    labels.push(t("Service windows") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><ServiceWindowsTab serviceId={service.uuid!} {...props} /></TabPanel>)

    labels.push(t("Contacts") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><ContactTab serviceId={service.uuid!} {...props} /></TabPanel>)

    labels.push(t("Approvers") + "")
    tabPanel.push(<TabPanel value={value} index={tabPanel.length}><ServiceApproversTab serviceId={service.uuid!} {...props} /></TabPanel>)
    return (
        <>
            <Can I={PermissionEnum.SERVICE_WRITE} this={service}>
                <ContactLabelAlert uuid={serviceUuid!} fetchContactsHook={useGetContactsByServiceIdQuery} labelContext={LabelContextEnum.SERVICE} />
            </ Can>

            <CustomTabs value={value} onChange={handleChange} rightMenu={
                <RessourceMenu
                    isAllowedToDelete={(data) => ability.can(PermissionEnum.SERVICE_WRITE, data)}
                    nameOfRessource="service"
                    primaryButtonText={t("Service") + ": " + service.name}
                    idToFetch={service.uuid}
                    deleteMutation={useDeleteServiceMutation}
                    getByIdAction={useGetServiceByIdQuery} >
                    <Can I={PermissionEnum.GOD} a={Service} >
                        <MoveServiceMenuItem serviceId={serviceUuid} />
                    </Can>
                </RessourceMenu>
            }>

                {labels.map(label => (<Tab key={label} label={label} />))}
            </CustomTabs>
            {tabPanel.map(tabPanel => tabPanel)}
        </>
    )
}