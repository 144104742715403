
import getEnvironment from '../../../env';
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper';
import { ServiceResponse } from '../../../models/Service';
import ServiceWindow, { ServiceWindowAddRequest, ServiceWindowRequest } from '../../../models/ServiceWindow';
import HandleQuery from '../../../redux/EndpointQueryHandler';
import { GetManyPackage } from '../../../redux/GetManyPackage';
import handleResponse from '../../../redux/handleResponse';
import { portalApiSlice } from '../../../redux/portalApiSlice';
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse';

const baseurl = getEnvironment().REACT_APP_API_BASEURL;
// Define a serviceWindow using a base URL and expected endpoints
export const serviceWindowApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServiceWindowsByServiceId: builder.query<ServiceWindow[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/services/${pack.uuid}/service-windows`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Service windows could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: ServiceWindow[], b, c) => transformMultipleResponseToRightType(ServiceWindow, a, b, c),
      providesTags: (_result, _error, uuid) => [{ type: 'service-windows', uuid: uuid }]
    }),
    getAllServiceWindows: builder.query<ServiceWindow[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/service-windows`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Service windows could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: ServiceWindow[], b, c) => transformMultipleResponseToRightType(ServiceWindow, a, b, c),
      providesTags: (_result, _error, uuid) => [{ type: 'service-windows', uuid: uuid }]
    }),
    getServiceWindowsByOrganisationId: builder.query<ServiceWindow[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/organisations/${pack.uuid}/service-windows`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Service windows could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: ServiceWindow[], b, c) => transformMultipleResponseToRightType(ServiceWindow, a, b, c),
      providesTags: (_result, _error, uuid) => [{ type: 'service-windows', uuid: uuid }]
    }),
    getServiceWindowsForClientsInOrganisationByOrganisationId: builder.query<ServiceWindow[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/organisations/${pack.uuid}/client-service-windows`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Service windows could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: ServiceWindow[], b, c) => transformMultipleResponseToRightType(ServiceWindow, a, b, c),
      providesTags: (_result, _error, uuid) => [{ type: 'service-windows', uuid: uuid }]
    }),
    getServiceWindowById: builder.query<ServiceWindow, string>({
      query: (uuid) => HandleQuery({
        url: `${baseurl}/service-windows/${uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Service window could not be fetched" }),
      }),
      transformResponse: (a: ServiceWindow, b, c) => transformSingleResponseToRightType(ServiceWindow, a, b, c),
      providesTags: (_result, _error, uuid) => [{ type: 'service-window', uuid: uuid }]
    }),
    updateServiceWindow: builder.mutation<ServiceWindow, RequestMutationWrapper<ServiceWindow>>({
      query: (organisation) => HandleQuery({
        url: `${baseurl}/service-windows/${organisation.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Service window was updated!", toastErrorText: "Service window coult not be updated" }),
        method: "PATCH",
        body: organisation.data
      }),
      transformResponse: (a: ServiceWindow, b, c) => transformSingleResponseToRightType(ServiceWindow, a, b, c),
      invalidatesTags: ['service-window', 'service-windows']
    }),
    updateServiceWindowServices: builder.mutation<ServiceResponse, RequestMutationWrapper<ServiceResponse>>({
      query: (serviceWindow) => HandleQuery({
        url: `${baseurl}/service-windows/${serviceWindow.parentId}/services`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Service window services was updated!", toastErrorText: "Service window services coult not be updated" }),
        method: "PUT",
        body: serviceWindow.data
      }),
      transformResponse: (a: ServiceResponse, b, c) => transformSingleResponseToRightType(ServiceResponse, a, b, c),
      invalidatesTags: ['service-window', 'service-windows']
    }),
    addServiceToServiceWindow: builder.mutation<void, RequestMutationWrapper<ServiceWindowAddRequest>>({
      query: (serviceWindow) => HandleQuery({
        url: `${baseurl}/service-windows/${serviceWindow.parentId}/services`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Service was added to service window", toastErrorText: "Service could not be added to service window" }),
        method: 'POST',
        body: {
          service_uuid: serviceWindow.data.service?.uuid
        },
      }),

      invalidatesTags: ['service-window', 'service-windows', 'services']
    }),
    removeServiceFromServiceWindow: builder.mutation<void, RequestMutationWrapper<ServiceResponse>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/service-windows/${request.parentId}/services/${request.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Service was removed from service window", toastErrorText: "Service could not be removed from service window" }),
        method: 'DELETE'
      }),

      invalidatesTags: ['service-window', 'service-windows', 'services']
    }),
    addServiceWindow: builder.mutation<ServiceWindow, RequestMutationWrapper<ServiceWindowRequest>>({
      query: (serviceWindow) => HandleQuery({
        url: `${baseurl}/service-windows`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Service window was created", toastErrorText: "Service window could not be created" }),
        method: 'POST',
        body: {
          description: serviceWindow.data.description,
          start_time: serviceWindow.data.start_time,
          end_time: serviceWindow.data.end_time,
          services: serviceWindow.data.services.map(x => x.uuid)
        },
      }),
      transformResponse: (a: ServiceWindow, b, c) => transformSingleResponseToRightType(ServiceWindow, a, b, c),

      invalidatesTags: ['service-window', 'service-windows']
    }),
    deleteServiceWindow: builder.mutation<void, RequestMutationWrapper<ServiceWindow>>({
      query: (serviceWindow) => HandleQuery({
        url: `${baseurl}/service-windows/${serviceWindow.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Service Window was deleted!", toastErrorText: "Service Window could not be deleted" }),
      }),

      invalidatesTags: ['service-windows']
    }),
  }),
})


export const { useGetServiceWindowsByServiceIdQuery, useAddServiceToServiceWindowMutation, useRemoveServiceFromServiceWindowMutation, useGetAllServiceWindowsQuery, useGetServiceWindowsForClientsInOrganisationByOrganisationIdQuery, useGetServiceWindowsByOrganisationIdQuery, useGetServiceWindowByIdQuery, useDeleteServiceWindowMutation, useUpdateServiceWindowMutation, useUpdateServiceWindowServicesMutation, useAddServiceWindowMutation } = serviceWindowApiSlice
















