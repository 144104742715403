import { Divider } from '@mui/material';
import { useState } from 'react';
import { useGetAllOrganisationsQuery } from './redux/organisationApiSlice';

import { useTranslation } from 'react-i18next';
import DatagridWrapper, { DataGridId } from '../../components/DatagridWrapper';
import { OpenInNewTabButton } from '../../components/OpenInNewTabButton';
import AgreementRequest from '../../models/Agreement';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import { DetailedOrganisationResponse } from '../../models/Organisation';
import { PermissionEnum } from '../Permission/PermissionEnum';
import { Can } from '../UserApi/logic/Can';
import { OrganisationInfoButton } from './organisationButtons/details/OrganisationInfoButton';
import { OrganisationAgreementsButton } from './organisationButtons/organisationAgreementsButton';
import { OrganisationClientsButton } from './organisationButtons/organisationClientsButton';
import { OrganisationEventsButton } from './organisationButtons/organisationEventsButton';
import { OrganisationServicesButton } from './organisationButtons/organisationServicesButton';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Category, Handshake, Laptop, Person } from '@mui/icons-material';
import { CountBadge } from '../../components/CountBadge';

export const OrganisationList = () => {
    return (<OrganisationListContent />)
}

const OrganisationListContent = () => {
    const { t } = useTranslation()

    const [clickedRow, setClickedRow] = useState<AgreementRequest | undefined>(undefined)
    const [uuid, setUuid] = useState(crypto.randomUUID()) //To rerender buttons when clicking a row

    const currentResult = useGetAllOrganisationsQuery({
        pagination: new PaginationQueryPackage()
    });

    if (currentResult.data === undefined)
        currentResult.data = []



    const getColumns = (organisations: DetailedOrganisationResponse[]) => {

        const columns: GridColDef[] = [
            { field: 'name', sortable: true, type: "string", headerName: t('Organisation'), flex: 3 },
            { field: 'description', sortable: true, type: "string", headerName: t('Description'), flex: 4 },
            { field: 'service_desk_email', sortable: true, type: "string", headerName: t('Service desk email'), flex: 2 },
            { field: 'service_desk_telephone_number', sortable: true, type: "string", headerName: t('Service desk telephone number'), flex: 2 },
            { field: 'hotline_telephone_number', sortable: true, type: "string", headerName: t('Hotline telephone number'), flex: 2 },
            { field: 'services_count', align: "center", renderCell: (params) => <CountBadge prefix={t("services")} icon={Category} count={params.row.services_count} />, sortable: true, type: "number", headerName: t('Services'), flex: 1 },
            { field: 'clients_count', align: "center", renderCell: (params) => <CountBadge prefix={t("clients")} icon={Laptop} count={params.row.clients_count} />, sortable: true, type: "number", headerName: t('Clients'), flex: 1 },
            { field: 'agreements_count', align: "center", renderCell: (params) => <CountBadge prefix={t("agreements")} icon={Handshake} count={params.row.agreements_count} />, sortable: true, type: "number", headerName: t('Agreements'), flex: 1 },
            { field: 'users_count', align: "center", renderCell: (params) => <CountBadge prefix={t("users")} icon={Person} count={params.row.users_count} />, sortable: true, type: "number", headerName: t('Users'), flex: 1 },
            {
                field: 'events', sortable: false, hideable: false, type: "string", valueGetter: () => "", headerName: t('Actions'), minWidth: 220, renderCell: (params => {
                    const organisation = organisations.find(x => x.uuid === params.id)!;
                    return (
                        <>
                            <OrganisationInfoButton
                                key={JSON.stringify(uuid)}
                                openFromStart={organisation.uuid == clickedRow?.uuid}
                                onClose={() => setClickedRow(undefined)}
                                organisationUuid={organisation.uuid!} />
                            <OpenInNewTabButton url={`/organisations/${organisation.uuid}`} />
                            <Divider orientation='vertical' />
                            <OrganisationServicesButton organisationUuid={organisation.uuid!} />
                            <Can I={PermissionEnum.CLIENT_READ} a={organisation}>
                                <OrganisationClientsButton organisationUuid={organisation.uuid!} />
                            </Can>
                            <Can I={PermissionEnum.SERVICE_READ} this={organisation}>
                                <Can I={PermissionEnum.CLIENT_READ} this={organisation}>
                                    <OrganisationAgreementsButton organisationUuid={organisation.uuid!} />
                                </Can>
                            </Can>
                            <Can I={PermissionEnum.AUDIT_READ} this={organisation}>
                                <OrganisationEventsButton organisationUuid={organisation.uuid!} />
                            </Can>

                        </>
                    )
                })
            }
        ];
        return columns
    }

    return (
        <DatagridWrapper
            dataGridId={DataGridId.ORGANISATIONS}
            getRowId={(row) => row.uuid}
            autoHeight
            loading={currentResult.isLoading}
            onRowClick={(row) => { setClickedRow(row.row); setUuid(crypto.randomUUID()) }}
            rows={currentResult.data}
            columns={getColumns(currentResult.data)}
        />
    )
}

