import * as Yup from 'yup';
import EasyForm, { FormType } from "../../../../components/Forms/EasyForm";
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import { TextRenderer } from "../../../../components/Forms/Renders/TextRenderer";
import { useGetClientByIdQuery, useUpdateClientMutation } from "../../redux/clientsApiSlice";
import { ClientTabProps } from "../ClientInfoButton";

import { useContext } from "react";
import { useTranslation } from 'react-i18next';
import { ClientBadge } from "../../../../components/Badges/ClientBadge";
import { OrganisationBadge } from "../../../../components/Badges/OrganisationBadge";
import OrganisationResponse from "../../../../models/Organisation";
import { PermissionEnum } from "../../../Permission/PermissionEnum";
import { AbilityContext } from "../../../UserApi/logic/Can";
import { HostingsiteBadge } from '../../../../components/Badges/HostingsiteBadge';
import { HostingsiteResponse } from '../../../../models/Hostingsite';

export default function InfoTab(props: ClientTabProps) {
    const { t } = useTranslation()

    const SignupSchema = Yup.object().shape({
        name: Yup.string().required(t("Required")),
        description: Yup.string().required(t("Required"))
    });
    const ability = useContext(AbilityContext)
    return (
        <EasyForm
            isAllowedToEdit={(client) => ability.can(PermissionEnum.CLIENT_WRITE, client)}
            createNew={() => { throw "No client should be created here" }}
            arrayPath=""
            getByIdAction={useGetClientByIdQuery}
            SignupSchema={SignupSchema}
            saveMutation={useUpdateClientMutation}
            columns={[
                new EasyFormColumn("Client information", "", [
                    new TextRenderer({ displayText: "ID", fieldName: "short_id", uniqueId: props.client_id! }),
                    new TextFieldRender({ displayText: "Client name", fieldName: "name", type: "text", uniqueId: props.client_id!, render: (content) => <ClientBadge textOverride={content as string} /> }),
                    new TextRenderer({ displayText: "Organisation", fieldName: "hosting_site.owner_organisation", uniqueId: props.client_id!, render: (content) => <OrganisationBadge ressource={content as unknown as OrganisationResponse} /> }),
                    new TextRenderer({ displayText: "Hostingsite", fieldName: "hosting_site", uniqueId: props.client_id!, render: (content) => <HostingsiteBadge ressource={content as unknown as HostingsiteResponse} /> }),
                    new TextFieldRender({ displayText: "General contact information", fieldName: "general_contact_information", multiline: true, type: "text", uniqueId: props.client_id! })
                ]),
                new EasyFormColumn("Descriptions", "", [
                    new TextFieldRender({ displayText: "Description", fieldName: "description", multiline: true, type: "text", uniqueId: props.client_id! }),
                    new TextFieldRender({ displayText: "Internal description", fieldName: "internal_description", multiline: true, type: "text", uniqueId: props.client_id! })
                ])
            ]}
            formType={FormType.INFORMATION}
            idToFetch={props.client_id}
            getFieldRows={() => []}
        />
    )
}