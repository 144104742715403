import { Button, Drawer } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export interface DrawerButtonProps {
    buttonContent: JSX.Element,
    children: any
    open: boolean
    disabled?: boolean
    setOpen: (open: boolean) => void
    onClose?: () => void
    onOpen?: () => void
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
    variant?: "text" | "outlined" | "contained",
    footerContent?: JSX.Element
}

export default function DrawerButton(props: DrawerButtonProps) {

    const handleClickOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        props.setOpen(true);
        props.onOpen?.()
    };

    return (
        <>
            <Button  {...props} onClick={handleClickOpen}>
                {props.buttonContent}
            </Button>
            <Drawer
                anchor="bottom"
                PaperProps={{ style: { height: "77%" } }}
                open={props.open}
                onClose={() => {
                    props.setOpen(false);
                    props.onClose?.()
                }}>
                <Box style={{ position: 'relative' }}>
                    <Box marginTop={2} marginRight={2} marginLeft={2} style={{ minHeight: '63vh' }} >
                        {props.children}
                    </Box>

                    {props.footerContent &&
                        <Box style={{
                            maxHeight: '5vh',
                            position: 'absolute',
                            width: '100%'
                        }}>
                            {props.footerContent}
                        </Box>
                    }
                </Box>
            </Drawer>

        </>
    )
}