import BaseModel from "./BaseModels/BaseModel";

export class NotificationResponse extends BaseModel {
    notifications?: Notification[]
    un_read?: number
}

export class NotificationPatch extends BaseModel {
    is_read?: boolean
}

export class Notification extends BaseModel {
    created_time?: Date
    da_description?: string
    en_description?: string
    is_read?: boolean
}