import { FormikProps } from 'formik';
import { TFunction } from "react-i18next";
import { useContext, useMemo, useState } from 'react';
import EasyFormColumn from '../../../components/Forms/EasyFormColumn';
import { EasyFormSideBySide } from '../../../components/Forms/FormLayout';
import { AutocompleteFieldRender } from '../../../components/Forms/Renders/AutocompleteFieldRender';
import { CreateServiceRequest } from '../../../models/Service';
import { UserResponse } from '../../../models/User';
import { AbilityContext } from '../../UserApi/logic/Can';
import { useLazyGetAllUsersByOrganisationQuery } from '../../UserApi/redux/userApiSlice';
import * as Yup from 'yup';
import { authorizationApiSlice } from '../../AuthorisationApi/redux/authroisationApiSlice';
import { UserBadge } from '../../../components/Badges/UserBadge';
import { GetServiceApprovers } from '../Logic/getServiceApprovers';

export function getApproverValidationScheme(_t: TFunction<"translation", undefined>) {
    return Yup.object().shape({

    });
}

export default function ApproversStep(props: { formikProps: FormikProps<CreateServiceRequest>, formPropsStuf: any }) {
    const ability = useContext(AbilityContext)
    const [availableUsers, setAvailableUsers] = useState<UserResponse[]>([])

    const lazyGetAllUsersByOrganisation = useLazyGetAllUsersByOrganisationQuery();

    const lazyGetAllUsersByOrganisationTrigger = lazyGetAllUsersByOrganisation[0];

    const lazyGetAuthorisationsByOrganisation = authorizationApiSlice.useLazyGetAuthorizationsForOrganisationQuery();
    const lazyGetAuthorisationsByOrganisationTrigger = lazyGetAuthorisationsByOrganisation[0];

    useMemo(() => {
        const hostingSite = props.formikProps.values.hosting_site
        GetServiceApprovers(ability, hostingSite, lazyGetAllUsersByOrganisationTrigger, lazyGetAuthorisationsByOrganisationTrigger)
            .then(result => setAvailableUsers(result))
    }, [props.formikProps.values.hosting_site])

    return (
        <EasyFormSideBySide
            key={JSON.stringify(availableUsers)}
            validateOnMount
            {...props.formikProps}
            {...props.formPropsStuf}
            columns={[
                new EasyFormColumn("Approvers", "If no approvers are selected, the administrators for the service will be selected as default.", [
                    new AutocompleteFieldRender({
                        displayText: "New approver",
                        fieldName: "approvers",
                        multipleSelect: true,
                        getOptionLabelFunc: (option: UserResponse) => option.email ?? "",
                        renderOption: (_props, option) => <UserBadge asText ressource={option} />,
                        options: availableUsers,
                        uniqueId: "service_approver.uuid!",
                    })
                ])
            ]}
            editMode={true} />
    )
}
