import { AnyAbility } from "@casl/ability"
import defineAbility from "../feature/UserApi/config/ability"
import UserRoleResponse from "../feature/UserApi/UserRoleResponse"
import OrganisationResponse from "./Organisation"
import { UserBase, UserAuthorisationResponse, Role } from "./User"

export class AuthUser extends UserBase {

    email?: string
    role?: Role
    first_name!: string
    last_name!: string
    keycloak_uuid?: string
    organisation?: OrganisationResponse
    permissions?: UserRoleResponse
    authorisations?: UserAuthorisationResponse[]

    allowEmptyOrganisation(): boolean {
        return this.role != undefined && [Role.ADMIN, Role.READ_ONLY].includes(this.role)
    }
    getAbility(): AnyAbility {
        return defineAbility(this);
    }
}

