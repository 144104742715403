import { AddBox } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import * as Yup from 'yup'
import { CreateInCollectionButton } from "../../components/CreateInCollectionButton"
import { FieldEditorRender } from "../../components/Forms/Renders/FieldEditorRender"
import { TextFieldRender } from "../../components/Forms/Renders/TextFieldRender"
import { ContactRequest } from "../../models/Contact"
import { useAddContactMutation } from "./redux/contactsApiSlice"

export interface CreateExternalContactButtonProps {
    ownerOrganisationUuid: string
    open: boolean
    afterSaveAction?: (response: unknown) => void
    onClose?: () => void
    initialValue: string
}

export const CreateExternalContactButton = (props: CreateExternalContactButtonProps) => {
    const { t } = useTranslation()


    const initialContact = new ContactRequest();
    initialContact.email = props.initialValue;

    return <CreateInCollectionButton
        key="createContactBox"
        afterSaveAction={(response) => {
            if (props.afterSaveAction) {
                props.afterSaveAction(response)
            }
        }
        }
        buttonJsxOverride={<></>}
        icon={AddBox}
        open={props.open}
        validationScheme={Yup.object().shape({
            email: Yup.string().required(t("Required")).email()
        })}
        ressourceOwnerId={props.ownerOrganisationUuid}
        fieldEditorRenders={getFunctionalContactRowsCreate([initialContact]).find(() => true)!}
        tooltip="Create contact"
        createNew={() => initialContact}
        saveMutation={useAddContactMutation}
        onClose={props.onClose} />
}


const getFunctionalContactRowsCreate = (contacts: ContactRequest[] | undefined) => {
    const rows: Array<FieldEditorRender[]> = []
    contacts?.forEach((contact) => {
        const newRow: FieldEditorRender[] = [
            new TextFieldRender({
                displayText: "First name",
                fieldName: "first_name",
                nestedfieldName: "first_name",
                type: "text",
                uniqueId: contact.uuid!
            }),
            new TextFieldRender({
                displayText: "Last name",
                fieldName: "last_name",
                nestedfieldName: "last_name",
                type: "text",
                uniqueId: contact.uuid!
            }),
            new TextFieldRender({
                displayText: "Email",
                fieldName: "email",
                nestedfieldName: "email",
                type: "text",
                uniqueId: contact.uuid!
            })
        ]

        rows.push(newRow)
    })
    return rows;
}