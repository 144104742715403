import { useContext } from "react";
import * as Yup from 'yup';
import { HostingsiteResponse } from "../../../../models/Hostingsite";
import { AbilityContext } from "../../../UserApi/logic/Can";
import { PermissionEnum } from "../../../Permission/PermissionEnum";
import EasyFormMany from "../../../../components/Forms/EasyFormMany";
import { CreateInCollectionButton } from "../../../../components/CreateInCollectionButton";
import EasyFormColumn from "../../../../components/Forms/EasyFormColumn";
import { DNSSuffix } from "../../../../models/DNSSuffix";
import { FieldEditorRender } from "../../../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender } from "../../../../components/Forms/Renders/TextFieldRender";
import { useAddDnsSuffixForceMutation, useAddDnsSuffixMutation, useDeleteDnsSuffixMutation, useGetDnsSuffixsByHostingSiteIdQuery, useUpdateDnsSuffixMutation, useUpdateDnsSuffixForceMutation } from "../../../OrganisationApi/redux/dnsSuffixApiSlice";
import ErrorCodeHook from "../../../../components/DTO/ErrorCodeHook";
import { ErrorCodeEnum } from "../../../../models/API/ErrorCodeEnum";

interface DNSTabProps {
    hostingSite: HostingsiteResponse
}

export default function DNSTab(props: DNSTabProps) {

    const SignupSchema = Yup.object().shape({
        dns_suffix: Yup.string().required()
    });

    const ability = useContext(AbilityContext)
    const getDNSRows = (resultData: DNSSuffix[] | undefined) => {
        const rows: Array<FieldEditorRender[]> = []
        resultData?.forEach((dnsSuffix) => {
            const newRow: FieldEditorRender[] = [
                new TextFieldRender({ displayText: "DNS-suffix", fieldName: "", nestedfieldName: "dns_suffix", type: "text", uniqueId: dnsSuffix?.uuid ?? "no_id_yet" }),
            ]

            rows.push(newRow)
        })
        return rows;
    }

    const errorToAddHook: ErrorCodeHook<DNSSuffix>[] = [];
    errorToAddHook.push(new ErrorCodeHook<DNSSuffix>(ErrorCodeEnum.OPERATION_REQUIRES_FORCE, "DNS suffix is already in use at another hosting site", "Create DNS suffix anyways?", useAddDnsSuffixForceMutation));

    const errorToUpdateHook: ErrorCodeHook<DNSSuffix>[] = [];
    errorToUpdateHook.push(new ErrorCodeHook<DNSSuffix>(ErrorCodeEnum.OPERATION_REQUIRES_FORCE, "DNS suffix is already in use at another hosting site", "Update DNS suffix anyways?", useUpdateDnsSuffixForceMutation));

    return (
        <EasyFormMany
            confirmDeletionText="Are you sure you wish to delete this dns suffix?"
            isAllowedToDelete={() => ability.can(PermissionEnum.HOSTING_SITE_WRITE, props.hostingSite)}
            isAllowedToEdit={() => ability.can(PermissionEnum.HOSTING_SITE_WRITE, props.hostingSite)}
            isAllowedToCreate={() => ability.can(PermissionEnum.HOSTING_SITE_WRITE, props.hostingSite)}
            ressourceOwnerId={props.hostingSite.uuid}
            arrayPath=""
            getManyByIdAction={useGetDnsSuffixsByHostingSiteIdQuery}
            saveMutation={useUpdateDnsSuffixMutation}
            deleteMutation={useDeleteDnsSuffixMutation}
            createButtonJsx={
                <CreateInCollectionButton
                    errorToMutation={errorToAddHook}
                    validationScheme={SignupSchema}
                    ressourceOwnerId={props.hostingSite.uuid}
                    fieldEditorRenders={getDNSRows([new DNSSuffix()]).find(() => true)!}
                    tooltip="Create dns"
                    createNew={() => new DNSSuffix()}
                    saveMutation={useAddDnsSuffixMutation} />
            }
            editButtonJsx={(dnsSuffix: DNSSuffix) =>
                <CreateInCollectionButton
                    errorToMutation={errorToUpdateHook}
                    validationScheme={SignupSchema}
                    toEdit={dnsSuffix}
                    createNew={() => { throw new Error("Dont create new here") }}
                    ressourceOwnerId={props.hostingSite.uuid}
                    fieldEditorRenders={getDNSRows([new DNSSuffix()]).find(() => true)!}
                    tooltip="Edit dns"
                    saveMutation={useUpdateDnsSuffixMutation} />
            }

            SignupSchema={SignupSchema}
            idToFetch={props.hostingSite.uuid}
            columns={[
                new EasyFormColumn("DNS", "", [])
            ]}
            getFieldRows={getDNSRows}
            newButtonTooltip="Add new dns entry"
        />
    )
}