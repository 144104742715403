import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Error } from "../models/API/Error";

interface ToastErrorProps {
    error: Error
}
export default function ToastError(props: ToastErrorProps) {
    const { t } = useTranslation()

    return (
        <Stack>
            <Typography variant="body2">
                {t(props.error.message)}
            </Typography>
        </Stack>)
}