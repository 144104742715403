import BaseModel from "./BaseModels/BaseModel"
import { IpStatus } from "./enums/IpStatus"

export default class IpInfo extends BaseModel {
    ipaddress?: string
    scope?: string
    hosting_site?: string
    sdn_v4_id?: string
    organisation?: string
    service_names?: string[]
    client_names?: string[]

    get status(): IpStatus {
        const is_client_absent = this.client_names == null || this.client_names.length == 0
        const is_service_absent = this.service_names == null || this.service_names.length == 0

        if (this.scope == null) {
            return IpStatus.AVAILABLE
        }
        else if (is_client_absent && is_service_absent) {
            return IpStatus.ASSIGNED
        }
        return IpStatus.IN_USE;
    }
}