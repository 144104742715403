import { Button, Chip, Menu, Stack, Typography } from "@mui/material"
import { useContext, useState } from "react";
import { UserContext } from "../../feature/UserApi/logic/FetchUser";
import { Role } from "../../models/User";
import { AdminPanelSettings, Engineering, PersonPin, ManageAccounts, PersonSearch, PersonOutline, People, PeopleOutline } from "@mui/icons-material";
import { AuthorisationContext, AuthorisationSelector } from "./AuthorisationSelector";
import { useTranslation } from "react-i18next";

export function getRoleIcon(role: Role): JSX.Element {
    if (role == Role.ADMIN)
        return <Engineering />
    if (role == Role.ORG_SUPER)
        return <AdminPanelSettings />
    if (role == Role.ORG_ADMIN)
        return <ManageAccounts />
    if (role == Role.ORG_READ_ONLY)
        return <PersonSearch />
    if (role == Role.ORG_ACCESS_PROSPECT) 
        return <PersonOutline />
    if (role == Role.READ_ONLY)
        return <PersonPin />
    if (role == Role.ORG_USER_READ_ONLY)
        return <PeopleOutline />
    if (role == Role.ORG_USER_ADMIN)
        return <People />

    return <></>
}

export const AuthorisationMenu = () => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { t } = useTranslation();
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const initialUserLoggedIn = useContext(UserContext);
    const authorisationContext = useContext(AuthorisationContext);
    return (
        <>

            <Button
                style={{ textTransform: "inherit" }}
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <Stack direction="row" spacing={2} alignItems="center" textAlign="left">
                    {authorisationContext?.userAuthorisationInUse ?
                        <>
                            <Chip color="error" label={authorisationContext?.userAuthorisationInUse?.organisation_name} />
                            {authorisationContext?.userAuthorisationInUse?.role ? getRoleIcon(authorisationContext?.userAuthorisationInUse?.role) : <></>}
                        </>
                        : <>
                            {getRoleIcon(initialUserLoggedIn!.role!)}
                        </>}

                </Stack>
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Stack width={400} spacing={2} padding={4}>
                    <Typography>{t("Authorisation")}</Typography>
                    <Typography variant="caption">{t("Select the authorisation you want to use")}</Typography>
                    <AuthorisationSelector />
                </Stack>
            </Menu>

        </>
    )
}