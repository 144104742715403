import { ButtonGroup, Grid, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import { CreatePortaliasButton } from "../feature/PortaliasApi/CreatePortaliasButton"
import { PortaliasList } from "../feature/PortaliasApi/portaliasList"

export const PortAliasesPage = () => {
    const { t } = useTranslation()

    return (

        <Grid container spacing={5}>
            <Grid item xs={6}>
                <Stack direction="row">
                    <Typography variant="h5">{t("Portaliases") + ""}</Typography>
                    <ButtonGroup variant="text">
                        <CreatePortaliasButton />
                    </ButtonGroup>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <PortaliasList />
            </Grid>
        </Grid>

    )
}