import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, MutationDefinition } from "@reduxjs/toolkit/dist/query"
import { UseMutation } from "@reduxjs/toolkit/dist/query/react/buildHooks"
import { RequestMutationWrapper } from "../../models/API/RequestMutationWrapper"

export default class ErrorCodeHook<T>{
    errorCode: number
    message: string
    confirmMessage: string
    hook: UseMutation<MutationDefinition<RequestMutationWrapper<T>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, T, any>>

    constructor(errorCode: number, message: string, confirmMessage: string, hook: UseMutation<any>) {
        this.errorCode = errorCode;
        this.message = message;
        this.confirmMessage = confirmMessage;
        this.hook = hook;
    }
}