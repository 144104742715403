import { ShareLocation } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { PropagationEnum } from "../../models/enums/PropagationEnum";
import { BadgeProps, BaseBadge } from "./BaseBadge";

export function PropagationBadge(props: BadgeProps<PropagationEnum>) {
    const { t } = useTranslation()

    const content = props.textOverride ?? props?.ressource;
    return <BaseBadge {...props} content={<>{t(content + "")}</>} icon={ShareLocation} />
}