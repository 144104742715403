import FieldRender, { FieldRenderPackage } from "./FieldRender"
import { FieldRenderType } from "./FieldRenderType"

export class FieldEditorRenderPackage extends FieldRenderPackage {
    fieldName!: string
    nestedfieldName?: string
    minWidth?: number | string
    showLabel?: boolean = true
    disabled?: boolean = false;
}

export abstract class FieldEditorRender extends FieldRender {
    fieldName: string
    nestedfieldName?: string
    minWidth?: number | string
    showLabel?: boolean = false
    disabled?: boolean = false;

    constructor(renderType: FieldRenderType, pack: FieldEditorRenderPackage) {
        super(renderType, pack)
        this.fieldName = pack.fieldName
        this.nestedfieldName = pack.nestedfieldName
        this.showLabel = pack.showLabel
        this.minWidth = pack.minWidth
        this.disabled = pack.disabled
    }

    getActualFieldname(index?: number) {
        if (index == undefined)
            return this.fieldName
        return this.nestedfieldName != undefined ? this.fieldName + "[" + index + "]." + this.nestedfieldName : this.fieldName
    }

    getActualConditionalFieldname(conditionalFieldName?: string, index?: number) {
        if (index == undefined)
            return conditionalFieldName
        return this.nestedfieldName != undefined ? this.fieldName + "[" + index + "]." + conditionalFieldName : conditionalFieldName
    }
}