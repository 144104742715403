import { ButtonGroup, Grid, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ContactlabelList } from "../feature/ContactsApi/contactlabelList"
import { CreateContactlabelButton } from "../feature/ContactsApi/CreateContactlabelButton"

export const ContactLabelPage = () => {
    const { t } = useTranslation()

    return (

        <Grid container spacing={5}>
            <Grid item xs={6}>
                <Stack direction="row">
                    <Typography variant="h5">{t("Contactlabels") + ""}</Typography>
                    <ButtonGroup variant="text">
                        <CreateContactlabelButton />
                    </ButtonGroup>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <ContactlabelList />
            </Grid>
        </Grid>

    )
}