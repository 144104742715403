export enum ServiceCategoryEnum {
    EMERGENCY = "EMERGENCY",
    JOURNAL = "JOURNAL",
    LPS = "LPS",
    LAB = "LAB",
    IDP = "IDP",
    RIS_PACS = "RIS_PACS",
    REPORT = "REPORT",
    VIDEO = "VIDEO",
    INFRASTRUCTURE = "INFRASTRUCTURE",
    PLATFORM = "PLATFORM",
    SPECIALTIES = "SPECIALTIES",
    PHARMACY = "PHARMACY",
    SHARED = "SHARED",
    TRANSPORT = "TRANSPORT",
    RESEARCH = "RESEARCH",
}