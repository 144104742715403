import { FieldEditorRender, FieldEditorRenderPackage } from "./FieldEditorRender";
import { FieldRenderType } from "./FieldRenderType";

export class TextRendererPackage extends FieldEditorRenderPackage {
    extraText?: TextRenderer
    suffixText?: string
    render?: (content: unknown) => JSX.Element
}

export class TextRenderer extends FieldEditorRender {
    extraText?: TextRenderer
    suffixText?: string
    render?: (content: unknown) => JSX.Element

    constructor(textRendererPackage: TextRendererPackage) {
        super(FieldRenderType.TextOnly, textRendererPackage);
        this.extraText = textRendererPackage.extraText;
        this.suffixText = textRendererPackage.suffixText
        this.render = textRendererPackage.render;
    }
}